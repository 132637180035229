import objectToFormData from "object-to-formdata";
import { Call, ApiUrl, PageParams, ParseGeneralResponse, optionalPageParams } from "./ApiUtils";
import AppConfig from "../config/AppConfig";

export function fetchOrganizations(page = 1, perPage = 10000) {
  perPage = perPage ? perPage : 10000
  const url = ApiUrl(
    `v2/organization?${optionalPageParams(page, perPage,false)}&operation_code=ORGI`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { organizations: "", pagination: "meta.pagination" },
    },
    false
  );
}

export function saveOrganization(isNew, data) {
  if (
    data.phone_number &&
    data.phone_number.length > 0 &&
    data.phone_number[0] !== "+"
  ) {
    data.phone_number = `+${data.phone_number}`;
  }
  const formData = objectToFormData(data);
  const url = isNew
    ? ApiUrl("v2/organization")
    : ApiUrl(`v2/organization/${data.id}`);
  const method = isNew ? "post" : "put";
  formData.append("operation_code", isNew ? "ORGC" : "ORGU");
  return Call(
    method,
    url,
    formData,
    ParseGeneralResponse,
    { responseDataKeys: { organization: "" } },
    false
  );
}

export function fetchOrganizationDetails(orgId) {
  const url = ApiUrl(`v2/organization/${orgId}?operation_code=ORGS`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { organization: "organization" },
  });
}

export function fetchUserOrgs(passOrg = true , user_id) {
  const url = ApiUrl(
    `v2/organization?user_id=${user_id}&operation_code=ORGI`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        organizations: "organizations",
        pagination: "meta.pagination",
      },
    },
    passOrg
  );
}

export function deleteOrganization(id) {
  const url = ApiUrl(`v2/organization/${id}?operation_code=ORGD`);
  return Call("delete", url, {}, ParseGeneralResponse);
}

export function setOrganizationSetting(data, orgId) {
  const { setting_name , setting_value } = data;
  const url = ApiUrl(`v2/organization/${orgId}/update_setting`);
  const formData = objectToFormData({ setting_name, setting_value, operation_code: "ORGSET" }); 
  return Call("put", url, formData, ParseGeneralResponse);
}

export function setOrganizationConfig(data, orgId) {
  data.operation_code="ORGSET";
  const url = ApiUrl(`v2/organization/${orgId}/configuration`);
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { org_config: "" },
  });
}

export function fetchOrganizationConfigs(orgId , passOrg=true) {
  const url = ApiUrl(`v2/organization/${orgId}/configuration_details?operation_code=ORGCD`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { org_configurations: "org_configurations" },
  } , passOrg);
}

export function fetchOrgDeliveryZipcodes(orgId) {
  const url = ApiUrl(`v2/delivery_zip?org_id=${orgId}&operation_code=ORGDZ`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { dow_zipcodes: "dow_zip_codes" },
  });
}

export function fetchCreditLimitExceededAccounts(orgId,wh_location_id) {
  const url = ApiUrl(`v2/credit_limit/exceeded_accounts?organization_id=${orgId}&org_id=${orgId}&wh_location_id=${wh_location_id}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { accounts: "accounts" },
  });
}

export function fetchOrgActivityLogs(orgId, page = 1, perPage = 20,reference="ALL", usersList = "ALL", fromDate, toDate, searchText) {
  const url = ApiUrl(`v2/organization/organization_activities_list?organization_id=${orgId}&page=${page}&per_page=${perPage}&reference=${reference}&user_name=${usersList}&from_date=${fromDate}&to_date=${toDate}&description=${searchText}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "", pagination: "meta.pagination" },
  });
}