import React, { useEffect, useState } from "react";
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import {
  Col,
  FormItem,
  Input,
  Row,
  Select,
  Radio,
} from "../../../common/UIComponents";
import {
  alertMessage,
  doValidate,
  formatePhoneNo,
} from "../../../common/Common";
import FormButtons from "../../common/FormButtons";
import MobileInput from "../../common/MobileInput";

import _, { isEmpty } from "lodash";
import { useForm } from "./useForm";
import FormErrors from "../../common/FormErrors";
import { saveContact } from "../../../api/Contacts";
import { fetchContactGroup } from "../../../api/ContactGroup";
import userStore from "../../../stores/UserStore";
import I18n from "../../../common/I18n";

const PHONE_REGEX = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;
// /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/;
function Form({
  initialValues,
  onSave = () => {},
  onCancel = () => {},
  isOrgLevel,
  accountId = '',
}) {
  const [roles, setRoles] = useState([]);
  const [backendErrors, setBackendErrors] = useState([]);

  const [values, errors, register, validate, setErrors] = useForm({
    first_name: {
      required: true,
      error: I18n.t("configurations.contact.errors.first_name"),
    },
    last_name: {
      required: false,
      error: I18n.t("configurations.contact.errors.last_name"),
    },
    email: {
      initialValue: "",
      required: false,
      //onValidate: (value) => EMAIL_REGEX.test(value),
      error: "Email is invalid",
      excludeErrorWhen: (value) => value.trim() === "",
      defaultError: I18n.t("configurations.contact.errors.email"),
    },
    // phone: {
    //   //required: ["TEXT", "BOTH"].includes(alert_preference),
    //   initialValue: "",
    //   required: true,
    //   onValidate: (value) => {
    //     if (!isEmpty(value)) {
    //       let m_no;
    //       if (value.startsWith("+1") || value.startsWith("+91")) {
    //         m_no = value.replace(/^\+[0-9]{1,3}(\s|\-)/, "");
    //         //m_no = value.replaceAll(" ", "").slice(-10);
    //       } else {
    //         //const mobileNo = value;
    //         value = m_no.replace(/[^0-9]/g, "");
    //       }
    //       return PHONE_REGEX.test(m_no);
    //     }
    //     return false;
    //   },
    //   error: "Phone is not valid",
    //   excludeErrorWhen: (value) => value.trim() === "",
    //   defaultError: I18n.t("configurations.contact.errors.mobile.primary"),
    // },
    phone: {
      initialValue: "",
      required: false,
      error: " Primary Mobile Number is invalid",
      excludeErrorWhen: (value) => value.trim() === "",
      defaultError: I18n.t("configurations.contact.errors.mobile.primary"),
    },
    phone_2: {
      initialValue: "",
      required: false,
      onValidate: (value) => {
        if (!isEmpty(value)) {
          if (value === "+") {
            value = "";
          } else if (value.match(/\d/g).length <= 11) {
            if (
              value.match(/\d/g).slice(0, 1)[0] !== "1" ||
              value.match(/\d/g).length === 10
            ) {
              return false;
            }
            const mobileNo = value;
            value = mobileNo.replace(/[^0-9]/g, "");
          } else {
            const mobileNo = value;
            value = mobileNo.replace(/[^0-9]/g, "");
          }
          return PHONE_REGEX.test(value);
        }
        return false;
      },

      error: " Secondary Mobile Number is invalid",
      //excludeErrorWhen: (value) => value.trim() === "",
      excludeErrorWhen: (value) => value === "",
      // defaultError: I18n.t("configurations.contact.errors.mobile.secondary"),
    },
    designation: {
      required: false,
      error: I18n.t("configurations.contact.errors.designation"),
    },
    alert_preference: {
      initialValue: "TEXT",
      required: true,
      error: "Alert prefrence required",
    },
   contacts_group_ids : {
     required: false,
     error: "Contact group required"
   },
  });

  const [formLoading, setFormLoading] = useState(false);
  const RadioGroup = Radio.Group;
  // component did mount
  useEffect(() => {
    if (initialValues) {
      register("id")(initialValues.id);
      register("first_name")(initialValues.first_name);
      register("last_name")(initialValues.last_name);
      register("email")(initialValues.email);
      register("phone")(initialValues.phone);
      register("phone_2")(initialValues.phone_2);
      // register("grp_id")(initialValues.grp_id);
      register("designation")(initialValues.designation);
      register("alert_preference")(initialValues.alert_preference);
      register("contacts_group_ids")(initialValues.contacts_group_ids);
    }
    getContactGroups();
    
  }, []);
  const handleSave = async () => {
    setBackendErrors([]);
    const isValid = validate();
    let levelId = "";
    let level = "";

    if (accountId || localStorage.getItem("accountId")) {
      levelId = accountId ? accountId : localStorage.getItem("accountId");
      level = "ACCOUNT";
    } else {
      levelId = userStore.getStateValue("selectedOrg");
      level = "ORGANIZATION";
    }

    if (!isValid) return;

    setFormLoading(true);
    try {
      const payload = {
        ...values,
        phone: formatePhoneNo(values.phone),
        phone_2: formatePhoneNo(values.phone_2),
      };

      const response = await saveContact(payload, isOrgLevel, accountId);
      if (response.errors) {
        setBackendErrors(Array.isArray(response.errors) ? response.errors : [response.errors]);
        return;
      }
      typeof onSave === "function" && onSave("Contact saved successfully", response.contact);
    } catch (error) {
      const errorMessages = error?.response?.data?.errors || ["Failed to save contact"];
      setBackendErrors(Array.isArray(errorMessages) ? errorMessages : [errorMessages]);
    } finally {
      setFormLoading(false);
    }
  };

  const registerInput = ({ target: { value } }, label) => {
    register(label)(value);
  };

  const getContactGroups = async () => {
    try {
      // const response = await fetchContactGroup(1, null);
      const response = await fetchContactGroup(1, null,"","",accountId);
      if (response.success) {
        const departments = response.contacts_groups || [];
        setRoles(departments);
      } else {
        throw new Error(response.errors[0]);
      }
    } catch (error) {
      alertMessage(error.message, "error", 10);
    } 
  }

  return (
    <div>
      <Row gutter={16}>
        <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem label={I18n.t("configurations.contact.firstName")} require>
            <Input
              value={values.first_name}
              onChange={(e) => registerInput(e, "first_name")}
            />
          </FormItem>
        </Col>
        <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem label={I18n.t("configurations.contact.lastName")}>
            <Input
              value={values.last_name}
              onChange={(e) => registerInput(e, "last_name")}
            />
          </FormItem>
        </Col>
        <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem
            label={I18n.t("configurations.contact.phone.primary")}
            require={(isEmpty(values.alert_preference)  || ['TEXT', 'BOTH'].includes(values.alert_preference))}
          >
            <MobileInput
              handleInputChange={(value) => register("phone")(value)}
              value={values.phone}
            />
          </FormItem>
        </Col>
        <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem label={I18n.t("configurations.contact.phone.secondary")}>
            <MobileInput
              handleInputChange={(value) => register("phone_2")(value)}
              value={values.phone_2}
            />
          </FormItem>
        </Col>
        <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem 
          label={I18n.t("configurations.contact.email")} 
          require={(isEmpty(values.alert_preference)  || ['EMAIL', 'BOTH'].includes(values.alert_preference))}>
            <Input
              value={values.email}
              onChange={(e) => registerInput(e, "email")}
            />
          </FormItem>
        </Col>
        <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem label={"Designation"}>
            <Input
              value={values.designation}
              onChange={(e) => registerInput(e, "designation")}
            />
          </FormItem>
        </Col>
        {/* <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem
            label={I18n.t("configurations.contact.contact_group")}
            require
          >
            <Select
              value={values.grp_id || undefined}
              showArrow
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={"Select Contact Group"}
              onChange={(value) => register("grp_id")(value)}
              mode="multiple"
            >
              {roles.map(({ id, name }) => (
                <Select.Option value={id} key={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col> */}

        <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem label={I18n.t("general.communication_preference")}>
            <RadioGroup
              onChange={(e) => register("alert_preference")(e.target.value)}
              value={values.alert_preference}
              className="marginTop10"
            >
              <Radio value={"TEXT"} name="opt1">
                {I18n.t("contactGroup.sms_only")}
              </Radio>
              <Radio value={"EMAIL"} name="opt1">
                {I18n.t("contactGroup.email_only")}
              </Radio>
              <Radio value={"BOTH"} name="opt1">
                {I18n.t("contactGroup.both")}
              </Radio>
            </RadioGroup>
          </FormItem>
        </Col>
        <Col sm={12} xs={24} md={12} lg={12}>
          <FormItem label="Contact Groups">
              <Select
              mode="multiple"
              value={Object.keys(values).length ? (values?.contacts_group_ids && values.contacts_group_ids.length > 0 ? values.contacts_group_ids : []) : []}
              showSearch
              allowClear
              style={{width:"100%"}}
              placeholder="Select"
              onChange={(e) => register("contacts_group_ids")(e)}>
              {roles.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
              </Select>
          </FormItem>
        </Col> 
      </Row>

      <Row>{FormErrors([...new Set(Object.values(errors))])}</Row>
      {FormErrors(backendErrors)}
      <Row>
        <Col xs={24}>{FormButtons(formLoading, handleSave, onCancel)}</Col>
      </Row>
    </div>
  );
}

export default Form;