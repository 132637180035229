import React, { useContext, useEffect, useState } from "react";
import I18n from "../../common/I18n";
import {
  Col,
  Row,
  Icon,
  Popover,
  Button,
  Tooltip,
  Modal,
  Spin,
} from "../../common/UIComponents";
import suggesttime_logo from "../../../assets/images/suggest_time.svg";
import truck_delivery from "../../../assets/images/driver.png";
import actual_time_logo from '../../../assets/images/on-time.svg';
import Text from "antd/lib/typography/Text";
import { isEmpty, set } from "lodash";
import { OrgContext } from "../../context/OrgContext";
import AppConfig from "../../config/AppConfig";
import EditIcon from "../common/EditIcon";
import DateTimeSelector from "../../common/DateTimeSelector";
import moment from "moment";
import {
  EditArrivalAndDepartureTime,
  fetchOrderDetails,
} from "../../api/OrdersApi";
import { alertMessage } from "../../common/Common";
import { momentTime } from "../../helpers/date_functions";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";

const OrderScheduleDate = (props) => {
  const { order, handleOrderDetailsOnUpdate, locationDetails, showActions = true } = props;
  const dataObject = locationDetails ? {
    ...locationDetails,
    actual_end_datetime: locationDetails?.actual_end_datetime_wt_tz ||  locationDetails.actual_end_datetime,
    actual_start_datetime: locationDetails?.actual_start_datetime_wt_tz ||  locationDetails.actual_start_datetime,
  } : order
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const [isMilitaryTime, setIsMilitaryTime] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [arrivalStartDate, setArrivalStartDate] = useState(
    dataObject.actual_start_datetime ? dataObject.actual_start_datetime : ""
  );
  const [arrivalStartTime, setArrivalStartTime] = useState(
    dataObject.actual_start_datetime ? dataObject.actual_start_datetime : ""
  );
  const [arrivalEndDate, setArrivalEndDate] = useState(
    dataObject.actual_end_datetime ? dataObject.actual_end_datetime : ""
  );
  const [arrivalEndTime, setArrivalEndTime] = useState(
    dataObject.actual_end_datetime ? dataObject.actual_end_datetime : ""
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      organizationSettings &&
      organizationSettings.is_military_time === "true"
    ) {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [organizationSettings]);

  const serviceDuration = !isEmpty(dataObject.service_duration)
    ? `${dataObject.service_duration} ${I18n.t("general.min_short_cut")}`
    : "NA";
  // const ReceivedDate = !isEmpty(order.received_at)
  //   ? `${order.received_at} ${I18n.t("general.min_short_cut")}`
  //   : "NA";
  /*const formatTimeStamp = (startTime, endTime, formatString) => {
    let startString = ''
    let endString = ''
    if (isEmpty(order[startTime]) && isEmpty(order[endTime])) {
      return 'NA'
    }
    if (!isEmpty(order[startTime])) {
      startString = moment(order[startTime]).format(formatString)
    }
    if (!isEmpty(order[endTime])) {
      endString = moment(order[endTime]).format(formatString)
    }
    if (!isEmpty(order[startTime]) && isEmpty(order[endTime])) {
      endString = startString;
      startString = 'Arr. at';
    }
    if (!isEmpty(order[endTime]) && isEmpty(order[startTime])) {
      startString = 'Dept. at';
    } 
    return `${startString} - ${endString} ${order.order_tz_short_name}`
  }*/

  const handleDateChange = (field, value) => {
    if (field === "arr_date") {
      setArrivalStartDate(value);
      if (!moment.isMoment(arrivalStartTime)) {
        setArrivalStartTime(moment());
      }
    } else if (field === "dep_date") {
      setArrivalEndDate(value);
      if (!moment.isMoment(arrivalEndDate)) {
        setArrivalEndTime(moment());
      }
    } else if (field === "arr_time") {
      setArrivalStartTime(value);
      if (!moment.isMoment(arrivalStartDate)) {
        setArrivalStartDate(moment());
      }
    } else if (field === "dep_time") {
      setArrivalEndTime(value);
      if (!moment.isMoment(arrivalEndDate)) {
        setArrivalEndDate(moment());
      }
    }
  };

  const handleSubmit = async () => {
    if (!arrivalStartDate || !arrivalStartTime || !arrivalEndDate || !arrivalEndTime) {
      alertMessage("Please fill in all fields", "error", 10);
      return;
    }
    setIsLoading(true)
    const payload = {
      order_id: order.order_id,
      actual_start_datetime: `${arrivalStartDate ? moment(arrivalStartDate).format("DD-MM-YYYY") : ""
        } ${arrivalStartTime ? moment(arrivalStartTime).format("HH:mm") : ""}`,
      actual_end_datetime: `${arrivalEndDate ? moment(arrivalEndDate).format("DD-MM-YYYY") : ""
        } ${arrivalEndTime ? moment(arrivalEndTime).format("HH:mm") : ""}`,
      location_id: locationDetails?.id ? locationDetails.id : '',
    };
    try {
      const res = await EditArrivalAndDepartureTime(payload);
      if (res.success) {
        alertMessage(res.message, "success", 10);
        setShowEditModal(false);
        const updatedList = await handleOrderDetailsOnUpdate();
      } else {
        renderAlertMessage(res.errors)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatTimeStamp = (startTime, endTime, formatString) => {

    const isEmptyValue = (value) => _.isEmpty(value) || _.isNil(value);
    const formatDate = (date, format) => (date ? moment(date).format(format) : "");

    if (isEmptyValue(dataObject[ startTime ]) && isEmptyValue(dataObject[ endTime ])) {
      return "NA";
    }

    const scheduleDate = formatDate(dataObject.scheduled_end_datetime, "YYYY-MM-DD");
    const skipDateCheck = false//isEmptyValue(scheduleDate);

    let startString = isEmptyValue(dataObject[ startTime ]) ? "Dept. at" : "Arr. at";
    let endString = startString;

    if (!isEmptyValue(dataObject[ startTime ])) {
      const startDate = formatDate(dataObject[ startTime ], "YYYY-MM-DD");
      startString = moment(dataObject[ startTime ]).format(
        !skipDateCheck ? `${AppConfig.dateFormat} ${formatString}` : formatString
      );
    }

    if (!isEmptyValue(dataObject[ endTime ])) {
      const endDate = formatDate(dataObject[ endTime ], "YYYY-MM-DD");
      endString = moment(dataObject[ endTime ]).format(
        !skipDateCheck ? `${AppConfig.dateFormat} ${formatString}` : formatString
      );
    }

    if(!isEmptyValue(dataObject[ startTime ]) && isEmptyValue(dataObject[ endTime ])) {
      endString = startString;
      startString = "Arr. at";
    }

    if(!isEmptyValue(dataObject[ endTime ]) && isEmptyValue(dataObject[ startTime ])) {
      startString = "Dept. at";
    }

    const timeZoneName = order.order_tz_short_name || "";
    // check if start and end date are same
    const isSameDate = formatDate(dataObject[ startTime ], "YYYY-MM-DD") === formatDate(dataObject[ endTime ], "YYYY-MM-DD");
    const formatEndString = isSameDate ? formatDate(dataObject[ endTime ], formatString) : endString;
    return `${startString} - ${formatEndString} ${timeZoneName}`;
  };

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const renderEditModal = () => {
    return (
        <Modal
          title={`Edit Arrival & Departure ${order.customer_order_number
            ? `of #${order.customer_order_number}`
            : ""
            }`}
          visible={showEditModal}
          onCancel={() => setShowEditModal(false)}
          onOk={handleSubmit}
          bodyStyle={{ padding: 10 }}
        >
          <Spin spinning={isLoading} delay={1000}>
          <Row>
            <DateTimeSelector
              dateProps={{
                label: "Arrival Date",
                format: "Do MMM YYYY",
                value: arrivalStartDate ? moment(arrivalStartDate) : "",
                onChange: (date) => {
                  // When Arrival date is selected
                  handleDateChange("arr_date", date);
                },
                style: { width: "100%", height: "100%" },
                disabledDate: (current) =>
                  current && current >= moment().endOf("day"),
              }}
              timeProps={{
                label: "Arrival Time",
                format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                showTime: {
                  format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                  use12Hours: !isMilitaryTime,
                },
                isMilitaryTime,
                onChange: (time) => {
                  handleDateChange("arr_time", time);
                },
                value: arrivalStartTime ? moment(arrivalStartTime) : "",
                style: { width: "100%", height: "100%", marginTop: "5px" },
                minuteStep: 1,
                className: "formMaterialTimePicker",
              }}
              gridStyle={{
                row: { type: "flex", gutter: 2 },
                dateCol: { span: 12 },
                timeCol: { span: 12 },
              }}
            />
            <DateTimeSelector
              dateProps={{
                label: "Departure Date",
                format: "Do MMM YYYY",
                value: arrivalEndDate ? moment(arrivalEndDate) : "",
                onChange: (date) => {
                  // When Departure date is selected
                  handleDateChange("dep_date", date);
                },
                style: { width: "100%", height: "100%" },
                disabledDate: (current) =>
                  current && current >= moment().endOf("day"),
              }}
              timeProps={{
                label: "Departure Time",
                format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                showTime: {
                  format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                  use12Hours: !isMilitaryTime,
                },
                isMilitaryTime,
                onChange: (time) => {
                  handleDateChange("dep_time", time);
                },
                value: arrivalEndTime ? moment(arrivalEndTime) : "",
                style: { width: "100%", height: "100%", marginTop: "5px" },
                minuteStep: 1,
                className: "formMaterialTimePicker",
              }}
              gridStyle={{
                row: { type: "flex", gutter: 2 },
                dateCol: { span: 12 },
                timeCol: { span: 12 },
              }}
            />
          </Row>
          </Spin>
        </Modal>
    );
  };

  const renderTime = (
    logo,
    label,
    startTime,
    endTime,
    formatString,
    className = "",
    showEditIcon = false,
  ) => {
    return (
      <Row>
        <Col xs={3}>
          <Icon
            component={() => (
              <img style={{ height: 15, marginTop: 4 }} src={logo} />
            )}
            className="centerToBlock"
          />
        </Col>
        <Col xs={21}>
          <Row type="flex">
            <Col xs={5}>
              <Text className={`textBold ${className}`}>
                {label}
              </Text>
            </Col>
            <Col xs={1}>:</Col>
            <Col xs={16}>
              <Text
                className={`${className ? className : "details_value_grey"
                  } textBold `}
              >
                {formatTimeStamp(startTime, endTime, formatString)}
                {showActions && showEditIcon && checkServiceExistance("SPOD") && <EditIcon handleClick={handleEditClick} />}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const timeFormat = isMilitaryTime ? "HH:mm" : "h:mm A";
  return (
    <Row justify="space-around" align="middle">
      <Col className="centerToBlock">
        <div style={{ width: "100%", height: "138px", overflowY: 'auto' }}>
          <Tooltip title={I18n.t("general.scheduled_time")} placement="leftTop">
            {renderTime(
              suggesttime_logo,
              I18n.t("order.times_short_form.SC"),
              "scheduled_start_datetime",
              "scheduled_end_datetime",
              timeFormat,
              "specialText",
              false
            )}
          </Tooltip>
          {/* <Tooltip title={I18n.t("order.estimated_time")} placement="leftTop">
            {renderTime(
              optimized,
              I18n.t("order.times_short_form.ET"),
              "suggested_start_datetime",
              "suggested_end_datetime",
              timeFormat,

            )}
          </Tooltip> */}
          { props.currentOrg.code === "NETMOVE" &&
          <Tooltip title={"Window Time"} placement="leftTop">
            {renderTime(
              actual_time_logo,
              I18n.t("order.window_time_short"),
              "start_window_time",
              "end_window_time",
              timeFormat,
              "specialText",
            )}
          </Tooltip>
          }
          <Tooltip title={I18n.t("order.actual_time")} placement="leftTop">
            {renderTime(
              truck_delivery,
              I18n.t("order.times_short_form.AT"),
              "actual_start_datetime",
              "actual_end_datetime",
              timeFormat,
              true,
              "specialText"
            )}
          </Tooltip>

          {/* <Row>
            <Col xs={3}>
            <Icon
            component={() => (
              <img style={{ height: "14px", marginTop: 2 }} src={receivedAt_logo} />
              )}
                className="centerToBlock"
              />
            </Col>
            <Col xs={ 21 }>
              <Row type="flex">
                <Col xs={ 8 }>
                <Text className="textBold fontSize14">
                { I18n.t("configurations.received_at") }
                </Text>
                </Col>
                <Col xs={ 2 }>
                :
                </Col>
                <Col xs={ 14 }>
                <Text className="details_value_grey fontSize14">
                { order.received_at
                  ? moment(order.received_at).format("MMM Do YYYY h:mm A")
                  : "NA" }
                  </Text>
                  </Col>
                  </Row>
                  </Col>
                </Row> */}
        </div>
      </Col>
      {showEditModal && renderEditModal()}
    </Row>
  );
};
export default OrderScheduleDate;