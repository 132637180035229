
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import _, { result, cloneDeep } from "lodash";
import PropTypes, { element } from "prop-types";
import {
  Col,
  FormItem,
  Input,
  Select,
  Tooltip,
  Row,
  Icon,
  Spin,
  Collapse,
  Modal,
  Radio,
  Button,
  Card,
  Tabs,
  Divider,
  DatePicker,
  Checkbox,
  Popconfirm,
  Alert,
  Text,
  message,
  SideBySideFormItem,
  TreeSelect,
} from "../../common/UIComponents";
import {
  alertMessage,
  doFormate,
  isBetweenDates,
  randomUUID,
  getFileType,
  isEmpty,
  doValidate,
  validateAddressForm,
  formatePhoneNo,
  randomNumber,
  checkNegitive,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import { saveAppointments } from "../../api/AppointmentsApi";
import FormErrors from "../common/FormErrors";
import OpininForm from "./OpinionFomTable";
import { loadGoogleMaps } from "../../utils/Utils";
import AppConfig from "../../config/AppConfig";
import ItemsForm from "./ItemsFullList";
import ItemForm from "./ItemForm";
import BaseModal from "../BaseModal";
import ExceptionsList from "./ExceptionsList";
import { fetchAccountShortLos } from "../../api/Los";
import { fetchShortFormAccounts, fetchAccountConfigs } from "../../api/Account";
import { getWarehouseAccessorials } from "../../api/Accessorials";
import {
  deleteItem,
  createOrder,
  fetchOrderDetails,
  fetchAvailableDates,
  fetchOrderEstimationAmount,
  fetchOrderReferences,
  dispatcherNotesApi,
  uploadPod,
  deleteOrderPic,
  fetchOriginDestination,
  verifyBillingZone,
  privateNotesApi,
  fetchConsolidatedMawbs,
} from "../../api/OrdersApi";
import { fetchLocations } from "../../api/LocationsApi";
import Legs from "../Tasks/Legs";
import UploadGallery from "../common/UploadGallery";
import {
  getValueFromArrayOfObjects,
  sortByDate,
} from "../../helpers/array_functions";
import {
  checkEndTime,
  isOptedExpedite,
  getDisabledHoursFromStartTime,
  retrueveLocationFromOrder,
  isOrderHasLocation,
  retrieveDataObject,
  formLocationFromOrder,
  formAppointments,
  pickPickupNDelivery,
  findAppointmentErros,
} from "../../helpers/orders";
import ConsigneeDetailsForm from "./ConsigneeDetailsForm";
import userStore from "../../stores/UserStore";
import OrderAccAccessorial from "./OrderAccAccessorial";
import Invoice from "../billing/Invoice";
import {
  checkIfAppointmenfFilled,
  checkIfRequiredFieldsAreFilled,
  checkServiceExistance,
  convertToKilograms,
  convertToPounds,
  formatByTimeConfig,
  getWeightUnits,
  isObjHaveValues,
  renameKeys,
  renderAlertMessage,
  transformKeysToMoment,
  updatePicSettingsWithBol,
} from "../../helpers/common";
import OrderQuoteAmount from "./OrderQuoteAmount";
import OrderAccessorialSelect from "./OrderAccessorialSelect";
import DispatcherNotes from "./DispatcherNotes";
import OrderStatus from "./OrderStatus";
import ItemDetailsForm from "./ItemDetailForm";
import RecoveryManage from "../recoveries/RecoveryManage";
import ReleaseManage from "../releases/ReleaseManage";
import { getHMSFromTimeString } from "../../helpers/date_functions";
import ConfiguredDeliveryTime from "../common/ConfiguredDeliveryTime";
import {
  setCreatePayload,
  setEditPayload,
  setLHCreatePayload,
  validateOrder,
} from "../../helpers/order_managemnt";
import { TransferOrderApi } from "../../api/TransferOrderApi";

import DateTimeSelector from "../../common/DateTimeSelector";
import TemplateOption from "../Templates/TemplateOption";
import { TemplatesApi } from "../Templates/Api";
import {
  defaultPreferences,
  pickupLocation,
  dropLocation,
  requiredFields,
  mobileFields,
  orderRequiredFields as defaultRequiredFields,
  dispatchFields,
  defaultDropParams,
} from "../../constants/orders";
import OrderPictures from "../common/OrderPictures";
import { fetchUsers } from "../../api/UsersApi";
import FormIcon from "../common/FormIcon";
import ItemGrouping from "./ItemGrouping";
import {
  adjustTemplateAppointment,
  handleAppointmentErrors,
} from "../Templates/helpers";
import Appointments from "../common/Appointments";
import { getWhLocationSlotDetails, validateslots } from "./helpers";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import LineHaulManageComponent from "../../containers/LineHaul/LineHaulManage";
import { fetchTrucks } from "../../api/TrucksApi";
import { VehicleTypeApi } from "../../api/VehicleType";
import BranchesIcon from "../common/BranchesIcon";
import { fetchContacts } from "../../api/Contacts";
import labelInfo from "../../../assets/images/labelInfo.svg";
import OrderActivityForm from "./OrderActivityForm";
import SubORdersForLh from "./SubORdersForLh";
import AccWhAccessorial from "./AccWhAccessorial";
import ContactList from "../configurations/Contacts/ContactList";
import AddContactByButton from "../configurations/Contacts/AddContactByButton";
import LabelInfo from "../configurations/LabelInfo";
import EndUserTitle from "../common/EndUserTitle";
import MiscData from "./MiscData";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import ItemsExceptionsList from "./ItemsExceptionsList";
import ItemsExceptionModal from "./ItemExceptionModal";
import PrivateNotes from "./PrivateNotes";
import { UserContext } from "../../context/UserContext";
import InvoicesLines from "../billing/InvoiceLines";
import { AccountUnitsContext } from "../../context/AccountUnitsContext";
import { getContrastColor, TagRenderer } from "../dispatches/helpers";

const commonKeys = [
  "warehouse_code",
  "account_code",
  // "los",
  "quantity",
  "weight",
  "weight_in_kgs",
  "mileage",
  "is_hazardous",
  "reference_1_type",
  "reference_1",
  "reference_2_type",
  "reference_2",
  "notes",
  "priority",
  "special"
];

const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { Panel } = Collapse;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { TreeNode } = TreeSelect;

class EditOrderForm extends Component {
  // static contextType = OrganizationSettingsContext
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        type_of_order: "D",
        drop_params: _.cloneDeep(defaultDropParams),
      },
      customerDetails: {
        type_of_order: "D",
        drop_params: _.cloneDeep(defaultDropParams),
      },
      inProgress: false,
      isDeletingItem: false,
      refreshProgress: false,
      configProcess: false,
      errors: [],
      callFrom: this.props.callFrom || "",
      currentPreplan: this.props.currentPreplan || {},
      isOutOfPreplan: false,
      showItemForm: false,
      currentItem: {},
      levelOfServices: [],
      warehouses: [],
      accounts: [],
      isNew: !this.props.customerDetails.id,
      accountInfo: this.props.customerDetails.account_code,
      suppres_schedule_dates: "false",
      skip_warn_hawb_uniqness: "false",
      currentOrder: {},
      config: {},
      uploadKey: {},
      imagesVisible: false,
      isModified: false,
      activeKey: "details",
      currentId: this.props.customerOrderId || "",
      currentParamType: "",
      setInvoiceData: false,
      references: [],
      organizationSettings: {},
      isLoadingOrgSettings: true,
      isMilitaryTime: false,
      driversData: [],
      fleets: [],
      templates: [],
      isFetchingTemplates: false,
      isFetchingDrivers: false,
      isTemplate: false,
      templateName: "",
      selectedTemplateId: "",
      isApplyingTemplate: false,
      currentLocation: null,
      groupItems: false,
      appointmentValidationInfo: {
        start_time: {
          isValid: true,
          error: "",
        },
        end_time: {
          isValid: true,
          error: "",
        },
      },
      trucks: [],
      vehicleTypes: [],
      airports: [],
      storageNeeded: false,
      nsr: false,
      isMultiDayTransfer: false,
      isMultiDayTransferTouched: false,
      Contacts: [],
      elementSize: "small",
      isDeliveryRequired: false,
      changedOrderType: false,
      orderConfig: this.props.orderConfig || { orderTypes: {} },
      showItemExceptionModal: false,
      currentRecord: {},
      orderRequiredFields: defaultRequiredFields,
      consolidatedData: [],
      consolidateLoading: false,
      allStatus: [],
      updatedKey: randomNumber(),
      weightUnitConfirmationModalOpen: false,
      tempAccountCode: '',
      allowChanges: "false"
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.PickupItemDetailsFormRef = React.createRef();
    this.DeliveryItemDetailsFormRef = React.createRef();

    this.debounceFetchTemplates = _.debounce(this.getTemplates, 500);
  }

  getTemplates = () => {
    if (this.state.accounts.length > 0 && this.state.warehouses.length > 0) {
      this.setState({ isFetchingTemplates: true });
      const orgId = userStore.getStateValue("selectedOrg");
      const payload = {
        organization_id: orgId,
        page: 1,
        per_page: 'all'
      };
      TemplatesApi.fetch(payload)
        .then((result) => {
          if (result.success) {
            const templates = _.map(result.templates, (template) => {
              const account = _.find(this.state.accounts, {
                id: template.account_id,
              });
              const warehouse = _.find(this.state.warehouses, {
                id: template.warehouse_id,
              });
              return {
                ...template,
                selected_account: account ? account : "",
                selected_warehouse: warehouse ? warehouse : "",
              };
            });
            this.setState({ templates });
          } else {
            renderAlertMessage(result.errors)
          }
        })
        .finally(() => {
          this.setState({ isFetchingTemplates: false });
          // only if operation is template_create and template_id is present
          if (
            this.props.location.search.includes("operation=template_create") &&
            this.props.location.search.includes("template_id")
          ) {
            const templateId =
              this.props.location.search.split("template_id=")[1];
            this.handleTemplateChange(templateId);
            this.props.history.replace("/orders");
            this.setState({ isApplyingTemplate: false });
            alertMessage(I18n.t("recurring_order.applied"), "success", 10);
          }
        });
    }
  };

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
          vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };

  getAirports = () => {
    fetchOriginDestination().then((result) => {
      if (result.success) {
        this.setState({
          airports: result.airports || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ airports: [], inProgress: false });
      }
    });
  };

  getLos = () => {
    let accountCode = this.state.formData.account_code;
    const accountIndex = _.findIndex(this.state.accounts, {
      code: this.state.formData.account_code,
    });
    if (accountIndex >= 0) {
      const account = this.state.accounts[accountIndex];
      if (!isEmpty(account.primary_account_id)) {
        accountCode = getValueFromArrayOfObjects(
          this.state.accounts,
          "id",
          account.primary_account_id,
          "code"
        );
      }
    }
    if (!isEmpty(accountCode)) {
      this.setState({ inProgress: true });
      fetchAccountShortLos(accountCode, this.state.formData.type_of_order).then((result) => {
        if (result.success) {
          const levelOfServices = result.account.account_los || [];
          this.setState({
            levelOfServices: [
              ...levelOfServices,
              {
                name: "None",
                code: "NONE",
                duration: "0",
                id: "NONE",
              },
            ],
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    }
  };

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  getAccountContactEmails = () => {
    let accountId = this.state.formData.account_id;
    const accountIndex = _.findIndex(this.state.accounts, {
      code: this.state.formData.account_code,
    });
    if (accountIndex >= 0) {
      const account = this.state.accounts[accountIndex];
      if (!isEmpty(account.primary_account_id)) {
        accountId = account.primary_account_id;
      } else {
        accountId = account.id;
      }
    }
    if (!isEmpty(accountId)) {
      this.setState({ inProgress: true });
      fetchContacts({
        page: 1,
        perPage: null,
        render_type: "list",
        accountId,
      }).then((result) => {
        if (result.success) {
          this.setState({
            Contacts: result.contacts || [],
            inProgress: false,
          });
        } else {
          this.setState({ inProgress: false, Contacts: [] });
        }
      });
    }
  };

  getAccounts = (whId) => {
    const accountsData = this.props.warehouseFilter.findMatchingAccounts(whId);
    this.setState(
      {
        accounts: accountsData || [],
        inProgress: false
      },
      () => {
        const foundAccount = this.state.accounts.find((account) => account.code === this.state.formData.account_code)
        if (_.isEmpty(foundAccount)) {
          this.setDeafultAccount();
        }
      }
    );
  };

  initialReference = () => {
    const { references, isNew, orderConfig } = this.state;
    const typeOfOrder = this.state.customerDetails.type_of_order;
    const formData = this.state.formData ? this.state.formData : {};
    if (references.length > 0 && typeOfOrder) {
      orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
        if (
          !isEmpty(references[0]) &&
          !isEmpty(formData[details.key]) &&
          isEmpty(formData[details.key].reference_1_type)
        ) {
          formData[details.key].reference_1_type =
            references[0].order_reference_type;
        }
        if (
          !isEmpty(references[1]) &&
          !isEmpty(formData[details.key]) &&
          isEmpty(formData[details.key].reference_2_type)
        ) {
          formData[details.key].reference_2_type =
            references[1].order_reference_type;
        }
      });
      this.setState({ references });
    }
  };

  getIntialLos = (accountVal) => {
    let accountCode = accountVal;
    const accountIndex = _.findIndex(this.state.accounts, {
      code: this.state.formData.account_code,
    });
    if (accountIndex >= 0) {
      const account = this.state.accounts[accountIndex];
      if (!isEmpty(account.primary_account_id)) {
        accountCode = getValueFromArrayOfObjects(
          this.state.accounts,
          "id",
          account.primary_account_id,
          "code"
        );
      }
    }
    if (!isEmpty(accountCode)) {
      this.setState({ inProgress: true });
      fetchAccountShortLos(accountCode).then((result) => {
        if (result.success) {
          const levelOfServices = result.account.account_los || [];
          this.setState({
            levelOfServices: [
              ...levelOfServices,
              {
                name: "None",
                code: "NONE",
                duration: "0",
                id: "NONE",
              },
            ],
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    }
  };

  initializeData = (orderData) => {
    this.setState({
      configProcess: true,
    });
    const typeOfOrder = this.state.customerDetails.type_of_order;
    const customerDetails = this.state.customerDetails
      ? this.state.customerDetails
      : {};
    const formData = this.state.formData ? this.state.formData : {};
    if (isEmpty(formData.organization_id)) {
      formData.organization_id = userStore.getStateValue("selectedOrg");
    }
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
      const orderDataCopyForIteration = _.cloneDeep(orderData);
      const orderTypeDetails = customerDetails[details.key]
        ? customerDetails[details.key]
        : {};
      if (!formData[details.key]) {
        formData[details.key] = {
          type_of_order: details.type_of_order,
          orderItems: [],
          // preferences: _.cloneDeep(defaultPreferences),
        };
      }

      orderTypeDetails.warehouse_code = orderTypeDetails.wh_location
        ? orderTypeDetails.wh_location.location_code
        : "";
      if (details.is_location_based) {
        if (formData[details.key].locations) {
          formData[details.key].locations.map((location, index) => {
            const orderItems = location.order_items || [];
            const preferences = location.preferences
              ? _.cloneDeep(location.preferences)
              : [];
            const customerPreferences = location.appointments || [];
            const updatedAppointments = formAppointments(
              customerPreferences,
              preferences,
              orderTypeDetails.has_expedite === false
            );
            formData[details.key].locations[index].orderItems = orderItems;
            formData[details.key].locations[index].preferences =
              updatedAppointments;
          });
        }
      } else {
        const orderItems = orderTypeDetails.order_items || [];
        formData[details.key].orderItems = orderItems;

        // formData[details.key].preferences = currentPreferences
        // formData[details.key].appointments = modifiedTransformedData
      }
      if (this.state.isNew && isEmpty(formData[details.key].service_duration)) {
        formData[details.key].service_duration =
          AppConfig.default_service_duration;
      }
      if (this.state.isNew && isEmpty(formData[details.key].address_type)) {
        formData[details.key].address_type = AppConfig.default_address_type;
      }
      if (this.state.isNew && isEmpty(formData[details.key].quantity)) {
        formData[details.key].quantity = 1;
      }
      if (this.state.isNew && orderDataCopyForIteration) {
        // formData[details.key].reference_1 = orderDataCopyForIteration.reference_1;
        // formData[details.key].reference_1_type = orderDataCopyForIteration.reference_1_type;
        // formData[details.key].reference_2 = orderDataCopyForIteration.reference_2;
        // formData[details.key].reference_2_type = orderDataCopyForIteration.reference_2_type;
        formData[details.key].mawb = orderDataCopyForIteration.mawb;
        // formData[details.key].hawb = orderDataCopyForIteration.hawb;
        // formData[details.key].references = orderDataCopyForIteration.references;
        formData[details.key].account_code = orderDataCopyForIteration.account_code;
        if (Array.isArray(orderDataCopyForIteration.losData)) {
          const deliveryLoc = orderDataCopyForIteration.losData.find(loc => loc.type_of_loc === 'DELIVERY');
          if (deliveryLoc) {
            formData[details.key].los = deliveryLoc.los_code;
          }
        } else {
          formData[details.key].los = orderDataCopyForIteration.losData;
        }
      }
    });
    this.setState(
      {
        formData,
        configProcess: false,
      },
      () => {
        this.setZipcodesData();
        this.getOrderReferences();
      }
    );
  };

  setZipcodesData = () => {
    this.state.orderConfig.orderTypes[this.state.formData.type_of_order].types.forEach(
      (details, index) => {
        if (details.is_location_based) {
          const currentOrder = this.state.formData[details.key]
            ? { ...this.state.formData[details.key] }
            : {};
          const locations = currentOrder.locations
            ? [...currentOrder.locations]
            : [];
          locations.forEach((loc, locIndex) => {
            this.getAvailableDates(details.key, locIndex);
          });
        } else {
          this.getAvailableDates(details.key);
        }
      }
    );
  };

  verifyZipcode = (zipData, orderType, locationIndex) => {
    const { formData,config } = this.state;
    const orgId = userStore.getStateValue("selectedOrg");
    const accountId = formData.account_id;
    const zipcode = !isEmpty(zipData) ? zipData : null;
    const zipCodeLength = !isEmpty(zipcode) ? zipcode.length : 0;
    if (zipCodeLength >= 5) {
      verifyBillingZone(orgId, accountId, zipcode).then((result) => {
        if (result.success && !_.isEmpty(result.data.warnings)) {
          alertMessage(result.data.warnings, "warning", 5);
        } else {
          const warehouse_code = result.data.wh_code || ""
          if(zipData) {
            if(orderType === "pickup_params") {
              this.handleOnChange("origin_port",warehouse_code)
            } else if(orderType === "drop_params") {
              this.handleOnChange("destination_port",warehouse_code)
            } else if(orderType === "transfer_params" && locationIndex === 0) {
              this.handleOnChange("origin_port",warehouse_code)
            } else if(orderType === "transfer_params" && locationIndex === 1) {
              this.handleOnChange("destination_port",warehouse_code)
            }
          }
          if (config?.pre_select_los_on_zipcodes === "true") {
            const los = result?.data?.los || {};
            if (locationIndex < 0) {
              this.handleOnChange("los", los.los_code);
            } else {
              this.handleOrderDetailsChange(
                orderType,
                locationIndex,
                "los_code",
                los.los_code
              );
            }
        } else {
          null;
          }
        }
      });
    }
  }

  // handleTypeChange = (element, value, isFromTemplate = false) => {
  //   const formData = { ...this.state.formData };
  //   const previousTypes = AppConfig.orderTypes[ formData.type_of_order ].types.map(
  //     (type) => type.key
  //   );
  //   formData[ element ] = value;
  //   const keysToRemoveIfNotTransfer = [ 'is_location_based', 'is_recovery', 'is_release', 'linehaul_location', 'linehaul_driver', 'linehaul_start_time', 'linehaul_end_time', 'linehaul_target_wh_location_id', 'linehaul_start_date', 'linehaul_end_date', 'linehaul_start_datetime', 'linehaul_end_datetime', 'linehaul_windows', 'linehaul_window_start_time', 'linehaul_window_end_time', 'locations' ];
  //   const mainOrderDataKeys = [ 'pallets', 'surface_area', 'address_type', 'hawb', 'mawb', 'private_notes', 'received_at_date', 'received_at_time', 'delivery_date', 'delivery_time', 'it_number', 'origin_port', 'destination_port', 'routing', 'account_manager_emails', 'references', 'customer_order_notes' , "id" , "customer_order_number", 'contact_customer']
  //   let storageNeeded = this.state.storageNeeded
  //   let isDeliveryRequired = this.state.isDeliveryRequired
  //   AppConfig.orderTypes[ value ].types.forEach((details, index) => {
  //     if (!formData[ details.key ]) {
  //       const isprevTransfer = previousTypes.includes("transfer_params");
  //       let prevData = {};
  //       let prevLocationInfo = {};
  //       let filteredTranferData = {};
  //       if (isprevTransfer) {
  //         // transfer to delivery , pickup
  //         const isPickup = details.key === 'pickup_params';
  //         const typeOfLoc = isPickup ? 'PICKUP' : 'DELIVERY';
  //         const transferData = _.get(formData, 'transfer_params', {});
  //         prevLocationInfo = _.find(transferData.locations, { type_of_loc: typeOfLoc });
  //         filteredTranferData = _.pick(_.get(formData, 'transfer_params', {}), mainOrderDataKeys);
  //       } else {
  //         prevData = _.get(this.state.customerDetails, previousTypes[ 0 ], {});
  //         filteredTranferData = _.pick(_.get(formData, previousTypes[ 0 ], {}), mainOrderDataKeys);
  //       }
  //       switch (true) {
  //         case details.is_location_based:
  //           let prevDetails = {
  //             pickup_params: {},
  //             drop_params: {},
  //           };

  //           previousTypes.forEach((type) => {
  //             prevDetails[ type ] = _.get(formData, type, {});
  //           });


  //         formData[details.key] = {
  //           type_of_order: formData.type_of_order,
  //           is_location_based: details.is_location_based,
  //           locations: [
  //             { ..._.cloneDeep(pickupLocation), ...prevDetails.pickup_params },
  //             { ..._.cloneDeep(dropLocation), ...prevDetails.drop_params },
  //           ],
  //           ...filteredTranferData
  //         };

  //           const isPickupSelected = previousTypes[0] === 'pickup_params' ? true : false;
  //           if(Object.keys(prevDetails[previousTypes[0]]).length > 0){
  //             const prevCsLocation = _.get(this.state.formData, `${previousTypes[0]}.cs_location`, null);
  //             const prevCsLocationId = _.get(prevCsLocation, 'id', null);
  //             const prevTypeData = _.get(prevDetails, previousTypes[0], {});
  //             const currentLocIndex = isPickupSelected ? 0 : 1;
  //             if(prevCsLocationId){
  //               formData[details.key].locations[currentLocIndex].id = prevCsLocationId;
  //               formData[details.key].locations[currentLocIndex]._id = prevCsLocationId;
  //             }
  //             formData[details.key].locations[currentLocIndex].los_code = prevTypeData.los;
  //           }

  //           let isDeliveryLocValid = this.checkIfValidLoc(
  //             formData[ "transfer_params" ].locations[ 1 ]
  //           );

  //           isDeliveryRequired = !isDeliveryLocValid;
  //           storageNeeded = false

  //           break;
  //         case formData.type_of_order === "M":
  //           prevDetails = this.state.formData[ previousTypes ];
  //           // get the pickup and delivery with the type_of_loc
  //           let prevDetailsData = {};
  //           let prevType = {}
  //         if (previousTypes.includes("transfer_params")) {
  //           const isPickup = details.key === 'pickup_params';
  //           const typeOfLoc = isPickup ? 'PICKUP' : 'DELIVERY';
  //           prevDetailsData = _.find(prevDetails.locations, { type_of_loc: typeOfLoc })
  //         }
  //         formData[details.key] = {
  //           ...prevType,
  //           ...prevDetailsData,
  //           ...filteredTranferData,
  //           is_location_based: details.is_location_based,
  //           type_of_order: details.type_of_order,
  //           orderItems: [],
  //         };
  //           break;
  //         default:
  //         formData[details.key] = {
  //           orderItems: [],
  //           preferences: [
  //             {
  //               s_no: 1,
  //               item_preference: "",
  //               item_option: [],
  //               has_expedite: false,
  //             },
  //           ],
  //           customer_order_number: _.get(prevData, 'customer_order_number', ''),
  //           status: _.get(prevData, 'status', ''),
  //           is_location_based: details.is_location_based,
  //           type_of_order: details.type_of_order,
  //           // if  isprevTransfer then spread the prevData else empty object
  //           ..._.cloneDeep(prevLocationInfo),
  //           ...filteredTranferData,
  //           los: _.get(prevLocationInfo, 'los_code', ''),
  //         };
  //           // remove the keys if not transfer
  //           if (isprevTransfer) {
  //             keysToRemoveIfNotTransfer.forEach((key) => {
  //               delete formData[ details.key ][ key ];
  //             });
  //           }

  //           break;
  //       }
  //       if (previousTypes.length > 0 && formData[ previousTypes[ 0 ] ]) {
  //         commonKeys.forEach((key) => {
  //           formData[ details.key ][ key ] = formData[ previousTypes[ 0 ] ][ key ];
  //         });
  //       }
  //     } else {
  //       formData[ details.key ].type_of_order = details.type_of_order;
  //     }
  //   });
  //   const presentTypes = AppConfig.orderTypes[ value ].types.map(
  //     (type) => type.key
  //   );
  //   const unwantedTypes = previousTypes.filter(
  //     (type) => !presentTypes.includes(type)
  //   );
  //   unwantedTypes.forEach((type) => delete formData[ type ]);
  //   this.setState({ formData, changedOrderType: isFromTemplate ? false : true  , storageNeeded , isDeliveryRequired })
  // };



  handleTypeChange = (element, value, isFromTemplate = false) => {
    // Create a copy of the form data to avoid mutation
    const formData = { ...this.state.formData };
    const previousTypes = this.state.orderConfig.orderTypes[formData.type_of_order].types.map(
      (type) => type.key
    );

    // Update the form data with the new element and value
    formData[element] = value;

    // Define keys to remove if not a transfer order
    const keysToRemoveIfNotTransfer = [
      'is_location_based',
      'is_recovery',
      'is_release',
      'locations',
    ];

    // Define main order data keys
    const mainOrderDataKeys = [
      'color_tags',
      'pallets',
      'surface_area',
      'address_type',
      'hawb',
      'mawb',
      'private_notes',
      'received_at_date',
      'received_at_time',
      'delivery_date',
      'delivery_time',
      'it_number',
      'origin_port',
      'destination_port',
      'routing',
      'account_manager_emails',
      'references',
      'customer_order_notes',
      "id",
      "customer_order_number",
      'contact_customer',
      'status',
    ];

    let storageNeeded = this.state.storageNeeded;
    let isDeliveryRequired = this.state.isDeliveryRequired;
    const { isNew } = this.state;

    if (element === "type_of_order" && ["T","MS"].includes(value)) {
      if (isNew) {
        storageNeeded = true
      }
    }

    this.state.orderConfig.orderTypes[value].types.forEach((details) => {
      // Check if the form data doesn't have the current order type
      if (!formData[details.key]) {
        // Check if the previous order type was 'transfer_params'
        const isPrevTransfer = previousTypes.includes("transfer_params");
        let prevData = {};
        let prevLocationInfo = {};
        let filteredTransferData = {};

        if (isPrevTransfer) {
          const isPickup = details.key === 'pickup_params';
          const typeOfLoc = isPickup ? 'PICKUP' : 'DELIVERY';
          const transferData = _.get(formData, 'transfer_params', {});
          prevLocationInfo = _.find(transferData.locations, { type_of_loc: typeOfLoc });
          filteredTransferData = _.pick(_.get(formData, 'transfer_params', {}), mainOrderDataKeys);
        } else {
          prevData = _.get(this.state.customerDetails, previousTypes[0], {});
          filteredTransferData = _.pick(_.get(formData, previousTypes[0], {}), mainOrderDataKeys);
        }

        switch (true) {
          case details.is_location_based:
            // Clone the previous details for pickup and drop locations
            let prevDetails = {
              pickup_params: {},
              drop_params: {},
            };

            previousTypes.forEach((type) => {
              prevDetails[type] = _.get(formData, type, {});
            });

            formData[details.key] = {
              type_of_order: formData.type_of_order,
              is_location_based: details.is_location_based,
              locations: [
                { ..._.cloneDeep(pickupLocation), ...prevDetails.pickup_params },
                { ..._.cloneDeep(dropLocation), ...prevDetails.drop_params },
              ],
              ...filteredTransferData,
            };

            const isPickupSelected = previousTypes[0] === 'pickup_params' ? true : false;

            if (Object.keys(prevDetails[previousTypes[0]]).length > 0) {
              const prevCsLocation = _.get(this.state.formData, `${previousTypes[0]}.cs_location`, null);
              const prevCsLocationId = _.get(prevCsLocation, 'id', null);
              const prevTypeData = _.get(prevDetails, previousTypes[0], {});
              const currentLocIndex = isPickupSelected ? 0 : 1;

              if (prevCsLocationId) {
                formData[details.key].locations[currentLocIndex].id = prevCsLocationId;
                formData[details.key].locations[currentLocIndex]._id = prevCsLocationId;
              }
              formData[details.key].locations[currentLocIndex].los_code = prevTypeData.los;
            }

            isDeliveryRequired = !this.checkIfValidLoc(formData["transfer_params"].locations[1]);
            // storageNeeded = false;

            formData[details.key].order_items = _.get(formData, `${previousTypes[0]}.orderItems`, []);

            break;

          case formData.type_of_order === "M":
            prevDetails = this.state.formData[previousTypes];

            // Get the pickup and delivery with the type_of_loc
            let prevDetailsData = {};
            let prevType = {};

            if (previousTypes.includes("transfer_params")) {
              const isPickup = details.key === 'pickup_params';
              const typeOfLoc = isPickup ? 'PICKUP' : 'DELIVERY';
              prevDetailsData = _.find(prevDetails.locations, { type_of_loc: typeOfLoc });
            }

            formData[details.key] = {
              ...prevType,
              ...prevDetailsData,
              ...filteredTransferData,
              is_location_based: details.is_location_based,
              type_of_order: details.type_of_order,
              // orderItems: _.get(formData , `${previousTypes[ 0 ]}.orderItems`, []),
            };
            break;

          default:
            formData[details.key] = {
              preferences: [
                {
                  s_no: 1,
                  item_preference: "",
                  item_option: [],
                  has_expedite: false,
                },
              ],
              customer_order_number: _.get(prevData, 'customer_order_number', ''),
              status: _.get(prevData, 'status', ''),
              is_location_based: details.is_location_based,
              type_of_order: details.type_of_order,
              ..._.cloneDeep(prevLocationInfo),
              ...filteredTransferData,
              los: _.get(prevLocationInfo, 'los_code', ''),
              orderItems: _.get(formData, `${previousTypes[0]}.order_items`, _.get(formData, `${previousTypes[0]}.orderItems`, [])),
            };

            // Remove the keys if not transfer
            if (isPrevTransfer) {
              keysToRemoveIfNotTransfer.forEach((key) => {
                delete formData[details.key][key];
              });
            }
            break;
        }

        if (previousTypes.length > 0 && formData[previousTypes[0]]) {
          commonKeys.forEach((key) => {
            formData[details.key][key] = formData[previousTypes[0]][key];
          });
        }
      } else {
        formData[details.key].type_of_order = details.type_of_order;
      }
    });

    // Calculate present and unwanted order types
    const presentTypes = this.state.orderConfig.orderTypes[value].types.map((type) => type.key);
    const unwantedTypes = previousTypes.filter((type) => !presentTypes.includes(type));

    // Delete unwanted order types from form data
    unwantedTypes.forEach((type) => delete formData[type]);

    // Update the state with the modified form data
    this.setState({ 
      formData, changedOrderType: isFromTemplate ? false : true, storageNeeded, isDeliveryRequired }
    , () => {
      this.handleWarehouseChange()
      this.getLos();
    });
  };

  handleOnChange = (element, value) => {
    const account_limit_exceeded = this.props.accountCodesWithExceededLimit.includes(
      value
    );
    if (account_limit_exceeded && element === 'account_code') {
      alertMessage("Selected account has exceeded its Credit Limit.", "warning", 10);
    }
    const { type_of_order: typeOfOrder } = this.state.formData;
    const { driversData, fleets } = this.state;
    if (value && ["origin_port", "destination_port", "hawb", "mawb", "reference_1", "reference_2"].includes(element)) {
      value = value.toUpperCase();
    }
    if (value && ["references"].includes(element)) {
      value = _.isArray(value) ? _.compact(value).map((ref) => ref.toUpperCase()) : [];
    }
    if (
      ["quantity", "width", "length", "height"].includes(element) &&
      !isEmpty(value)
    ) {
      value = Math.floor(value);
    }
    const formData = { ...this.state.formData };
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
      formData[details.key][element] = value;

      if (element === "dispatch_status") {
        const selectedColorStatus = this.props.colorStatus.find((obj) => obj.code === value);
      
        if (selectedColorStatus) {
          // If a matching code is found
          formData[details.key].color_tags = [selectedColorStatus];
        } else {
          // If no matching code is found (e.g., value is null or invalid)
          formData[details.key].color_tags = []; // Set an empty array or handle as needed
        }
      }

      if(element === "account_code" && this.state.isNew ){
        const assignColorTag = this.state.accounts.find((account) => account.code === value);
        const colorTag = assignColorTag ? _.get(assignColorTag, 'color_tags[0]', null) : null;
        if(colorTag){
          formData[details.key].color_tags = [colorTag];
        }else{
          formData[details.key].color_tags = [];
        }
      }
      

      if (element === "account_code" && !_.isEmpty(value)) {
        formData[[details.key]]["reference_1_type"] = "";
        formData[[details.key]]["reference_1"] = "";
        formData[[details.key]]["reference_2_type"] = "";
        formData[[details.key]]["reference_2"] = "";
        formData[[details.key]]["account_manager_emails"] = [];
        formData[[details.key]]["required_accessorials"] = [];
      }
      if (element === "reference_1_type" && isEmpty(value)) {
        formData[[details.key]]["reference_1"] = "";
      }
      if (element === "reference_2_type" && isEmpty(value)) {
        formData[[details.key]]["reference_2"] = "";
      }
      if (element === "driver_id") {
        const driverIndex = _.findIndex(driversData, {
          id: value,
        });
        if (driverIndex >= 0 && !isEmpty(value)) {
          const driver = driversData[driverIndex];
          formData[[details.key]]["fleet_id"] = driver.driver_fleet_id;
        } else {
          formData[[details.key]]["fleet_id"] = "";
        }
      }
    });
    if (element === "account_code") {
      formData[element] = value;
      const accountIndex = _.findIndex(this.state.accounts, {
        code: value,
      });
      if (accountIndex >= 0 && !isEmpty(value)) {
        const account = this.state.accounts[accountIndex];
        formData["account_id"] = account.id;
      } else {
        formData["account_id"] = "";
      }
    }

    if (
      typeOfOrder === "T" &&
      (element === "warehouse_code" || element === "account_code")
    ) {
      const prevLocations = this.state.formData["transfer_params"].locations;
      formData["transfer_params"].locations = prevLocations.map((location) => {
        return {
          ...location,
          los_code: "",
          los_id: "",
          los: "",
        };
      });
    }

    this.setState({ formData }, () => {
      if (element === "account_code") {
        this.setZipcodesData();
        this.getLos();
        this.getAccountContactEmails();
        this.getAccountConfigs();
        this.handleOnChange("los", "");
        this.getOrderReferences();
        this.clearActiveItem();
      }
      if (element === "warehouse_code") {
        if (
          this.state.callFrom === "preplan" ||
          this.state.callFrom === "dispatch"
        ) {
          this.handleStateOnchange("isOutOfPreplan", true);
        } else {
          const filteredWH = this.state.warehouses.find(
            (wh) => wh.location_code === value
          );
          const whId = filteredWH ? filteredWH.id : "";
          // const whId = this.state.warehouses.find(
          //   (wh) => wh.location_code  === value,
          // ).id
          // clear account
          // if previous account is not present in new warehouse, clear account
          //this.handleOnChange('account_code', '')
          this.getAccounts(whId)
        }
        this.handleWarehouseChange()
      }
    });
  };

  // handleOrderDetailsChange = (typeOfOrder, locationIndex, element, value, cb = null) => {
  //   if ((element === 'weight' || element === 'quantity') && !isEmpty(value)) {
  //     value = Math.floor(value)
  //   }
  //   const formData = { ...this.state.formData }
  //   if(isOrderHasLocation(formData[typeOfOrder], locationIndex)){
  //     formData[typeOfOrder]["locations"][locationIndex][element] = value
  //   } else {
  //     formData[typeOfOrder][element] = value
  //   }

  //   if (element === 'received_at_time') {
  //     // set received_at_date to today's date if empty
  //     if (isEmpty(formData[ typeOfOrder ][ 'received_at_date' ])) {
  //       formData[ typeOfOrder ][ 'received_at_date' ] = moment()
  //     }
  //   } else if (element === 'received_at_date') {
  //     // set received_at_time to now if empty
  //     if (value === null) {
  //       formData[ typeOfOrder ][ 'received_at_time' ] = null;
  //     } else
  //       if (isEmpty(formData[ typeOfOrder ][ 'received_at_time' ])) {
  //         formData[ typeOfOrder ][ 'received_at_time' ] = moment();
  //       } else {
  //         formData[ typeOfOrder ][ 'received_at_time' ] = moment(formData[ typeOfOrder ][ 'received_at_time' ], "HH:mm:ss");
  //       }
  //   }

  //   this.setState({ formData }, () => {
  //     if (element === 'preferences') {
  //       this.setState({
  //         // isModified: true,
  //       })
  //     }
  //     if (
  //         element === 'warehouse_code' &&
  //         (this.state.callFrom === 'preplan' ||
  //             this.state.callFrom === 'dispatch')
  //     ) {
  //       this.handleOnChange('isOutOfPreplan', true)
  //     }
  //     if (cb) {
  //       cb()
  //     }
  //   })
  // }

  // Function to validate and format 'weight' or 'quantity' value
  formatOrderValue = (element, value) => {
    if ((element === "weight" || element === "quantity") && !isEmpty(value)) {
      return Math.floor(value);
    }
    return value;
  };

  // Function to update formData based on typeOfOrder and locationIndex
  updateFormData = (
    typeOfOrder,
    locationIndex,
    element,
    value,
    locationElement
  ) => {
    const formData = { ...this.state.formData };
    if (isOrderHasLocation(formData[typeOfOrder], locationIndex)) {
      formData[typeOfOrder]["locations"].forEach((location, index) => {
        if (index == locationIndex) {
          location[element] = value;
          if (element === "is_recovery") {
            location.recovery_status = value ? "in_transit" : "";
          }
          if (element === "is_release" && value) {
            location.release_status = value ? "pending" : "";
          }
        }
      });
    } else {
      formData[typeOfOrder][element] = value;
    }

    return formData;
  };

  // Function to handle special elements 'received_at_time' and 'received_at_date'
  handleSpecialElements = (typeOfOrder, element, value, formData) => {
    if (
      [
        "received_at_date",
        "received_at_time",
        "delivery_date",
        "delivery_time",
      ].includes(element)
    ) {
      if (["received_at_time", "delivery_time"].includes()) {
        const dateElement =
          element === "received_at_time" ? "received_at_date" : "delivery_date";
        if (isEmpty(formData[typeOfOrder][dateElement])) {
          formData[typeOfOrder][dateElement] = moment();
        }
      } else if (["received_at_date", "delivery_date"].includes(element)) {
        const timeElement =
          element === "received_at_date" ? "received_at_time" : "delivery_time";

        if (isEmpty(formData[typeOfOrder][timeElement])) {
          formData[typeOfOrder][timeElement] = moment();
        } else {
          formData[typeOfOrder][timeElement] = moment(
            formData[typeOfOrder][timeElement],
            "HH:mm:ss"
          );
        }
      }
    }
    return formData;
  };

  // Post-update actions
  postUpdateActions = (element, cb = null) => {
    if (element === "preferences") {
      this.setState({
        // isModified: true,
      });
    }
    if (
      element === "warehouse_code" &&
      (this.state.callFrom === "preplan" || this.state.callFrom === "dispatch")
    ) {
      this.handleOnChange("isOutOfPreplan", true);
    }
    if (cb) {
      cb();
    }
  };

  handleOrderDetailsChange = (
    typeOfOrder,
    locationIndex,
    element,
    value,
    cb = null,
    locationElement
  ) => {
    value = this.formatOrderValue(element, value);
    let formData = this.updateFormData(
      typeOfOrder,
      locationIndex,
      element,
      value,
      locationElement
    );
    formData = this.handleSpecialElements(
      typeOfOrder,
      element,
      value,
      formData
    );
   
    if (element === "recovery_status" && value && value.length > 0) {
      formData[typeOfOrder]["locations"].forEach((location, index) => {
        if (index == locationIndex) {
          location.recovery_status = value;
        }
      });
      this.setState({ formData }, () => {
        this.postUpdateActions(element, cb);
      });
      return;
    }

    let isMultiDayTransfer = this.state.isMultiDayTransfer;
    let isDeliveryLocValid = false;
    let isMultiDayTransferTouched = this.state.isMultiDayTransferTouched;
    let storageNeeded = this.state.storageNeeded;

    if (["T", "LH","MS"].includes(formData.type_of_order)) {
      if (locationIndex === 1) {
        isDeliveryLocValid = this.checkIfValidLoc(
          formData["transfer_params"].locations[locationIndex]
        );
      } else {
        isDeliveryLocValid = !this.state.isDeliveryRequired;
      }

      if (this.state.isNew) {
        if (element === "appointments" && !isMultiDayTransferTouched) {
          isMultiDayTransfer = this.isMultiDayTransfer(
            formData["transfer_params"].locations
          );
          if (isMultiDayTransfer) {
            isMultiDayTransferTouched = true;
            storageNeeded = true;
            if (!this.state.storageNeeded) {
              message.success({
                content:
                  "Intermediary Storage is selected as the transfer is multi-day",
                duration: 5,
              });
            }
          }
        }
      }
      if (element === "is_recovery" || element === "is_release") {
        if (value && !storageNeeded) {
          storageNeeded = true;
        } else if (!value) {
          storageNeeded = formData["transfer_params"].locations.some(
            (location) => {
              return location.is_recovery || location.is_release;
            }
          );
        }
      }
    }

    this.setState(
      {
        formData,
        isMultiDayTransfer,
        isDeliveryRequired: !isDeliveryLocValid,
        isMultiDayTransferTouched,
        storageNeeded,
      },
      () => {
        this.postUpdateActions(element, cb);
      }
    );
   
    
  };

  getDrivers = async () => {
    this.setState({ isFetchingDrivers: true });
    const result = await fetchUsers("driver", 1, null);
    if (result.success) {
      this.setState({ driversData: result.users, isFetchingDrivers: false });
    } else {
      this.setState({ isFetchingDrivers: false });
    }
  };

  getTrucks = () => {
    fetchTrucks({ status: "active" }, 1, null).then((result) => {
      if (result.success) {
        this.setState({
          trucks: result?.trucks ? result.trucks : [],
        });
      }
    });
  };

  componentDidMount() {
    this.getWarehouses();
    const { code } = this.props.currentOrg;
    if(["EFW"].includes(code)){
      this.setState({
        suppres_schedule_dates: "true",
      })
    }
    if (code === I18n.t("account.hl_code")) {
      if (!this.state.orderRequiredFields.some(obj => obj.form_field === "hawb")) {
        this.setState({
          orderRequiredFields: [...this.state.orderRequiredFields, { form_field: 'hawb', ui_name: I18n.t('general.hawb'), isRequired: true, inputType: 'text' }]
        })

      }
    }
    if (this.state.isNew && this.props.location.state && !_.isEmpty(this.props.location.state)) {
      const orderData = this.props.location.state;
      if (orderData) {
        this.initializeData(orderData);
        this.getIntialLos(orderData.account_code);
      }
    }
    if (!this.state.isNew) {
      this.getOrderDetails(() => {
        let params = new URL(window.location.href).searchParams;
        const tab = params.get("activeTab")
          ? params.get("activeTab")
          : this.props.tab
            ? this.props.tab
            : "";
        if (tab) {
          this.setState({ activeKey: tab });
        } else if (this.props.callFrom === "releases") {
          //this.props.callFrom === 'recoveries'|| this.props.callFrom === 'linehaul'
          this.setState({ activeKey: this.props.callFrom });
        }
      });
    } else if (this.props.templateId) {
      alertMessage(I18n.t("recurring_order.applying"), "info", 10);
      this.setState({ isApplyingTemplate: true });
    } else {
      this.initializeData();
    }
    this.getAccounts();
    this.getDrivers();
    this.getTrucks();
    this.getVehicleTypes();
    this.getAirports();
    if (window.google) {
      this.updateStateMap();
    } else {
      loadGoogleMaps(() => {
        this.setState({
          map: true,
        });
      });
    }
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isLoadingOrgSettings: this.props.isFetchingOrgSettings,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == "true",
    });
    this.debounceFetchTemplates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isLoadingOrgSettings: this.props.isFetchingOrgSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == "true",
      });
    }

    // on modification of order details
    if (this.state.isModified !== prevState.isModified) {
      this.props.onModified(this.state.isModified);
    }
    // if warehouses, accounts are changed fetch templates
    if (
      !_.isEqual(this.state.warehouses, prevState.warehouses) ||
      !_.isEqual(this.state.accounts, prevState.accounts)
    ) {
      this.debounceFetchTemplates();
    }

    if (
      !_.isEqual(
        prevProps.warehouseFilter.warehouseWithAccounts,
        this.props.warehouseFilter.warehouseWithAccounts
      )
    ) {
      this.getAccounts();
    } 
  }

  formOnCancel = () => {
    const queryString = require("query-string");
    // it will return object contains the query params
    var parsed = queryString.parse(this.props.location.search);
    this.props.onCancel(this.state.isModified);
    if (this.props.location.pathname === "/orders") {
      if (parsed.activeTab === "releases") {
        this.props.history.push(`/releases`);
      }
      if (parsed.activeTab === "recoveries") {
        this.props.history.push(`/recovery`);
      }
    }
  };

  getOrderReferences = () => {
    this.setState({ inProgress: true });
    const { formData, isNew } = this.state;
    fetchOrderReferences(formData.organization_id, formData.account_id).then(
      (result) => {
        if (result.success) {
          this.setState(
            {
              references: result.references || [],
              inProgress: false,
            },
            () => {
              if (isNew) {
                this.initialReference();
              }
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false, references: [] });
        }
      }
    );
  };

  divideRecievedAt = (dateTime) => {
    // if null return empty string
    if (!dateTime) {
      return { received_at_date: "", received_at_time: "" };
    }

    // dateTime : "2022-06-21 08:03:36" using moment
    const date = moment(dateTime).format("YYYY-MM-DD");
    const time = moment(dateTime).format("HH:mm:ss");
    return { received_at_date: date, received_at_time: time };
  };

  divideDeliveryBy = (dateTime) => {
 
    // if null return empty string
    if (!dateTime) {
      return { delivery_date: "", delivery_time: "" };
    }

    // dateTime : "2022-06-21 08:03:36" using moment
    const date = moment(dateTime).format("YYYY-MM-DD");
    const time = moment(dateTime).format("HH:mm:ss");
    return { delivery_date: date, delivery_time: time };
  };

  getOrderDetails = (cb = null) => {
    this.setState({
      refreshProgress: true,
    });
    const { type_of_order } = this.state.formData;
    fetchOrderDetails(this.state.currentId, "DISPATCHER").then((result) => {
      if (result.success) {
        const currentOrders = [...result.orders];
        const customerDetails = {};
        let orderItems = [];
        if (currentOrders.length > 0) {
          const orderType = currentOrders[0].type_of_order
            ? currentOrders[0].type_of_order
            : "D"; // "" Considering by default D
          const dependancyOrders = currentOrders.filter(
            (order) => !isEmpty(order.related_order)
          );
          customerDetails.type_of_order =
            ["TR", "TD"].includes(orderType) || dependancyOrders.length > 0
              ? "M"
              : orderType;
          /* start Assigning default order type to D */
          currentOrders.forEach((order, index) => {
            if (isEmpty(order.type_of_order)) {
              currentOrders[index].type_of_order = "D";
            }
          });
          /* end Assigning default order type to D */
          if (customerDetails.type_of_order === "M") {
            currentOrders.forEach((order, index) => {
              if (!isEmpty(order.related_order)) {
                if (order.type_of_order === "R") {
                  currentOrders[index].type_of_order = "TR";
                } else if (order.type_of_order === "D") {
                  currentOrders[index].type_of_order = "TD";
                }
              }
            });
          }

          customerDetails.account_id = currentOrders[0].account_id;
          customerDetails.account_code = currentOrders[0].account_code;
          customerDetails.organization_id = currentOrders[0].organization_id;
          customerDetails.storageNeeded = currentOrders[0].wh_storage;
          customerDetails.nsr = !currentOrders[0].nsr;

        }
        this.state.orderConfig.orderTypes[customerDetails.type_of_order].types.forEach(
          (details, index) => {
            const defaultApptValues = {
              slots: [],
              item_preference: null,
              confirmed: false,
              start_time: null,
              end_time: null,
              has_expedite: false,
            };
            let decodeTimeFormat = !this.state.isMilitaryTime
              ? "HH:mm"
              : "hh:mm A";
            const currentOrder = _.find(currentOrders, {
              type_of_order: details.type_of_order,
            });
            if (!isEmpty(currentOrder)) {
              const { received_at_date, received_at_time } =
                this.divideRecievedAt(currentOrder.received_at);
              const { delivery_date, delivery_time } = this.divideDeliveryBy(
                currentOrder.delivery_by
              );
              currentOrder.received_at_date = received_at_date;
              currentOrder.received_at_time = received_at_time;
              currentOrder.delivery_date = delivery_date;
              currentOrder.delivery_time = delivery_time;
              // currentOrder.weight = getWeightUnits({ account_code: currentOrder.account_code, accountUnitContext: this.props.accountUnitContext.accountWeightUnits }) == "kgs" ? Math.round(convertToKilograms(currentOrder.weight, false)) : currentOrder.weight 
              // dont convert just send as it is
              currentOrder.weight = currentOrder.weight;
              customerDetails[details.key] = currentOrder;
              const currentItems = currentOrder.order_items || [];
              orderItems = [].concat(orderItems, currentItems);
            } else {
              customerDetails[details.key] = {};
            }
            customerDetails[details.key].company_name =
              currentOrder?.cs_location
                ? currentOrder.cs_location.company_name
                : "";
            customerDetails[details.key].weight =
              !isEmpty(currentOrder.weight)
                ? currentOrder.weight
                : "";
            customerDetails[details.key].quantity =
              currentOrder?.quantity
                ? currentOrder.quantity
                : "";    
            customerDetails[details.key].pallets =
              currentOrder?.pallets
                ? currentOrder.pallets
                : "";
            customerDetails[details.key].surface_area =
              currentOrder?.surface_area
                ? currentOrder.surface_area
                : "";
            customerDetails[details.key].acc_loc_code =
              currentOrder?.cs_location
                ? currentOrder.cs_location.acc_loc_code
                : "";     
            customerDetails[details.key].is_location_based =
              details.is_location_based;
            customerDetails[details.key]["invoices"] = currentOrder?.invoices?.length
              ? currentOrder.invoices
              : [];
            if (details.is_location_based) {
              let locations = [
                { ...pickupLocation, is_location_based: true },
                { ...dropLocation, is_location_based: true },
              ];
              if (currentOrder.locations && currentOrder.locations.length > 0) {
                locations = formLocationFromOrder(
                  currentOrder,
                  this.state.isMilitaryTime
                );
                if (locations.length === 1) {
                  if (locations[0].type_of_loc === "PICKUP") {
                    locations.push({
                      ...dropLocation,
                      is_location_based: true,
                    });
                  } else {
                    locations.push({
                      ...pickupLocation,
                      is_location_based: true,
                    });
                  }
                }
              }
              currentOrder.locations = locations;
            } else {
              const defaultApptValues = {
                slots: [],
                item_preference: null,
                confirmed: false,
                start_time: null,
                end_time: null,
                has_expedite: false,
              };
              let transformedData = transformKeysToMoment(
                currentOrder.appointments,
                {
                  dateKeys: ["appt_date"],
                  timeKeys: ["start_time", "end_time"],
                  dateFormat: "YYYY-MM-DD",
                  timeFormat: decodeTimeFormat,
                  isMilitaryTime: this.state.isMilitaryTime,
                }
              );
              // modify transformedData to add default values
              const modifiedTransformedData = transformedData.map((appt) => {
                return {
                  ...defaultApptValues,
                  ...appt,
                  has_expedite: currentOrder.has_expedite,
                };
              });
              currentOrder.appointments = modifiedTransformedData;
              customerDetails[details.key].orderItems = [];
              // customerDetails[details.key].preferences = [...(_.cloneDeep(defaultPreferences))]
              customerDetails[details.key].feasible_dow = [];
              customerDetails[details.key].feasible_dates = [];
            }
          }
        );

        const formData = _.cloneDeep(customerDetails);
        const isMultiDayTransfer =
          formData.type_of_order === "T"
            ? this.isMultiDayTransfer(currentOrders[0].locations)
            : false;
        this.setState(
          {
            // result.orders.customer_order
            customerDetails,
            formData,
            refreshProgress: false,
            uploadKey: randomNumber(8),
            orderItems,
            storageNeeded: customerDetails.storageNeeded,
            isMultiDayTransfer,
            isMultiDayTransferTouched: true,
            nsr: customerDetails.nsr,
            isDeliveryRequired: ['T', 'LH', 'MS'].includes(formData.type_of_order) && formData["transfer_params"]?.locations?.length > 1,
          },
          () => {
            this.initializeData();
            this.getAccountConfigs();
            this.getAccountContactEmails();
            this.getLos();
            // this.getOrderReferences();
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          refreshProgress: false,
        });
      }
    });
  };

  getOrderQuote = (id, typeOfOrder) => {
    this.setState({
      quotationProgress: true,
    });
    fetchOrderEstimationAmount(id).then((result) => {
      if (result.success) {
        const amount = result.estimation_amount;
        this.setState({
          quotationProgress: false,
        });
        this.handleOrderDetailsChange(
          typeOfOrder,
          -1,
          "quotation_amount",
          amount
        );
        alertMessage("Quotation amount updated successfully", "success", 10);
      }
    });
  };

  getAccountConfigs = () => {
    const { config, formData } = this.state;
    if (formData.account_code) {
      fetchAccountConfigs(formData.account_id, formData.organization_id).then(
        (result) => {
          if (result.success) {
            const accountConfigurations = result.account_configurations || [];
            accountConfigurations.forEach((setting) => {
              config[setting.setting_name] = setting.setting_value;
            });
            if (
              !isEmpty(config.pod_pic_settings) &&
              _.isString(config.pod_pic_settings) &&
              config.pod_pic_settings === "[]"
            ) {
              config.pod_pic_settings = [];
            }
            config.pod_pic_settings = updatePicSettingsWithBol(
              config.pod_pic_settings
            );
            if (isEmpty(config.communication_preference)) {
              config.communication_preference = "SMSES";
            }

            const found_show_note_to_user = _.find(accountConfigurations, (setting) => setting.setting_name === "show_note_to_user");

            if (_.isEmpty(found_show_note_to_user) || _.isEmpty(_.trim(found_show_note_to_user.setting_value))) {
              config.show_note_to_user = "";
            }

            const newState = {
              config
            }
            if (this.state.isNew) {
              newState.nsr = config['pod_signature'] === 'true' || isEmpty(config['pod_signature']);
            }
            this.setState({
              ...newState
            });
          } else {
            renderAlertMessage(result.errors)
          }
        }
      );
    } else {
      this.setState({
        config: {},
      });
    }
  };

  updateStateMap = () => {
    this.setState({
      map: true,
    });
  };

  getWarehouses = () => {
    this.setState({ inProgress: false });
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState(
          {
            warehouses: result.locations.locations || [],
            inProgress: false,
          },
          () => {
            this.setDeafultWarehouse();
          }
        );
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  setDeafultWarehouse = () => {
    if (this.state.isNew === true) {
      const currentWh =
        typeof this.props.warehouseFilter.selectedWarehouses === "string"
          ? _.find(this.props.warehouseFilter.warehouses, {
            id: this.props.warehouseFilter.selectedWarehouses,
          }).location_code
          : _.find(this.props.warehouseFilter.warehouses, {
            id: this.props.warehouseFilter.selectedWarehouses[0],
          }).location_code;
      this.handleOnChange("warehouse_code", currentWh);
    }
  };

  setDeafultAccount = () => {
    if (this.state.isNew === true && this.state.accounts.length === 1) {
      this.setState(
        {
          formData: Object.assign({}, this.state.formData, {
            account_code: _.get(this.state.accounts[0], 'code', ''),
            account_id: _.get(this.state.accounts[0], 'id', ''),
            // pickup_params: Object.assign({}, this.state.formData.pickup_params, {
            //   account_code: _.get(this.state.accounts[0], 'code', ''),
            // })
          }),
        },
        () => {
          this.getLos();
          this.getAccountContactEmails();
        }
      );
    }
  };

  handleOnItemsChange = (id, element, value) => {
    const orderItems = this.state.orderItems || [];
    const itemIndex = _.findIndex(orderItems, ["item_id", id]);
    if (itemIndex >= 0) {
      orderItems[itemIndex][element] = value;
      this.setState({
        orderItems,
      });
    }
  };

  askConfirmMessage = (orderType, locationIndex, index, element, value) => {
    if (element === "item_preference" && !isEmpty(value)) {
      const { config } = this.state;
      const currentOrder = this.state.formData[orderType]
        ? this.state.formData[orderType]
        : {};
      const dataObject = retrieveDataObject(currentOrder, locationIndex);
      const formattedValue = moment(value).format("YYYY-MM-DD");
      if (isEmpty(dataObject.feasible_dates)) {
        dataObject.feasible_dates = [];
      }
      if (
        config.delivery_zipcodes === "true" &&
        !dataObject.feasible_dates.includes(formattedValue)
      ) {
        confirm({
          title:
            "Deliveries are not scheduled for this zone on this day of the week",
          content: <span className="">{"Do you want to proceed ?"}</span>,
          onOk: () => {
            this.handlePreferencesChange(
              orderType,
              locationIndex,
              index,
              element,
              value
            );
          },
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onCancel: () => { },
        });
      } else {
        this.handlePreferencesChange(
          orderType,
          locationIndex,
          index,
          element,
          value
        );
      }
    } else {
      this.handlePreferencesChange(
        orderType,
        locationIndex,
        index,
        element,
        value
      );
    }
  };

  handlePreferencesChange = (
    orderType,
    locationIndex,
    index,
    element,
    value
  ) => {
    const { hourValue, minuteValue } = getHMSFromTimeString(
      this.state.organizationSettings.route_delivery_end_time,
      "HH:mm",
      null,
      null
    );
    const dispatcherEndHour = hourValue;
    const dataObject = retrieveDataObject(
      this.state.formData[orderType],
      locationIndex
    );
    const preferences = [...dataObject.preferences];
    // if (index <= preferences.length - 1) {
    //   preferences[index][element] = value;
    //   this.setState({ preferences });
    // }

    if (element === "item_preference") {
      if (value !== null) {
        const formattedValue = moment(value).format("YYYY-MM-DD");

        const ifExists = preferences.filter(
          (preference) =>
            preference.item_preference &&
            moment(preference.item_preference).format("YYYY-MM-DD") ===
            formattedValue
        );
        if (ifExists.length > 0) {
          alertMessage(
            `${I18n.t("tracking.duplicate_appointments")} ${I18n.t(
              "messages.appointment_form.select_another_date"
            )}`,
            "error",
            5
          );
        } else if (index <= preferences.length - 1) {
          preferences[index][element] = value;
          this.handleOrderDetailsChange(
            orderType,
            locationIndex,
            "preferences",
            preferences,
            () => {
              this.clearPreferenceConfirm(orderType, locationIndex, index);
            }
          );
          // this.setState({ preferences }, () => {
          //   this.clearPreferenceConfirm(index);
          // });
        }
      } else if (index <= preferences.length - 1) {
        preferences[index][element] = value;
        this.handleOrderDetailsChange(
          orderType,
          locationIndex,
          "preferences",
          preferences,
          () => {
            this.clearPreferenceConfirm(orderType, locationIndex, index);
          }
        );
        // this.setState({ preferences }, () => {
        //   this.clearPreferenceConfirm(index);
        // });
      }
    } else if (element === "start_time" || element === "end_time") {
      if (value !== null) {
        // For from_date,  to_date
        let hasErrors = false;
        const currentPreference = preferences[index];
        if (element === "start_time") {
          const startHour = moment(value).hour();
          if (startHour > dispatcherEndHour) {
            value = moment(value)
              .set("hour", dispatcherEndHour - 1)
              .set("minute", 0);
          }
          preferences[index].end_time = checkEndTime(
            currentPreference,
            value,
            dispatcherEndHour,
            dispatcherEndHour
          );
        }
        if (element === "end_time") {
          const endHour = moment(value).hour();
          if (endHour > dispatcherEndHour) {
            value = moment(value)
              .set("hour", dispatcherEndHour)
              .set("minute", 0);
          }
          if (!isEmpty(currentPreference.start_time)) {
            const isBefore = value.isBefore(currentPreference.start_time);
            if (isBefore) {
              hasErrors = true;
              alertMessage(
                I18n.t("messages.from_time_exceeds_to_time"),
                "error",
                5
              );
            }
          }
        }
        if (!hasErrors && index <= preferences.length - 1) {
          preferences[index][element] = value;
          preferences[index].item_option = ["CUSTOM"];
          this.handleOrderDetailsChange(
            orderType,
            locationIndex,
            "preferences",
            preferences,
            () => {
              this.clearPreferenceConfirm(orderType, locationIndex, index);
            }
          );
          // this.setState({ preferences }, () => {
          //   this.clearPreferenceConfirm(index);
          // });
        }

        //Start of if start_time is equal to end_time then expedite will be checked
        if (
          !isEmpty(currentPreference.start_time) &&
          moment(currentPreference.start_time).format("h:mm A") ===
          moment(currentPreference.end_time).format("h:mm A")
        ) {
          currentPreference.has_expedite = true;
        }
        //End of if start_time is equal to end_time then expedite will be checked
      } else if (index <= preferences.length - 1) {
        preferences[index][element] = value;
        if (
          isEmpty(preferences[index].start_time) &&
          isEmpty(preferences[index].end_time)
        ) {
          preferences[index].item_option = [];
        }
        this.handleOrderDetailsChange(
          orderType,
          locationIndex,
          "preferences",
          preferences,
          () => {
            this.clearPreferenceConfirm(orderType, locationIndex, index);
          }
        );
        // this.setState({ preferences }, () => {
        //   this.clearPreferenceConfirm(index);
        // });
      }
    } else {
      preferences[index][element] = value;
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "preferences",
        preferences
      );
    }
  };

  checkIfValidLoc = (locData) => {
    const currentOrg = this.props.currentOrg
    const isLKTOrg = currentOrg.code === 'LK'
    let requiredFields = [
      "customer_first_name",
      "customer_phone_one",
      "customer_email",
      "los_code",
      {
        key: "cs_location",
        fields: ["address_line1", "city", "state", "country", "zipcode"],
      },
    ];

    if (isLKTOrg) {
      requiredFields.push("company_name")
    }

    // Function to check if all fields in an object are filled
    const areFieldsFilled = (data, fields) => {
      return fields.every(
        (field) => data[field] !== undefined && data[field] !== ""
      );
    };

    const requiredFieldsFilled = requiredFields.map((field) => {
      if (typeof field === "string") {
        return locData[field] !== undefined && locData[field] !== "";
      } else if (field.key && field.fields) {
        if (locData[field.key] === undefined || locData[field.key] === null) {
          return false;
        }
        return areFieldsFilled(locData[field.key], field.fields);
      }
    });
    let isEverthingFilled = requiredFieldsFilled.every((filled) => filled);
    let isEverthingEmpty = requiredFieldsFilled.every((filled) => !filled);

    let isLocValid = isEverthingFilled || isEverthingEmpty;

    if (
      (isEverthingEmpty && locData.is_release) ||
      (isEverthingEmpty && locData.service_duration?.length > 0)
    ) {
      isLocValid = false;
    }

    const checkIfValidAppts = (appts) => {
      let isValid = true;
      if (
        appts.length === 1 &&
        appts[0].appt_date === "" &&
        appts[0].start_time === null &&
        appts[0].end_time === null &&
        appts[0].has_expedite === false
      ) {
        isValid = false;
      }
      return isValid;
    };

    if (
      isEverthingEmpty &&
      locData.appointments &&
      checkIfValidAppts(locData.appointments)
    ) {
      isLocValid = false;
    }

    return isLocValid;
  };

  handleStateOnchange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  handleItemOptionOnChange = (
    orderType,
    locationIndex,
    index,
    element,
    value,
    checked,
    type = "radio"
  ) => {
    const { formData } = this.state;
    const dataObject = retrieveDataObject(formData[orderType], locationIndex);
    const preferences = [...dataObject.preferences];
    if (index <= preferences.length - 1) {
      const optionIndex = preferences[index][element].indexOf(value);
      if (optionIndex >= 0) {
        if (value !== "CUSTOM") {
          preferences[index].start_time = null;
          preferences[index].end_time = null;
        }
        if (checked) {
          if (type === "radio" || ["ANY", "CUSTOM"].includes(value)) {
            preferences[index][element] = [value];
          } else {
            preferences[index][element] = preferences[index][element].filter(
              (ele) => !["ANY", "CUSTOM"].includes(ele)
            );
            preferences[index][element].push(value);
          }
        } else {
          if (value === "CUSTOM") {
            preferences[index].start_time = null;
            preferences[index].end_time = null;
          }
          preferences[index][element].splice(optionIndex, 1);
        }
      } else {
        if (value !== "CUSTOM") {
          preferences[index].start_time = null;
          preferences[index].end_time = null;
        }
        // eslint-disable-next-line no-lonely-if
        if (type === "radio" || ["ANY", "CUSTOM"].includes(value)) {
          preferences[index][element] = [value];
        } else {
          // Assuming getting A, M , E
          preferences[index][element] = preferences[index][element].filter(
            (ele) => !["ANY", "CUSTOM"].includes(ele)
          );
          preferences[index][element].push(value);
        }
      }
      if (
        preferences[index][element].includes("M") &&
        preferences[index][element].includes("A") &&
        preferences[index][element].includes("E")
      ) {
        preferences[index][element] = ["ANY"];
      }
      // this.setState({ preferences }, () => {
      //   this.clearPreferenceConfirm(index);
      // });
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "preferences",
        preferences,
        () => {
          this.clearPreferenceConfirm(orderType, locationIndex, index);
        }
      );
    }
  };

  getAvailableDates = (orderType, locationIndex = -1) => {
    const currentOrder = this.state.formData[orderType]
      ? { ...this.state.formData[orderType] }
      : {};
    const dataObject = retrieveDataObject(currentOrder, locationIndex);
    const address =
      dataObject.cs_location && dataObject.cs_location.l_address
        ? dataObject.cs_location.l_address
        : {};
    if (
      checkServiceExistance("DDBOZ") &&
      !isEmpty(currentOrder.account_code) &&
      !isEmpty(address.zipcode)
    ) {
      fetchAvailableDates(currentOrder.account_code, address.zipcode).then(
        (result) => {
          if (result.success) {
            let orderTypeDetails = null;
            const availableDates = result.availableDates || {};
            dataObject.feasible_dow = availableDates.feasible_dow || [];
            dataObject.feasible_dates = availableDates.feasible_dates || [];
            if (locationIndex >= 0) {
              const locations = [...currentOrder.locations];
              locations[locationIndex] = dataObject;
              orderTypeDetails = Object.assign({}, currentOrder, {
                locations,
              });
            } else {
              orderTypeDetails = Object.assign({}, dataObject);
            }
            this.setState({
              formData: Object.assign({}, this.state.formData, {
                [orderType]: Object.assign({}, orderTypeDetails),
              }),
            });
          } else {
            renderAlertMessage(result.errors)
            this.setState({ inProgress: false });
          }
        }
      );
    }
  };

  handleOnAddressChange = (
    orderType,
    locationIndex = -1,
    element,
    value,
    obj
  ) => {
    const currentOrder = this.state.formData[orderType]
      ? _.cloneDeep(this.state.formData[orderType])
      : {};
    let orderTypeDetails = {};
    let dataObject = currentOrder;
    const locations = currentOrder.locations || [];
    if (locationIndex >= 0) {
      dataObject = retrueveLocationFromOrder(currentOrder, locationIndex);
    }
    const address =
      dataObject.cs_location && dataObject.cs_location.l_address
        ? dataObject.cs_location.l_address
        : {};
    let newAddress = Object.assign({}, address);
    if (obj) {
      newAddress = Object.assign({}, address, { ...obj });
      if (this.state.isNew) {
        delete newAddress.id;
      }
    } else if (element) {
      newAddress[element] = value;
    }
    if (locationIndex >= 0) {
      if (!this.state.isNew && dataObject?.cs_location?.id) {
        newAddress.id = dataObject.cs_location.id;
      }
      dataObject.cs_location = Object.assign({}, dataObject.cs_location, {
        l_address: newAddress,
      });
      locations[locationIndex] = dataObject;
      orderTypeDetails = Object.assign({}, currentOrder, {
        locations,
      });
    } else {
      if (!this.state.isNew && currentOrder?.cs_location?.id) {
        newAddress.id = currentOrder.cs_location.id;
      }
      orderTypeDetails = Object.assign({}, currentOrder, {
        cs_location: Object.assign({}, currentOrder.cs_location, {
          l_address: newAddress,
        }),
      });
    }

    this.setState(
      {
        formData: Object.assign({}, this.state.formData, {
          [orderType]: orderTypeDetails,
        }),
        // isModified: true,
      },
      () => {
        if (!isEmpty(newAddress.zipcode) && (newAddress.zipcode !== address.zipcode)) {
          this.getAvailableDates(orderType, locationIndex);
          this.verifyZipcode(newAddress.zipcode, orderType, locationIndex);
        }
      }
    );
  };
  handleApptChange = (
    orderType,
    locationIndex,
    indexToModify,
    element,
    value
  ) => {
    const dataObject = retrieveDataObject(
      this.state.formData[orderType],
      locationIndex
    );
    // check if apppointments exist else use preferences
    let modifiedPreferences = !_.isEmpty(dataObject.appointments)
      ? [...dataObject.appointments]
      : dataObject?.preferences?.length
        ? [...dataObject.preferences]
        : [..._.cloneDeep(defaultPreferences)];
    modifiedPreferences = modifiedPreferences.map((preference) => ({
      ...preference,
      confirmed: false,
    }));

    const { currentPreplan, callFrom } = this.state;
    const { currentRoute } = this.props;
    let endDate = "";
    let startDate = "";

    if (callFrom === "preplan" && currentPreplan) {
      startDate = moment(currentPreplan.delivery_start_date).format(
        "YYYY-MM-DD"
      );
      endDate =
        currentPreplan.preplan_type === "single_day"
          ? startDate
          : moment(currentPreplan.delivery_end_date).format("YYYY-MM-DD");
    } else if (callFrom === "dispatch" && currentRoute) {
      startDate = moment(currentRoute.r_scheduled_start_datetime).format(
        "YYYY-MM-DD"
      );
      endDate = moment(currentRoute.r_scheduled_end_datetime).format(
        "YYYY-MM-DD"
      );
    }

    const selectedDate = moment(value).format("YYYY-MM-DD");
    const isOutOfPreplan =
      !isEmpty(value) &&
      startDate &&
      endDate &&
      !isBetweenDates(startDate, endDate, selectedDate);

    const handleOnOk = () => {
      const formData = this.state.formData;
      const orderTypeData = formData[orderType];
      const updatedAppointments = modifiedPreferences.map(
        (preference, index) => {
          if (index === indexToModify) {
            // update the preference
            return {
              ...preference,
              confirmed: true,
              [element]: value,
            };
          }
          // return the original preference if not the one to update
          return preference;
        }
      );

      const modifiedLocationsData = orderTypeData.locations.map(
        (location, index) => {
          if (index === locationIndex) {
            return {
              ...location,
              appointments: updatedAppointments,
            };
          }
          return location;
        }
      );

      this.setState({
        isOutOfPreplan: true,
        formData: {
          ...formData,
          [orderType]: {
            ...orderTypeData,
            appointments: updatedAppointments,
            locations: modifiedLocationsData,
          },
        },
      });
    };

    // Handle Cancel operation (empty in this case)
    const handleOnCancel = () => { };

    if (isOutOfPreplan) {
      const dialogTitle = isEmpty(value)
        ? "Clear the appointment?"
        : "Change the appointment?";
      const dialogContent = `This order will be deleted from this ${callFrom === "preplan" ? "preplan" : "route"
        }`;

      confirm({
        title: dialogTitle,
        content: dialogContent,
        onOk: handleOnOk,
        onCancel: handleOnCancel,
      });
    } else {
      // if appointmented changes and again changes back to current date
      const formData = this.state.formData;
      const orderTypeData = formData[orderType];
      const updatedAppointments = modifiedPreferences.map(
        (preference, index) => {
          if (index === indexToModify) {
            // update the preference
            return {
              ...preference,
              confirmed: true,
              [element]: value,
            };
          }
          // return the original preference if not the one to update
          return preference;
        }
      );

      const modifiedLocationsData = orderTypeData.locations.map(
        (location, index) => {
          if (index === locationIndex) {
            return {
              ...location,
              appointments: updatedAppointments,
            };
          }
          return location;
        }
      );

      this.setState({
        isOutOfPreplan: false,
        formData: {
          ...formData,
          [orderType]: {
            ...orderTypeData,
            appointments: updatedAppointments,
            locations: modifiedLocationsData,
          },
        },
      });
    }
  };

  handleDateChange = (orderType, locationIndex, index, element, value) => {
    //  this.askConfirmMessage(orderType, index, element, value)
    //  this.handlePreferencesChange(orderType, index, element, value)
    const dataObject = retrieveDataObject(
      this.state.formData[orderType],
      locationIndex
    );
    const preferences = [...dataObject.preferences];
    const modifiedPreferences = preferences.map((preference) =>
      Object.assign({}, preference, { confirmed: false })
    );

    let endDate = "";
    let startDate = "";
    if (
      (this.state.callFrom === "preplan" && this.state.currentPreplan) ||
      this.state.callFrom === "dispatch"
    ) {
      const currentRoute = this.props.currentRoute
        ? this.props.currentRoute
        : {};
      if (this.state.callFrom === "preplan") {
        startDate = moment(
          this.state.currentPreplan.delivery_start_date
        ).format("YYYY-MM-DD");
        if (this.state.currentPreplan.preplan_type === "single_day") {
          endDate = startDate;
        } else {
          endDate = moment(this.state.currentPreplan.delivery_end_date).format(
            "YYYY-MM-DD"
          );
        }
      } else {
        startDate = moment(currentRoute.r_scheduled_start_datetime).format(
          "YYYY-MM-DD"
        );
        endDate = moment(currentRoute.r_scheduled_end_datetime).format(
          "YYYY-MM-DD"
        );
      }
      const selectedDate = moment(value).format("YYYY-MM-DD");
      if (isEmpty(value) || !isBetweenDates(startDate, endDate, selectedDate)) {
        confirm({
          title: `Do you want to ${isEmpty(value) ? "clear" : "change"
            } the appointment`,
          content: `When clicked the OK button, this order will be deleted from this ${this.state.callFrom === "preplan" ? "preplan" : "route"
            }`,
          onOk: () => {
            modifiedPreferences[index].confirmed = true;
            this.setState(
              {
                isOutOfPreplan: true,
                // isModified: true,
              },
              () => {
                this.askConfirmMessage(
                  orderType,
                  locationIndex,
                  index,
                  element,
                  value
                );
              }
            );
          },
          onCancel: () => { },
        });
      } else {
        this.setState({
          isOutOfPreplan: false,
        });
        this.askConfirmMessage(orderType, locationIndex, index, element, value);
      }
    } else {
      this.askConfirmMessage(orderType, locationIndex, index, element, value);
    }
  };

  handleConfirmChange = (orderType, locationIndex, index, element, value) => {
    const preferences = [...this.state.formData[orderType].preferences];
    const modifiedPreferences = preferences.map((preference) =>
      Object.assign({}, preference, { confirmed: false })
    );
    modifiedPreferences[index].confirmed = true;
    this.setState(
      {
        formData: Object.assign({}, this.state.formData, {
          [orderType]: Object.assign({}, this.state.formData[orderType], {
            preferences: modifiedPreferences,
          }),
        }),
      },
      () => {
        this.handleSave();
      }
    );
  };

  validateMobileNumbs = (formData, dovalidateNumbs = false) => {
    const typeOfOrder = this.state.formData.type_of_order;
    let mobileFieldData = [...mobileFields];
    let errors = [];
    const mobileErrorsType = [];
    const isValidateMobile =
      this.state.config[
      I18n.t("configurations.move_to_exception_on_invalid_number")
      ];
    if (isValidateMobile === "false" || isEmpty(isValidateMobile)) {
      mobileFieldData = mobileFields.map((field) =>
        Object.assign({}, field, { isRequired: false })
      );
    }
    const reqFields = mobileFieldData.filter(
      (field) => field.isRequired === true
    );
    const nonReqFields = mobileFieldData.filter(
      (field) => field.isRequired === false
    );
    let reqErrors = [];
    let nonReqErrors = [];
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
      const currentOrder = formData[details.key] ? formData[details.key] : {};
      currentOrder.customer_phone_one = formatePhoneNo(
        currentOrder.customer_phone_one
      );
      currentOrder.customer_phone_two = formatePhoneNo(
        currentOrder.customer_phone_two
      );
      if (details.is_location_based) {
        formData[details.key].locations.forEach((loc, locIndex) => {
          if (
            locIndex === 1 &&
            !this.state.isDeliveryRequired
          ) {
            return;
          }
          loc.customer_phone_one = formatePhoneNo(loc.customer_phone_one);
          loc.customer_phone_two = formatePhoneNo(loc.customer_phone_two);
          let mobileErrors = [];
          let currentErrors = [];
          if (reqFields.length > 0) {
            mobileErrors = doValidate(reqFields, loc);
            if (mobileErrors.length > 0) {
              if (currentOrder.status !== "EXCEPTION") {
                mobileErrorsType.push(details.label);
              }
              currentErrors = [].concat(currentErrors, mobileErrors);
              reqErrors = [].concat(reqErrors, mobileErrors);
            }
          }
          if (nonReqFields.length > 0) {
            mobileErrors = doValidate(nonReqFields, loc);
            if (mobileErrors.length > 0) {
              currentErrors = [].concat(currentErrors, mobileErrors);
              nonReqErrors = [].concat(nonReqErrors, mobileErrors);
            }
          }

          if (currentErrors.length > 0) {
            errors = [].concat(errors, [
              `In stop ${locIndex + 1} details, ${currentErrors.join(", ")}`,
            ]);
          }
        });
      } else {
        let mobileErrors = [];
        let currentErrors = [];
        if (reqFields.length > 0) {
          mobileErrors = doValidate(reqFields, currentOrder);
          if (mobileErrors.length > 0) {
            if (currentOrder.status !== "EXCEPTION") {
              mobileErrorsType.push(details.label);
            }
            currentErrors = [].concat(currentErrors, mobileErrors);
            reqErrors = [].concat(reqErrors, mobileErrors);
          }
        }
        if (nonReqFields.length > 0) {
          mobileErrors = doValidate(nonReqFields, currentOrder);
          if (mobileErrors.length > 0) {
            currentErrors = [].concat(currentErrors, mobileErrors);
            nonReqErrors = [].concat(nonReqErrors, mobileErrors);
          }
        }

        if (currentErrors.length > 0) {
          errors = [].concat(errors, [
            `In ${details.label} details, ${currentErrors.join(", ")}`,
          ]);
        }
      }
    });
    return { errors, mobileErrorsType, reqErrors, nonReqErrors };
  };

  handleIfLinehaul = () => {
    const {customerDetails, formData} = this.state;
      if (formData.account_code != customerDetails.account_code || formData.transfer_params?.warehouse_code != customerDetails.transfer_params?.warehouse_code) {
        confirm({
          title: "The Account or Warehouse of the order has been changed.",
          content: "Do you want to update these changes in the corresponding linehaul?",
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            this.setState({ allowChanges: "true" }, () => this.handleValidateLocationOrder())
          },
          onCancel: () => {
            this.setState({ allowChanges: "false" }, () => this.handleValidateLocationOrder())
          },
        })
      } else {
        this.handleValidateLocationOrder();
      }
  }

  handleValidate = () => {
    const typeOfOrder = this.state.formData.type_of_order;
    const {customerDetails} = this.state;
    if (["T", "LH","MS"].includes(typeOfOrder)) {
      if (!this.state.isNew && typeOfOrder === "T" && customerDetails?.transfer_params?.is_lh_order) {
        this.handleIfLinehaul();
      }
      else {
      this.handleValidateLocationOrder();
      }
    } else {
      this.handleValidateOrder();
    }
  };

  handleValidateOrder = () => {
    const { customerDetails, formData } = this.state;
    this.setState({ inProgress: true, errors: [] });
    let errors = [];
    const typeOfOrder = this.state.formData.type_of_order;
    const processedData = {};
    const isValidateMobile =
      this.state.config[
      I18n.t("configurations.move_to_exception_on_invalid_number")
      ];
    if (!isEmpty(typeOfOrder)) {
      let validationErrors = [];
      let mobileErrorsType = [];
      let totalMobileErrors = [];
      this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
        const currentOrder = formData[details.key] ? formData[details.key] : {};
        currentOrder.customer_phone_one = formatePhoneNo(
          currentOrder.customer_phone_one
        );
        currentOrder.customer_phone_two = formatePhoneNo(
          currentOrder.customer_phone_two
        );
        const appointments = [];
        const accessorials = [];
        // const preferences = currentOrder.preferences || []
        const { errors: SlotErrors, appointments: slots } = validateslots(
          currentOrder.appointments,
          this.state.isMilitaryTime
        );
        if (SlotErrors.length > 0) {
          // validationErrors = [].concat(validationErrors, errors)
          errors = [].concat(
            errors,
            SlotErrors.map(
              (error) => error + " In " + details.label + " details"
            )
          );
        }
        if (slots.length > 0) {
          appointments.push(...slots);
        }

        if (isEmpty(currentOrder.type_of_order)) {
          currentOrder.type_of_order = details.type_of_order;
        }

        let allRequiredFields = [...requiredFields];
        // if lkt org, add company name to required fields
        if (this.props.currentOrg.code === 'LK') {
          allRequiredFields.push({
            form_field: 'company_name',
            ui_name: I18n.t('general.company_name'),
            isRequired: true,
            inputType: 'text',
          })
        }
        // if (
        //   this.state.config[
        //     I18n.t("configurations.move_to_exception_on_invalid_number")
        //   ] === "true"
        // ) {
        //   allRequiredFields = [].concat(allRequiredFields, mobileFields);
        // }

        const customerErrors = doValidate(
          allRequiredFields,
          currentOrder,
          `In ${details.label} details, `
        );
        let orderErrors = [];
        if (index === 0) {
          orderErrors = doValidate(this.state.orderRequiredFields, currentOrder);
        }
        const csLocation =
          currentOrder.cs_location && currentOrder.cs_location.l_address
            ? currentOrder.cs_location.l_address
            : {};
        const addressErrors = validateAddressForm(csLocation);
        validationErrors = [].concat(
          validationErrors,
          orderErrors,
          errors,
          customerErrors,
          !isEmpty(addressErrors)
            ? [`${addressErrors} in ${details.label} Address`]
            : []
        );
        // if (index === 0) {
        //   if (!isEmpty(currentOrder.reference_1_type)) {
        //     if (isEmpty(currentOrder.reference_1)) {
        //       validationErrors.push(
        //         `${I18n.t("order.reference_value")} 1 is required`
        //       );
        //     }
        //   }
        //   if (!isEmpty(currentOrder.reference_2_type)) {
        //     if (isEmpty(currentOrder.reference_2)) {
        //       validationErrors.push(
        //         `${I18n.t("order.reference_value")} 2 is required`
        //       );
        //     }
        //   }
        // }

        processedData[details.key] = {};
        processedData[details.key].appointments = [...slots];
        // processedData[details.key].preferences = [...appointments]
        processedData[details.key].accessorials = [...accessorials];
        processedData[details.key].csLocation = { ...csLocation };
      });
      // validating based on setting in the configuration

      if (isValidateMobile === "true") {
        const mobileErrors = this.validateMobileNumbs(formData, true);
        if (
          mobileErrors.nonReqErrors.length === 0 &&
          validationErrors.length === 0 && // present errors other than mobile
          mobileErrors.mobileErrorsType.length > 0
        ) {
          this.showExpMoveMsg(
            mobileErrors.mobileErrorsType,
            formData,
            processedData,
            validationErrors
          );
        } else {
          validationErrors = [].concat(validationErrors, mobileErrors.errors);
          this.handleSave(formData, processedData, validationErrors);
        }
      } else {
        // if account is not at selected
        this.handleSave(formData, processedData, validationErrors);
      }
    }
  };

  isDeliveryBeforePickup(locations) {
    // Extract the pickup and delivery appointments
    if (locations.length < 2) {
      return false;
    }

    // if both slots are "ANY" then return false
    // if(locations.length > 1 && locations[0]?.appointments[0]?.slots[0] === 'ANY' && locations[1]?.appointments[0]?.slots[0] === 'ANY'){
    //   return false;
    // }

    const pickupAppt = _.get(
      locations.find((loc) => loc.type_of_loc === "PICKUP"),
      "appointments[0]"
    );
    const deliveryAppt = _.get(
      locations.find((loc) => loc.type_of_loc === "DELIVERY"),
      "appointments[0]"
    );
    const pickupSlot = _.get(pickupAppt, "slots[0]", "");
    const deliverySlot = _.get(deliveryAppt, "slots[0]", "");
    const pickupDateTime = moment(_.get(pickupAppt, "appt_date", ""));
    const deliveryDateTime = moment(_.get(deliveryAppt, "appt_date", ""));

    const areNotSameDay = !pickupDateTime.isSame(deliveryDateTime, "day");
    const isAfter = pickupDateTime.isAfter(deliveryDateTime, "day");

    if (areNotSameDay && !isAfter) {
      return false;
    }
    if (
      !isAfter &&
      !isEmpty(pickupSlot) &&
      !isEmpty(deliverySlot) &&
      deliverySlot !== "CUSTOM" &&
      pickupSlot === deliverySlot
    ) {
      return false;
    }

    const pickupTime = moment.isMoment(_.get(pickupAppt, "start_time"))
      ? _.get(pickupAppt, "start_time")
      : moment(_.get(pickupAppt, "start_time"), "HH:mm:ss");
    const deliveryTime = moment.isMoment(_.get(deliveryAppt, "start_time"))
      ? _.get(deliveryAppt, "start_time")
      : moment(_.get(deliveryAppt, "start_time"), "HH:mm:ss");

    // Combine the date and time for pickup and delivery
    const pickupDateTimeWithTime = moment(pickupDateTime).set({
      hour: pickupTime.hours(),
      minute: pickupTime.minutes(),
      second: pickupTime.seconds(),
    });
    const deliveryDateTimeWithTime = moment(deliveryDateTime).set({
      hour: deliveryTime.hours(),
      minute: deliveryTime.minutes(),
      second: deliveryTime.seconds(),
    });

    // Compare the dates and times and return true if delivery is before pickup
    return deliveryDateTimeWithTime.isBefore(pickupDateTimeWithTime);
  }

  handleValidateLocationOrder = () => {
    const { customerDetails, formData } = this.state;
    this.setState({ inProgress: true, errors: [] });
    const errors = [];
    const typeOfOrder = this.state.formData.type_of_order;
    const isTransferOrder = typeOfOrder === "T";
    const processedData = {};
    const isValidateMobile =
      this.state.config[
      I18n.t("configurations.move_to_exception_on_invalid_number")
      ];
    if (!isEmpty(typeOfOrder)) {
      let validationErrors = [];
      this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
        let orderErrors = [];
        let locationErrors = [];
        const currentOrder = formData[details.key] ? formData[details.key] : {};
        const fieldsToExclude = isTransferOrder
          ? ["weight", "los"]
          : ["weight"];

        const transferOrderRequiredFields = this.state.orderRequiredFields.filter(
          (i) => !fieldsToExclude.includes(i.form_field)
        );
        orderErrors = doValidate(transferOrderRequiredFields, currentOrder);
        processedData[details.key] = { locations: [] };
        currentOrder.customer_phone_one = formatePhoneNo(
          currentOrder.customer_phone_one
        );
        currentOrder.customer_phone_two = formatePhoneNo(
          currentOrder.customer_phone_two
        );
        let totalStops = currentOrder?.locations
          ? currentOrder.locations.length
          : 0;
        if (!isEmpty(currentOrder.source_wh)) {
          totalStops = totalStops + 1;
        }
        if (!isEmpty(currentOrder.destination_wh)) {
          totalStops = totalStops + 1;
        }
        if (totalStops < 2) {
          orderErrors.push("Atleast two stops should be required");
        }
        if (currentOrder.locations) {
          let isFilled = true;
          const hasPickupLocation = _.find(currentOrder.locations, {
            type_of_loc: "PICKUP",
          });
          if (!hasPickupLocation) {
            orderErrors.push("Pickup stop is required");
          }


          currentOrder.locations.forEach((loc, locIndex) => {
            if (
              locIndex === 1 &&
              !this.state.isDeliveryRequired &&
              isTransferOrder
            ) {
              return;
            }

            const location = {};
            const appointments = loc.appointments || [];
            const csLocation =
              loc.cs_location && loc.cs_location.l_address
                ? loc.cs_location.l_address
                : {};
            const isDeliveryOrder = loc.type_of_loc === "DELIVERY";
            if (isDeliveryOrder) {
           
              
              const detailsObject = _.pick(loc, [
                "customer_first_name",
                "customer_last_name",
                "customer_email",
                "customer_phone_one",
                "customer_phone_two",
                "company_name",
                "weight",
                "quantity",
                "pallets",
                "surface_area",
                'acc_loc_code',
                "service_duration",
                "los_code",
                "release_status",
                "is_release",
                "is_predefined_loc"
              ]);
              const addressObject = _.pick(csLocation, [
                "address_line1",
                "address_line2",
                "city",
                "state",
                "country",
                "zipcode",
              ]);
              const isAppointfilled =
                appointments.length > 0
                  ? checkIfAppointmenfFilled(appointments[0])
                  : false;
              isFilled =
                isObjHaveValues(detailsObject) ||
                isObjHaveValues(addressObject) ||
                isAppointfilled;
              if (!isFilled) {
                return;
              }
            }
            const { errors: SlotErrors, appointments: slots } = validateslots(
              loc.appointments,
              this.state.isMilitaryTime
            );
            if (SlotErrors.length > 0) {
              locationErrors = [].concat(
                locationErrors,
                SlotErrors.map(
                  (error) => error + " in " + loc.type_of_loc + " details"
                )
              );
            }
            // check level of service if empty in location
            if (this.state.formData.type_of_order !== "MS") {
              if (_.isEmpty(loc.los_code)) {
                locationErrors.push(
                  loc.type_of_loc + " Level of service is required"
                );
              }
            }

            if (!isDeliveryOrder) {
              if (loc.is_recovery && _.isEmpty(loc.recovery_status)) {
                locationErrors.push(
                  "Recovery status is required in " +
                  loc.type_of_loc +
                  " details"
                );
              }
            } else if (isDeliveryOrder) {
              if (loc.is_release && _.isEmpty(loc.release_status)) {
                locationErrors.push(
                  "Release status is required in " +
                  loc.type_of_loc +
                  " details"
                );
              }
            }

            if (isEmpty(currentOrder.type_of_order)) {
              currentOrder.type_of_order = details.type_of_order;
            }

            let allRequiredFields = [...requiredFields];

            // if (
            //   this.state.config[
            //     I18n.t("configurations.move_to_exception_on_invalid_number")
            //   ] === "true"
            // ) {
            //   allRequiredFields = [].concat(allRequiredFields, mobileFields);
            // }

            const customerErrors = doValidate(
              allRequiredFields,
              loc,
              `In ${loc.type_of_loc} details, `
            );
            const addressErrors = validateAddressForm(csLocation);
            // if no company name, add error
            if (this.props.currentOrg.code === 'LK' && isEmpty(loc.company_name)) {
              locationErrors.push("Company name is required in " + loc.type_of_loc + " details")
            }

            // if (index === 0) {
            //   if (!isEmpty(currentOrder.reference_1_type)) {
            //     if (isEmpty(currentOrder.reference_1)) {
            //       validationErrors.push(
            //         `${I18n.t("order.reference_value")} 1 is required`
            //       );
            //     }
            //   }
            //   if (!isEmpty(currentOrder.reference_2_type)) {
            //     if (isEmpty(currentOrder.reference_2)) {
            //       validationErrors.push(
            //         `${I18n.t("order.reference_value")} 2 is required`
            //       );
            //     }
            //   }
            // }
            locationErrors = [].concat(
              locationErrors,
              errors,
              customerErrors,
              !isEmpty(addressErrors)
                ? [`${addressErrors} in location ${loc.type_of_loc} Address`]
                : []
            );
            location.appointments = [...slots];
            location.service_duration = loc.service_duration;
            location.weight = loc.weight;
            location.quantity = loc.quantity;
            location.pallets = loc.pallets;
            location.surface_area = loc.surface_area;
            location.los_code = loc.los_code;
            location.l_address = loc.cs_location?.l_address
              ? { ...loc.cs_location.l_address }
              : {};
            location.type_of_loc = loc.type_of_loc;
            location.release_status = loc.release_status;
            location.details = _.pick(loc, [
              "id",
              "customer_first_name",
              "customer_last_name",
              "customer_email",
              "customer_phone_one",
              "customer_phone_two",
              "company_name",
              "weight",
              "quantity",
              "pallets",
              "surface_area",
              'acc_loc_code',
              "l_type",
              "type_of_loc",
              "is_recovery",
              "recovery_status",
              "is_release",
              "is_predefined_loc"
            ]);
            processedData[details.key].locations.push(location);
          });
          if (isFilled && this.isDeliveryBeforePickup(currentOrder.locations)) {
            locationErrors.push(
              "Delivery date and time should be after pickup date and time"
            );
          }
          // if(locationErrors.length === 0){
          //   const priorValidaityErrors  = findAppointmentErros(currentOrder.locations);
          //   locationErrors = [].concat(locationErrors, priorValidaityErrors);
          // }
        }
        validationErrors = [].concat(
          validationErrors,
          orderErrors,
          locationErrors
        );
      });
      // validating based on setting in the configuration

      if (this.state.isTemplate) {
        // if teplatename is empty throw error
        if (isEmpty(this.state.templateName)) {
          validationErrors.push("Template name is required")
        }
      }

      if (isValidateMobile === "true") {
        const mobileErrors = this.validateMobileNumbs(formData, true);
        if (
          mobileErrors.nonReqErrors.length === 0 &&
          validationErrors.length === 0 && // present errors other than mobile
          mobileErrors.mobileErrorsType.length > 0
        ) {
          this.showExpMoveMsg(
            mobileErrors.mobileErrorsType,
            formData,
            processedData,
            validationErrors
          );
        } else {
          validationErrors = [].concat(validationErrors, mobileErrors.errors);
          this.handleSave(formData, processedData, validationErrors);
        }
      } else {
        // if account is not at selected
        this.handleSave(formData, processedData, validationErrors);
      }
    }
  };

  handleSave = (formData, processedData, validationErrors) => {
    const typeOfOrder = this.state.formData.type_of_order;
    const { accounts, levelOfServices, customerDetails } = this.state;
    if (validationErrors.length === 0) {
      // if (appointments.length > 0 || (customerDetails.custom_appt_required && customerDetails.custom_appt_notes)) {
      if (!this.state.isNew) {
        let data = {
          type_of_order: formData.type_of_order,
          dispatch_status: formData.dispatch_status,
          org_id: userStore.getStateValue("selectedOrg"),
          // wh_storage: this.state.storageNeeded
        };
        let isLocationBased = false;
        this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
          const currentOrder = formData[details.key];
          currentOrder.customer_phone_one = formatePhoneNo(
            currentOrder.customer_phone_one
          );
          currentOrder.customer_phone_two = formatePhoneNo(
            currentOrder.customer_phone_two
          );
          if (details.is_location_based) {
            isLocationBased = true;
            data.wh_storage =
              processedData[details.key]?.locations?.length === 1
                ? true
                : this.state.storageNeeded;
            const payload = setLHCreatePayload(
              processedData[details.key],
              currentOrder,
              this.state.suppres_schedule_dates,
              this.state.skip_warn_hawb_uniqness,
              accounts,
              levelOfServices,
              this.state.nsr
            );
            data = { ...data, ...payload };
          } else {
            data[details.key] = setEditPayload(
              processedData[details.key],
              currentOrder,
              this.state.suppres_schedule_dates,
              this.state.skip_warn_hawb_uniqness,
              accounts,
               levelOfServices,
              this.state.nsr,
            );
          }
          // data[details.key].order_weight = getWeightUnits({ account_code: currentOrder.account_code, accountUnitContext: this.props.accountUnitContext.accountWeightUnits }) == "kgs" ? convertToPounds(data[details.key].order_weight, false) : data[details.key].order_weight;
          // dont convert just send as it is
          // data[details.key].order_weight = data[details.key].order_weight;
        });
        data.allow_changes_to_linehaul = this.state.allowChanges
        let func = null;
        if (isLocationBased) {
          if (this.state.formData.type_of_order === "MS") {
            func = TransferOrderApi.multistop;
          } else {
              func = TransferOrderApi.manage;
          } 
        } else {
            func = saveAppointments;
        }
        func(data, this.state.isNew).then((result) => {
          if (result.success) {
            this.setState({
              inProgress: false,
              errors: [],
              suppres_schedule_dates: "false",
              skip_warn_hawb_uniqness: "false",
              changedOrderType: false,
              updatedKey: randomNumber(),
            });
            if (
              this.state.callFrom === "preplan" ||
              this.state.callFrom === "dispatch"
            ) {
              const orders = [];
              this.state.orderConfig.orderTypes[
                this.state.formData.type_of_order
              ].types.forEach((details, index) => {
                if (this.state.formData[details.key]) {
                  orders.push({
                    ...this.state.formData[details.key],
                    appointments: processedData[details.key].appointments,
                  });
                }
              });
              this.props.handleSuccess(
                I18n.t("messages.order_saved"),
                this.state.isOutOfPreplan,
                true, //this.state.isModified
                orders
              );
            } else {
              // alertMessage(I18n.t('messages.order_saved'));
              this.props.handleSuccess(I18n.t("messages.order_saved"), false);
              this.getOrderDetails();
              if (this.state.orderItems && this.state.orderItems.length > 0) {
                this.setState({ activeKey: "accessorials" });
              }
            }
          } else {
            if (result.warnings && result.warnings.length > 0) {
              this.capacityError(result.warnings);
            }
            this.setState({
              inProgress: false,
              errors:
                result.warnings && result.warnings.length > 0
                  ? []
                  : result.errors,
              suppres_schedule_dates: "false",
              skip_warn_hawb_uniqness: "false",
            });
          }
        })
        this.setState({ allowChanges: "false" })
      } else {
        let data = {
          type_of_order: formData.type_of_order,
          dispatch_status: formData.dispatch_status,
          is_template: this.state.isTemplate,
        };
        if (this.state.isTemplate) {
          data.template_name = this.state.templateName;
        }
        //data.wh_storage = this.state.storageNeeded
        let isLocationBased = false;
        this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
          const currentOrder = formData[details.key];
          currentOrder.customer_phone_one = formatePhoneNo(
            currentOrder.customer_phone_one
          );
          currentOrder.customer_phone_two = formatePhoneNo(
            currentOrder.customer_phone_two
          );
          if (details.is_location_based) {
            isLocationBased = true;
            data.wh_storage =
              processedData[details.key]?.locations?.length === 1
                ? true
                : this.state.storageNeeded;
            const payload = setLHCreatePayload(
              processedData[details.key],
              currentOrder,
              this.state.suppres_schedule_dates,
              this.state.skip_warn_hawb_uniqness,
              accounts,
              levelOfServices,
              this.state.nsr
            );
            data = { ...data, ...payload };
          } else {
            data[details.key] = setCreatePayload(
              processedData[details.key],
              currentOrder,
              this.state.suppres_schedule_dates,
              this.state.skip_warn_hawb_uniqness,
              accounts,
              levelOfServices,
              this.state.nsr,
            );
          }
          // data[details.key].order_weight = getWeightUnits({ account_code: currentOrder.account_code, accountUnitContext: this.props.accountUnitContext.accountWeightUnits }) == "kgs" ? convertToPounds(data[details.key].order_weight, false) : data[details.key].order_weight;
          // dont convert just send as it is
        });
        let func = null;
        if (isLocationBased) {
          if (this.state.formData.type_of_order === "MS") {
            func = TransferOrderApi.multistop;
          } else {
              func = TransferOrderApi.manage;
          } 
        } else {
              func = createOrder;
        }
        func(data, this.state.isNew).then((result) => {
          if (result.success) {
            const orders = result.orders || [];
            const currentId = orders.length > 0 ? orders[0].id : "";
            this.setState(
              {
                inProgress: false,
                errors: [],
                isNew: false,
                suppres_schedule_dates: "false",
                skip_warn_hawb_uniqness: "false",
                currentId,
                updatedKey: randomNumber(),
              },
              () => {
                if (
                  this.state.callFrom === "preplan" ||
                  this.state.callFrom === "dispatch"
                ) {
                  this.props.handleSuccess(
                    I18n.t("messages.order_saved"),
                    this.state.isOutOfPreplan
                  );
                } else {
                  this.setState({ inProgress: false, errors: [] });
                  // this.handleAddItemClick();
                  this.props.handleSuccess(
                    `${I18n.t("messages.order_saved")}. ${!isLocationBased ? I18n.t("messages.create_items") : ""
                    }`,
                    true,
                    currentId
                  );
                  this.getOrderDetails(() => {
                    this.handleAddItemClick();
                  });
                }
              }
            );
          } else {
            if (result.warnings && result.warnings.length > 0) {
              this.capacityError(result.warnings);
            }
            this.setState({
              inProgress: false,
              errors:
                result.warnings && result.warnings.length > 0
                  ? []
                  : result.errors,
              suppres_schedule_dates: "false",
              skip_warn_hawb_uniqness: "false",
            });
          }
        });
      }
    } else {
      this.setState({ errors: validationErrors, inProgress: false });
    }
  };

  capacityError = (warnings) => {
    const capacityWarnings = warnings.filter(
      (err) => err.indexOf("capacity") >= 0 || err.indexOf("exceeded") >= 0
    );
    const hawbWarnings = warnings.filter(
      (err) => err.indexOf("HAWB") >= 0 || err.indexOf("MAWB") >= 0
    );
    confirm({
      width: "60%",
      title: I18n.t("messages.go_ahead_confirm"),
      content: (
        <Row>
          {capacityWarnings.length > 0 && (
            <div className="marginTop10">
              <Col>
                <h4>
                  <Icon type="warning" className="textRed" />
                  &nbsp;&nbsp;{I18n.t("order.capacity_error")}
                </h4>
              </Col>
              {capacityWarnings.map((err, index) => (
                <Col
                  key={`capacity_err${index + 1}`}
                  className="fontWeight500 smallContent paddingLeft25"
                >
                  {err}
                </Col>
              ))}
            </div>
          )}
          {hawbWarnings.length > 0 && (
            <div className="marginTop10">
              {hawbWarnings.map((err, index) => (
                <Col
                  key={`hawb_err${index + 1}`}
                  className="fontWeight500 smallContent"
                >
                  <h4>
                    <Icon type="warning" className="textRed" />
                    &nbsp;&nbsp;{err}
                  </h4>
                </Col>
              ))}
            </div>
          )}
        </Row>
      ),
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        this.setState(
          {
            suppres_schedule_dates: "true",
            skip_warn_hawb_uniqness: "true",
          },
          () => {
            this.handleValidate();
          }
        );
      },
      onCancel: () => { },
    });
  };
  showExpMoveMsg = (warnings, formData, processedData, validationErrors) => {
    confirm({
      width: "60%",
      title: (
        <Row>
          <Col key={`invalid_err`} className="fontWeight500 smallContent">
            {`Invalid Mobile Number${warnings.length > 1 ? "s" : ""}`}.&nbsp;
            {I18n.t("configurations.move_to_exception_on_invalid_warng_msg", {
              field: warnings.join(", "),
              haveMore: warnings.length === 1 ? "" : "s",
            })}
          </Col>
        </Row>
      ),
      content: <div>{I18n.t("messages.proceed_confirm")}</div>,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        this.handleSave(formData, processedData, validationErrors);
      },
      onCancel: () => {
        this.setState({
          inProgress: false,
        });
        return false;
      },
    });
  };

  handleContactSaved = (contact, emails = []) => {
    // This function should update your contact list
    // You can fetch the updated list from the server or update it from local state
    if (contact?.id) {
      emails.push(contact.id);
      this.handleOnChange("account_manager_emails", emails);
    }
    this.getAccountContactEmails(); // Replace this with your logic
  };

  fomatString = (str, format = "") => doFormate(str, format);

  //  clearPreference = (orderType, locationIndex, rowPosition) => {
  //   const preferences = [...this.state.formData[orderType].preferences]
  //   preferences[rowPosition].item_preference = ''
  //   preferences[rowPosition].item_option = []
  //   preferences[rowPosition].confirmed = false
  //   preferences[rowPosition].start_time = null
  //   preferences[rowPosition].end_time = null
  //   preferences[rowPosition].has_expedite = false
  //   // this.setState({
  //   //   preferences,
  //   // });
  //   this.handleOrderDetailsChange(orderType, 'preferences', preferences)
  // }
  clearPreferenceConfirm = (orderType, locationIndex, rowPosition) => {
    const dataObject = retrieveDataObject(
      this.state.formData[orderType],
      locationIndex
    );
    const preferences = [...dataObject.preferences];
    // const preferences = [...this.state.formData[orderType].preferences]
    if (preferences[rowPosition].confirmed) {
      preferences[rowPosition].confirmed = false;
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "preferences",
        preferences
      );
    }
  };

  renderItemForm = () => {
    const formData = { ...this.state.formData };
    let account = {};
    const accountIndex = _.findIndex(this.state.accounts, {
      code: this.state.formData.account_code,
    });
    if (accountIndex >= 0) {
      account = this.state.accounts[accountIndex];
    }
    let quantity = 0;
    this.state.orderConfig.orderTypes[formData.type_of_order].types
      .filter((type, index) => index === 0)
      .forEach((details, index) => {
        if (!isEmpty(formData[details.key])) {
          quantity = quantity + formData[details.key].quantity;
        }
      });
    formData.quantity = quantity;

    return (
      <BaseModal
        title={`${this.state.isItemNew
            ? I18n.t("general.add")
            : I18n.t("general.update")
          } ${I18n.t("order.item")}`}
        onCancel={() => this.closeItemForm()}
        width="60%"
        style={{ top: 50 }}
        maskClosable={false}
      >
        <ItemForm
          item={this.state.currentItem}
          order={formData}
          close={this.state.closeItemForm || []}
          saveItem={this.handleUpdateItem}
          account={account}
          isItemNew={this.state.isItemNew}
          items={this.state.orderItems}
          orderConfig={this.props.orderConfig}
        />
      </BaseModal>
    );
  };

  closeItemForm = () => {
    this.setState({
      currentItem: {},
      showItemForm: false,
      currentParamType: "",
    });
  };

  handleItemClick = (id, orderType) => {
    const item = _.find(this.state.orderItems, { item_id: id });
    if (!isEmpty(item)) {
      const dimObject = _.pick(item, [
        "item_width",
        "item_height",
        "item_length",
      ]);
      this.setState({
        currentItem: { ...item, dim: { ...dimObject } },
        // showItemForm: true,
        isItemNew: false,
        currentParamType: !isEmpty(orderType) ? orderType : [],
      });
    }

    if (orderType === "pickup_params") {
      this.PickupItemDetailsFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      this.DeliveryItemDetailsFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  handleAddItemClick = (orderType) => {
    this.setState({
      currentItem: {},
      showItemForm: true,
      isItemNew: true,
      currentParamType: !isEmpty(orderType) ? orderType : [],
    });
  };
  handleDeleteClick = (id, orderType) => {
    const { formData } = this.state;
    this.setState({ isDeletingItem: true })
    const typeOfOrder = formData.type_of_order;
    deleteItem(id, this.state.isNew).then((result) => {
      if (result.success) {
        alertMessage(
          result.message ? result.message : I18n.t("messages.deleted")
        );
        this.setUpdatedQuoteAmount();
        if (!isEmpty(orderType) && !isEmpty(formData[orderType])) {
          const orderTypeItems = formData[orderType].orderItems
            ? formData[orderType].orderItems
            : [];
          const itemIndex = _.findIndex(orderTypeItems, ["item_id", id]);
          if (itemIndex >= 0) {
            orderTypeItems.splice(itemIndex, 1);
            formData[orderType].orderItems = [...orderTypeItems];
          }
        }
        const itemIndex = _.findIndex(this.state.orderItems, ["item_id", id]);
        if (itemIndex >= 0) {
          const { orderItems } = this.state;
          orderItems.splice(itemIndex, 1);
          this.setState(
            {
              orderItems,
              inProgress: false,
              errors: [],
              isModified: true,
              formData,
            },



            () => {
              {
                this.state.orderConfig.orderTypes[typeOfOrder].types.forEach(
                  (details, index) => {
                    this.setState((prevState) => {
                      let formData = Object.assign({}, prevState.formData); // creating copy of state variable formData
                      formData[details.key].weight = result.order_weight; // update the weight property, assign a new value
                      formData[details.key].weight_in_kgs = result.weight_in_kgs;
                      formData[details.key].mileage = result.mileage;
                      formData[details.key].quantity = result.order_quantity; // update the quantity property, assign a new value
                      formData[details.key].surface_area = result.order_area; // update the surface_area property, assign a new value
                      formData[details.key].pallets = result.order_pallets;
                      return { formData }; // return new formData object
                    });
                  }
                );
              }
              this.setState({ isDeletingItem: false });
            }
          );
        } else {
          this.setState({ inProgress: false, errors: [], isDeletingItem: false });
        }

      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
        this.setState({ isDeletingItem: false });
      }
    });
  };

  setItemtoFormData = (item, updatedData) => {
    const { formData } = this.state;
    const processingData = !isEmpty(updatedData) ? updatedData : formData;
    const typeOfOrder = processingData.type_of_order;
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
      if (
        processingData[details.key] &&
        item.customer_order_id === processingData[details.key].id
      ) {
        const items = processingData[details.key].orderItems
          ? processingData[details.key].orderItems
          : [];
        const itemIndex = _.findIndex(items, ["item_id", item.item_id]);
        if (itemIndex >= 0) {
          items[itemIndex] = item;
        } else {
          items.push(item);
        }
        processingData[details.key].orderItems = [...items];
      }
    });
    return processingData;
  };

  setUpdatedQuoteAmount = () => {
    const { formData } = this.state;
    const typeOfOrder = formData.type_of_order;
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
      if (formData[details.key] && formData[details.key].id) {
        this.getOrderQuote(formData[details.key].id, details.key);
      }
    });
  };

  clearActiveItem = () => {
    this.setState({
      currentItem: {},
      showItemForm: false,
      isItemNew: false,
      currentParamType: "",
    });
  };

  handleUpdateItemGrouping = (items) => {
    const { orderItems, formData } = this.state;
    const typeOfOrder = formData.type_of_order;
    let updatedData = _.cloneDeep(formData);
    if (_.isArray(items)) {
      items.forEach((item) => {
        const itemIndex = _.findIndex(orderItems, ["item_id", item.id]);
        if (itemIndex >= 0) {
          orderItems[itemIndex].item_group_id = item.item_group_id;
          updatedData = this.setItemtoFormData(
            orderItems[itemIndex],
            updatedData
          );
        }
      });
    }
    this.setState(
      {
        orderItems,
        isModified: true,
        formData: _.cloneDeep(updatedData),
        groupItems: false,
        isItemNew: false,
        currentItem: {},
      },
      () => {
        this.setUpdatedQuoteAmount();
      }
    );
  };

  handleUpdateItem = (
    items,
    closeForm = false,
    order_area,
    order_quantity,
    order_weight,
    order_weight_in_kgs,
    mileage
  ) => {
    const { orderItems, formData } = this.state;
    const typeOfOrder = formData.type_of_order;
    let updatedData = _.cloneDeep(formData);
    if (_.isArray(items)) {
      items.forEach((item) => {
        const itemIndex = _.findIndex(orderItems, ["item_id", item.item_id]);
        if (itemIndex >= 0) {
          orderItems[itemIndex] = item;
        } else {
          orderItems.push(item);
        }
        updatedData = this.setItemtoFormData(item, updatedData);
      });
    } else {
      const itemIndex = _.findIndex(orderItems, ["item_id", items.item_id]);
      if (itemIndex >= 0) {
        orderItems[itemIndex] = items;
      } else {
        orderItems.push(items);
      }
      updatedData = this.setItemtoFormData(items, updatedData);
    }
    {
      this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
        updatedData[details.key].weight = order_weight;
        updatedData[details.key].weight_in_kgs = order_weight_in_kgs;
        updatedData[details.key].mileage = mileage;
        updatedData[details.key].quantity = order_quantity;
        updatedData[details.key].surface_area = order_area;
      });
    }
    this.setState(
      {
        orderItems,
        isModified: true,
        formData: _.cloneDeep(updatedData),
      },
      () => {
        this.setUpdatedQuoteAmount();
        if (closeForm) {
          this.closeItemForm();
        }
      }
    );
  };

  renderExceptions = (orderType) => {
    const { formData } = this.state;
    const currentOrder = formData[orderType] ? formData[orderType] : {};
    const orderExceptions = currentOrder.order_exceptions || [];
    return (
      <ExceptionsList
        data={orderExceptions}
        pagination={{ position: "none" }}
        size="small"
        timeZone={currentOrder.order_timeZoneId}
        timeZoneName={currentOrder.order_tz_short_name}
        isMilitaryTime={this.state.isMilitaryTime}
        scroll={{ y: this.props.fromIndividualScreen ? "calc(100vh - 290px)" : "calc(100vh - 180px)" }}
      />
    );
  };

  handleWarehouseChange = () => {
    const formData = _.cloneDeep(this.state.formData)
    const currentOrder = formData[formData?.type_of_order] || {};
    const pickup_warehouse_code = formData?.pickup_params?.warehouse_code;
    const delivery_warehouse_code = formData?.drop_params?.warehouse_code;
    const transfer_warehouse_code = formData?.transfer_params?.warehouse_code;
    const typeOfOrder = formData?.type_of_order;
  
    const origin_port =
      typeOfOrder === "T"
        ? transfer_warehouse_code
        : typeOfOrder === "R"
        ? pickup_warehouse_code
        : currentOrder?.origin_port || "";
  
    const destination_port =
      typeOfOrder === "T"
        ? transfer_warehouse_code
        : typeOfOrder === "D"
        ? delivery_warehouse_code
        : currentOrder?.destination_port || "";
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
          formData[details.key]["origin_port"] = origin_port;
          formData[details.key]["destination_port"] = destination_port;
    });
  
    this.setState({
      formData,
    });
  };
  
  

  renderLabelInfo = (orderType, size = 6) => {
    const { formData, elementSize, airports } = this.state;
    const currentOrder = formData[orderType] ? formData[orderType] : {};
    return (
      <Collapse
        defaultActiveKey={["labelInfo"]}
        // style={{ height: "auto" }}
        expandIconPosition="right"
        className="marginTop10 customCollapse"
      >
        <Panel
          header={"Label Info"}
          key="labelInfo"
          // style={{ height: currentOrder.type_of_order === "T" ?110 : 81 }}
          style={{ height: 110 }}
        >
          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Row gutter={16}>
                <Col sm={24} xs={24} md={size} lg={size}>
                  <SideBySideFormItem
                    label="Origin"
                    labelCol={{ lg: 6, xl: 6, xxl: 6 }}
                    wrapperCol={{ lg: 18, xl: 18, xxl: 18 }}
                  >
                    <Input
                      value={currentOrder.origin_port || ""}
                      onChange={(e) =>
                        this.handleOnChange("origin_port", e.target.value)
                      }
                      size={elementSize}
                      className="textUpperCase
                      "
                    />
                  </SideBySideFormItem>
                </Col>
                <Col sm={24} xs={24} md={size} lg={size}>
                  <SideBySideFormItem label="Destination">
                    <Input
                      value={currentOrder.destination_port || ""}
                      onChange={(e) =>
                        this.handleOnChange("destination_port", e.target.value)
                      }
                      size={elementSize}
                      className="textUpperCase"
                    />
                  </SideBySideFormItem>
                </Col>
                <Col sm={24} xs={24} md={size} lg={size}>
                  <SideBySideFormItem label="IT Number">
                    <Input
                      value={currentOrder.it_number}
                      onChange={(e) =>
                        this.handleOnChange("it_number", e.target.value)
                      }
                      size={elementSize}
                    />
                  </SideBySideFormItem>
                </Col>
                <Col sm={24} xs={24} md={size} lg={size}>
                  <SideBySideFormItem
                    label="Routing"
                    labelCol={{ lg: 7, xl: 7, xxl: 7 }}
                    wrapperCol={{ lg: 17, xl: 17, xxl: 17 }}
                  >
                    <Input
                      value={currentOrder.routing}
                      onChange={(e) =>
                        this.handleOnChange("routing", e.target.value)
                      }
                      size={elementSize}
                    />
                  </SideBySideFormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    );
  };

  giveWeightUnitFullForm = (val) => {
    if (val === "kgs") {
      return "Kilograms (kgs)";
    } else if (val === "lbs") {
      return "Pounds (lbs)";
    }

    return "Pounds (lbs)"
  }

  checkForWeightUnitChange = (e) => {
    const existingWeightUnit = _.get(this.props.accountUnitContext.accountWeightUnits, [this.state.formData.account_code], 'lbs');
    const AccountWeightUnits = _.get(this.props.accountUnitContext.accountWeightUnits, e, 'lbs');

    const orderType = this.state.orderConfig.orderTypes[this.state.formData.type_of_order].types[0].key;
    const existingWeight = this.state.formData[orderType].weight;

    if (existingWeightUnit !== AccountWeightUnits && !isEmpty(existingWeight)) {
      return this.handleWeightUnitConfirmationModalOnChange(true, e);
    }

    return this.handleOnChange("account_code", e);
  }

  handleWeightUnitConfirmationModalOnChange = (val, e = "") => {
    this.setState({ weightUnitConfirmationModalOpen: val, tempAccountCode: val ? e : "" });
  }


  handleWeightUnitConfirmationModalOnOk = () => {
    const units = getWeightUnits({
      account_code: this.state.tempAccountCode,
      accountUnitContext: this.props.accountUnitContext.accountWeightUnits
    });
    const orderType = this.state.orderConfig.orderTypes[ this.state.customerDetails.type_of_order ].types[ 0 ].key;

    let revisedWeight;
    if (units === "kgs") {
      revisedWeight = convertToKilograms(this.state.formData[orderType].weight, false);
    } else if (units === "lbs") {
      revisedWeight = convertToPounds(this.state.formData[orderType].weight, false);
    }

    const orderItems = !_.isEmpty(this.state.orderItems) ? this.state.orderItems.map((item) => {
      return {
        ...item,
        item_weight: units === "kgs" ? convertToKilograms(item.item_weight, false) : convertToPounds(item.item_weight, false),
        total_weight: units === "kgs" ? convertToKilograms(item.total_weight, false) : convertToPounds(item.total_weight, false),
        dim_weight: units === "kgs" ? convertToKilograms(item.dim_weight, false) : convertToPounds(item.dim_weight, false),
      }
    }) : []

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [ orderType ]: {
          ...prevState.formData[ orderType ],
          weight: revisedWeight,
          orderItems
        }
      },
      orderItems,
      weightUnitConfirmationModalOpen: false,
    }), () => {
      this.handleOnChange("account_code", this.state.tempAccountCode);
    });
  };

  renderWeightUnitConfirmationModal = () => {
    const orderType = this.state.orderConfig.orderTypes[ this.state.customerDetails.type_of_order ].types[ 0 ].key;
    const currentWeight = _.get(this.state.formData, `${orderType}.weight`, 0);

    const existingWeightUnit = _.get(this.props.accountUnitContext.accountWeightUnits, [ this.state.formData.account_code ], 'lbs');
    const newWeightUnit = _.get(this.props.accountUnitContext.accountWeightUnits, [ this.state.tempAccountCode ], 'lbs');

    // Calculate converted weight
    const convertedWeight = newWeightUnit === "kgs" ?
      convertToKilograms(currentWeight, false) :
      convertToPounds(currentWeight, false);

    return (
      <Modal
        title={ I18n.t("general.weight_unit_confirmation") }
        visible={ this.state.weightUnitConfirmationModalOpen }
        onOk={ this.handleWeightUnitConfirmationModalOnOk }
        onCancel={ () => this.handleWeightUnitConfirmationModalOnChange(false) }
        width={ 400 }
      >
        <div style={ { textAlign: 'center', padding: '20px 0' } }>
          <Icon
            type="info-circle"
            theme="filled"
            style={ {
              fontSize: '32px',
              color: '#1890ff',
              marginBottom: '16px'
            } }
          />

          <h3 style={ {
            marginBottom: '24px',
            fontSize: '16px',
            fontWeight: 'normal'
          } }>
            The weight will be converted as follows:
          </h3>

          <div style={ {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px 0',
            gap: '16px'
          } }>
            <div style={ {
              padding: '12px 24px',
              background: '#f5f5f5',
              borderRadius: '4px',
              fontWeight: 'bold'
            } }>
              { currentWeight } { existingWeightUnit }
            </div>

            <Icon type="arrow-right" style={ { fontSize: '20px', color: '#8c8c8c' } } />

            <div style={ {
              padding: '12px 24px',
              background: '#e6f7ff',
              borderRadius: '4px',
              fontWeight: 'bold',
              border: '1px solid #91d5ff'
            } }>
              { convertedWeight } { newWeightUnit }
            </div>
          </div>

          <p style={ {
            marginTop: '24px',
            color: '#595959',
            fontSize: '14px'
          } }>
            Do you want to proceed with this conversion?
          </p>
        </div>
      </Modal>
    );
  };


  renderReferences = (orderType) => {
    const { formData, elementSize } = this.state;
    const currentOrder = formData[orderType] ? formData[orderType] : {};
    const { code = "" } = this.props.currentOrg;
    return (
      <Row gutter={16}>
        <Col xs={24} md={24}>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12} lg={12}>
              <SideBySideFormItem label={I18n.t("general.hawb")} require={code === I18n.t("account.hl_code") ? true : false}>
                <Input
                  value={currentOrder.hawb}
                  onChange={(e) => this.handleOnChange("hawb", e.target.value)}
                  size={elementSize}
                  className="textUpperCase"
                />
              </SideBySideFormItem>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <SideBySideFormItem label={I18n.t("general.mawb")}>
                <Input
                  value={currentOrder.mawb}
                  onChange={(e) => this.handleOnChange("mawb", e.target.value)}
                  size={elementSize}
                  className="textUpperCase"
                />
              </SideBySideFormItem>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <SideBySideFormItem
                label={`${I18n.t("order.reference_title_short")} 1`}
              >
                <Select
                  value={currentOrder.reference_1_type}
                  onChange={(e) => this.handleOnChange("reference_1_type", e)}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size={elementSize}
                >
                  <Select.Option key="ref" value="">
                    -- Select --
                  </Select.Option>
                  {this.state.references
                    .filter(
                      (ref) =>
                        !isEmpty(ref.order_reference_type) &&
                        ref.order_reference_type !=
                        currentOrder.reference_2_type
                    )
                    .map((refrence) => (
                      <Select.Option
                        key={refrence.id}
                        value={refrence.order_reference_type}
                      >
                        {refrence.order_reference_type}
                      </Select.Option>
                    ))}
                </Select>
              </SideBySideFormItem>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <SideBySideFormItem
                label={`${I18n.t("order.reference_value_short_form")} 1`}
              //require={!isEmpty(currentOrder.reference_1_type)}
              >
                <Input
                  value={currentOrder.reference_1}
                  disabled={isEmpty(currentOrder.reference_1_type)}
                  onChange={(e) =>
                    this.handleOnChange("reference_1", e.target.value)
                  }
                  size={elementSize}
                  className="textUpperCase"
                />
              </SideBySideFormItem>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <SideBySideFormItem
                label={`${I18n.t("order.reference_title_short")} 2`}
              >
                <Select
                  value={currentOrder.reference_2_type}
                  onChange={(e) => this.handleOnChange("reference_2_type", e)}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size={elementSize}
                >
                  <Select.Option key="ref" value="">
                    -- Select --
                  </Select.Option>
                  {this.state.references
                    .filter(
                      (ref) =>
                        !isEmpty(ref.order_reference_type) &&
                        ref.order_reference_type !=
                        currentOrder.reference_1_type
                    )
                    .map((refrence) => (
                      <Select.Option
                        key={refrence.id}
                        value={refrence.order_reference_type}
                      >
                        {refrence.order_reference_type}
                      </Select.Option>
                    ))}
                </Select>
              </SideBySideFormItem>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <SideBySideFormItem
                label={`${I18n.t("order.reference_value_short_form")} 2`}
              //require={!isEmpty(currentOrder.reference_2_type)}
              >
                <Input
                  value={currentOrder.reference_2}
                  disabled={isEmpty(currentOrder.reference_2_type)}
                  onChange={(e) =>
                    this.handleOnChange("reference_2", e.target.value)
                  }
                  size={elementSize}
                  className="textUpperCase"
                />
              </SideBySideFormItem>
            </Col>
            <Col sm={24} xs={24} md={24} lg={24}>
              <SideBySideFormItem
                label="Other References"
                labelCol={{ lg: 7, xl: 7, xxl: 7 }}
                wrapperCol={{ lg: 17, xl: 17, xxl: 17 }}
              >
                <Select
                  value={_.isArray(currentOrder.references) ? _.compact(currentOrder.references) : []}
                  onChange={(e) => this.handleOnChange("references", e)}
                  mode="tags"
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size={elementSize}
                  placeholder="Enter or select references"
                  maxTagCount={1}
                >
                  {/* <Select.Option key="references" value="">
                    -- Select --
                  </Select.Option> */}
                </Select>
              </SideBySideFormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  renderNotes = (orderType) => {
    const { formData, elementSize, isNew } = this.state;
    const currentOrder = formData[orderType] ? formData[orderType] : {};
    const { currentRole } = this.props.userContext;
    const gridSize = 24;
    const isLocationBased = ["T", "LH","MS"].includes(currentOrder.type_of_order);
    return (
      <Row gutter={[32, 12]}>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize} >
          <SideBySideFormItem label={I18n.t("order.notes")} labelCol={{
            xs: 24,
            sm: 24,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 21,
              lg: 21,
              xl: 21,
              xxl: 21,
            }}>
            <TextArea
              rows={2}
              autoSize={{ minRows: 2, maxRows: 2 }}
              placeholder={I18n.t("order.notes")}
              value={currentOrder.notes ?? 
                    (this.props.currentOrg.code === "NETMOVE" 
                        ? "DRIVER MUST CALL 1 HOUR PRIOR TO ARRIVAL" 
                        : "")}
              onChange={(e) =>
                this.handleOrderDetailsChange(
                  orderType,
                  -1,
                  "notes",
                  e.target.value
                )
              }
              size={elementSize}
            />
          </SideBySideFormItem>
        </Col>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize}>
          <SideBySideFormItem label={`${I18n.t("general.instruction")} 1`}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 5,
              lg: 5,
              xl: 5,
              xxl: 5,
            }}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 19,
              lg: 19,
              xl: 19,
              xxl: 19,
            }}
          >
            <TextArea
              rows={2}
              autoSize={{ minRows: 2, maxRows: 2 }}
              placeholder={I18n.t("general.instruction")}
              value={currentOrder.instructions_from_account_1}
              onChange={(e) =>
                this.handleOnChange(
                  "instructions_from_account_1",
                  e.target.value
                )
              }
              size={elementSize}
            />
          </SideBySideFormItem>
        </Col>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize}>
          <SideBySideFormItem label={`${I18n.t("general.instruction")} 2`}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 5,
              lg: 5,
              xl: 5,
              xxl: 5,
            }}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 19,
              lg: 19,
              xl: 19,
              xxl: 19,
            }}
          >
            <TextArea
              rows={2}
              autoSize={{ minRows: 2, maxRows: 2 }}
              placeholder={I18n.t("general.instruction")}
              value={currentOrder.instructions_from_account_2}
              onChange={(e) =>
                this.handleOnChange(
                  "instructions_from_account_2",
                  e.target.value
                )
              }
              size={elementSize}
            />
          </SideBySideFormItem>
        </Col>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize}>
          <SideBySideFormItem label={`${I18n.t("general.instruction")} 3`}
            labelCol={{
              xs: 24,
              sm: 24,
              md: 5,
              lg: 5,
              xl: 5,
              xxl: 5,
            }}
            wrapperCol={{
              xs: 24,
              sm: 24,
              md: 19,
              lg: 19,
              xl: 19,
              xxl: 19,
            }}>
            <TextArea
              rows={2}
              autoSize={{ minRows: 2, maxRows: 2 }}
              placeholder={I18n.t("general.instruction")}
              value={currentOrder.instructions_from_account_3}
              onChange={(e) =>
                this.handleOnChange(
                  "instructions_from_account_3",
                  e.target.value
                )
              }
              size={elementSize}
            />
          </SideBySideFormItem>
        </Col>
        {false && !["shipper", "driver"].includes(currentRole) && (
          <Col
            className="marginTop10"
            sm={24}
            xs={24}
            md={gridSize}
            lg={gridSize}
          >
            <FormItem label={I18n.t("general.privateNotes")}>
              <TextArea
                size={elementSize}
                rows={3}
                autoSize={{ minRows: 3, maxRows: 3 }}
                placeholder={I18n.t("general.privateNotes")}
                value={currentOrder.private_notes}
                onChange={(e) =>
                  this.handleOrderDetailsChange(
                    orderType,
                    -1,
                    "private_notes",
                    e.target.value
                  )
                }
              />
            </FormItem>
          </Col>
        )}
      </Row>
    );
  };
  renderWarehouse = (currentOrder = {}, element, req_key = "location_code") => {
    const { currentOrg } = this.props;
    return (
      <Col sm={24} xs={24} md={12} lg={8}>
        <SideBySideFormItem label={I18n.t("location.location")} require>
          <Select
            value={currentOrder[element] || ""}
            showSearch
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => this.handleOnChange(element, e)}
            size={this.state.elementSize}
            dropdownMatchSelectWidth={false}
          >
            <Select.Option key="warehouse" value="">
              -- Select --
            </Select.Option>
            {this.state.warehouses
              .filter(warehouse => !(this.state.isNew && _.get(currentOrg, 'code') === 'ACTFT' && warehouse[req_key] === 'AUS'))
              .map((warehouse) => (
                <Select.Option
                  key={warehouse[req_key]}
                  value={warehouse[req_key]}
                  disabled={warehouse[req_key] === "AUS"}
                >
                  {warehouse.name}
                </Select.Option>
              ))}
          </Select>
        </SideBySideFormItem>
      </Col>
    );
  };

  calculateOrderTimeData(start_time, end_time) {
    const { start_time: startTimeString = "", end_time: endTimeString = "" } = {
      start_time,
      end_time,
    };
    const dispatcherDisabledStartHours = getDisabledHoursFromStartTime(
      startTimeString,
      endTimeString,
      false
    );
    const dispatcherDisabledEndHours = getDisabledHoursFromStartTime(
      startTimeString,
      endTimeString,
      false
    );
    const startTimes = getHMSFromTimeString(
      startTimeString,
      "HH:mm",
      null,
      null
    );
    const endTimes = getHMSFromTimeString(endTimeString, "HH:mm", null, null);

    return {
      startTimes,
      endTimes,
      dispatcherDisabledStartHours,
      dispatcherDisabledEndHours,
    };
  }

  getServiceDuration = (los_code) => {
    const { config, organizationSettings } = this.state;
    const reqLOSObject = getValueFromArrayOfObjects(
      this.state.levelOfServices,
      "code",
      los_code
    );
    if (reqLOSObject?.duration) {
      return parseInt(reqLOSObject.duration);
    } else if (config && !isEmpty(config.service_duration)) {
      return parseInt(config.service_duration);
    } else if (
      organizationSettings &&
      !isEmpty(organizationSettings.service_duration)
    ) {
      return parseInt(organizationSettings.service_duration);
    }
    return 0;
  };

  _renderOrderDetails = ({ typeOfOrder }) => {
    const { formData, customerDetails, driversData, fleets, vehicleTypes, organizationSettings } =
      this.state;
    const { isMilitaryTime, elementSize } = this.state;
    const unDesiredStatus = ['NEW', 'RECEIVED', 'PENDING', 'VERIFIED', 'ON_HOLD'];
    const isLocTypeOfOrder = ["M", "LH"].includes(formData.type_of_order);
    const isEdit = !this.state.isNew;
    const isAirCargo = this.props.isAirCargo();

    return (
      <Fragment>
        {this.state.orderConfig.orderTypes[typeOfOrder].types
          .filter((details, index) => index === 0)
          .map((details, index) => {
            const currentOrder = !isEmpty(formData[details.key])
              ? formData[details.key]
              : {};
            const requiredConsignee = organizationSettings["require_consignee_details"] === "true" // && isAirCargo 
            return (
              <Row gutter={8}>
                {/* First Row */}
                <Row gutter={8}>
                  <Col xs={24} sm={24} md={16}>
                    <Collapse
                      defaultActiveKey={["1"]}
                      // style={{ height: "auto" }}
                      expandIconPosition="right"
                      className="marginTop20 customCollapse"
                    >
                      <Panel
                        header={
                          <Fragment>
                            {I18n.t("order.info")}
                            {!this.state.isNew &&
                              currentOrder.is_location_based &&
                              this.renderOrderStatus(currentOrder)}
                          </Fragment>
                        }
                        key="1"
                        extra={
                          <div style={{ paddingRight: 20 }}>
                            {
                              !this.state.isNew &&
                                currentOrder.is_location_based ? (
                                <div className="marginRight20">
                                  {this.renderOrderHeadindDetails(currentOrder)}
                                </div>
                              ) : (
                                !_.isEmpty(currentOrder.pdf_response) && currentOrder?.pdf_response?.response &&
                                <MiscData pdf_response={currentOrder.pdf_response} />
                              )
                            }
                          </div>

                        }
                        disabled
                        // style={{ height: currentOrder.type_of_order === "LH"  ? 170  : 165 }}
                        style={{ 
                          height: currentOrder.type_of_order === "LH" 
                            ? 180 
                            : currentOrder.type_of_order === "T" && 
                            this.props.organizationSettings.Priority === "true"
                              ? 175
                              : 148 
                        }}
                      >
                        <Row gutter={16}>
                          {this.renderWarehouse(currentOrder, "warehouse_code")}
                          <Col sm={24} xs={24} md={12} lg={8}>
                            <SideBySideFormItem
                              label={I18n.t("general.account")}
                              require
                            >
                              <Select
                                value={currentOrder.account_code || ""}
                                showSearch
                                style={{ width: "100%" }}
                                filterOption={(input, option) => {
                                  const children = option.props.children;
                                  if (Array.isArray(children)) {
                                    if (typeof children[0] === "string") {
                                      return children[0]
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0;
                                    }
                                    else {
                                      return children[0]
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0;
                                    }
                                  } else {
                                    return children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0;
                                  }
                                }
                                }
                                onChange={(e) => this.handleOnChange("account_code", e)}
                                size={elementSize}
                                dropdownMatchSelectWidth={false}
                              >
                                <Select.Option key="account" value="">
                                  -- Select --
                                </Select.Option>
                                {this.state.accounts.map((account) => {
                                  const accountName = `${account.name} (${account.code})`;
                                  const account_limit_exceeded = this.props.accountCodesWithExceededLimit.includes(
                                    account.code
                                  );
                                  return (
                                    <Select.Option
                                      key={account.code}
                                      value={account.code}
                                    >
                                      {`${accountName} `} {account_limit_exceeded &&
                                        <Tooltip
                                          title={I18n.t("order.account_limit_exceeded")}
                                          placement="left"
                                          key="accountLimitExceeded"
                                        >
                                          <Icon type="info-circle" style={{ color: "red" }} />
                                        </Tooltip>}
                                    </Select.Option>
                                  );
                                }
                                )}
                              </Select>
                            </SideBySideFormItem>
                          </Col>
                          <Col
                            sm={24}
                            xs={24}
                            md={12}
                            lg={8}
                            className="paddingRight8"
                          >
                            <SideBySideFormItem
                              label={I18n.t("order.vehicle_type")}
                              name="vehicle_type"
                            >
                              <Select
                                showSearch
                                key="vehicle_type"
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  this.handleOnChange("vehicle_type", e)
                                }
                                value={
                                  !isEmpty(currentOrder.vehicle_type)
                                    ? currentOrder.vehicle_type
                                    : ""
                                }
                                size={elementSize}
                              >
                                <Select.Option key="LH" value="">
                                  -- Select --
                                </Select.Option>
                                {vehicleTypes.map((code) => (
                                  <Select.Option
                                    key={code.name}
                                    value={code.code}
                                  >
                                    {code.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </SideBySideFormItem>
                          </Col>
                          <Col sm={24} xs={24} md={12} lg={8}>
                            <SideBySideFormItem
                              label={I18n.t("order.type")}
                              require
                            >
                              <Select
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  this.handleTypeChange("type_of_order", e)
                                }
                                value={
                                  !isEmpty(formData.type_of_order)
                                    ? formData.type_of_order
                                    : "D"
                                }
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                size={elementSize}
                                // disabled={shouldDisableCurrentOrder}
                                disabled={
                                  isEdit &&
                                  (isLocTypeOfOrder || !unDesiredStatus.includes(currentOrder.status))
                                }
                              >
                                {Object.keys(this.state.orderConfig.orderTypes)
                                  // .filter((key) => key !== 'X')
                                  .filter((key) => {
                                    const hideTypes = ["X", "LH"];
                                    const hideKey = isAirCargo ? ["X", "M"] : ["X"];
                                    if (
                                      this.state.isNew &&
                                      hideTypes.includes(key)
                                    ) {
                                      return false;
                                    } else if (
                                      // !this.state.isNew &&
                                      // hideKey.includes(key)
                                      hideKey.includes(key)
                                    ) {
                                      return false;
                                    } else {
                                      return true;
                                    }
                                  })
                                  .map((key) => (
                                    <Select.Option
                                      value={key}
                                      key={key}
                                      disabled={
                                        !this.state.isNew &&
                                        (["R", "D", 'T'].includes(
                                          formData.type_of_order
                                        ) &&
                                          ["M", "LH"].includes(key))
                                      }
                                    >
                                      {this.state.orderConfig.orderTypes[key].label}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </SideBySideFormItem>
                          </Col>
                          {checkServiceExistance("LOSI") &&
                            !["T", "LH"].includes(formData.type_of_order) && (
                              <Col sm={24} xs={24} md={12} lg={8}>
                                <SideBySideFormItem
                                  label={I18n.t("los.label")}
                                  require
                                >
                                  <Select
                                    value={currentOrder.los || ""}
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) => {
                                      const children = option.props.children;
                                      if (Array.isArray(children)) {
                                        if (typeof children[0] === "string") {
                                          return children[0]
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0;
                                        }
                                        else {
                                          return children[0]
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0;
                                        }
                                      } else {
                                        return children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0;
                                      }
                                    }
                                    }
                                    onChange={(e) => {
                                      this.handleOnChange("los", e);
                                      if (this.state.isNew) {
                                        if (formData.type_of_order === "M") {
                                          const duration =
                                            this.getServiceDuration(e);
                                          this.handleOrderDetailsChange(
                                            "pickup_params",
                                            -1,
                                            "service_duration",
                                            duration
                                          );
                                          this.handleOrderDetailsChange(
                                            "drop_params",
                                            -1,
                                            "service_duration",
                                            duration
                                          );
                                        } else {
                                          const duration =
                                            this.getServiceDuration(e);
                                          this.handleOrderDetailsChange(
                                            details.key,
                                            -1,
                                            "service_duration",
                                            duration
                                          );
                                        }
                                      }
                                    }}
                                    // disabled={["COMPLETED", "DISPATCHED"].includes(
                                    //   currentOrder.status
                                    // )}
                                    size={elementSize}
                                  >
                                    <Select.Option key="LOS" value="">
                                      -- Select --
                                    </Select.Option>
                                    {this.state.levelOfServices.map((los) => (
                                      <Select.Option
                                        key={los.code}
                                        value={los.code}
                                      >
                                        {los.name} ({los.code})
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </SideBySideFormItem>
                              </Col>
                            )}

                          {["T", "LH"].includes(formData.type_of_order) && (
                            <>
                              <Col sm={24} xs={24} md={12} lg={8}>
                                <SideBySideFormItem
                                  label={"Pickup " + I18n.t("los.short_form")}
                                  require
                                >
                                  <Select
                                    value={
                                      _.get(
                                        formData,
                                        `${details.key}.locations[0].los_code`,
                                        ""
                                      ) || ""
                                    }
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) => {
                                      const children = option.props.children;
                                      if (Array.isArray(children)) {
                                        if (typeof children[0] === "string") {
                                          return children[0]
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0;
                                        }
                                        else {
                                          return children[0]
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0;
                                        }
                                      } else {
                                        return children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0;
                                      }
                                    }
                                    }
                                    onChange={(e) => {
                                      this.handleOrderDetailsChange(
                                        details.key,
                                        0,
                                        "los_code",
                                        e
                                      );
                                      if (this.state.isNew) {
                                        const duration =
                                          this.getServiceDuration(e); // getValueFromArrayOfObjects(this.state.levelOfServices, "code", e);
                                        this.handleOrderDetailsChange(
                                          details.key,
                                          0,
                                          "service_duration",
                                          duration
                                        );
                                      }
                                    }}
                                    size={elementSize}
                                  >
                                    <Select.Option key="LOS" value="">
                                      -- Select --
                                    </Select.Option>
                                    {this.state.levelOfServices.map((los) => (
                                      <Select.Option
                                        key={los.code}
                                        value={los.code}
                                      >
                                        {los.name} ({los.code})
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </SideBySideFormItem>
                              </Col>
                              <Col sm={24} xs={24} md={12} lg={8}>
                                <SideBySideFormItem
                                  label={"Delivery " + I18n.t("los.short_form")}
                                  require={this.state.isDeliveryRequired}
                                >
                                  <Select
                                    value={
                                      _.get(
                                        formData,
                                        `${details.key}.locations[1].los_code`,
                                        ""
                                      ) || ""
                                    }
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) => {
                                      const children = option.props.children;
                                      if (Array.isArray(children)) {
                                        if (typeof children[0] === "string") {
                                          return children[0]
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0;
                                        }
                                        else {
                                          return children[0]
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0;
                                        }
                                      } else {
                                        return children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0;
                                      }
                                    }
                                    }
                                    onChange={(e) => {
                                      this.handleOrderDetailsChange(
                                        details.key,
                                        1,
                                        "los_code",
                                        e
                                      );
                                      if (this.state.isNew) {
                                        const reqLOSObject =
                                          getValueFromArrayOfObjects(
                                            this.state.levelOfServices,
                                            "code",
                                            e
                                          );
                                        this.handleOrderDetailsChange(
                                          details.key,
                                          1,
                                          "service_duration",
                                          reqLOSObject
                                            ? reqLOSObject.duration
                                              ? parseInt(reqLOSObject.duration)
                                              : 0
                                            : 0
                                        );
                                      }
                                    }}
                                    size={elementSize}
                                  >
                                    <Select.Option key="LOS" value="">
                                      -- Select --
                                    </Select.Option>
                                    {this.state.levelOfServices.map((los) => (
                                      <Select.Option
                                        key={los.code}
                                        value={los.code}
                                      >
                                        {los.name} ({los.code})
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </SideBySideFormItem>
                              </Col>
                            </>
                          )}
                          <Col sm={24} xs={24} md={12} lg={8}>
                            <SideBySideFormItem
                              label={I18n.t("order.is_hazardous")}
                            >
                              <RadioGroup
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "is_hazardous",
                                    e.target.value
                                  )
                                }
                                value={
                                  !isEmpty(currentOrder.is_hazardous)
                                    ? currentOrder.is_hazardous
                                    : false
                                }
                                style={{ marginTop: 3 }}
                                size={elementSize}
                              >
                                <Radio value>Yes</Radio>
                                <Radio value={false}>NO</Radio>
                              </RadioGroup>
                            </SideBySideFormItem>
                          </Col>

                          <Col
                            sm={24}
                            xs={24}
                            md={12}
                            lg={9}
                          >
                            <Row type="flex" gutter={16}>
                              <Col className="textBold">
                                <sup className="textRed">*</sup>{I18n.t("order.address_type")}:
                              </Col>
                              <Col>
                                <RadioGroup
                                  onChange={(e) =>
                                    this.handleOnChange(
                                      "address_type",
                                      e.target.value
                                    )
                                  }
                                  value={
                                    !isEmpty(currentOrder.address_type)
                                      ? currentOrder.address_type
                                      : AppConfig.default_address_type
                                  }
                                  size={elementSize}
                                >
                                  <Radio value="RESEDENTIAL" className="fontSize13 fontWeight500">
                                    {I18n.t("order.resedential_type")}
                                  </Radio>
                                  <Radio value="COMMERCIAL" className="fontSize13 fontWeight500">
                                    {I18n.t("order.commercial_type")}
                                  </Radio>
                                </RadioGroup>
                              </Col>
                            </Row>
                            {/* <SideBySideFormItem
                              label={I18n.t("order.address_type")}
                              require
                              name="address_type"
                              labelCol={{ lg: 7, xl: 7, xxl: 7 }}
                              wrapperCol={{ lg: 17, xl: 17, xxl: 17 }}
                            >
                              <RadioGroup
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "address_type",
                                    e.target.value
                                  )
                                }
                                value={
                                  !isEmpty(currentOrder.address_type)
                                    ? currentOrder.address_type
                                    : AppConfig.default_address_type
                                }
                                style={{ marginTop: 3 }}
                                size={elementSize}
                              >
                                <Radio value="RESEDENTIAL">
                                  {I18n.t("order.resedential_type")}
                                </Radio>
                                <Radio value="COMMERCIAL">
                                  {I18n.t("order.commercial_type")}
                                </Radio>
                              </RadioGroup>
                            </SideBySideFormItem> */}
                          </Col>


                          {["LH"].includes(currentOrder.type_of_order) && (
                            <>
                              <Col sm={24} xs={24} md={12} lg={6}>
                                <SideBySideFormItem
                                  label={I18n.t("general.Driver")}
                                >
                                  <Select
                                    value={currentOrder.driver_id || ""}
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) =>
                                      this.handleOnChange("driver_id", e)
                                    }
                                    disabled={
                                      !["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"].includes(
                                        currentOrder.status
                                      )
                                    }
                                    size={elementSize}
                                  >
                                    <Select.Option key="driver_id" value="">
                                      -- Select --
                                    </Select.Option>
                                    {driversData.map((driver) => (
                                      <Select.Option
                                        key={driver.id}
                                        value={driver.id}
                                      >
                                        {driver.employee_code}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </SideBySideFormItem>
                              </Col>
                              <Col sm={24} xs={24} md={12} lg={8}>
                                <SideBySideFormItem
                                  label={"Billing Type"}
                                >
                                  <Select
                                    value={currentOrder.lh_billing || ""}
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) =>
                                      this.handleOnChange("lh_billing", e)
                                    }
                                    disabled={
                                      !["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"].includes(
                                        currentOrder.status
                                      )
                                    }
                                    size={elementSize}
                                  >
                                    <Option key="lh_billing" value="">-- Select --</Option>
                                    <Option value="STANDARD">{"Standalone"}</Option>
                                    <Option value="INDV">{"Individual Billing"}</Option>
                                  </Select>
                                </SideBySideFormItem>
                              </Col>
                            </>

                          )}
                          <Col sm={24} xs={24} md={12} lg={6}>
                            <SideBySideFormItem
                              label={"Special"}
                            >
                               <RadioGroup
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "special",
                                    e.target.value
                                  )
                                }
                                value={
                                  !isEmpty(currentOrder.special)
                                    ? currentOrder.special
                                    : false
                                }
                                size={elementSize}
                              >
                                <Radio value>Yes</Radio>
                                <Radio value={false}>NO</Radio>
                              </RadioGroup>
                            </SideBySideFormItem>
                          </Col>
                          {this.props.organizationSettings.Priority === "true" && (
                          <Col sm={24} xs={24} md={12} lg={6}>
                            <SideBySideFormItem
                              label={I18n.t("configurations.priority")}
                            >
                               <RadioGroup
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "priority",
                                    e.target.value
                                  )
                                }
                                value={
                                  !isEmpty(currentOrder.priority)
                                    ? currentOrder.priority
                                    : false
                                }
                                // style={{ marginTop: 5 }}
                                size={elementSize}
                              >
                                <Radio value>Yes</Radio>
                                <Radio value={false}>NO</Radio>
                              </RadioGroup>
                            </SideBySideFormItem>
                          </Col>
                          )}
                           <Col
                            sm={24}
                            xs={24}
                            md={12}
                            lg={7}
                          >
                            {this.renderNSR()}
                          </Col>
                          <Col sm={24} xs={24} md={12} lg={8}>
                            <SideBySideFormItem
                              label={I18n.t("order.dispatch_status_label")}
                            >
                              <Select
                                onChange={(e) =>
                                  this.handleOnChange("dispatch_status", e)
                                }
                                value={
                                  !_.isEmpty(currentOrder.color_tags)
                                    ? _.get(currentOrder, 'color_tags[0].code', null)
                                    : null
                                }
                                style={{
                                  backgroundColor: !_.isEmpty(currentOrder.color_tags)
                                    ? currentOrder.color_tags[0]?.color
                                    : null,
                                  color: getContrastColor(!_.isEmpty(currentOrder.color_tags) ? currentOrder.color_tags[0].color : null)
                                }}
                                size={elementSize}
                                className="colorDropDown customSelection"
                              >
                                <Select.Option key="dispatch_status" value= {null}>-- Select --</Select.Option>
                                {this.props.colorStatus.map((obj) => (
                                  <Select.Option
                                    key={obj.code}
                                    value={obj.code}
                                    style={{ backgroundColor: obj.color, color: getContrastColor(obj.color) }}
                                  >
                                    {_.startCase(obj.label)}
                                  </Select.Option>
                                ))}
                              </Select>
                            </SideBySideFormItem>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col xs={24} md={8}>
                    <Collapse
                      defaultActiveKey={["measurements"]}
                      // style={{ height: "auto" }}
                      expandIconPosition="right"
                      className="marginTop20 customCollapse"
                    >
                      <Panel
                        header={
                          <Fragment>{I18n.t("order.measurements")}</Fragment>
                        }
                        key="measurements"
                        // style={{ height: 170 }}
                        // style={{ height: currentOrder.type_of_order === "LH" ? 170 : 165 }}
                        style={{ 
                          height: currentOrder.type_of_order === "LH" 
                            ? 170 
                            : currentOrder.type_of_order === "T" && 
                            this.props.organizationSettings.Priority === "true"
                            ? 165 
                            : 138
                        }}
                      >
                        <Row>
                         <Col sm={12} xs={12} md={12} lg={12}>
                            <SideBySideFormItem
                              // label={`${I18n.t("order.weight")} (${_.lowerCase(
                              //   AppConfig.weight_unit
                              // )})`}
                              label={
                                <span >
                                  {/* {`${I18n.t("order.weight")} (${_.lowerCase(AppConfig.weight_unit)}):`}
                                  <LabelInfo title={I18n.t("order.weightInfo")}
                                  /> */}
                                  {`${I18n.t("order.weight")} :`}
                                </span>
                              }
                              requireColumn={false}
                              require={
                                !["T", "LH","MS"].includes(formData.type_of_order)
                              }
                              labelCol={{
                                xs: 24,
                                sm: 24,
                                md: 8,
                                lg: 6,
                                xl: 8,
                                xxl: 7,
                              }}
                              wrapperCol={{
                                xs: 24,
                                sm: 24,
                                md: 14,
                                lg: 16,
                                xl: 16,
                                xxl: 17,
                              }}
                            >
                              <div style={{ display: 'flex', gap: '10px' }}>
                              <Input
                                type="number"
                                value={currentOrder.weight}
                                // onChange={(e) =>
                                //   this.handleOnChange("weight", e.target.value)
                                // }
                                //className="width100Per"
                                onChange={(e) => {

                                  const lbsValue = parseFloat(e.target.value);
                                  const kgsValue = lbsValue ? convertToKilograms(lbsValue, false) : '';
                                  this.handleOnChange("weight", lbsValue);
                                  this.handleOnChange("weight_in_kgs", kgsValue);
                                }}
                                // precision={0}
                                min={0}
                                step="1"
                                size={elementSize}
                                style={{ width: 90 }}
                              />
                                <span className="textBold" style={{ marginLeft: '-9px' }}>Lbs</span>
                                <Input
                                  type="number"
                                  value={currentOrder.weight_in_kgs}
                                  onChange={(e) => {
                                    const kgsValue = parseFloat(e.target.value);
                                    const lbsValue = kgsValue ? convertToPounds(kgsValue, false) : '';
                                    this.handleOnChange("weight_in_kgs", kgsValue);
                                    this.handleOnChange("weight", lbsValue);
                                  }}
                                  // precision={0}
                                  min={0}
                                  step="1"
                                  size={elementSize}
                                  style={{ width: 90 }}
                                />
                                <span className="textBold" style={{ marginLeft: '-9px' }}>Kgs</span>
                                </div>
                            </SideBySideFormItem>
                          </Col>
                          <Col sm={10} xs={10} md={10} lg={10} offset={1}>
                            <SideBySideFormItem
                              label={"Miles"}
                              labelCol={{
                                xs: 24,
                                sm: 24,
                                md: 15,
                                lg: 14,
                                xl: 14,
                                xxl: 13,
                              }}
                              wrapperCol={{
                                xs: 24,
                                sm: 24,
                                md: 9,
                                lg: 10,
                                xl: 10,
                                xxl: 11,
                              }}
                              className="alignRight"
                            >
                              <Input
                                type="number"
                                value={currentOrder.mileage}
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "mileage",
                                    e.target.value
                                  )
                                }
                                //className="width100Per"
                                precision={0}
                                step="1"
                                min={0}
                                size={elementSize}
                                style={{ width: 75 }}
                              /> 
                              </SideBySideFormItem>
                            </Col>
                          </Row>
                          <Row gutter={8} style={{ marginTop: 20 }}>
                            <Col sm={8} xs={24} md={8} lg={8}>
                              <SideBySideFormItem
                                label={`${I18n.t("order.quantity")}`}
                                labelCol={{
                                  xs: 12,
                                  sm: 14,
                                  md: 14,
                                  lg: 12,
                                  xl: 12,
                                  xxl: 11,
                                }}
                                wrapperCol={{
                                  xs: 12,
                                  sm: 10,
                                  md: 10,
                                  lg: 12,
                                  xl: 12,
                                  xxl: 13,
                                }}
                              >
                                <Input
                                  type="number"
                                  value={currentOrder.quantity}
                                  onChange={(e) => this.handleOnChange("quantity", e.target.value)}
                                precision={0}
                                step="1"
                                min={0}
                                size={elementSize}
                                style={{ width: 75 }}
                              />
                            </SideBySideFormItem>
                          </Col>
                          <Col sm={8} xs={24} md={8} lg={8}>
                            <SideBySideFormItem
                              label={`${I18n.t("order.pallets")}`}
                              labelCol={{
                                xs: 20,
                                sm: 16,
                                md: 12,
                                lg: 10,
                                xl: 9,
                                xxl: 8,
                              }}
                              wrapperCol={{
                                xs: 4,
                                sm: 8,
                                md: 12,
                                lg: 14,
                                xl: 15,
                                xxl: 16,
                              }}
                            >
                              <Input
                                type="number"
                                value={currentOrder.pallets}
                                onChange={(e) =>
                                  this.handleOnChange("pallets", e.target.value)
                                }
                                //className="width100Per"
                                precision={0}
                                step="1"
                                min={0}
                                size={elementSize}
                                style={{ width: 75 }}
                              />
                            </SideBySideFormItem>
                          </Col>
                          <Col sm={8} xs={24} md={8} lg={8}>
                            <SideBySideFormItem
                              label={
                                <Fragment>
                                  {I18n.t("order.surface_area_sf")}&nbsp; (
                                  {I18n.t("order.surface_area_units")}
                                  <sup>2</sup>)
                                </Fragment>
                              }
                              labelCol={{
                                xs: 22,
                                sm: 18,
                                md: 14,
                                lg: 12,
                                xl: 11,
                                xxl: 10,
                              }}
                              wrapperCol={{
                                xs: 2,
                                sm: 6,
                                md: 10,
                                lg: 12,
                                xl: 13,
                                xxl: 14,
                              }}
                            >
                              <Input
                                type="number"
                                value={currentOrder.surface_area}
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "surface_area",
                                    e.target.value
                                  )
                                }
                                //className="width100Per"
                                precision={0}
                                step="1"
                                min={0}
                                size={elementSize}
                                style={{ width: 75 }}
                              />
                            </SideBySideFormItem>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                {/* Second Row */}
                <Row gutter={8}>
                  <Col xs={24} md={8} className="order_details_form">
                    <Row>
                      <Collapse
                        defaultActiveKey={["references"]}
                        // style={{ height: "auto" }}
                        expandIconPosition="right"
                        className="marginTop10 customCollapse"
                      >
                        <Panel
                          header={
                            <Fragment>{I18n.t("order.references")}</Fragment>
                          }
                          key="references"
                          style={{ height: 167 }}
                        >
                          <Row gutter={16}>
                            <Col xs={24}>
                              {this.renderReferences(details.key)}
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </Row>
                    <Row>
                      {this.renderReceivedAt(
                        details.key,
                        currentOrder,
                        isMilitaryTime
                      )}
                    </Row>
                  </Col>
                  <Col xs={24} md={8} className="order_details_form">
                    <Collapse
                      defaultActiveKey={["notes"]}
                      // style={{ height: "auto" }}
                      expandIconPosition="right"
                      className="marginTop10 customCollapse"
                    >
                      <Panel
                        header={<Fragment>{I18n.t("order.notes")}</Fragment>}
                        key="notes"
                        style={{ height: 277 }}
                      >
                        <Row gutter={16}>
                          <Col xs={24}>{this.renderNotes(details.key)}</Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col xs={24} md={8}>
                    <Collapse
                      defaultActiveKey={["managerial_emails"]}
                      // style={{ height: 279 }}
                      expandIconPosition="right"
                      className="marginTop10 customCollapse"
                    >
                      <Panel
                        header={
                          <>
                            {I18n.t("order.managerial_emails")}
                            <Tooltip
                              placement="topRight"
                              title={I18n.t(
                                "configurations.definitions.order_level_shipper_emails"
                              )}
                              overlayStyle={{ width: "200px" }}
                              trigger="click"
                              autoAdjustOverflow={true}
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                            >
                              &nbsp;
                              <img
                                src={labelInfo}
                                alt="labelInfo"
                                className="labelInfoForEmails"
                              />
                            </Tooltip>
                          </>
                        }
                        key="managerial_emails"
                        style={{ height: 278 }}
                      >
                        <Row type="flex" justify="space-between">
                          <Col xs={3}>Select:</Col>
                          <Col xs={15}>
                            <TreeSelect
                              value={
                                currentOrder?.account_manager_emails?.length
                                  ? currentOrder.account_manager_emails
                                  : null
                              }
                              style={{ width: "98%" }} // Adjust the width as needed
                              showSearch
                              dropdownStyle={{
                                maxHeight: 300,
                                overflow: "auto",
                              }}
                              placeholder="--Select--"
                              allowClear
                              treeDefaultExpandAll
                              dropdownMatchSelectWidth={false}
                              onChange={(emails) => {
                                // const validEmails = emails.filter(this.validateEmail);
                                this.handleOnChange(
                                  "account_manager_emails",
                                  emails
                                );
                              }}
                              treeCheckable
                              maxTagCount={1}
                              treeNodeFilterProp="title"
                              size={elementSize}
                            >
                              {this.state.Contacts?.length && (
                                <TreeNode value="ALL" title="All" key="0-1">
                                  {this.state.Contacts.map((contact) => (
                                    <TreeNode
                                      value={contact.id}
                                      title={contact.first_name}
                                      key={contact.id}
                                    />
                                  ))}
                                </TreeNode>
                              )}
                            </TreeSelect>
                          </Col>
                          <Col xs={6} className="alignRight">
                            <AddContactByButton
                              accountId={this.state.formData.account_id}
                              saveOn={(conatct) =>
                                this.handleContactSaved(
                                  conatct,
                                  currentOrder?.account_manager_emails
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <div className="marginTop10">
                          <ContactList
                            data={this.state.Contacts.filter((contact) =>
                              currentOrder?.account_manager_emails?.includes(
                                contact.id
                              )
                            )}
                            scroll={{ y: 100, x: "max-content" }}
                            size="small"
                            pagination={{ position: "none" }}
                            hideColumns={[
                              "alert_preference",
                              "actions",
                              "designation",
                            ]}
                          />
                        </div>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                {/* <Col xs={24} md={8} className="order_details_form">
                    {this.renderReceivedAt(details.key, currentOrder, isMilitaryTime)}
                  </Col> */}
                {/* Third Row */}
                <Row gutter={8}>
                  <Col xs={8}>
                    <Row>
                      <Col xs={24} md={24} className="order_details_form">
                        {this.renderDeliveryInfo(
                          details.key,
                          currentOrder,
                          isMilitaryTime
                        )}
                      </Col>
                      {
                        requiredConsignee && <Col xs={24} md={24} className="order_details_form">
                          {this.renderLabelInfo(details.key, 12)}
                        </Col>
                      }
                    </Row>
                  </Col>
                  <Col xs={16}>
                    {
                      requiredConsignee ? <Row gutter={8}>
                        <Col xs={24}>
                          {this.renderEndUserDetails(details.key)}
                        </Col>
                      </Row>
                        :
                        this.renderLabelInfo(details.key)
                    }
                  </Col>

                </Row>

              </Row>
            );
          })}
      </Fragment>
    );
  };

  imageAction = (orderType, currentLocation = null) => {
    this.setState({
      imagesVisible: true,
      currentParamType: orderType,
      currentLocation,
    });
  };


  renderDispatcherNotes = (orderType) => {
    const { formData } = this.state;
    const currentOrder = _.cloneDeep(formData[orderType]);
    const { customer_order_notes } = currentOrder;
    return (
      <>
        <DispatcherNotes
          organization_id={formData.organization_id}
          currentSourceRecord={currentOrder}
          customer_order_notes={customer_order_notes}
          key={currentOrder.id}
          noteTitle={I18n.t("order.dispatcher_notes")}
          apiEndpoint={dispatcherNotesApi}
          refer_type="order"
          scroll={{ y: 100 }}
        />
      </>
    );
  };

  renderNSR = () => {
    return (
      <Row gutter={16} type="flex">
        <Col>
          {/* <Checkbox
            checked={this.state.nsr}
            onChange={() =>
              this.setState({
                nsr:
                  !this.state.nsr,
              })
            }
          >
          No Signature Required
          </Checkbox> */}
          <Row type="flex" gutter={16}>
            <Col className="textBold">{I18n.t('order.req_signature')}:</Col>
            <Col>
              <RadioGroup
                onChange={(e) =>
                  this.setState({
                    nsr: e.target.value,
                  })
                }
                value={
                  !isEmpty(this.state.nsr)
                    ? this.state.nsr
                    : false
                }
                style={{ fontSize: 10 }}
                size={this.state.elementSize}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>NO</Radio>
              </RadioGroup>
            </Col>
          </Row>
          {/* <SideBySideFormItem
              label={"Signature Required"}
            >
              
            </SideBySideFormItem> */}
        </Col>
      </Row>
    )
  }

  renderPrivateNotes = (orderType) => {
    const { formData } = this.state;
    const currentOrder = _.cloneDeep(formData[orderType]);
    const order_private_notes = currentOrder.order_private_notes || [];
    return (
      <PrivateNotes
        organization_id={formData.organization_id}
        currentSourceRecord={currentOrder}
        customer_order_notes={order_private_notes}
        key={currentOrder.id}
        noteTitle={"Private Notes"}
        apiEndpoint={privateNotesApi}
        refer_type="order"
        scroll={{ y: 100 }}
      />
    );
  };

  renderOrderPics = (orderType, locationIndex, isNewLocation) => {
    const { formData } = this.state;
    const currentOrder = _.cloneDeep(formData[orderType]);
    let locationDetails =
      currentOrder.is_location_based && locationIndex >= 0
        ? currentOrder.locations &&
          currentOrder.locations.length >= locationIndex
          ? currentOrder.locations[locationIndex]
          : null
        : null;
    return currentOrder.account_id ? (
      <OrderPictures
        currentLocation={locationDetails}
        order_id={currentOrder.id}
        account_id={currentOrder.account_id}
        takeMultipleSigns={false}
        showType="imageClick"
        isNewLocation={isNewLocation}
        currentOrder={currentOrder}
      />
    ) : (
      <Fragment />
    );
  };

  updatePallets = (item, orderType) => {
    this.setState((prevState) => {
      const updatedOrderType = {
        ...prevState.formData[orderType],
        pallets: item
      };

      return {
        formData: {
          ...prevState.formData,
          [orderType]: updatedOrderType
        }
      };
    });
  }

  onCloseUploadGallery = () => {
    this.setState(
      {
        imagesVisible: false,
        currentParamType: "",
        currentLocation: null,
      },
      () => {
        this.getOrderDetails();
      }
    );
  };

  renderUploadGallery = () => {
    const { formData, config, currentLocation } = this.state;
    const currentOrder =
      this.state.currentParamType && formData[this.state.currentParamType]
        ? formData[this.state.currentParamType]
        : {};
    // const currentOrder = formData[this.state.currentParamType]
    const orderPictures = currentLocation?.pictures
      ? currentLocation.pictures.length
        ? [...currentLocation.pictures]
        : []
      : currentOrder.pictures || [];
    const normalPictures = orderPictures.filter(
      (pic) =>
        (pic.image_type === "normal" || pic.image_type === "signature") &&
        pic.picture &&
        pic.picture.url
    );
    const picSettings = config.pod_pic_settings || [];
    if (_.findIndex(picSettings, ["pic_code", "signature"]) < 0) {
      picSettings.push({
        pic_code: "signature",
        mandatory: config.pod_signature,
      });
    }
    const settingPicCodes = picSettings.map((setting) => setting.pic_code);
    const otherImgs = normalPictures
      .filter(
        (picture) =>
          picture.image_type !== "print" &&
          (isEmpty(picture.pic_code) ||
            !settingPicCodes.includes(picture.pic_code))
      )
      .map((picture) => ({
        captured_at: picture.captured_at,
        converted_img: picture.picture.url,
        id: picture._id,
        ack_id: picture.ack_id,
        picture_type: "normal",
        picture_obj: "",
        pic_title: !isEmpty(picture.pic_title) ? picture.pic_title : "",
        isNew: false,
        fileType: getFileType(picture.picture.url),
      }));
    picSettings.forEach((setting, index) => {
      let pic = "";
      const isSignature = setting.pic_code === "signature";
      if (isSignature) {
        pic = _.find(normalPictures, { image_type: "signature" });
      } else {
        pic = _.find(normalPictures, { pic_code: setting.pic_code });
      }
      if (!isEmpty(pic)) {
        picSettings[index] = Object.assign({}, setting, {
          src: pic.picture.url,
          ack_id: pic.ack_id,
          id: pic._id,
          isNew: false,
          fileType: getFileType(pic.picture.url),
        });
      } else {
        picSettings[index] = Object.assign({}, setting, {
          src: "",
          ack_id: `${randomUUID()}_n`,
          id: "",
          isNew: true,
        });
      }
      picSettings[index].pic_title = isSignature
        ? I18n.t("configurations.pod_signature_label")
        : setting.pic_code;
    });
    config.pod_pic_settings = [...picSettings];
    return (
      <BaseModal
        title={I18n.t("order.upload_images")}
        onCancel={this.onCloseUploadGallery}
        visible={this.state.imagesVisible}
        width="70%"
        style={{ top: 30 }}
        maskClosable={false}
      >
        <UploadGallery
          config={config}
          refreshOrder={this.getOrderDetails}
          otherImgs={otherImgs}
          order={currentLocation}
          key={this.state.uploadKey}
          closeGallery={this.onCloseUploadGallery}
          uploadApi={uploadPod}
          deleteAttachments={deleteOrderPic}
          currentLocation={this.state.currentLocation}
        />
      </BaseModal>
    );
  };

  onOk = (value) => { };

  detailsNavigator = (
    orderType,
    locationIndex,
    title = "Pickup Details",
    key
  ) => {
    const { formData } = this.state;
    if (formData[orderType]) {
      if (formData[orderType].is_location_based) {
        const dataObject =
          formData[orderType]?.locations &&
            formData[orderType].locations[locationIndex]
            ? formData[orderType].locations[locationIndex]
            : {};
        return this.renderDetails(
          dataObject,
          orderType,
          locationIndex,
          title,
          key
        );
      } else {
        const currentOrder = formData[orderType] ? formData[orderType] : {};
        return this.renderDetails(
          currentOrder,
          orderType,
          locationIndex,
          title,
          key
        );
      }
    } else {
      return <Fragment />;
    }
  };

  renderReceivedAt = (orderType, currentOrder, isMilitaryTime) => {
    return (
      <Collapse
        defaultActiveKey={["renderReceivedAt"]}
        style={{ height: "auto" }}
        expandIconPosition="right"
        className="marginTop10 customCollapse"
      >
        <Panel
          header={<Fragment>{I18n.t("order.received_info")}</Fragment>}
          key="renderReceivedAt"
          style={{ height: 100 }}
        >
          <DateTimeSelector
            FormItem={SideBySideFormItem}
            dateProps={{
              format: "Do MMM YYYY",
              label: I18n.t("general.date"),
              value: currentOrder.received_at_date
                ? moment(currentOrder.received_at_date)
                : null,
              onChange: (date) => {
                this.handleOrderDetailsChange(
                  orderType,
                  -1,
                  "received_at_date",
                  date
                );
              },
              style: { width: "100%" },
              disabledDate: (current) => {
                return (
                  current && current.valueOf() > Date.now() // disabled the future dates
                );
              },
              size: this.state.elementSize,
            }}
            timeProps={{
              format: isMilitaryTime ? "HH:mm" : "hh:mm A",
              label: I18n.t("general.time"),
              showTime: {
                format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                use12Hours: !isMilitaryTime,
              },
              showSecond: false,
              onChange: (time) => {
                this.handleOrderDetailsChange(
                  orderType,
                  -1,
                  "received_at_time",
                  time
                );
              },
              style: { width: "100%", height: "100%", marginTop: "4px" },
              value: currentOrder.received_at_time
                ? moment(
                  currentOrder.received_at_time,
                  isMilitaryTime ? "HH:mm" : "hh:mm A"
                )
                : null,
              placeholder: "Select Time",
              minuteStep: 1,
              isMilitaryTime,
              size: this.state.elementSize,
            }}
            gridStyle={{
              row: { type: "flex", gutter: 16 },
              dateCol: { span: 12 },
              timeCol: { span: 12 },
            }}
          />
        </Panel>
      </Collapse>
    );
  };

  renderDeliveryInfo = (orderType, currentOrder, isMilitaryTime) => {
    return (
      <Collapse
        defaultActiveKey={["renderDeliveryInfo"]}
        expandIconPosition="right"
        className="marginTop10 customCollapse"
      >
        <Panel
          header={"Deliver By"}
          key="renderDeliveryInfo"
          style={{ height: 81 }}
        >
          <DateTimeSelector
            FormItem={SideBySideFormItem}
            dateProps={{
              format: "Do MMM YYYY",
              label: I18n.t("general.date"),
              value: currentOrder.delivery_date
                ? moment(currentOrder.delivery_date)
                : null,
              onChange: (date) => {
                this.handleOrderDetailsChange(
                  orderType,
                  -1,
                  "delivery_date",
                  date
                );
              },
              style: { width: "100%" },
              // disabledDate: (current) => {
              //   return (
              //     current && current.valueOf() > Date.now() // disabled the future dates
              //   );
              // },
              size: this.state.elementSize,
            }}
            timeProps={{
              format: isMilitaryTime ? "HH:mm" : "hh:mm A",
              label: I18n.t("general.time"),
              showTime: {
                format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                use12Hours: !isMilitaryTime,
              },
              showSecond: false,
              onChange: (time) => {
                this.handleOrderDetailsChange(
                  orderType,
                  -1,
                  "delivery_time",
                  time
                );
              },
              style: { width: "100%", height: "100%", marginTop: "4px" },
              value: currentOrder.delivery_time
                ? moment(
                  currentOrder.delivery_time,
                  isMilitaryTime ? "HH:mm" : "hh:mm A"
                )
                : null,
              placeholder: "Select Time",
              minuteStep: 1,
              isMilitaryTime,
              size: this.state.elementSize,
            }}
            gridStyle={{
              row: { type: "flex", gutter: 8 },
              dateCol: { span: 12 },
              timeCol: { span: 12 },
            }}
          />
        </Panel>
      </Collapse>
    );
  };

  renderOrderStatus = (currentOrder, showSubTitle = true) => {
    const subTitle = _.get(currentOrder, "customer_order_number", "");
    const orderExceptions = _.get(currentOrder, "order_exceptions", []);
    const exceptionsInDesc =
      currentOrder.status === "EXCEPTION"
        ? sortByDate(orderExceptions, "updated_at", "descend")
        : "";
    return (
      <Fragment>
        {showSubTitle && subTitle.length > 0 ? <small>({subTitle})</small> : null}
        <OrderStatus
          record={currentOrder}
          displayKey="status"
          label=""
          showLabel={false}
          prependText={" - "}
          popoverMsg={
            exceptionsInDesc.length > 0
              ? exceptionsInDesc[0].exception_message
              : ""
          }
        />
      </Fragment>
    );
  };

  renderOrderHeadindDetails = (currentOrder) => {
    const { id, distance, quotation_amount } = currentOrder;

    const distanceDisplay = distance ? (
      <Col className="textBold paddingRight20">
        <BranchesIcon />
        {I18n.t("general.distance")} : {distance} mi.
      </Col>
    ) : null;

    return (
      <Row type="flex" justify="space-between" gutter={16}>
        <Col className="hideText">{id}</Col>
        {distanceDisplay}
        <Col
          className="textBold anchor_cursor"
          onClick={(e) => {
            e.stopPropagation();
            this.goToTab("billing");
          }}
        >
          {I18n.t("order.quote")}:{checkNegitive(quotation_amount)}
        </Col>
        <Col
          className="anchor_cursor textBold"
          onClick={(e) => {
            e.stopPropagation();
            this.setUpdatedQuoteAmount();
          }}
        >
          <Icon type="sync" spin={this.state.quotationProgress} />
        </Col>


        <Col style={{ width: 25 }}>
          {!_.isEmpty(currentOrder.pdf_response) && currentOrder?.pdf_response?.response && <MiscData pdf_response={currentOrder.pdf_response} />}
        </Col>
      </Row>
    );
  };

  _handleAppointmentErrors = (field, error) => {
    const currenApptErrors = handleAppointmentErrors(
      field,
      error,
      this.state.appointmentValidationInfo
    );
    this.setState({ appointmentValidationInfo: currenApptErrors });
  };

  _onAddressSelect = (selectedAddressInfo, orderType, locationIndex = null) => {
    if (!isEmpty(selectedAddressInfo?.type)) {
      delete selectedAddressInfo.id;
    }
    
    
    if (selectedAddressInfo?.type === "predefined") {
      const { first_name, last_name, email, phone, company_name,acc_loc_code } =
        selectedAddressInfo;
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "customer_first_name",
        first_name
      );
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "customer_last_name",
        last_name
      );
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "customer_phone_one",
        phone
      );
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "customer_email",
        email
      );
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "company_name",
        company_name
      );
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "acc_loc_code",
        acc_loc_code
      );
    }

    const currentOrder = this.state.formData[orderType]
      ? _.cloneDeep(this.state.formData[orderType])
      : {};
    let dataObject = {};
    if (!this.state.isNew) {
      if (locationIndex >= 0) {
        dataObject = retrueveLocationFromOrder(currentOrder, locationIndex);
      }
      if (locationIndex >= 0 && dataObject?.cs_location?.id) {
        selectedAddressInfo.id = dataObject.cs_location.id;
      } else {
        // if(currentOrder?.cs_location?.id){
        //   selectedAddressInfo.id = currentOrder.cs_location.id
        // }
      }
    }
    this.handleOrderDetailsChange(
      orderType,
      locationIndex,
      "cs_location",
      selectedAddressInfo
    );
    this.handleOrderDetailsChange(
      orderType,
      locationIndex,
      "company_name",
      selectedAddressInfo.company_name
    );
    if (locationIndex >= 0 && selectedAddressInfo.id) {
      this.handleOrderDetailsChange(
        orderType,
        locationIndex,
        "id",
        selectedAddressInfo.id
      );
    }
    if (selectedAddressInfo?.l_address?.zipcode) {
      this.getAvailableDates(orderType, locationIndex);
      this.verifyZipcode(selectedAddressInfo.l_address.zipcode, orderType,locationIndex);
    }
  };

  renderEndUserDetails = (orderType, title = "Consignee Details") => {
    const locationIndex = -1;
    const { formData, organizationSettings, config, elementSize, isNew } =
      this.state;
    const currentOrder = formData[orderType] ? formData[orderType] : {};
    const consignee_details = currentOrder.consignee_details || {};
    const onAddressSelect = (selectedAddressInfo, orderType) => {
      if (selectedAddressInfo && _.isObject(selectedAddressInfo)) {
        if (!isEmpty(selectedAddressInfo.type)) {
          delete selectedAddressInfo.id;
        }
        if (selectedAddressInfo.type === "predefined") {
          const { first_name, last_name, email, phone, company_name, l_address } =
            selectedAddressInfo;

          const updatedDetails = _.assign({}, consignee_details, {
            first_name,
            // last_name,
            // email,
            phone,
            // company_name,
            l_address,
          });
          this.handleOnChange("consignee_details", updatedDetails);
        } else {
          const { l_address } = selectedAddressInfo;
          const updatedDetails = _.assign({}, consignee_details, {
            l_address
          });
          this.handleOnChange("consignee_details", updatedDetails);
        }
      }
    };

    const onAddressChange = (element, value, obj) => {
      const address =
        consignee_details && consignee_details.l_address
          ? consignee_details.l_address
          : {};
      let newAddress = Object.assign({}, address);
      if (obj) {
        newAddress = Object.assign({}, address, { ...obj });
        if (this.state.isNew) {
          delete newAddress.id;
        }
      } else if (element) {
        newAddress[element] = value;
      }
      const updatedDetails = _.assign({}, consignee_details, {
        l_address: newAddress,
      });
      this.handleOnChange("consignee_details", updatedDetails);
    };
    const onDetailsChange = (element, value) => {
      const keysToMap = {
        customer_first_name: "first_name",
        customer_last_name: "last_name",
        customer_email: "email",
        customer_phone_one: "phone",
        customer_phone_two: "phone_2",
      };
      const updatedDetails = _.assign({}, consignee_details);
      if (keysToMap[element]) {
        const newEle = keysToMap[element];
        updatedDetails[newEle] = value;
      } else {
        updatedDetails[element] = value;
      }
      this.handleOnChange("consignee_details", updatedDetails);
    };
    const styleProps =
      orderType === "transfer_params"
        ? {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 24,
          xxl: 24,
        }
        : {
          xs: 24,
          sm: 24,
          md: 12,
          lg: locationIndex < 1 ? 24 : 24, // 24
          xl: locationIndex < 1 ? 24 : 24, // 24
          xxl: locationIndex < 1 ? 24 : 24, // 12
        };
    const cs_address = _.get(consignee_details, "l_address", {});
    return (
      <Fragment>
        {formData[orderType] && (
          <Collapse
            defaultActiveKey={["ndUserDetails"]}
            // style={{ height: "auto" }}
            expandIconPosition="right"
            className="marginTop10 customCollapse consigneeDetailss"
          >
            <Panel
              header={<Fragment>{I18n.t("order.customer_details")}</Fragment>}
              key="ndUserDetails"
              style={{ height: 207 }}
            >
              <ConsigneeDetailsForm

              showpredefined_loc={true}
                onAddressSelect={(address) => onAddressSelect(address, orderType)}
                formData={{
                  ...consignee_details,
                  customer_first_name: consignee_details.first_name || "",
                  customer_last_name: consignee_details.last_name || "",
                  customer_email: consignee_details.email || "",
                  customer_phone_one: consignee_details.phone || "",
                  customer_phone_two: consignee_details.phone_2 || "",
                  cs_location: {
                    l_address: consignee_details.l_address || {},
                  },
                }}
                orderType={orderType}
                locIndex={locationIndex}
                handleOnAddressChange={(element, value, obj) =>
                  onAddressChange(element, value, obj)
                }
                handleOnChange={(element, value) =>
                  onDetailsChange(element, value)
                }
                isDeliveryRequired={false}
                isPickup={true}
                map={this.state.map}
                predefined_locations={organizationSettings.predefined_locations}
                includePredefinedStops={true}
                editableFields={[
                  "email",
                  "customer_phone_one",
                  "customer_phone_two",
                  "address",
                ]}
                reqFrom="orders"
                gridSize={{
                  xs: 24,
                  sm: 24,
                  md: 12,
                  lg: 8,
                  xl: 8,
                  // lg: locationIndex >= 0 ? 8 : 4,
                  // xl: locationIndex >= 0 ? 8 : 4,
                }}
                size={elementSize}
                customerReqFields={requiredFields
                  .filter((rec) => rec.isRequired)
                  .map((rec) => rec.form_field)}
                requireSideBySide={false}
                reqMinimumDetails={true}
                accountConfigs={this.state.config}
              />
            </Panel>
          </Collapse>
        )}
      </Fragment>
    );
  };


  renderDetails = (
    dataObject,
    orderType,
    locationIndex = null,
    title = "Pickup Details",
    key
  ) => {
    const {
      customerDetails,
      formData,
      orderItems,
      organizationSettings,
      config,
      elementSize,
      isNew,
    } = this.state;
    const isMilitaryTime = organizationSettings["is_military_time"] == "true";
    const feasibleDates = customerDetails.feasible_dates || [];
    const currentOrder = formData[orderType] ? formData[orderType] : {};
    const isNewLocation =
      locationIndex >= 0 &&
      !_.has(currentOrder.locations[locationIndex], "_id");
    const orderExceptions = currentOrder.order_exceptions || [];
    const exceptionsInDesc =
      currentOrder.status === "EXCEPTION"
        ? sortByDate(orderExceptions, "updated_at", "descend")
        : "";

    //const selectedWh = _.find(this.state.warehouses, { location_code: currentOrder.warehouse_code } )
    const selectedWh = _.find(this.state.warehouses, {
      location_code: dataObject.wh_location,
    });

    let { start_time = "", end_time = "" } = selectedWh || {};
    // if start_time and end_time are not present, then organizationSettings will be used
    if (_.isEmpty(start_time) && _.isEmpty(end_time)) {
      (start_time = organizationSettings.route_delivery_start_time),
        (end_time = organizationSettings.route_delivery_end_time);
    }

    const {
      startTimes,
      endTimes,
      dispatcherDisabledStartHours,
      dispatcherDisabledEndHours,
    } = this.calculateOrderTimeData(start_time, end_time);

    const styleProps =
      orderType === "transfer_params"
        ? {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 24,
          xxl: 24,
        }
        : {
          xs: 24,
          sm: 24,
          md: 12,
          lg: locationIndex < 1 ? 24 : 24, // 24
          xl: locationIndex < 1 ? 24 : 24, // 24
          xxl: locationIndex < 1 ? 24 : 24, // 12
        };
    const cs_address = _.get(dataObject.cs_location, "l_address", {});
    const isTransferPickup = currentOrder.type_of_order === "T" && dataObject?.type_of_loc === 'PICKUP'
    return (
      <Fragment>
        {formData[orderType] && (
          <Card
            title={
              <Fragment>
                {title}
                {!this.state.isNew &&
                  !currentOrder.is_location_based &&
                  this.renderOrderStatus(currentOrder)}
              </Fragment>
            }
            key={key}
            showArrow={false}
            size="small"
            className="customCard marginTop10"
            extra={
              !this.state.isNew && !currentOrder.is_location_based ? (
                this.renderOrderHeadindDetails(currentOrder)
              ) : currentOrder.is_location_based ? (
                <Fragment>
                  {/* <Popconfirm
                          placement="topRight"
                          title={I18n.t("messages.delete_confirm")}
                          onConfirm={(e) => {
                            e.stopPropagation()
                            this.handleDeleteStop(orderType, locationIndex)
                          }}
                          okText="Yes"
                          cancelText="No"
                      >
                        <Button type="danger" size="small" className='marginRight5'>{I18n.t('general.delete')}</Button>
                      </Popconfirm> */}
                  
                    <Button type="cancel" size="small" className="textRed"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleClearStop(orderType, locationIndex);
                    }}>
                    
                      {I18n.t("general.clear")}
                    </Button>
             
                </Fragment>
              ) : (
                <Fragment />
              )
            }
          >
            <Row gutter={8}>
              <Col
                xs={24}
                sm={24}
                lg={locationIndex < 0 ? 12 : 24}
                md={locationIndex < 0 ? 12 : 24}
                xl={locationIndex < 0 ? 12 : 24}
              >
                <Row gutter={16} className="appointmentForm">
                  <Col sm={24} xs={24} md={24} lg={24}>
                    <Card size="small">
                      <Row
                        type={"flex"}
                        justify={
                          currentOrder.type_of_order === "T" &&
                            locationIndex === 1
                            ? "end"
                            : "space-between"
                        }
                      >
                        <Col sm={24} xs={24} md={14} lg={15}>
                          {/* <Row type='flex' gutter sm={ 16 } xs={ 16 } md={ 24 } align='middle' justify={ locationIndex === 1 ? 'end' : 'space-between' }> */}
                          {currentOrder.type_of_order === "T" &&
                            locationIndex >= 0 &&
                            currentOrder.type_of_order !== "LH" && (
                              <OrderActivityForm
                                formData={dataObject}
                                handleOnChange={(element, value) =>
                                  this.handleOrderDetailsChange(
                                    orderType,
                                    locationIndex,
                                    element,
                                    value
                                  )
                                }
                              />
                            )}
                        </Col>
                        {this.state.formData.type_of_order === "MS" && (
                        <Col sm={24} xs={24} md={10} lg={10}>
                            <SideBySideFormItem
                              label={"Location Type"}
                            >
                              <RadioGroup
                                onChange={(e) =>
                                  this.handleOrderDetailsChange(
                                    orderType,
                                    locationIndex,
                                    "type_of_loc",
                                    e.target.value
                                  )
                                }
                                value={
                                  !isEmpty(dataObject.type_of_loc) 
                                  ? dataObject.type_of_loc 
                                  : AppConfig.default_type_of_loc
                                }
                                size={this.state.elementSize}
                              >
                                <Radio value={"PICKUP"}>Pickup</Radio>
                                <Radio value={"DELIVERY"}>Delivery</Radio>
                              </RadioGroup>
                            </SideBySideFormItem>
                          </Col>
                          )}
                        <Col sm={24} xs={24} md={10} lg={9}>
                          <SideBySideFormItem
                            label={`${I18n.t(
                              "configurations.service_duration_label"
                            )}`}
                            labelCol={{
                              xs: 24,
                              sm: 24,
                              md: 15,
                              lg: 12,
                              xl: 12,
                              xxl: 12,
                            }}
                            wrapperCol={{
                              xs: 24,
                              sm: 24,
                              md: 9,
                              lg: 12,
                              xl: 12,
                              xxl: 12,
                            }}
                          >
                            <Input
                              type="number"
                              value={dataObject.service_duration}
                              onChange={(e) =>
                                this.handleOrderDetailsChange(
                                  orderType,
                                  locationIndex,
                                  "service_duration",
                                  e.target.value
                                )
                              }
                              style={{ width: 110 }}
                              className="width100Per"
                              precision={0}
                              step="1"
                              min={0}
                              addonAfter="mins"
                              onBlur={(e) => {
                                if (Number(dataObject.service_duration) < 0) {
                                  this.handleOrderDetailsChange(
                                    orderType,
                                    locationIndex,
                                    "service_duration",
                                    0
                                  );
                                }
                              }}
                              size={elementSize}
                            />
                          </SideBySideFormItem>
                        </Col>
                      </Row>
                      {this.state.formData.type_of_order === "MS" && (
                      <Row
                        type="flex"
                        style={{marginTop: 10}}
                      >
                      
                        <Col sm={24} xs={24} md={9} lg={9}>
                            <SideBySideFormItem
                              label={"Total Weight"}
                              style={{paddingTop: 3}}
                            >
                             <Input
                              type="number"
                              value={dataObject.weight}
                              onChange={(e) =>
                                this.handleOrderDetailsChange(
                                  orderType,
                                  locationIndex,
                                  "weight",
                                  e.target.value
                                )
                              }
                              style={{ width: 110 }}
                              className="width100Per"
                              precision={0}
                              step="1"
                              min={0}
                              size={elementSize}
                            />
                            </SideBySideFormItem>
                        </Col>
                        <Col sm={24} xs={24} md={7} lg={7}>
                            <SideBySideFormItem
                              label={"Quantity"}
                              style={{paddingTop: 3}}
                            >
                             <Input
                              type="number"
                              value={dataObject.quantity}
                              onChange={(e) =>
                                this.handleOrderDetailsChange(
                                  orderType,
                                  locationIndex,
                                  "quantity",
                                  e.target.value
                                )
                              }
                              style={{ width: 110 }}
                              className="width100Per"
                              precision={0}
                              step="1"
                              min={0}
                              size={elementSize}
                            />
                            </SideBySideFormItem>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={8}>
                            <SideBySideFormItem
                              style={{paddingTop: 3}}
                              label={"Skids/Pallets"}
                            >
                             <Input
                              type="number"
                              value={dataObject.pallets}
                              onChange={(e) =>
                                this.handleOrderDetailsChange(
                                  orderType,
                                  locationIndex,
                                  "pallets",
                                  e.target.value
                                )
                              }
                              style={{ width: 110 }}
                              className="width100Per"
                              precision={0}
                              step="1"
                              min={0}
                              size={elementSize}
                            />
                            </SideBySideFormItem>
                        </Col>
                      </Row>
                      )}
                      {this.state.formData.type_of_order === "MS" && (
                      <Row 
                        style={{marginTop: 10}}
                        type="flex">
                        <Col sm={24} xs={24} md={9} lg={9}>
                            <SideBySideFormItem
                              style={{paddingTop: 3}}
                              label={
                                <Fragment>
                                  {I18n.t("order.surface_area_sf")}&nbsp; (
                                  {I18n.t("order.surface_area_units")}
                                  <sup>2</sup>)
                                </Fragment>
                              }
                            >
                             <Input
                              type="number"
                              value={dataObject.surface_area}
                              onChange={(e) =>
                                this.handleOrderDetailsChange(
                                  orderType,
                                  locationIndex,
                                  "surface_area",
                                  e.target.value
                                )
                              }
                              
                              style={{ width: 110 }}
                              className="width100Per"
                              precision={0}
                              step="1"
                              min={0}
                              size={elementSize}
                            />
                            </SideBySideFormItem>
                        </Col>
                      </Row>
                      )}
                    </Card>
                  </Col>
                  <Col
                    sm={24}
                    xs={24}
                    md={24}
                    lg={24}
                    className={
                      currentOrder.type_of_order == "T" ? "paddingTop10" : ""
                    }
                  >
                    <Card
                      size="small"
                      title={
                        this.props.isAirCargo ? null :
                          <Fragment>
                            {/* {isTransferPickup ? I18n.t("order.shipper_details") : I18n.t("customer.details")} */}
                            <EndUserTitle stringToAppend={I18n.t("general.details")} />
                          </Fragment>
                      }
                      bodyStyle={{ textAlign: "left" }}
                    >
                      {cs_address.coordinates &&
                        (parseFloat(cs_address.coordinates[0]) === 0.0 ||
                          parseFloat(cs_address.coordinates[1]) === 0.0) && (
                          <div
                            className="marginLeft10"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <Icon
                              type="warning"
                              style={{ fontSize: "18px", color: "#ff0000" }}
                            />
                            <span className="marginLeft10">
                              {" "}
                              Invalid Address, Location not resolved.{" "}
                            </span>
                          </div>
                        )}
                      <ConsigneeDetailsForm

                        showpredefined_loc={true}
                        onAddressSelect={(address) =>
                          this._onAddressSelect(
                            address,
                            orderType,
                            locationIndex
                          )
                        }
                        formData={{
                          ...dataObject,
                        }}
                        orderType={orderType}
                        locIndex={locationIndex}
                        handleOnAddressChange={(element, value, obj) =>
                          this.handleOnAddressChange(
                            orderType,
                            locationIndex,
                            element,
                            value,
                            obj
                          )
                        }
                        handleOnChange={(element, value) =>
                          this.handleOrderDetailsChange(
                            orderType,
                            locationIndex,
                            element,
                            value
                          )
                        }
                        isDeliveryRequired={
                          orderType === "transfer_params" &&
                            dataObject.type_of_loc === "DELIVERY"
                            ? this.state.isDeliveryRequired
                            : true
                        }
                        map={this.state.map}
                        predefined_locations={
                          organizationSettings.predefined_locations
                        }
                        includePredefinedStops={true}
                        editableFields={[
                          "email",
                          "customer_phone_one",
                          "customer_phone_two",
                          "address",
                        ]}
                        reqFrom="orders"
                        gridSize={{
                          xs: 24,
                          sm: 24,
                          md: 12,
                          lg: 8,
                          xl: 8,
                          // lg: locationIndex >= 0 ? 8 : 4,
                          // xl: locationIndex >= 0 ? 8 : 4,
                        }}
                        //requireSideBySide={locationIndex >= 0 ? false : true}
                        size={elementSize}
                        customerReqFields={requiredFields.filter(rec => rec.isRequired).map(rec => rec.form_field)}
                        accountConfigs={this.state.config}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                lg={locationIndex < 0 ? 12 : 24}
                md={locationIndex < 0 ? 12 : 24}
                xl={locationIndex < 0 ? 12 : 24}
              >
                <Row gutter={16} className="appointmentForm">
                  <Col sm={24} xs={24} md={24} lg={24}>
                    {this.state.callFrom === "preplan" && (
                      <Row>
                        <Col xs={24}>
                          {this.state.currentPreplan && (
                            <Col xs={24} className="textBold">
                              Preplan Scheduled Info :{" "}
                              {moment(
                                this.state.currentPreplan.delivery_start_date
                              ).format(AppConfig.date_format)}{" "}
                              -{" "}
                              {moment(
                                this.state.currentPreplan.delivery_end_date
                              ).format(AppConfig.date_format)}
                            </Col>
                          )}
                        </Col>
                      </Row>
                    )}
                    <Row type="flex" align="middle" gutter={[8, 8]}>
                      <Col {...styleProps}>
                        <Card
                          title={I18n.t("appointmentForm.preference_title")}
                          size="small"
                          extra={
                           
                              <Button type="danger" size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleOrderDetailsChange(
                                  orderType,
                                  locationIndex,
                                  "appointments",
                                  []
                                );
                              }}
                              >
                                {I18n.t("general.clear")}
                              </Button>
                          }
                        >
                          {currentOrder && currentOrder.custom_appt_notes && (
                            <Row className="marginBottom15">
                              <Col xs={24} sm={24} md={24} lg={24}>
                                <span className="textBold">
                                  {I18n.t(
                                    "appointmentForm.appt_suggested_notes"
                                  )}{" "}
                                  &nbsp;:&nbsp;
                                </span>
                                <span>{currentOrder.custom_appt_notes}</span>
                              </Col>
                            </Row>
                          )}

                          <ConfiguredDeliveryTime
                            start_time={start_time}
                            end_time={end_time}
                            isMilitaryTime={this.state.isMilitaryTime}
                          />
                          {this.state.warehouses.length &&
                            currentOrder.warehouse_code ? (
                            <Appointments
                              source={"ORDER_FORM"}
                              isNewAppointment={this.state.isNew}
                              warehouse_code={currentOrder.warehouse_code}
                              slots={getWhLocationSlotDetails(
                                this.state.warehouses,
                                currentOrder.warehouse_code
                              )}
                              feasible_dates={currentOrder.feasible_dates || []}
                              selectedTemplateId={this.state.selectedTemplateId}
                              isMilitaryTime={this.state.isMilitaryTime}
                              showClear={false}
                              hasDeliveryZipcodesConfig={
                                (config.validate_delivery_zipcode === "BOTH" ||
                                  currentOrder.address_type ===
                                  config.validate_delivery_zipcode) &&
                                config.delivery_zipcodes === "true"
                              }
                              // adjustStyle={
                              //   {
                              //     label: 2,
                              //     wrapper: 22,
                              //   }
                              // }
                              onSlotsChange={(selectedSlot, isCustom) => {
                                const [start_time, end_time] =
                                  selectedSlot.split("-");
                                const newFormData = _.cloneDeep(dataObject);
                                let newAppointments = [];
                                if (selectedSlot.length > 0 || isCustom) {
                                  // let selectedSlots = isCustom ? [ 'CUSTOM' ] : slots.map((slot) => `${slot.start_time}-${slot.end_time}`);
                                  let selectedSlots = isCustom
                                    ? ["CUSTOM"]
                                    : [selectedSlot];
                                  // add the check to replace '08:00-20:00' with 'ANY'
                                  if (selectedSlots[0] === "08:00-20:00") {
                                    selectedSlots = ["ANY"];
                                  }
                                  if (
                                    !_.isEmpty(dataObject.appointments) &&
                                    dataObject.appointments.length > 0
                                  ) {
                                    newAppointments.push({
                                      ...newFormData.appointments[0],
                                      slots: selectedSlots,
                                      start_time: isCustom ? null : start_time,
                                      end_time: isCustom ? null : end_time,
                                    });
                                  } else {
                                    newAppointments.push({
                                      slots: selectedSlots,
                                      has_expedite: false,
                                      start_time: isCustom ? null : start_time,
                                      end_time: isCustom ? null : end_time,
                                    });
                                  }
                                  this.handleOrderDetailsChange(
                                    orderType,
                                    locationIndex,
                                    "appointments",
                                    newAppointments
                                  );
                                }
                              }}
                              // onSlotsChange={ (slots, isCustom) => {
                              //   const newFormData = _.cloneDeep(dataObject);
                              //   let newAppointments = [];
                              //   if (slots.length > 0 || isCustom) {
                              //     let selectedSlots = isCustom ? [ 'CUSTOM' ] : slots.map((slot) => `${slot.start_time}-${slot.end_time}`);
                              //     // add the check to replace '08:00-20:00' with 'ANY'
                              //     if (selectedSlots.length === 1 && selectedSlots[ 0 ] === '08:00-20:00') {
                              //       selectedSlots = [ 'ANY' ];
                              //     }
                              //     if (!_.isEmpty(dataObject.appointments) && dataObject.appointments.length > 0) {
                              //       newAppointments.push({
                              //         ...newFormData.appointments[ 0 ],
                              //         slots: selectedSlots,
                              //         start_time: isCustom ? null : slots[ 0 ].start_time,
                              //         end_time: isCustom ? null : slots[ slots.length - 1 ].end_time,
                              //       });
                              //     } else {
                              //       newAppointments.push({
                              //         slots: selectedSlots,
                              //         has_expedite: false,
                              //         start_time: isCustom ? null : slots[ 0 ].start_time,
                              //         end_time: isCustom ? null : slots[ slots.length - 1 ].end_time,
                              //       });
                              //     }
                              //     if(currentOrder.is_location_based){
                              //       this.handleOrderDetailsChange(
                              //         orderType,
                              //         locationIndex,
                              //         'appointments',
                              //         newAppointments,
                              //       )
                              //     } else {
                              //       // newFormData.appointments = newAppointments;
                              //       // this.setState({ formData: newFormData });
                              //       this.handleOrderDetailsChange(
                              //         orderType,
                              //         -1,
                              //         'appointments',
                              //         newAppointments
                              //       )
                              //     }

                              //   }
                              // } }
                              clearPreference={(index) =>
                              // this.clearPreference(orderType, index)
                              {
                                if (locationIndex >= 0) {
                                  this.setState(
                                    {
                                      formData: {
                                        ...this.state.formData,
                                        [orderType]: {
                                          ...this.state.formData[orderType],
                                          locations: this.state.formData[
                                            orderType
                                          ].locations.map((location, i) => {
                                            if (i === locationIndex) {
                                              return {
                                                ...location,
                                                appointments: [
                                                  {
                                                    appt_date: "",
                                                    slots: [],
                                                    has_expedite: false,
                                                    start_time: null,
                                                    end_time: null,
                                                  },
                                                ],
                                              };
                                            }
                                            return location;
                                          }),
                                        },
                                      },
                                    },
                                    () => {
                                      this.setState({
                                        isMultiDayTransfer: false,
                                        storageNeeded: false,
                                      });
                                    }
                                  );
                                } else {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      [orderType]: {
                                        ...this.state.formData[orderType],
                                        appointments: [
                                          {
                                            appt_date: "",
                                            slots: [],
                                            has_expedite: false,
                                            start_time: null,
                                            end_time: null,
                                          },
                                        ],
                                      },
                                    },
                                  });
                                }
                              }
                              }
                              handleDateChange={
                                this.state.callFrom.length
                                  ? (index, element, value, cb) => {
                                    this.handleApptChange(
                                      orderType,
                                      locationIndex,
                                      index,
                                      element,
                                      value
                                    );
                                  }
                                  : null
                              }
                              // switchToCustomSelection={ (index) => {
                              //   const prevAppt = _.cloneDeep(dataObject.appointments[ 0 ]);
                              //   const newAppointments = [
                              //     {
                              //       ...prevAppt,
                              //       slots: [ 'CUSTOM' ],
                              //       start_time: null,
                              //       end_time: null,
                              //     },
                              //   ]
                              //   if(currentOrder.is_location_based){
                              //     this.handleOrderDetailsChange(
                              //       orderType,
                              //       locationIndex,
                              //       'appointments',
                              //       newAppointments,
                              //     )
                              //   }
                              //   else {
                              //     this.handleOrderDetailsChange(
                              //       orderType,
                              //       -1,
                              //       'appointments',
                              //       newAppointments,
                              //     )
                              //   }
                              // } }
                              itemOnChange={(index, element, value) => {
                                const newFormData = _.cloneDeep(dataObject);
                                const newAppointments = [];
                                if (
                                  !_.isEmpty(newFormData.appointments) &&
                                  newFormData.appointments.length > 0
                                ) {
                                  const isCustom =
                                    element === "slots"
                                      ? (Array.isArray(value) &&
                                        value.length > 0 &&
                                        value[0] === "CUSTOM") ||
                                      newFormData.appointments[0].slots[0] ===
                                      "CUSTOM"
                                      : newFormData.appointments[0].slots[0] ===
                                      "CUSTOM";
                                  newAppointments.push({
                                    ...newFormData.appointments[0],
                                    [element]: value,
                                    start_time: isCustom
                                      ? element === "slots"
                                        ? null
                                        : element === "start_time"
                                          ? value
                                          : newFormData.appointments[0].start_time
                                      : newFormData.appointments[0].start_time,
                                    end_time: isCustom
                                      ? element === "slots"
                                        ? null
                                        : element === "end_time"
                                          ? value
                                          : newFormData.appointments[0].end_time
                                      : newFormData.appointments[0].end_time,
                                  });
                                } else {
                                  newAppointments.push({
                                    appt_date: "",
                                    slots: [],
                                    has_expedite: false,
                                    start_time: null,
                                    end_time: null,
                                    [element]: value,
                                  });
                                }
                                // if(element === "appt_date"){
                                //   this.handleshowMessage(orderType,locationIndex,'appointments',newAppointments)
                                // }
                                if (currentOrder.is_location_based) {
                                  this.handleOrderDetailsChange(
                                    orderType,
                                    locationIndex,
                                    "appointments",
                                    newAppointments
                                  );
                                } else {
                                  // newFormData.appointments = newAppointments;
                                  // this.setState({ formData: newFormData });
                                  this.handleOrderDetailsChange(
                                    orderType,
                                    -1,
                                    "appointments",
                                    newAppointments
                                  );
                                }
                                // newFormData[ orderType ].appointments = newAppointments;
                                // this.setState({ formData: newFormData });
                              }}
                              data={{
                                preferences: _.get(
                                  dataObject,
                                  "appointments",
                                  []
                                ),
                                endHour: endTimes.hourValue,
                                endMinute: endTimes.minuteValue,
                                startHour: startTimes.hourValue,
                                startMinute: startTimes.minuteValue,
                                disabledStartHours:
                                  dispatcherDisabledStartHours,
                                disabledEndHours: dispatcherDisabledEndHours,
                                showExpedite: true,
                              }}
                              size={this.state.elementSize}
                            />
                          ) : (
                            <Text className="textBold">
                              "No Warehouse Found"
                            </Text>
                          )}

                          {currentOrder.customer_comments && (
                            <Row className="marginTop10 marginBottom10">
                              <Col xs={24} sm={24} md={24} lg={24}>
                                <Row>
                                  <Col sm={24} xs={24} md={24} lg={24}>
                                    <FormIcon />
                                    &nbsp;
                                    <span className="textBold">
                                      {I18n.t(
                                        "appointmentForm.notes_to_driver"
                                      )}{" "}
                                      &nbsp;:&nbsp;
                                    </span>
                                    <span>
                                      {currentOrder.customer_comments}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Card>
                      </Col>
                      {/* { !this.state.isNew && formData[orderType]?.id && (
                    <Col { ...styleProps }>
                      { this.renderOrderPics(orderType, locationIndex , (orderType == 'transfer_params') && isNewLocation) }
                    </Col>
                  )} */}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={16} className="appointmentForm">
              {!currentOrder.is_location_based && (
                <Col xs={24}>
                  <div className="appointmentForm">
                    {this.renderItems(orderType)}
                  </div>
                </Col>
              )}

              {!this.state.isNew && !currentOrder.is_location_based && (
                <Col xs={24} className="marginTop10 padding10">
                  <Row>
                    <Col
                      sm={24}
                      xs={24}
                      md={24}
                      lg={24}
                      className="dispatcherNotes"
                    >
                      {this.renderDispatcherNotes(orderType)}
                    </Col>
                  </Row>
                </Col>
              )}
              {!this.state.isNew && !currentOrder.is_location_based && (
                <Col xs={24} className="marginTop10 padding10">
                  <Row>
                    <Col
                      sm={24}
                      xs={24}
                      md={24}
                      lg={24}
                      className="dispatcherNotes"
                    >
                      {this.renderPrivateNotes(orderType)}
                    </Col>
                  </Row>
                </Col>
              )}
              {false &&
                currentOrder.order_exceptions &&
                currentOrder.order_exceptions.length > 0 && (
                  <Col sm={24} xs={24} md={24} lg={24}>
                    <Collapse
                      defaultActiveKey={["1"]}
                      style={{ height: "auto" }}
                      expandIconPosition="right"
                      className="marginTop20"
                    >
                      <Panel
                        header={<Fragment>{I18n.t("exceptions.log")}</Fragment>}
                        key="1"
                      >
                        <Row gutter={16}>
                          <Col xs={24}>{this.renderExceptions(orderType)}</Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                )}
            </Row>
          </Card>
        )}
      </Fragment>
    );
  };
  renderItemsExceptionModal = () => {
    const { currentRecord, showItemExceptionModal } = this.state;
    return (
      <ItemsExceptionModal
        visible={showItemExceptionModal}
        onCancel={() => {
          this.setState({ showItemExceptionModal: false });
        }}
        data={currentRecord}
        isMilitaryTime={this.state.isMilitaryTime}
      />
    );
  }

  renderItems = (orderType) => {
    const { orderItems, formData, accounts, currentItem, currentParamType } =
      this.state;
    let items = [];
    let orderId = "";
    if (!isEmpty(orderType)) {
      if (!["T", "LH","MS"].includes(formData[orderType].type_of_order)) {
        items =
          formData[orderType] && formData[orderType].orderItems
            ? formData[orderType].orderItems
            : [];
      } else {
        items = Array.isArray(orderItems) ? orderItems : [];
      }

      orderId = formData[orderType].id ? formData[orderType].id : "";
    } else {
      items = orderItems;
    }
    // let orderItems = [];
    // const typeOfOrder = formData.type_of_order;
    // AppConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
    //   orderItems = [].concat(orderItems, formData[details.key].orderItems);
    // });
    let account = {};
    const accountIndex = _.findIndex(accounts, {
      code: formData.account_code,
    });
    if (accountIndex >= 0) {
      account = accounts[accountIndex];
    }
    let quantity = 0;
    this.state.orderConfig.orderTypes[formData.type_of_order].types
      .filter((type, index) => index === 0)
      .forEach((details, index) => {
        if (!isEmpty(formData[details.key])) {
          quantity = quantity + formData[details.key].quantity;
        }
      });
 
    return (
      <Fragment>
        {!this.state.isNew && (
          <Collapse
            defaultActiveKey={["1"]}
            style={{ height: "auto" }}
            expandIconPosition="right"
            className="marginTopq0"
            size="small"
          >
            <Panel
              header={<Fragment>{I18n.t("order.item_details")}</Fragment>}
              key="1"
              size="small"
            // extra={
            //   checkServiceExistance("CCOI") ? (
            //     <Button
            //       size="small"
            //       onClick={(event) => {
            //         this.handleAddItemClick();
            //         event.stopPropagation();
            //       }}
            //       type="primary"
            //     >
            //       {I18n.t("general.add")}&nbsp;{I18n.t("order.item")}
            //     </Button>
            //   ) : null
            // }
            >
              {!this.state.groupItems ? (
                <Fragment>
                  <Card
                    size="small"
                    title={
                      Object.keys(currentItem).length
                        ? "Update Item"
                        : "New Item"  
                    }
                  >
                    {!this.state.inProgress && (
                      <ItemDetailsForm
                        item={orderType === currentParamType ? currentItem : {}}
                        order={{
                          ...formData,
                          quantity,
                        }}
                        currentItem={
                          orderType === currentParamType ? currentItem : {}
                        }
                        saveItem={this.handleUpdateItem}
                        account={account}
                        items={this.state.orderItems}
                        clearActiveItem={this.clearActiveItem}
                        ItemDetailsFormRef={
                          orderType === "pickup_params"
                            ? this.PickupItemDetailsFormRef
                            : this.DeliveryItemDetailsFormRef
                        }
                        size={this.state.elementSize}
                        orderConfig={this.props.orderConfig}
                        currentOrg={this.props.currentOrg}
                        accountUnitContext={this.props.accountUnitContext}
                        updatePallets={this.updatePallets}
                        currentOrderType={orderType}

                      />
                    )}
                  </Card>
                  <Row gutter={16}>
                    {items.length > 1 && (
                      <div className="alignRight marginTop10">
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            this.setState({
                              groupItems: true,
                            });
                          }}
                        >
                          {" "}
                          {I18n.t("order.group_items")}
                        </Button>
                      </div>
                    )}
                    <ItemsForm
                      organizationSettings={this.props.organizationSettings}
                      accountUnitContext={this.props.accountUnitContext}
                      account={account}
                      data={items || []}
                      pagination={{ position: "none" }}
                      locationData = {this.state.formData.transfer_params}
                      formData = {this.state.formData?.type_of_order}
                      isVisible
                      onChange={(id, element, value) =>
                        this.handleOnItemsChange(id, element, value)
                      }
                      showItemType={false}
                      editClick={(id) => this.handleItemClick(id, orderType)}
                      deleteClick={(id) =>
                        this.handleDeleteClick(id, orderType)

                      }
                      isLoading={this.state.isDeletingItem}
                      showStopColumn={this.state.formData?.type_of_order === "MS"}
                      orderConfig={this.props.orderConfig}
                      rowClassName={(record, index) =>
                        record.item_picture_data && record.item_picture_data?.some(item => _.get(item, "exception_code") !== "") ? "errRow" : ""
                      }
                      handleModalView={(record) => {
                        this.setState({
                          showItemExceptionModal: true,
                          currentRecord: record,
                        });
                      }
                      }
                      userContext = {this.props.userContext}
                    />

                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  <ItemGrouping
                    items={items || []}
                    onCancel={() => {
                      this.setState({
                        groupItems: false,
                        currentItem: {},
                        isItemNew: true,
                      });
                    }}
                    customer_order_id={orderId}
                    updateItems={(updatedItems) => {
                      this.handleUpdateItemGrouping(updatedItems);
                    }}
                    orderConfig={this.props.orderConfig}
                  />
                </Fragment>
              )}
            </Panel>
          </Collapse>
        )}
        {this.state.showItemExceptionModal && this.renderItemsExceptionModal()}
      </Fragment>
    );
  };

  goToTab = (step) => {
    if (this.state.changedOrderType) {
      Modal.warning({
        title: 'Warning',
        content: 'You have made changes to the order. Please save the order before proceeding to next step.',
      });
      return;
    }

    // clear the search params
    const { history } = this.props;
    if (history.location.search) {
      history.push(history.location.pathname);
    }

    this.setState(
      {
        activeKey: step,
        setInvoiceData: step === "billing" ? true : false,
      },
      () => {
        this.setState({
          setInvoiceData: false,
        });
      }
    );
  };

  _renderOrderAccAccessorialBlock = ({
    details,
    currentLocation = null,
    csLocation = {},
  }) => {
    const { formData } = this.state;
    return (
      <Collapse
        defaultActiveKey={[`acc${details.key}`]}
        style={{ height: "auto" }}
        expandIconPosition="right"
        className="marginBottom10 customCollapse"
        key={`accessorials${details.key}`}
      >
        <Panel
          header={
            <Fragment>
              {!currentLocation
                ? details.label
                : I18n.t(`order.${currentLocation.type_of_loc}`)}
              &nbsp;
              {I18n.t("general.accessorial")}
            </Fragment>
          }
          key={`acc${details.key}`}
        >
          {formData[details.key] && (
            <Row gutter={16}>
              <Col xs={24}>
                {this._renderOrderAccAccessorial(
                  details.key,
                  currentLocation ? currentLocation.id : csLocation.id,
                  currentLocation,
                )}
              </Col>
            </Row>
          )}
        </Panel>
      </Collapse>
    );
  };

  _renderOrderAccAccessorial = (typeOfOrder, currentLocation = null, haveLocations = null) => {
    const { formData, updatedKey } = this.state;
    const currentOrder = formData[typeOfOrder] ? formData[typeOfOrder] : {};
    const los = haveLocations ?
      (_.get(_.find(currentOrder.locations, { id: currentLocation }), "los_code", "") || "") :
      currentOrder.los;
    return (
      <OrderAccAccessorial
        account_id={currentOrder.account_id}
        currentLocation={currentLocation}
        config={this.state.config}
        customer_order_id={currentOrder.id}
        org_id={userStore.getStateValue("selectedOrg")}
        //handleStateOnchange={this.handleStateOnchange}
        onCancel={this.formOnCancel}
        handleOnChange={this.handleStateOnchange}
        drivers={this.state.driversData}
        isMilitaryTime={this.state.isMilitaryTime}
        required_accessorials={
          currentOrder?.required_accessorials
            ? currentOrder.required_accessorials
            : []
        }
        haveMultipleLocations={currentOrder?.locations?.length > 1}
        losCode={los}
        updatedKey={updatedKey}
      />
    );
  };

  _renderOrderQuoteAmount = ({ typeOfOrder }) => {
    const { formData } = this.state;
    const orders = [];
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details) => {
      const currentOrder = formData[details.key] ? formData[details.key] : {};
      const locations = currentOrder.locations || [];
      if (locations?.length) {
        locations.forEach(loc => {
          orders.push({
            id: currentOrder.id,
            order_no: currentOrder.customer_order_number,
            label: details.label,
            quote_order_number: currentOrder.quote_order_number,
            location_id: loc.id || '',
            type_of_loc: loc.type_of_loc,
          });
        })
      } else {
        orders.push({
          id: currentOrder.id,
          order_no: currentOrder.customer_order_number,
          label: details.label,
          quote_order_number: currentOrder.quote_order_number,
          location_id: currentOrder?.cs_location?.id || '',
          type_of_loc: null,
        });
      }

    });
    return (
      <OrderQuoteAmount
        orders={orders}
        org_id={userStore.getStateValue("selectedOrg")}
        actionType="edit"
        handleOnChange={this.handleStateOnchange}
      />
    );
  };

  _renderAdjustments = ({ typeOfOrder }) => {
    const { formData } = this.state;
    const orders = [];
    return (<Fragment>
      {
        this.state.orderConfig.orderTypes[typeOfOrder].types.map((details) => {
          const currentOrder = formData[details.key] ? formData[details.key] : {};
          return <Collapse
          defaultActiveKey={[`adj${currentOrder.id}`]}
          style={{ height: 'auto' }}
          expandIconPosition="right"
          className="marginBottom10 customCollapse"
          key={`ColaAdj`}
          size="small"
      >
        <Panel
            header={
              <Fragment>{currentOrder.customer_order_number} &nbsp;{I18n.t('adjustments.label')}</Fragment>
            }
            key={`adj${currentOrder.id}`}
            size="small"
        ><InvoicesLines order={currentOrder} /></Panel></Collapse>
        })
      }
    </Fragment>);
  };

  updateFormByOrders = (
    typeOfOrder,
    orders,
    element,
    keyValue = "",
    defaultValue = []
  ) => {
    const formData = _.cloneDeep(this.state.formData);
    let isModified = false;
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details) => {
      const formOrder = formData[details.key] ? formData[details.key] : {};
      const currentOrder = _.find(orders, { order_id: formOrder.id });
      if (!isEmpty(currentOrder)) {
        isModified = true;
        formData[details.key][element] = currentOrder[keyValue] || defaultValue;
      }
    });
    if (isModified) {
      this.setState({
        formData,
      });
    }
  };

  _renderWarehouseAccessorials = ({ typeOfOrder }) => {
    const { formData } = this.state;
    const orders = [];
    this.state.orderConfig.orderTypes[typeOfOrder].types.forEach((details) => {
      const currentOrder = formData[details.key] ? formData[details.key] : {};
      orders.push({
        id: currentOrder.id,
        order_no: currentOrder.customer_order_number,
        label: details.label,
        required_accessorials: currentOrder.required_accessorials,
      });
    });
    return (
      <AccWhAccessorial
        accountId={formData.account_id}
        orders={orders}
        actionType="edit"
        handleOnChange={(value) =>
          this.updateFormByOrders(
            typeOfOrder,
            value,
            "required_accessorials",
            "accessorials",
            []
          )
        }
        org_id={userStore.getStateValue("selectedOrg")}
      />
    );
  };

  getOrderNo = (typeOfOrder) => {
    const { formData } = this.state;
    let orderNos = [];
    if (!isEmpty(typeOfOrder)) {
      orderNos = this.state.orderConfig.orderTypes[typeOfOrder].types.map((details) =>
        formData[details.key] ? formData[details.key].customer_order_number : ""
      );
    }
    orderNos = _.compact(orderNos);
    return orderNos.length > 0 ? orderNos.join(", ") : "";
  };

  // handleTemplateChange = (value) => {
  //   // find the template and get the data using loadash
  //   const { templates } = this.state;
  //   const templateData = _.find(templates, { id: value });
  //   if (!templateData) return;
  //   this.handleTypeChange('type_of_order', templateData.order_type);
  //   const orderShipmentInfo = {};
  //   if (!(templateData.order_type === "T")) {
  //     AppConfig.orderTypes[ templateData.order_type ].types.forEach((details) => {
  //       const { key} = details;
  //       const { type_of_order  } = details;
  //       let orderData = templateData.locations.find(i => {
  //         if ((i.l_type === "D" && type_of_order === "D") || (i.l_type === "D" && type_of_order === "TD")) {
  //           return i;
  //         } else if ((i.l_type === "R" && type_of_order === "R") || (i.l_type === "R" && type_of_order === "TR")) {
  //           return i;
  //         }
  //       });
  //       orderShipmentInfo[ key ] = {
  //         ...this.state.formData[ key ],
  //         account_code : _.isObject(templateData.selected_account) ? templateData.selected_account.code : "",
  //         account_id : templateData.account_id,
  //         appointments: adjustTemplateAppointment(orderData.appointment, this.state.isMilitaryTime).map((appt) => {
  //           return renameKeys(appt, {
  //             item_option: "slots",
  //           });
  //         }),
  //         service_duration: orderData.service_duration,
  //         orderItems: [],
  //         accountAccessorials: [],
  //         warehouse_code : _.isObject(templateData.selected_warehouse) ? templateData.selected_warehouse.location_code : "",
  //         warehouse_id: templateData.warehouse_id,
  //         los: templateData.los,
  //         weight: templateData.weight,
  //         quantity: templateData.quantity,
  //         notes: templateData.notes,
  //         pallets: templateData.skids_pallets,
  //         is_hazardous: templateData.hazmat,
  //         orderAccessorials: [],
  //         cs_location: {
  //           l_address: orderData.address
  //         },
  //         company_name: orderData.company_name,
  //         customer_first_name: orderData.first_name,
  //         customer_last_name: orderData.last_name,
  //         customer_phone_one: orderData.phone_1,
  //         customer_phone_two: orderData.phone_2,
  //         customer_email: orderData.email,
  //         type_of_order: details.type_of_order,
  //         reference_1_type : '',
  //         reference_1 : '',
  //         reference_2_type : '',
  //         reference_2 : '',
  //         locations: [...templateData.locations]
  //       };
  //     });
  //   } else {
  //     // linehaul
  //     const linehaulLocations = templateData.locations.map((location) => {
  //       const defaultLoc = location.type_of_loc === 'PICKUP' ? pickupLocation : dropLocation;
  //       return {
  //         ...defaultLoc,
  //         ...location,
  //         appointments: adjustTemplateAppointment(location.appointment, this.state.isMilitaryTime).map((appt) => {
  //           return renameKeys(appt, {
  //             item_option: "slots",
  //           });
  //         }),
  //         service_duration: location.service_duration,
  //         cs_location: {
  //           l_address: location.address
  //         },
  //         company_name: location.company_name,
  //         customer_first_name: location.first_name,
  //         customer_last_name: location.last_name,
  //         customer_phone_one: location.phone_1,
  //         customer_phone_two: location.phone_2,
  //         customer_email: location.email,
  //       };
  //     });

  //     const selectedAccCode = this.state.accounts.find(i => i.id === templateData.account_id).code
  //     templateData.account_code = selectedAccCode

  //     orderShipmentInfo[ 'transfer_params' ] = {
  //       ...this.state.formData[ 'transfer_params' ],
  //       account_code: selectedAccCode,
  //       account_id: templateData.account_id,
  //       orderItems: [],
  //       accountAccessorials: [],
  //       warehouse_code: _.isObject(templateData.selected_warehouse) ? templateData.selected_warehouse.location_code : "",
  //       warehouse_id: templateData.warehouse_id,
  //       los: templateData.los,
  //       weight: templateData.weight,
  //       quantity: templateData.quantity,
  //       hawb: templateData.hawb,
  //       mawb: templateData.mawb,
  //       notes: templateData.notes,
  //       pallets: templateData.skids_pallets,
  //       is_hazardous: templateData.hazmat,
  //       orderAccessorials: [],
  //       type_of_order: templateData.order_type,
  //       reference_1_type: '',
  //       reference_1: '',
  //       reference_2_type: '',
  //       reference_2: '',
  //       is_location_based: true,
  //       locations: [...linehaulLocations],
  //       driver_id: templateData.driver_id,
  //       fleet_id: templateData.fleet_id,
  //     };
  //   }

  //   this.setState({
  //         formData: {
  //           ...this.state.formData,
  //           account_code: templateData.account_code,
  //           account_id: templateData.account_id,
  //           type_of_order: templateData.order_type,
  //           ...orderShipmentInfo,
  //         },
  //         selectedTemplateId : value,
  //       } , () => {
  //         this.setZipcodesData();
  //         this.getLos();
  //         this.getAccountConfigs();
  //         this.getOrderReferences();
  //         this.clearActiveItem();
  //       }
  //   );

  // };

  /**
   * Find and return template data based on id
   * @param {Array} templates - Templates array from state
   * @param {string} id - Template id
   * @returns {Object} - Template data
   */
  getTemplateDataById = (templates, id) => _.find(templates, { id: id });

  /**
   * Adjusts template appointment
   * @param {Array} appointment - Template's appointment array
   * @param {boolean} isMilitaryTime - Military time flag
   * @returns {Array} - Adjusted appointment array
   */
  adjustAppointment = (appointment, isMilitaryTime) =>
    adjustTemplateAppointment(appointment, isMilitaryTime).map((appt) =>
      renameKeys(appt, { item_option: "slots" })
    );

  /**
   * Generate common order information
   * @param {Object} templateData - Data of the template
   * @returns {Object} - Common order information
   */
  generateCommonOrderInfo = (templateData) => ({
    account_code: _.isObject(templateData.selected_account)
      ? templateData.selected_account.code
      : "",
    account_id: templateData.account_id,
    warehouse_code: _.isObject(templateData.selected_warehouse)
      ? templateData.selected_warehouse.location_code
      : "",
    warehouse_id: templateData.warehouse_id,
    los: templateData.los,
    weight: templateData.weight,
    weight_in_kgs: templateData.weight_in_kgs,
    mileage: templateData.mileage,
    quantity: templateData.quantity,
    notes: templateData.notes,
    pallets: templateData.skids_pallets,
    is_hazardous: templateData.hazmat,
    locations: [...templateData.locations],
    vehicle_type: templateData.vehicle_type,
    priority : templateData.priority,
    special : templateData.special,
  });

  /**
   * Create new order shipment info
   * @param {Object} templateData - Data of the template
   * @returns {Object} - Shipment information
   */
  createOrderShipmentInfo = (templateData, formData) => {
    const orderShipmentInfo = {};
    if (!["T", "LH","MS"].includes(templateData.order_type)) {
      this.state.orderConfig.orderTypes[templateData.order_type].types.forEach((details) => {
        const { key, type_of_order } = details;
        const orderData = templateData.locations.find(
          (i) =>
            (i.l_type === "D" &&
              (type_of_order === "D" || type_of_order === "TD")) ||
            (i.l_type === "R" &&
              (type_of_order === "R" || type_of_order === "TR"))
        );
        orderShipmentInfo[key] = {
          ...formData[key],
          appointments: this.adjustAppointment(
            orderData.appointment,
            this.state.isMilitaryTime
          ),
          service_duration: orderData.service_duration,
          weight: orderData.weight,
          quantity: orderData.quantity,
          pallets: orderData.pallets,
          surface_area: orderData.surface_area,
          orderItems: [],
          accountAccessorials: [],
          ...this.generateCommonOrderInfo(templateData),
          cs_location: { l_address: orderData.address },
          company_name: orderData.company_name,
          weight: orderData.weight,
          quantity: orderData.quantity,
          pallets: orderData.pallets,
          surface_area: orderData.surface_area,
          acc_loc_code: orderData.acc_loc_code,
          customer_first_name: orderData.first_name,
          customer_last_name: orderData.last_name,
          customer_phone_one: orderData.phone_1,
          customer_phone_two: orderData.phone_2,
          customer_email: orderData.email,
          type_of_order: details.type_of_order,
          reference_1_type: "",
          reference_1: "",
          reference_2_type: "",
          reference_2: "",
          orderAccessorials: [],
        };
      });
    } else {
      // linehaul
      const linehaulLocations = templateData.locations.map((location) => ({
        // ...adjustLinehaulLocation(location),
        appointments: this.adjustAppointment(
          location.appointment,
          this.state.isMilitaryTime
        ),
        service_duration: location.service_duration,
        cs_location: { l_address: location.address },
        company_name: location.company_name,
        weight: location.weight,
        quantity: location.quantity,
        pallets: location.pallets,
        surface_area: location.surface_area,
        acc_loc_code: location.acc_loc_code,
        customer_first_name: location.first_name,
        customer_last_name: location.last_name,
        customer_phone_one: location.phone_1,
        customer_phone_two: location.phone_2,
        customer_email: location.email,
        type_of_loc: location.type_of_loc,
        los_code: location.los_code || '',
        los_id: location.los_id || '',
        los_name: location.los_name || '',
      }));

      const selectedAccCode = this.state.accounts.find(
        (i) => i.id === templateData.account_id
      ).code;
      templateData.account_code = selectedAccCode;

      orderShipmentInfo["transfer_params"] = {
        ...formData["transfer_params"],
        account_code: selectedAccCode,
        orderItems: [],
        accountAccessorials: [],
        ...this.generateCommonOrderInfo(templateData),
        hawb: templateData.hawb,
        mawb: templateData.mawb,
        orderAccessorials: [],
        type_of_order: templateData.order_type,
        reference_1_type: "",
        reference_1: "",
        reference_2_type: "",
        reference_2: "",
        is_location_based: true,
        locations: linehaulLocations,
        driver_id: templateData.driver_id,
        fleet_id: templateData.fleet_id,
      };
    }
    return orderShipmentInfo;
  };

  handleTemplateChange = (value) => {
    const { templates, formData } = this.state;
    const templateData = this.getTemplateDataById(templates, value);
    if (!templateData) return;

    this.handleTypeChange("type_of_order", templateData.order_type, true);
    const orderShipmentInfo = this.createOrderShipmentInfo(
      templateData,
      formData
    );

    this.setState(
      {
        formData: {
          ...formData,
          account_code: templateData.account_code,
          account_id: templateData.account_id,
          type_of_order: templateData.order_type,
          ...orderShipmentInfo,
        },
        selectedTemplateId: value,
      },
      () => {
        this.setZipcodesData();
        this.getLos();
        this.getAccountContactEmails();
        this.getAccountConfigs();
        this.getOrderReferences();
        this.clearActiveItem();
      }
    );
  };

  renderExtraOperations = () => {
    return (
      <div
        style={{
          width: "45vw",
        }}
      >
        <Select
          // showSearch
          className="select-template"
          dropdownClassName="select-template-dropdown"
          placeholder={I18n.t("recurring_order.select")}
          optionFilterProp="children"
          optionLabelProp="label"
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={this.handleTemplateChange}
          loading={this.state.isFetchingTemplates}
          style={{ width: "100%" }}
          // search using template name
          filterOption={(input, option) => {
            const {
              data: { name },
            } = option.props;
            return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          value={
            this.state.selectedTemplateId.length > 0
              ? this.state.selectedTemplateId
              : undefined
          }
        >
          {this.state.templates.map((template) => (
            <Select.Option
              key={template.id}
              value={template.id}
              label={
                <TemplateOption
                  data={template}
                  onlylabel
                  isMilitaryTime={this.state.isMilitaryTime}
                />
              }
            >
              <TemplateOption
                data={template}
                drivers={this.state.driversData}
                isMilitaryTime={this.state.isMilitaryTime}
              />
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  handleAddStop = (paramsType) => {
    const { customerDetails, formData, orderItems } = this.state;
    const orderObject = formData[paramsType] ? formData[paramsType] : {};
    if (!isEmpty(orderObject)) {
      if (orderObject.is_location_based) {
        const locations = orderObject.locations ? orderObject.locations : [];
        locations.push({
          type_of_loc: locations.length > 0 ? "DELIVERY" : "PICKUP",
          orderItems: [],
          preferences: _.cloneDeep(defaultPreferences),
          loc_order_sequence: locations.length,
        });
        orderObject.locations = locations;
        formData[paramsType] = orderObject;
      }
      this.setState({
        formData,
      });
    }
  };

  getAllStatus = (incomindData) => {
    const allStatuses = incomindData.map((data) => data.status);
    this.setState({ allStatus: allStatuses });
  };

  fetchData = () => {
    this.setState({ consolidateLoading: true });
    const { currentId: orderId } = this.state;
    const payload = {
      order_id: orderId,
    };
    fetchConsolidatedMawbs(payload)
      .then((res) => {
        if (res.success) {
          this.setState({ consolidateLoading: false });
          this.setState({ consolidatedData: res.data.mawb_orders });
          this.getAllStatus(res.data.mawb_orders);
        } else {
          this.setState({ consolidateLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ consolidateLoading: false });
        alertMessage(err.message, "error");
      })
      .finally(() => {
        this.setState({ consolidateLoading: false });
      });
  };

  handleDeleteStop = (paramsType, index) => {
    const { formData } = this.state;
    const orderObject = formData[paramsType] ? formData[paramsType] : {};
    if (
      !isEmpty(orderObject) &&
      orderObject.is_location_based &&
      orderObject.locations &&
      orderObject.locations[index]
    ) {
      const locations = orderObject.locations ? orderObject.locations : [];
      locations.splice(index, 1);
      orderObject.locations = locations.map((loc, index) => ({
        ...loc,
        loc_order_sequence: index,
      }));
      formData[paramsType] = orderObject;
      this.setState({
        formData,
      });
    }
  };
  handleClearStop = (paramsType, index) => {
    const { formData } = this.state;
    const orderObject = formData[paramsType] ? formData[paramsType] : {};
    if (
      !isEmpty(orderObject) &&
      orderObject.is_location_based &&
      orderObject.locations &&
      orderObject.locations[index]
    ) {
      const locations = orderObject.locations ? orderObject.locations : [];
      if (locations[index]) {
        locations[index] =
          locations[index].type_of_loc === "PICKUP"
            ? _.cloneDeep(pickupLocation)
            : _.cloneDeep(dropLocation);
        formData[paramsType] = orderObject;
        this.setState({
          formData,
          isMultiDayTransfer: false,
          storageNeeded: this.state.storageNeeded, //false
          isDeliveryRequired:
            index === 1 ? false : this.state.isDeliveryRequired,
        });
      }
    }
  };

  isMultiDayTransfer = (locations) => {
    if (locations?.length < 2) {
      return false;
    }
    // Array to hold appointment dates.
    let apptDates = [];

    // Traverse all locations.
    for (const location of locations) {
      // Traverse all appointments in a location.
      if (!location.appointments) {
        continue;
      }
      for (const appointment of location.appointments) {
        if (!isEmpty(appointment) && appointment.appt_date) {
          // Convert appt_date into Moment.js object.
          const apptDate = moment(appointment.appt_date);
          apptDates.push(apptDate);
        }
      }
    }

    // Sort the array to make it easier to check.
    apptDates.sort();

    // Check if there is more than one day between any two consecutive dates.
    for (let i = 0; i < apptDates.length - 1; i++) {
      if (!apptDates[i].isSame(apptDates[i + 1], "day")) {
        return true; // The transfer spans multiple days.
      }
    }

    // If no appointment spans multiple days, return false.
    return false;
  };

  AddStop = () => {
    const newStopIndex = this.state.formData.stops.length + 1;
    const newStop = { key: `Stop ${newStopIndex}`, locations: [] };
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        stops: [...prevState.formData.stops, newStop],
      },
    }));
  };

  addLocation = (details) => {
    const formData = _.cloneDeep(this.state.formData)
 
    const detailsKey = details.key
    const locations = formData?.[detailsKey]?.locations || [];
    locations.push({
      type_of_loc: 'PICKUP', 
      loc_order_sequence: locations.length + 1
    })
    formData[details.key].locations = [...locations];
    this.setState({
      formData,
    }) 
  }

  render() {
    const { customerDetails, formData, orderItems } = this.state;
    const typeOfOrder = formData.type_of_order ? formData.type_of_order : "D";
    // const currentOrder = formData[orderType] ? formData[orderType] : {}
    // const selectedWh = _.find(this.state.warehouses, { location_code: currentOrder.warehouse_code } )
    const orderNo = this.getOrderNo(typeOfOrder);
    const isRecoveryOrRelease =
      (typeOfOrder === "T" &&
        _.get(formData, `transfer_params.locations[0].is_recovery`, false)) ||
      _.get(formData, `transfer_params.locations[1].is_release`, false);
    const isNew = this.state.isNew;
    const { currentRole } = this.props.userContext
    const isMawbPresent = _.get(customerDetails, "mawb", null);

    return (
      <div>
        <Spin
          spinning={
            this.state.inProgress ||
            this.state.refreshProgress ||
            this.state.configProcess ||
            this.state.isApplyingTemplate
          }
        >
          {this.props.showHeader && (
            <Fragment>
              <div className="modal_heading">
                <Row>
                  <Col xs={22}>
                    {isNew
                      ? "Create Order"
                      : `Edit ${I18n.t("order.details")} ${!isEmpty(orderNo) ? `(${orderNo})` : ""
                      }`}
                  </Col>
                  <Col xs={2} className="alignRight">
                    <Icon
                      type="close"
                      className="cursorPointer"
                      onClick={this.formOnCancel}
                    />
                  </Col>
                </Row>
              </div>
              <Divider style={{ margin: "5px 2px" }} />
            </Fragment>
          )}
          <div className="card-container tabHover ">
            <Tabs
              activeKey={this.state.activeKey}
              onChange={this.goToTab}
              type="card"
              tabBarExtraContent={isNew ? this.renderExtraOperations() : <></>}
            >
              <TabPane
                className={
                  this.props.showHeader === false
                    ? "orderEditScrollForNewScreen"
                    : "editScroll"
                }
                tab={I18n.t("order.details")}
                key="details"
              //  style={{
              //   height: this.props.showHeader ? 'calc(100vh - 170px)' : 'calc(100vh - 10px)',
              //   overflowY: 'auto'
              //  }}
              >
                {!_.isEmpty(this.state.config.show_note_to_user) &&
                  <Alert message={`Note : ${this.state.config.show_note_to_user}`} type="info" showIcon/>
                }
                <Row gutter={16} className="appointmentForm">
                  <Col sm={24} xs={24} md={24} lg={24}>
                    <span style={{ color: "white", float: "right" }}>
                      {customerDetails.id}
                    </span>
                    <this._renderOrderDetails typeOfOrder={typeOfOrder} />
                  </Col>
                </Row>
                {/* <div className="appointmentForm">{this.renderItems()}</div> */}
                <div className="appointmentForm">
                  {!isEmpty(typeOfOrder) &&
                    this.state.orderConfig.orderTypes[typeOfOrder].types.map(
                      (details, index) => {
                        const isLocationbased = details.is_location_based;
                        return (
                          <Fragment>
                            {
                              <Fragment>
                                {this.state.formData.type_of_order === "MS" && (
                                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={() => this.addLocation(details)}>Add Stop</Button>
                                  </div>
                                )}
                                
                                <Row
                                  type="flex"
                                  justify="space-between"
                                  align="middle"
                                  gutter={4}
                                >
                                  {details.is_location_based ? (
                                    <Fragment>
                                      {formData[details.key].locations.map(
                                        (location, locationIndex) => (
                                          <Col span={12} key={locationIndex}>
                                            {this.detailsNavigator(
                                              details.key,
                                              locationIndex,
                                              `${typeOfOrder === 'MS' ? `Stop ${locationIndex+1}` : locationIndex
                                                ? "Delivery Details" +
                                                (location.status
                                                  ? " - " + location.status
                                                  : "") +
                                                (location.is_release &&
                                                  location.release_type
                                                  ? " (" +
                                                  (location.release_type ===
                                                    "off_wh"
                                                    ? "3rd Party"
                                                    : location.release_type ===
                                                      "cross_dock"
                                                      ? "Cross Dock"
                                                      : location.release_type) +
                                                  ")"
                                                  : "")
                                                : "Pickup Details" +
                                                (location.status
                                                  ? " - " + location.status
                                                  : "")
                                              }`,
                                              `order${details.key}`
                                            )}
                                          </Col>
                                        )
                                      )}
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      {this.detailsNavigator(
                                        details.key,
                                        -1,
                                        `${details.label} Details`,
                                        `order${details.key}`
                                      )}
                                    </Fragment>
                                  )}
                                </Row>
                              </Fragment>
                            }
                            {/* {(
                                  this.state.isMultiDayTransfer || isRecoveryOrRelease
                                )} */}
                            {details.is_location_based && this.state.formData.type_of_order !== "MS" && (
                              <Row style={{ marginTop: 10 }}>
                                <Col>
                                  <Checkbox
                                    checked={this.state.storageNeeded}
                                    onChange={() =>
                                      this.setState({
                                        storageNeeded:
                                          !this.state.storageNeeded,
                                      })
                                    }
                                    disabled={isRecoveryOrRelease || this.state.formData.type_of_order === "LH" }
                                  >
                                    <span className="textBold fontSize14">
                                      Intermediate Storage Needed (This will get
                                      items back to the warehouse.)
                                    </span>
                                  </Checkbox>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              {!this.state.isNew && details.is_location_based && (
                                <Fragment>
                                  <Col
                                    xs={24}
                                    className="marginTop10 padding10"
                                  >
                                    <Row>
                                      {/* isLocationbased */}
                                      <Col
                                        sm={24}
                                        xs={24}
                                        md={24}
                                        lg={24}
                                        className="dispatcherNotes"
                                      >
                                        {this.renderDispatcherNotes(
                                          details.key
                                        )}
                                      </Col>
                                      {!["shipper", "driver"].includes(currentRole) && <Col
                                        sm={24}
                                        xs={24}
                                        md={24}
                                        lg={24}
                                        className="dispatcherNotes"
                                      >
                                        {this.renderPrivateNotes(
                                          details.key
                                        )}
                                      </Col>
                                      }


                                    </Row>
                                  </Col>
                                  {typeOfOrder !== "LH" && (
                                    <Col xs={24}>
                                      <div className="appointmentForm">
                                        {this.renderItems(details.key)}
                                      </div>
                                    </Col>
                                  )}
                                </Fragment>
                              )}
                            </Row>
                          </Fragment>
                        );
                      }
                    )}
                  {isNew && (
                    <Row gutter={16} type="flex" style={{ marginTop: 10 }}>
                      <Col>
                        <Checkbox
                          checked={this.state.isTemplate}
                          onChange={() =>
                            this.setState({
                              isTemplate: !this.state.isTemplate,
                              templateName: "",
                            })
                          }
                        >
                          Save as {I18n.t("recurring_order.label")}
                        </Checkbox>
                      </Col>
                      {/* {this.state.isTemplate && (
                        <Col>
                          Template Name<sup className="textRed">*</sup> 
                          <Input
                            value={this.state.templateName}
                            onChange={(e) =>
                              this.setState({ templateName: e.target.value })
                            }
                            placeholder={I18n.t("recurring_order.name")}
                            width={200}
                          />
                        </Col>
                      )} */}
                      {this.state.isTemplate && (
                        <Row gutter={16} type="flex" style={{ alignItems: "center" }}>
                          <Col>
                            <label>
                              Template Name<sup className="textRed">*</sup>
                            </label>
                          </Col>
                          <Col>
                            <Input
                              value={this.state.templateName}
                              onChange={(e) => this.setState({ templateName: e.target.value })}
                              placeholder={I18n.t("recurring_order.name")}
                              width={200}
                            />
                          </Col>
                        </Row>
                      )}
                    </Row>
                  )}
                  {FormErrors([...new Set(this.state.errors)])}
                  {
                    // ['NEW', ...AppConfig.notStartedList, 'PREPLAN'].includes(customerDetails.status) &&
                    <Row>
                      <Col xs={24} className="alignCenter">
                        {FormButtons(
                          this.state.inProgress,
                          this.handleValidate,
                          this.formOnCancel,
                          true,
                          true,
                          "default",
                          isNew
                            ? //? this.state.formData.type_of_order === "T"
                            I18n.t("general.save_n_next")
                            : I18n.t("general.save"),
                          "Cancel",
                          isNew
                            ? {
                              button: {
                                width: 130,
                              },
                            }
                            : {}
                        )}
                      </Col>
                    </Row>
                  }
                </div>
              </TabPane>
              {typeOfOrder === "LH" && (
                <TabPane
                  tab={I18n.t("general.sub_orders")}
                  key="subOrders"
                >
                  <SubORdersForLh
                    customerDetails={
                      this.state.customerDetails?.transfer_params
                    }
                    fromIndividualScreen={this.props.fromIndividualScreen}
                    accounts={this.state.accounts}
                    isConsolidatedPresent={isMawbPresent}
                    consolidatedData={this.state.consolidatedData}
                    consolidateLoading={this.state.consolidateLoading}
                    fetchData={this.fetchData}
                    linehaulTab={true}
                    insert_auto_linehaul_sub_orders={this.state.config?.insert_auto_linehaul_sub_orders || "false"}
                  />
                </TabPane>
              )}
              {!this.state.isNew &&
                checkServiceExistance(["OAA", "OQA"], "ANY") && (
                  <TabPane
                    className="editScroll"
                    tab={I18n.t("general.accessorial")}
                    key="accessorials"
                  >
                    <div className="appointmentForm">
                      <Row>
                        <Col sm={24} xs={24} md={24} lg={24}>
                          {checkServiceExistance("OQA") && (
                            <this._renderOrderQuoteAmount
                              typeOfOrder={typeOfOrder}
                            />
                          )}

                          {checkServiceExistance(["OAA", "AAAC"], "ALL") &&
                            !isEmpty(typeOfOrder) && (
                              <this._renderWarehouseAccessorials
                                typeOfOrder={typeOfOrder}
                              />
                            )}

                          {checkServiceExistance(["OAA", "AAAC"], "ALL") &&
                            !isEmpty(typeOfOrder) &&
                            <Row gutter={10}>
                              {this.state.orderConfig.orderTypes[typeOfOrder].types.map(
                                (details, index) => (
                                  <Col span={(this.state.orderConfig.orderTypes[typeOfOrder].types).length > 1 ? 12 : 24}>
                                    <Row gutter={10}>
                                      {details.is_location_based ? (
                                        formData[details.key].locations.filter(loc => !isEmpty(loc.id)).map(
                                          (location, locationIndex) => (
                                            <Col span={formData[details.key].locations.filter(loc => !isEmpty(loc.id)).length > 1 ? 12 : 24}>
                                              <this._renderOrderAccAccessorialBlock
                                                details={details}
                                                currentLocation={location}
                                              />
                                            </Col>
                                          )
                                        )
                                      ) : (
                                        <Col>
                                          <this._renderOrderAccAccessorialBlock
                                            details={details}
                                            currentLocation={null}
                                            csLocation={
                                              formData[details.key].cs_location
                                            }
                                          />
                                        </Col>
                                      )}
                                    </Row>
                                  </Col>
                                )
                              )}
                            </Row>
                          }

                          {checkServiceExistance("OQA") && (
                            <this._renderAdjustments
                              typeOfOrder={typeOfOrder}
                            />)
                          }
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                )}
              {!this.state.isNew && (
                <TabPane
                  tab={I18n.t("account.billing.est_billing")}
                  key="billing"
                  className="editScroll"
                >
                  <div className="appointmentForm">
                    <Row>
                      <Col sm={24} xs={24} md={24} lg={24}>
                        {!isEmpty(typeOfOrder) &&
                          this.state.orderConfig.orderTypes[typeOfOrder].types.map(
                            (details, index) => (
                              <Collapse
                                defaultActiveKey={[`billing${details.key}`]}
                                style={{ height: "auto" }}
                                expandIconPosition="right"
                                className="marginBottom10 customCollapse"
                              >
                                <Panel
                                  header={
                                    <Fragment>
                                      {details.label}&nbsp;
                                      {I18n.t("account.billing.label")}
                                    </Fragment>
                                  }
                                  key={`billing${details.key}`}
                                  size="small"
                                >
                                  <Invoice
                                    order_id={
                                      this.state.formData[details.key].id
                                    }
                                    order={this.state.formData[details.key]}
                                    callInvoceDetails={true}
                                    showHeading={false}
                                    showOtherDetails={false}
                                    updateQuote={(id, amount) =>
                                      this.handleOrderDetailsChange(
                                        details.key,
                                        -1,
                                        "quotation_amount",
                                        amount
                                      )
                                    }
                                    setData={this.state.setInvoiceData}
                                    activeKey={this.state.activeKey}
                                  />
                                </Panel>
                              </Collapse>
                            )
                          )}
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              )}
              {false && !this.state.isNew &&
                !["T", "LH","MS"].includes(typeOfOrder) &&
                checkServiceExistance(["ORC", "ORU"], "ANY") && (
                  <TabPane
                    tab={I18n.t("recoveries.label")}
                    key="recoveries"
                    className="editScroll"
                  >
                    {!isEmpty(typeOfOrder) &&
                      this.state.orderConfig.orderTypes[typeOfOrder].types
                        .filter((details) =>
                          ["R", "D", "T", "LH","MS"].includes(details.type_of_order)
                        )
                        .map((details, index) => (
                          <Collapse
                            defaultActiveKey={[`recoveries${details.key}`]}
                            style={{ height: "auto" }}
                            expandIconPosition="right"
                            className="marginBottom10 customCollapse"
                          >
                            <RecoveryManage
                              order_id={this.state.formData[details.key].id}
                              actionType="manage"
                              orderWH={_.find(this.state.warehouses, {
                                location_code:
                                  this.state.formData[details.key]
                                    .warehouse_code,
                              })}
                              typeOfOrder={typeOfOrder}
                            />
                          </Collapse>
                        ))}
                  </TabPane>
                )}
              {false && !this.state.isNew &&
                !["T", "LH", "M","MS"].includes(typeOfOrder) &&
                checkServiceExistance(["OREC", "OREU"], "ANY") && (
                  <TabPane
                    tab={I18n.t("releases.label")}
                    key="releases"
                    className="editScroll"
                  >
                    {!isEmpty(typeOfOrder) &&
                      this.state.orderConfig.orderTypes[typeOfOrder].types.map(
                        (details, index) => (
                          <Collapse
                            defaultActiveKey={[`releases${details.key}`]}
                            style={{ height: "auto" }}
                            expandIconPosition="right"
                            className="marginBottom10 customCollapse"
                          >
                            <ReleaseManage
                              order_id={this.state.currentId}
                              actionType="manage"
                              orderWH={_.find(this.state.warehouses, {
                                location_code:
                                  this.state.formData[details.key]
                                    .warehouse_code,
                              })}
                              account_id={
                                this.state.formData[details.key].account_id
                              }
                              org_id={this.state.formData[details.key].org_id}
                            />
                          </Collapse>
                        )
                      )}
                  </TabPane>
                )}
              {false && !this.state.isNew && typeOfOrder != "M" && (
                <TabPane
                  tab={I18n.t("linehaul.label")}
                  className="editScroll"
                  key="linehaul"
                >
                  <LineHaulManageComponent
                    orderId={this.state.currentId}
                    account={this.state.accountInfo}
                    isMilitaryTime={this.state.isMilitaryTime}
                  />
                </TabPane>
              )}
              {!this.state.isNew && checkServiceExistance("OEA") && (
                <TabPane
                  tab={I18n.t("exceptions.log")}
                  key="exceptions"
                >
                  <div className="appointmentForm">
                    <Row>
                      <Col sm={24} xs={24} md={24} lg={24}>
                        {!isEmpty(typeOfOrder) &&
                          this.state.orderConfig.orderTypes[typeOfOrder].types.map(
                            (details, index) => (
                              <Collapse
                                defaultActiveKey={[`exceptions${details.key}`]}
                                style={{ height: "auto" }}
                                expandIconPosition="right"
                                className="marginBottom10 customCollapse"
                              >
                                <Panel
                                  header={
                                    <Fragment>
                                      {details.label}&nbsp;
                                      {I18n.t("exceptions.log")}
                                    </Fragment>
                                  }
                                  key={`exceptions${details.key}`}
                                >
                                  {this.state.formData[details.key] &&
                                    this.state.formData[details.key]
                                      .order_exceptions &&
                                    this.state.formData[details.key]
                                      .order_exceptions.length > 0 && (
                                      <Row gutter={16}>
                                        <Col xs={24}>
                                          {this.renderExceptions(details.key)}
                                        </Col>
                                      </Row>
                                    )}
                                </Panel>
                              </Collapse>
                            )
                          )}
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </Spin>

        {false && this.state.showItemForm && this.renderItemForm()}
        {this.state.imagesVisible && this.renderUploadGallery()}
        {/* {this.state.weightUnitConfirmationModalOpen && this.renderWeightUnitConfirmationModal()} */}
      </div>
    );
  }
}

EditOrderForm.propTypes = {
  customerDetails: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  callFrom: PropTypes.string,
  currentPreplan: PropTypes.shape(),
  onModified: PropTypes.func,
  showHeader: PropTypes.bool,
  fromIndividualScreen: PropTypes.bool,
};

EditOrderForm.defaultProps = {
  callFrom: "",
  currentPreplan: {},
  onModified: () => { },
  showHeader: true,
  fromIndividualScreen: false,
};
const EditOrderFormComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const userContext = useContext(UserContext);
  const { orgSettings: organizationSettings, accountsExceeded, currentOrg, isAirCargo, dispatchStatusColors } = useContext(OrgContext);
  const accountUnitContext = useContext(AccountUnitsContext);
  const accountCodesWithExceededLimit = accountsExceeded.map(rec => rec.account_code);
  const { orderConfig = {
    orderTypes: {}
  } } = useContext(OrderConfigContext);
  return (
    <EditOrderForm
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      accountCodesWithExceededLimit={accountCodesWithExceededLimit}
      accountUnitContext={accountUnitContext}
      currentOrg={currentOrg}
      orderConfig={orderConfig}
      isAirCargo={isAirCargo}
      userContext={userContext}
      colorStatus={dispatchStatusColors}
      {...props}
    />
  );
});

export default EditOrderFormComponent;
