/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import _ from "lodash";
import React, { Component, Fragment } from 'react';
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Tooltip, message, Link , Icon} from "./UIComponents";
import { getParamId, getParam } from "../utils/Utils";
import AppConfig from "../config/AppConfig";
import {
  formatByTimeConfig,
  formateTwoUTCDates,
  formateTwoUTCTimes,
} from "../helpers/common";
import Mimetypes from "./Mimetypes.json";
import refreshImg from "../../assets/images/refresh.png";
import I18n from "./I18n";
import { BetaBadge } from "./BetaBadge";
import { 
  AgentIcon, 
  AreaChartIcon, 
  BusinessIcon, 
  ChatIcon, 
  CheckBookIcon, 
  CreditMemoIcon, 
  CustomerReportIcon, 
  DashboardIcon, 
  DispatchIcon, 
  DollarIcon, 
  DriverReportIcon, 
  DunningIcon, 
  EdiIcon, 
  EmailIcon, 
  FormIcon, 
  FusionIcon, 
  GanttChartIcon, 
  GetCashIcon, 
  IIFIcon, 
  ImageIcon, 
  InvoiceIcon, 
  InvoiceStatusIcon, 
  LayersIcon, 
  LinehaulIcon, 
  LocationIcon, 
  LockIcon, 
  OrderIcon, 
  PaymentJournalIcon, 
  PendingIcon, 
  PreplanIcon, 
  QuoteIcon, 
  RatingIcon, 
  RecoveryIcon, 
  ReleaseIcon, 
  RepeatIcon, 
  SettingsIcon, 
  SpiralBondBookletIcon, 
  SteeringWheelIcon, 
  TimeSpanIcon, 
  TransactionIcon, 
  TruckIcon, 
  TruckTypeIcon, 
  UploadIcon, 
  UserIcon, 
  UserSheildIcon, 
  WarehouseIcon 
} from "./CustomIcon";

function disabledDate(current) {
  return current && current > moment().endOf("day");
}

const isSameDay = (dateFilter, r_actual_start_datetime) => {
  const dateFilterMoment = moment(dateFilter);
  const r_actual_start_datetimeMoment = moment(r_actual_start_datetime);
  return dateFilterMoment.isSame(r_actual_start_datetimeMoment, "day");
};

function alertMessage(messageToShow, type, duration = 2) {
  switch (type) {
    case "warning": {
      message.warning(messageToShow, duration);
      return;
    }
    case "error": {
      message.error(messageToShow, duration);
      return;
    }
    case "info": {
      message.info(messageToShow, duration);
      return;
    }
    default: {
      message.success(messageToShow, duration);
    }
  }
}

function compareString(string1, string2) {
  string1 = !isEmpty(string1) ? String(string1) : "";
  string2 = !isEmpty(string2) ? String(string2) : "";
  return string1.toLowerCase().localeCompare(string2.toLowerCase());
}

function compareNumber(number1, number2) {
  const first = number1 || 0;
  const second = number2 || 0;
  return parseInt(first) - parseInt(second);
}

function compareFloatNumber(number1, number2) {
  const first = parseFloat(number1) || 0;
  const second = parseFloat(number2) || 0;
  return first - second;
}

function compareDate(date1, date2) {
  if (!date1 || !date2) {
    return !date1 ? -1 : 1;
  }
  return new Date(date1) - new Date(date2);
}

function doFormate(str, format = "") {
  if (str === "" || str === null || str === undefined) {
    return "NA";
  }
  if (format !== "") {
    if (format === "upper") {
      return str.toUpperCase();
    }
    return str.toLowerCase();
  }
  return str;
}

function isEmpty(value, checkZero = false) {
  if (value === "" || value === null || value === undefined || (checkZero && value == 0)) {
    return true;
  }
  return false;
}

function isEmptyForBoolean(value) {
  if (value === false || value === null)
      return true;
  else
      return false;
}

function formatAddress(address) {
  let addressArray = address.split(",");
  const output = {
    country: "",
    state: "",
    city: "",
    address_line2: "",
    address_line1: "",
  };
  const address_line1 = addressArray.splice(0, 1).join(", ");
  addressArray = addressArray.reverse();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < addressArray.length; i++) {
    output[Object.keys(output)[i]] = addressArray[i];
  }
  output.address_line1 =
    addressArray.length > 4
      ? `${address_line1}, ${addressArray.slice(4).reverse().join(", ")}`
      : address_line1;
  return output;
}

function validateFields(inputType, value = "") {
  // AppConfig.regexMobile.test((value));
  switch (inputType) {
    case "email":
      return AppConfig.regexEmail.test(String(value).trim().toLowerCase());
    // case "name":
    //   return AppConfig.regexName.test(String(value).trim().toLowerCase());
    case "mobile":
      return value.length >= 11 && value.length <= 16;
    case "number":
      return AppConfig.regexNumber.test(value);
    case "floatNumber":
      return AppConfig.regexFloatNumber.test(value)
    case "url":
      return AppConfig.regexUrl.test(value);
    case "price":
      return AppConfig.regexPrice.test(value) || AppConfig.regexFloatNumber.test(value);
    case "object":
      return _.isObject(value) && Object.keys(value).length > 0;
    case "array":
      return _.isArray(value) && value.length > 0;
    case "weight":
      return AppConfig.regexWeight.test(value);
    case "code":
      return AppConfig.regexCode.test(value);
    case "date":
      return value && moment(value).isValid();
    default:
      return true;
  }
}

function doValidate(requiredFields = [], data = {}, appendString = "") {
  const errors = [];
  requiredFields.forEach((fieldData) => {
    if (
      fieldData.inputType === "mobile" &&
      !isEmpty(data[fieldData.form_field])
    ) {
      if (data[fieldData.form_field] === "+") {
        data[fieldData.form_field] = "";
      } else {
        const mobileNo = !isEmpty(data[fieldData.form_field])
          ? data[fieldData.form_field]
          : "";
        data[fieldData.form_field] = mobileNo.replace(/[^0-9]/g, "");
      }
    }
    if (
      fieldData.isRequired &&
      (isEmpty(data[fieldData.form_field]) ||
        (fieldData.inputType === "array" &&
          data[fieldData.form_field].length == 0))
    ) {
      // (_.isNil(data[fieldData.form_field]) || !data[fieldData.form_field])

      errors.push(
        `${appendString || `${appendString} `} ${
          fieldData.ui_name
        } can't be blank`
      );
    } else if (
      data[fieldData.form_field] &&
      !validateFields(fieldData.inputType, data[fieldData.form_field])
    ) {
      errors.push(
        `${appendString || `${appendString} `} ${
          fieldData.ui_name
        } is not valid`
      );
    } else if (
      fieldData.minLength &&
      data[fieldData.form_field].length < fieldData.minLength
    ) {
      errors.push(
        `${appendString || `${appendString} `} ${
          fieldData.ui_name
        } minimum length should be ${fieldData.minLength}`
      );
    }
  });
  return errors;
}

function validateAddressForm(obj = {}) {
  const addressReqFields = ["address_line1", "city", "state", "country", "zipcode"];
  const errors = [];
  addressReqFields.forEach((x) => {
    if (_.isNil(obj[x]) || !obj[x]) {
      errors.push(I18n.t(`general.${x}`));
    }
  });
  if (errors.length > 0) {
    return `${errors.join(",")} ${errors.length === 1 ? "is" : "are"} required`;
  }
  return "";
}

function formatDate(dateValue, defaultValue = true) {
  let formattedDate = "";
  if (dateValue) {
    formattedDate = moment(new Date(dateValue)).format("YYYY-MM-DD HH:mm:ss Z");
  } else {
    defaultValue = defaultValue ? moment().format("YYYY-MM-DD HH:mm:ss Z") : "";
  }
  return formattedDate;
}

function formateImageName(firstName = "", middleName = "", lastName = "") {
  const firstChar =
    !isEmpty(firstName) && firstName.length > 0
      ? firstName.charAt(0).toUpperCase()
      : "";
  const middleChar =
    !isEmpty(middleName) && middleName.length > 0
      ? middleName.charAt(0).toUpperCase()
      : "";
  const lastChar =
    !isEmpty(lastName) && lastName.length > 0
      ? lastName.charAt(0).toUpperCase()
      : "";
  if (lastChar) {
    return `${firstChar}${lastChar}`;
  } else if (middleChar) {
    return `${firstChar}${middleChar}`;
  } else if (firstName.length >= 2) {
    const firstNameArr = firstName.trim().split(" ");
    if (firstNameArr.length === 1) {
      return `${firstName.substr(0, 2)}`;
    } else if (firstNameArr.length > 1) {
      return `${firstNameArr[0].charAt(0).toUpperCase()}${firstNameArr[
        firstNameArr.length - 1
      ]
        .charAt(0)
        .toUpperCase()}`;
    }
  }
  return firstChar;
}

function randomColor(appendHash = true) {
  return `${appendHash ? '#' : ''}${(0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)}`;
}

function formatStopTime(startTime, endTime, timeZone = "") {
  let timeString = "";
  if (startTime || endTime) {
    if (!startTime || startTime === "NA") {
      timeString = `${I18n.t("stop.departure_at")} - ${endTime} ${timeZone}`;
    } else if (!endTime || endTime === "NA") {
      timeString = `${I18n.t("stop.arrival_at")} - ${startTime} ${timeZone}`;
    } else {
      timeString = `${startTime} - ${endTime} ${timeZone}`;
    }
  }
  return timeString;
}

function formatStopUTcTime(
  startTime,
  endTime,
  timeZone = "",
  showOnlyTimes = false,
  isMilitaryTime = false
) {
  if (showOnlyTimes) {
    let timeString = "";
    if (startTime || endTime) {
      if (!startTime || startTime === "NA") {
        timeString = `${I18n.t("stop.departure_at")} - ${formatByTimeConfig(
          moment.utc(endTime) , isMilitaryTime ,"HH:mm" , "hh:mm A")} ${timeZone}`;
      } else if (!endTime || endTime === "NA") {
        timeString = `${I18n.t("stop.arrival_at")} - ${formatByTimeConfig(
          moment.utc(startTime) , isMilitaryTime ,"HH:mm" , "hh:mm A")} ${timeZone}`;
      } else {
        timeString = formateTwoUTCTimes(startTime, endTime, isMilitaryTime);
        timeString = `${timeString} ${timeZone}`;
      }
    }
    return timeString;
  }
  let timeString = "";
  if (startTime || endTime) {
    if (!startTime || startTime === "NA") {
        timeString = `${I18n.t("stop.departure_at")} - ${formatByTimeConfig(
          moment.utc(endTime) , isMilitaryTime ,"MMM Do HH:mm" , "MMM Do hh:mm A")} ${timeZone}`; 
    } else if (!endTime || endTime === "NA") {
      timeString = `${I18n.t("stop.arrival_at")} - ${formatByTimeConfig(
        moment.utc(startTime) , isMilitaryTime ,"MMM Do HH:mm" , "MMM Do hh:mm A")} ${timeZone}`;
    } else {
      timeString = formateTwoUTCDates(startTime, endTime, true , isMilitaryTime);
      timeString = `${timeString} ${timeZone}`;
    }
  }
  return timeString;
}

const navigateToAccount = ( accounts, codeOrId, history, screenFrom= "") => {
  const accountIndex = accounts.findIndex(rec => rec.code === codeOrId || rec.id === codeOrId)
  if (accountIndex >= 0) {
    const accountData = Object.assign({}, accounts[accountIndex]);
    const account_id = !isEmpty(accountData.primary_account_id)
      ? accountData.primary_account_id
      : accountData.id;
      history.push({
        pathname: `/accounts/${account_id}/settings`,
        state: { screenFrom: screenFrom }, // Pass screenFrom as state
      });
  }
};

const currencyConversion = (
  price = 0.0,
  decimalPoints = '',
  country = I18n.t("configurations.default_country"),
  currency = I18n.t("configurations.default_currency")
) => {
  const fractionDigits = decimalPoints ? decimalPoints : localStorage.getItem('round_off_decimals');
  const amount = new Intl.NumberFormat(`en-${country}`, {
    minimumFractionDigits: fractionDigits || AppConfig.default_decimal_points,
    style: "currency",
    currency,
  }).format(price);
  return currency === "USD"
    ? [amount.slice(0, 1), " ", amount.slice(1)].join("")
    : amount;
};

function isBetweenDates(
  startDate = moment(),
  endDate = moment(),
  dateToCheck,
  isStartDateIncludes = true,
  isEndDateIncludes = true
) {
  return moment(dateToCheck).isBetween(
    startDate,
    endDate,
    undefined,
    `${isStartDateIncludes ? "[" : "("}${isEndDateIncludes ? "]" : ")"}`
  );
}

function randomNumber(length = 5) {
  return Math.floor(Math.random() * 10 ** length);
}

function findTotal(
  data = [],
  recordColumn,
  qtyColumn = null,
  isRequiredConversion = false
) {
  const decimalPoints = window.localStorage.getItem('round_off_decimals');
  const priceRecords = data.map((record) => {
  const recordColValue = !isEmpty(record[recordColumn]) ? 
  parseFloat(record[recordColumn]) :
  (0).toFixed(decimalPoints);
    const price = !isEmpty(qtyColumn) &&
    !isEmpty(record[qtyColumn]) &&
    parseInt(record[qtyColumn]) !== 0
      ? recordColValue * parseInt(record[qtyColumn])
      : recordColValue;
    return price;
  });
  const cleanedRecords = _.compact(priceRecords).map((price) =>
    price ? parseFloat(price) : 0.0
  );
  if (isRequiredConversion) {
    return currencyConversion(_.sum(cleanedRecords).toFixed(decimalPoints));
  }
  return _.sum(cleanedRecords).toFixed(2);
}

const checkNegitive = (value, isConvert = true, customDecimalPoints = null) => {
  const appDecimalPoints = customDecimalPoints ?? (localStorage.getItem('round_off_decimals') || AppConfig.default_decimal_points);
  const decimalPoints = appDecimalPoints;
  const converted = !isEmpty(value) ? parseFloat(value).toFixed(decimalPoints) : parseFloat(0).toFixed(decimalPoints);
  return isConvert ? currencyConversion(converted, decimalPoints) : converted;
  /*
    const isNegitive = converted < 0;
    return (
      `${isNegitive ? '-' : ''} ${I18n.t('general.price_symbol')} ${isNegitive ? (converted * -1).toFixed(2) : converted}`
    ); */
};

const inchesToFeet = (inchValue) => {
  const converted = !isEmpty(inchValue) ? parseFloat(inchValue/12).toFixed(0) : 0;
  return converted;
}

const sqInchesToSqFeet = (inchValue, decimals = 2) => {
  const converted = !isEmpty(inchValue) ? parseFloat(inchValue/144).toFixed(decimals) : 0;
  return converted;
}

function findNumberTotal(
  data = [],
  recordColumn,
  qtyColumn = null,
  decimals = 2
) {
  const priceRecords = data.map((record) =>
    !isEmpty(qtyColumn) &&
    !isEmpty(record[qtyColumn]) &&
    parseInt(record[qtyColumn]) !== 0
      ? record[recordColumn] * parseInt(record[qtyColumn])
      : parseFloat(record[recordColumn])
  );
  const cleanedRecords = _.compact(priceRecords).map((price) =>
    price ? parseFloat(price) : 0.0
  );
  return (_.sum(cleanedRecords)).toFixed(decimals);
}

const checkWithinRange = (
  records,
  minKey,
  maxKey,
  inputMinVal,
  inputMaxVal
) => {
  let isExisted = false;
  records.forEach((record, recIndex) => {
    if (!isEmpty(record[minKey]) && !isEmpty(record[maxKey])) {
      if (
        parseFloat(record[minKey]) <= parseFloat(inputMinVal) &&
        parseFloat(inputMinVal) <= parseFloat(record[maxKey])
      ) {
        isExisted = true;
      }
      if (
        parseFloat(record[minKey]) <= parseFloat(inputMaxVal) &&
        parseFloat(inputMaxVal) <= parseFloat(record[maxKey])
      ) {
        isExisted = true;
      }
    }
  });
  return isExisted;
};

const formatePhoneNo = (contactNumber) => {
  const phoneNum =
    // !isEmpty(contactNumber) & (contactNumber !== "+") ? contactNumber : "";
    !isEmpty(contactNumber) & !AppConfig.phonePrefix.some((val) => val === contactNumber)
      ? contactNumber
      : "";
  let newPhoneNo = `${phoneNum.replace(/[^0-9+]/g, "")}`;
  if (newPhoneNo && newPhoneNo.length > 0 && newPhoneNo[0] !== "+") {
    newPhoneNo = `+${newPhoneNo}`;
  }
  return newPhoneNo;
};

const ellipseText = (text, size = null, showTooltip = false, tootlTipDelay = null, showPopupContainer = true, showDots = true) => {
  const oriText = !isEmpty(text) ? text : "";
  const finalText =
  size && oriText.length > size ? `${oriText.substr(0, size)}${showDots ? '...' : ''}` : oriText;
  return showTooltip ? (
    <Tooltip 
      title={oriText} 
      mouseEnterDelay={tootlTipDelay}
      getPopupContainer={showPopupContainer ? (triggerNode) => triggerNode.parentNode : null}
      overlayStyle={{ width: oriText? oriText.length * 10 : 65, fontSize: 12, minWidth: 80}}
    >
      {finalText}
    </Tooltip>
  ) : (
    finalText
  );
};

const customPasteSplit = (data) => {
  const separators = [
    ",",
    ";",
    "\\(",
    "\\)",
    "\\*",
    "/",
    ":",
    "\\?",
    "\n",
    "\r",
  ];
  return data.split(new RegExp(separators.join("|"))).map((d) => d.trim());
};

const isArrayEqual = (x, y) => _(x).xorWith(y, _.isEqual).isEmpty();

const randomUUID = () => {
  const myuuid = uuidv4();
  return myuuid;
};

const formatCode = (text) => {
  if (!isEmpty(text)) {
    let formattedText = "";
    formattedText = text.toUpperCase();
    formattedText = !isEmpty(formattedText)
      ? formattedText.replace(/\s+/g, "")
      : "";
    return formattedText;
  }
  return "";
};

const base64MimeType = (encoded) => {
  let result = null;

  if (typeof encoded !== "string") {
    return result;
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
};

const getFileType = (fileName) => {
  if (!isEmpty(fileName)) {
    let fileExtension = "";
    if (_.startsWith(fileName, "data:")) {
      const mimeExtension = base64MimeType(fileName);
      if (!isEmpty(mimeExtension)) {
        const key = _.findKey(Mimetypes, (item) => item === mimeExtension);
        if (!isEmpty(key)) {
          fileExtension = key.replace(".", "");
        }
      }
    } else {
      fileExtension = fileName.split(".").pop();
    }

    if (AppConfig.fileTypes.image.includes(fileExtension)) {
      return "image";
    } else if (AppConfig.fileTypes.pdf.includes(fileExtension)) {
      return "pdf";
    } else if (AppConfig.fileTypes.docs.includes(fileExtension)) {
      return "doc";
    } else if (AppConfig.fileTypes.video.includes(fileExtension)) {
      return "video";
    } else if (AppConfig.fileTypes.sheet.includes(fileExtension)) {
      return "sheet";
    }
    return "file";
  }
  return "";
};

const getFileIcon = (fileType) => {
  let fileIcon = "";
  switch (fileType) {
    case "image":
    case "image/png":
      fileIcon = "file-image";
      break;
    case "pdf":
      fileIcon = "file-pdf";
      break;
    case "doc":
      fileIcon = "file-word";
      break;
    case "video":
      fileIcon = "play-circle";
      break;
    case "sheet":
      fileIcon = "file-excel";
      break;
    default:
      fileIcon = "file";
      break;
  }
  return fileIcon;
};

const formateArrayToWithPlus = (data = []) => {
  if (!isEmpty(data) && data.length > 0) {
    return `${data[0]}${data.length > 1 ? `, +${data.length - 1}` : ""}`;
  }
  return "";
};

const refreshIcon = (cb, showLabel=false, showIcon = false) => (
  showLabel ?
  <Fragment>
    <Link onClick={cb}>
      {
        !showIcon ?
        <img
        src={refreshImg}
        className="cursorPointer textPrimary "
        onClick={cb}
        style={{ width: 15, marginTop: -5 }}
        />
        :
        <Icon type="reload" style= {{ fontSize: 12}} />
      }
      <span style={{ marginLeft: 5}}>{I18n.t('general.refresh')}</span>
    </Link>
  </Fragment> :
  <img
    src={refreshImg}
    className="cursorPointer textPrimary "
    onClick={cb}
    style={{ width: 15, marginTop: -5 }}
  />
);

const base64ToPdf = (base64, fileName) => {
  var link = document.createElement("a");
  link.href = "data:application/pdf;base64," + base64;
  link.download = fileName || "invoice.pdf";
  link.click();
};

const upperFirst = (str) => {
  return _.upperFirst(_.lowerCase(str));
}

const retrieverValueFromObject = (record ={}, reqKey = "") => {
  if(record && reqKey) {
    const reqValue = doFormate(record[reqKey])
    return reqValue;
  }
  return "";
}

const renderIconComponent = (icon) => {
  switch(icon) {
    case 'dashboard':
      return <DashboardIcon />
    case 'order':
      return <OrderIcon />
    case 'pending':
      return <PendingIcon />
    case 'upload':
      return <UploadIcon />
    case 'quotes':
      return <QuoteIcon />
    case 'recovery':
      return <RecoveryIcon />
    case 'release':
      return <ReleaseIcon />
    case 'linehaul':
      return <LinehaulIcon />
    case 'repeat':
      return <RepeatIcon />
    case 'preplan':
      return <PreplanIcon />
    case 'dispatch':
      return <DispatchIcon />
    case 'dollar':
      return <DollarIcon />
    case 'invoice':
      return <InvoiceIcon />
    case 'getcash':
      return <GetCashIcon />
    case 'batch':
      return <LayersIcon />
    case 'creditmemo':
      return <CreditMemoIcon />
    case 'checkbook':
      return <CheckBookIcon />
    case 'account':
      return <BusinessIcon />
    case 'form':
      return <FormIcon />
    case 'survey':
      return <RatingIcon />
    case 'setting':
      return <SettingsIcon />
    case 'user':
      return <UserIcon />
    case 'vtype':
      return <TruckTypeIcon />
    case 'driver':
      return <SteeringWheelIcon />
    case 'truck':
      return <TruckIcon />
    case 'role':
      return <UserSheildIcon />
    case 'agent':
      return <AgentIcon />
    case 'location':
      return <LocationIcon />
    case 'warehouse':
      return <WarehouseIcon />
    case 'insights':
      return <AreaChartIcon />
    case 'aging':
      return <TimeSpanIcon />
    case 'transaction':
      return <TransactionIcon />
    case 'paymentjournal':
      return <PaymentJournalIcon />
    case 'glcode':
      return <SpiralBondBookletIcon />
    case 'dunning':
      return <DunningIcon />
    case 'edi':
      return <EdiIcon />
    case 'customer-report':
      return <CustomerReportIcon />
    case 'driver-report':
      return <DriverReportIcon />
    case 'gantt-chart':
      return <GanttChartIcon />
    case 'pod':
      return <ImageIcon />
    case 'invoice-status':
      return <InvoiceStatusIcon />
    case 'invoice-fusion':
      return <FusionIcon />
    case 'iif-generation':
      return <IIFIcon />
    case 'chat':
      return <ChatIcon />
    case 'bounced_emails':
      return <EmailIcon />
    default:
      return <Icon type={icon} className="ant-icon" />
  }
}

const renderIcon = (isCustomIcon, data) => {
  const icon = _.get(data, 'icon');
  const text = _.get(data, 'text');
  const style = _.get(data, 'style');

  return isCustomIcon ? (
    <img src={icon} alt={text} style={style} />
  ) : (
    renderIconComponent(icon)
  );
};

  const renderBadge = (data) => {
    const badge = _.get(data, 'badge');
    const className = _.get(data, 'className');
    const text = _.get(data, 'text');
  
    return badge ? (
      <BetaBadge
        count={badge}
        className={className}
        text={text}
      />
    ) : (
      <span className={className}>{text}</span>
    );
  };

  function roundDecimal(number = 0.0) {
    const decimalPoints = parseInt(window.localStorage.getItem("round_off_decimals")) || 2;
    return parseFloat(number.toFixed(decimalPoints));
  }

  function checkIfDriverLoggedIn(role) {
    const mobileRoles = AppConfig.mobileRoles.map((role) => role.role_code);
    if (mobileRoles.includes(role)) {
      return true;
    }
    return false;
  }
  const lockIconTooltip = ({
    overlayStyle = {},
    tooltipColor = "#2c3763",
    iconClassName = "",
    userName = "Unknown",
    iconStyle = {}
  }) => {
    return (
      <Tooltip
        title={`Driver route is currently in use by ${userName}. Wait or unlock to assign orders.`}
        color={tooltipColor}
        overlayStyle={{ width: 200, ...overlayStyle }}
      >
        <LockIcon className={iconClassName} color={iconStyle?.color} />
      </Tooltip>
    );
  };


export {
  _,
  alertMessage,
  compareString,
  compareNumber,
  disabledDate,
  doFormate,
  doValidate,
  isEmpty,
  isEmptyForBoolean,
  isBetweenDates,
  formatAddress,
  formatDate,
  formateImageName,
  findTotal,
  formatStopTime,
  formatStopUTcTime,
  getParam,
  getParamId,
  randomColor,
  randomNumber,
  validateFields,
  validateAddressForm,
  checkNegitive,
  currencyConversion,
  checkWithinRange,
  formatePhoneNo,
  ellipseText,
  customPasteSplit,
  isArrayEqual,
  randomUUID,
  formatCode,
  base64MimeType,
  navigateToAccount,
  getFileType,
  getFileIcon,
  formateArrayToWithPlus,
  findNumberTotal,
  base64ToPdf,
  isSameDay,
  upperFirst,
  inchesToFeet,
  sqInchesToSqFeet,
  refreshIcon,
  retrieverValueFromObject,
  renderIcon,
  renderBadge,
  compareFloatNumber,
  compareDate,
  roundDecimal,
  checkIfDriverLoggedIn,
  lockIconTooltip
};
