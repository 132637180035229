import React, { useState, useRef, useEffect } from "react";
import { Layout, Menu, Dropdown } from "antd";
import { Document, Page } from "react-pdf";

const { Sider, Content } = Layout;

const PDFPreviewComponent = ({ pdfFile, podPicSettings, onPageLabelsUpdate,organizationSettings }) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [pageLabels, setPageLabels] = useState({});

  const contentWrapperRef = useRef(null);
  const siderRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = () => {
      setContextMenuVisible(false);
    };

    // Attach click handler globally
    document.addEventListener("click", handleDocumentClick);
    
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handlePageClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    const contentDiv = contentWrapperRef.current;
    if (contentDiv) {
      const pageElement = contentDiv.querySelector(`[data-page-number="${pageNumber}"]`);
      if (pageElement) {
        pageElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleScroll = () => {
    const contentDiv = contentWrapperRef.current;
    if (!contentDiv) return;
  
    const pages = contentDiv.querySelectorAll(".react-pdf__Page");
    const scrollTop = contentDiv.scrollTop;
    let currentPage = selectedPage;
  
    pages.forEach((page, index) => {
      const rect = page.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight) {
        currentPage = index + 1;
      }
    });
  
    if (currentPage !== selectedPage) {
      setSelectedPage(currentPage);
  
      const siderDiv = document.querySelector(`[data-sider-page="${currentPage}"]`);
      if (siderDiv) {
        const rect = siderDiv.getBoundingClientRect();
        const parentRect = siderDiv.parentNode.getBoundingClientRect();
  
        if (rect.top < parentRect.top || rect.bottom > parentRect.bottom) {
          siderDiv.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
      }
    }
  };

  const handleRightClick = (e, pageNumber) => {
    e.preventDefault();
    setSelectedPage(pageNumber);
      let xPercent = ((e.clientX / window.innerWidth) * 100).toFixed(2);
      let yPercent = ((e.clientY / window.innerHeight) * 100).toFixed(2);
      
    xPercent = Math.max(0, xPercent - 10);
    yPercent = Math.max(0, yPercent - 4);
  
    setContextMenuPosition({ x: `${xPercent}%`, y: `${yPercent}%` });
    setContextMenuVisible(true);
  };


  const handleMenuClick = (e) => {
    const selectedOption = e.key;
    const updatedLabels = { ...pageLabels };

    for (let label in updatedLabels) {
      const pageIndex = updatedLabels[label].indexOf(selectedPage);
      if (pageIndex !== -1) {
        updatedLabels[label].splice(pageIndex, 1);
      }
    }

    if (updatedLabels[selectedOption]) {
      updatedLabels[selectedOption].push(selectedPage);
    } else {
      updatedLabels[selectedOption] = [selectedPage];
    }

    setPageLabels(updatedLabels);
    setContextMenuVisible(false);

    onPageLabelsUpdate(updatedLabels);
  };

  const uniquePicCodes = new Set();

const contextMenu = (
  <Menu onClick={handleMenuClick}>
    <Menu.ItemGroup title="Tags">
      {["BOL", ...(organizationSettings?.airline_documents === "true" ? ["AIRLINE"] : [])]
        .filter((pic_code) => {
          if (uniquePicCodes.has(pic_code)) return false;
          uniquePicCodes.add(pic_code);
          return true;
        })
        .map((pic_code) => (
          <Menu.Item key={pic_code} value={pic_code}>
            {pic_code}
          </Menu.Item>
        ))}
      {podPicSettings
        .filter((setting) => {
          if (uniquePicCodes.has(setting.pic_code)) return false;
          uniquePicCodes.add(setting.pic_code);
          return true;
        })
        .map((setting) => (
         <Menu.Item key={setting.pic_code} value={setting.pic_code}>
            {setting.pic_code}
          </Menu.Item>
      ))}
      </Menu.ItemGroup>
    </Menu>
 );

  return (
    <Layout style={{ height: "600px" }}>
      <Sider
        ref={siderRef}
        width={150}
        style={{ background: "#f0f2f5", padding: "10px", overflowY: "auto" }}
      >
        <div style={{ height: "100%" }}>
          {numPages &&
            Array.from(new Array(numPages), (_, index) => {
                const labelsForPage = Object.keys(pageLabels).filter(
                (label) => pageLabels[label].includes(index + 1)
                );

                return (
                <div
                  key={index + 1}
                  data-sider-page={index + 1}
                  style={{
                  cursor: "pointer",
                  marginBottom: "10px",
                  padding: "10px",
                  backgroundColor: selectedPage === index + 1 ? "#091724" : "#fff",
                  color: selectedPage === index + 1 ? "#fff" : "#000",
                  borderRadius: "4px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => handlePageClick(index + 1)}
                  onContextMenu={(e) => handleRightClick(e, index + 1)}
                >
                <div
                  style={{
                    width: "160px",
                    height: "160px",
                    margin: "0 auto",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  }}
                    >
                    <Document file={pdfFile}>
                        <Page pageNumber={index + 1} scale={0.8} width={150} />
                    </Document>
                    </div>
                    <div
                    style={{
                        marginTop: "5px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                    >
                    {labelsForPage.length > 0
                        ? labelsForPage.join(", ")
                        : index + 1}
                    </div>
                </div>
                );
            })}
        </div>
    </Sider>
      <div
        ref={contentWrapperRef}
        style={{
          padding: "20px",
          background: "#fff",
          overflowY: "auto",
          height: "100%",
        }}
        onScroll={handleScroll}
      >
        <Content>
          <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={index + 1}
                pageNumber={index + 1}
                className="react-pdf__Page"
                data-page-number={index + 1}
              />
            ))}
          </Document>
        </Content>
      </div>
      {contextMenuVisible && (
        <div
          style={{
            position: "absolute",
            top: contextMenuPosition.y,
            left: contextMenuPosition.x,
            zIndex: 1000,
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Dropdown overlay={contextMenu} visible={true} trigger={["contextMenu"]}>
            <div />
          </Dropdown>
        </div>
      )}
    </Layout>
  );
};

export default PDFPreviewComponent;
