/* eslint-disable no-nested-ternary */
import _ from "lodash";
import moment from "moment";
import { alertMessage, getFileType, isEmpty, randomNumber } from "../common/Common";
import AppConfig from "../config/AppConfig";
import userStore from "../stores/UserStore";
import { Modal, message } from 'antd';
import { fetchLocations } from "../api/LocationsApi";
import { fetchShortFormAccounts } from "../api/Account";
import { isTokenValid } from "../api/ApiUtils";
import Axios from "axios";
import { fetchOrderDetails } from "../api/OrdersApi";
import { fetchAvailableDrivers } from "../api/PreplanApi";
import { fetchOrganizationConfigs } from "../api/Organisations";
const {confirm} = Modal

function formatFullName(data) {
  if (data && (!isEmpty(data.first_name) || !isEmpty(data.last_name))) {
    return `${!isEmpty(data.first_name) ? data.first_name : ""}${
      !isEmpty(data.last_name) ? ` ${data.last_name}` : ""
    }`;
  }
  return "";
}

function clearSession () {
  window.localStorage.clear();
  window.location.reload();
}

function getDriverFullDisplayName(driver) {
  if (!isEmpty(driver)) {
    return `${driver.first_name} ${driver.last_name} - ${driver.employee_code}`;
  }
  return "";
}

function getDriverDisplayName(driver) {
  if (!isEmpty(driver)) {
    return `${driver.driver_name} - ${driver.employee_code}`;
  }
  return "";
}

function getDriverName(driver = {}) {
  if (!isEmpty(driver.employee_code)) {
    return driver.employee_code;
  }
  if (!isEmpty(driver.full_name)) {
    return driver.full_name;
  }
  const name = formatFullName(driver);
  return name;
  // `${driver.first_name}${driver.last_name ? ` ${driver.last_name}` : ''}`;
}

function driverNameFromRoute(route, type) {
  let routeName = "";
  if (type === "object") {
    if (!isEmpty(route) && _.isObject(route) && Object.keys(route).length > 0) {
      if (route.primary_driver) {
        routeName = formatFullName(route.primary_driver);
      }
      if (route.secondary_driver) {
        const secondaryName = formatFullName(route.secondary_driver);
        routeName = `${
          routeName
            ? `${routeName}${
                secondaryName ? ` ,${secondaryName}` : secondaryName
              }`
            : secondaryName
        }`;
      }
    }
  } else {
    if (route.primary_driver) {
      routeName = route.primary_driver;
    }
    if (route.secondary_driver) {
      const secondaryName = route.secondary_driver;
      routeName = `${
        routeName
          ? `${routeName}${
              secondaryName ? ` ,${secondaryName}` : secondaryName
            }`
          : secondaryName
      }`;
    }
  }

  return routeName;
}

function drivercodeFromRoute(route, type) {
  let routeName = "";
  if (type === "object") {
    if (!isEmpty(route) && _.isObject(route) && Object.keys(route).length > 0) {
      if (route.primary_driver) {
        routeName = route.primary_driver.employee_code;
      }
      if (
        route.secondary_driver &&
        !isEmpty(route.secondary_driver.employee_code)
      ) {
        const secondaryName = route.secondary_driver.employee_code;
        routeName = `${
          routeName
            ? `${routeName}${
                secondaryName ? ` ,${secondaryName}` : secondaryName
              }`
            : secondaryName
        }`;
      }
    }
  } else {
    if (route.primary_driver_employee_code) {
      routeName = route.primary_driver_employee_code;
    }
    if (route.secondary_driver_employee_code) {
      const secondaryCode = route.secondary_driver_employee_code;
      routeName = `${
        routeName
          ? `${routeName}${
              secondaryCode ? ` ,${secondaryCode}` : secondaryCode
            }`
          : secondaryCode
      }`;
    }
  }

  return routeName;
}

const formateTwoDates = (firstRecord, lastRecord, showTime = false , isMilitaryTime = false) => {
  let heading = "";
  if (firstRecord === lastRecord) {
    const firstRecordYear = moment(firstRecord, "YYYY-MM-DD").format("YYYY");
    const firstRecordMonth = moment(firstRecord, "YYYY-MM-DD").format("MMM");
    const firstRecordDay = !showTime ? moment(firstRecord, "YYYY-MM-DD").format("Do"):  formatByTimeConfig(moment(lastRecord, "YYYY-MM-DD") , isMilitaryTime , `Do${showTime ? " HH:mm" : ""}` , `Do${showTime ? " hh:mm A" : ""}`)

    if (!isEmpty(firstRecord)) {
      heading = `${firstRecordMonth} ${firstRecordDay}, ${firstRecordYear}`;
    }
  } else {
    const firstRecordYear = moment(firstRecord, "YYYY-MM-DD").format("YYYY");
    const lastRecordYear = moment(lastRecord, "YYYY-MM-DD").format("YYYY");
    const firstRecordMonth = moment(firstRecord, "YYYY-MM-DD").format("MMM");
    const lastRecordMonth = moment(lastRecord, "YYYY-MM-DD").format("MMM");
    const firstRecordDay = formatByTimeConfig(moment(firstRecord, "YYYY-MM-DD") , isMilitaryTime , `Do ${showTime ? " HH:mm" : ""}` , `Do ${showTime ? " hh:mm A" : ""}`)
    const lastRecordDay = formatByTimeConfig(moment(lastRecord, "YYYY-MM-DD") , isMilitaryTime , `Do ${showTime ? " HH:mm" : ""}` , `Do ${showTime ? " hh:mm A" : ""}`)
    if (firstRecordMonth === lastRecordMonth) {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment(firstRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      }
      ${
        firstRecordYear !== lastRecordYear
          ? `${firstRecordYear} - `
          : firstRecordDay !== lastRecordDay
          ? " - "
          : ""
      }
      ${firstRecordYear !== lastRecordYear ? lastRecordMonth : ""} 
      ${
        lastRecordYear !== firstRecordYear || firstRecordDay !== lastRecordDay
          ? `${lastRecordDay} ${
              showTime ? formatByTimeConfig(moment(lastRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
            }`
          : ""
      } ${lastRecordYear}`;
    } else if (lastRecordYear === firstRecordYear) {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment(firstRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      } - ${lastRecordMonth} ${lastRecordDay} ${
        showTime ? formatByTimeConfig(moment(lastRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      } ${firstRecordYear}`;
    } else {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment(firstRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      } ${firstRecordYear} - ${lastRecordMonth} ${lastRecordDay} ${
        showTime ? formatByTimeConfig(moment(lastRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      } ${lastRecordYear}`;
    }
  }
  return heading;
};

const formateTwoDateDays = (firstRecord, lastRecord, showTime = false , isMilitaryTime = false) => {
  let heading = "";
  if (firstRecord === lastRecord) {
    const firstRecordYear = moment(firstRecord, "YYYY-MM-DD").format("YYYY");
    const firstRecordMonth = moment(firstRecord, "YYYY-MM-DD").format("MMM");
    const firstRecordDay = moment(firstRecord, "YYYY-MM-DD").format(`Do`);
    const firstRecordDates = moment(firstRecord,"YYYY-MM-DD").format(`dddd`)

    if (!isEmpty(firstRecord)) {
      heading = `${firstRecordDay} ${firstRecordMonth} ${firstRecordDates}, ${firstRecordYear}`;
    }
  } else {
    const firstRecordYear = moment(firstRecord, "YYYY-MM-DD").format("YYYY");
    const lastRecordYear = moment(lastRecord, "YYYY-MM-DD").format("YYYY");
    const firstRecordMonth = moment(firstRecord, "YYYY-MM-DD").format("MMM");
    const lastRecordMonth = moment(lastRecord, "YYYY-MM-DD").format("MMM");
    const firstRecordDay = moment(firstRecord, "YYYY-MM-DD").format(`Do dddd`);
    const lastRecordDay = moment(lastRecord, "YYYY-MM-DD").format(` Do dddd`);
    if (firstRecordMonth === lastRecordMonth) {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment(firstRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      }
      ${
        firstRecordYear !== lastRecordYear
          ? `${firstRecordYear} - `
          : firstRecordDay !== lastRecordDay
          ? " - "
          : ""
      }
      ${firstRecordYear !== lastRecordYear ? lastRecordMonth : ""} 
      ${
        lastRecordYear !== firstRecordYear || firstRecordDay !== lastRecordDay
          ? `${lastRecordDay} ${
              showTime ? formatByTimeConfig(moment(lastRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
            }`
          : ""
      } ${lastRecordYear}`;
    } else if (lastRecordYear === firstRecordYear) {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment(firstRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      } - ${lastRecordMonth} ${lastRecordDay} ${
        showTime ? formatByTimeConfig(moment(lastRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      } ${firstRecordYear}`;
    } else {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment(firstRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      } ${firstRecordYear} - ${lastRecordMonth} ${lastRecordDay} ${
        showTime ? formatByTimeConfig(moment(lastRecord) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
      } ${lastRecordYear}`;
    }
  }
  return heading;
};

const formatSingleDay = (date, showTime = false, isMilitaryTime = false) => {
  if (isEmpty(date)) {
    return "";
  }

  const momentDate = moment(date, "YYYY-MM-DD");
  const formattedDate = momentDate.format("Do MMM dddd, YYYY");

  if (!showTime) {
    return formattedDate;
  }

  const formattedTime = formatByTimeConfig(
    momentDate,
    isMilitaryTime,
    "HH:mm",
    "hh:mm A"
  );

  return `${formattedDate} ${formattedTime}`;
};

const formateTwoUTCDates = (firstRecord, lastRecord, showTime = false , isMilitaryTime = false) => {
  let heading = "";

  if (firstRecord === lastRecord) {
    const firstRecordYear = moment.utc(firstRecord).format("YYYY");
    const firstRecordMonth = moment.utc(firstRecord).format("MMM");
    const firstRecordDay = formatByTimeConfig(moment.utc(lastRecord) , isMilitaryTime , `Do ${showTime ? "HH:mm" : ""}` , `Do ${showTime ? "hh:mm A" : ""}`);
    if (!isEmpty(firstRecord)) {
      heading = `${firstRecordMonth} ${firstRecordDay}, ${firstRecordYear}`;
    }
  } else {
    const firstRecordYear = moment.utc(firstRecord).format("YYYY");
    const lastRecordYear = moment.utc(lastRecord).format("YYYY");
    const firstRecordMonth = moment.utc(firstRecord).format("MMM");
    const lastRecordMonth = moment.utc(lastRecord).format("MMM");
    const firstRecordDay = moment.utc(firstRecord).format("Do");
    const lastRecordDay = moment.utc(lastRecord).format("Do");
    if (firstRecordMonth === lastRecordMonth) {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment.utc(firstRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`) : ""
      }
      ${
        firstRecordYear !== lastRecordYear
          ? " - "
          : firstRecordDay !== lastRecordDay
          ? " - "
          : ""
      }
      ${firstRecordYear !== lastRecordYear ? lastRecordMonth : ""} 
      ${
        lastRecordYear !== firstRecordYear || firstRecordDay !== lastRecordDay
          ? `${lastRecordDay} ${
              showTime ? formatByTimeConfig(moment.utc(lastRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`) : ""
            }`
            : `-${showTime ? formatByTimeConfig(moment.utc(lastRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`) : ""}`
      }`;
    } else if (lastRecordYear === firstRecordYear) {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment.utc(firstRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`) : ""
      } - ${lastRecordMonth} ${lastRecordDay} ${
        showTime ? formatByTimeConfig(moment.utc(lastRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`) : ""
      }`;
    } else {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? formatByTimeConfig(moment.utc(firstRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`) : ""
      } - ${lastRecordMonth} ${lastRecordDay} ${
        showTime ? formatByTimeConfig(moment.utc(lastRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`) : ""
      }`;
    }
  }
  return heading;
};

const formateTwoUTCTimes = (firstRecord, lastRecord , isMilitaryTime = false) => {
  const heading = `${formatByTimeConfig(moment.utc(firstRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`)} - ${formatByTimeConfig(moment.utc(lastRecord) , isMilitaryTime , `HH:mm` , `hh:mm A`)}`;
  return heading;
};

const formateTwoUTCDatesWithYear = (
  firstRecord,
  lastRecord,
  showTime = false
) => {
  let heading = "";

  if (firstRecord === lastRecord) {
    const firstRecordYear = moment.utc(firstRecord).format("YYYY");
    const firstRecordMonth = moment.utc(firstRecord).format("MMM");
    const firstRecordDay = moment
      .utc(lastRecord)
      .format(`Do ${showTime ? "hh:mm A" : ""}`);
    if (!isEmpty(firstRecord)) {
      heading = `${firstRecordMonth} ${firstRecordDay}, ${firstRecordYear}`;
    }
  } else {
    const firstRecordYear = moment.utc(firstRecord).format("YYYY");
    const lastRecordYear = moment.utc(lastRecord).format("YYYY");
    const firstRecordMonth = moment.utc(firstRecord).format("MMM");
    const lastRecordMonth = moment.utc(lastRecord).format("MMM");
    const firstRecordDay = moment.utc(firstRecord).format("Do");
    const lastRecordDay = moment.utc(lastRecord).format("Do");
    if (firstRecordMonth === lastRecordMonth) {
      // heading = `${firstRecordMonth} ${firstRecordDay} ${firstRecordYear !== lastRecordYear ? firstRecordYear : ''} - ${firstRecordYear !== lastRecordYear ? lastRecordMonth : ''} ${lastRecordDay} ${lastRecordYear}`;
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? moment.utc(firstRecord).format("hh:mm A") : ""
      }
      ${
        firstRecordYear !== lastRecordYear
          ? `${firstRecordYear} - `
          : firstRecordDay !== lastRecordDay
          ? " - "
          : ""
      }
      ${firstRecordYear !== lastRecordYear ? lastRecordMonth : ""} 
      ${
        lastRecordYear !== firstRecordYear || firstRecordDay !== lastRecordDay
          ? `${lastRecordDay} ${
              showTime ? moment.utc(lastRecord).format("hh:mm A") : ""
            }`
          : `-${showTime ? moment.utc(lastRecord).format("hh:mm A") : ""}`
      } ${lastRecordYear}`;
      // if (showTime) {
      //   heading = `${heading} ${moment.utc(firstRecord).format('hh:mm A')} - ${moment.utc(lastRecord).format('hh:mm A')}`;
      // }
    } else if (lastRecordYear === firstRecordYear) {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? moment.utc(firstRecord).format("hh:mm A") : ""
      } - ${lastRecordMonth} ${lastRecordDay} ${
        showTime ? moment.utc(lastRecord).format("hh:mm A") : ""
      } , ${firstRecordYear}`;
    } else {
      heading = `${firstRecordMonth} ${firstRecordDay} ${
        showTime ? moment.utc(firstRecord).format("hh:mm A") : ""
      } ${firstRecordYear} - ${lastRecordMonth} ${lastRecordDay} ${
        showTime ? moment.utc(lastRecord).format("hh:mm A") : ""
      } ${lastRecordYear}`;
    }
  }
  return heading;
};

const formatServiceDuration = (
  serviceDuration,
  defaultValue = AppConfig.default_service_duration
) =>
  !isEmpty(serviceDuration)
    ? secondsToHms(parseInt(serviceDuration), true, true, false)
    : defaultValue;

const validateMobile = (mobile, isRequired = false) => {
  let mobileNo = "";
  if (!isEmpty(mobile)) {
    if (mobile === "+") {
      mobileNo = "";
      if (!isRequired) {
        return true;
      }
    } else {
      mobileNo = mobile;
      mobileNo = mobileNo.replace(/[^0-9]/g, "");
    }
    if (AppConfig.regexMobile.test(mobileNo)) {
      return true;
    }
    return false;
  } else {
    if (isRequired) {
      return false;
    }
    return true;
  }
};

const renderWeightHeading = (weight, checkMaxRangeValue = false) => {
  if (checkMaxRangeValue && weight.max_weight === AppConfig.rangeMaxValue) {
    return `${weight.min_weight} or above`;
  }
  return `${weight.min_weight} - ${weight.max_weight}`;
};

const renderWeightWithUnitCheckedHeading = (weight, checkMaxRangeValue = false, units = "lbs") => {
  if (checkMaxRangeValue && weight.max_weight === AppConfig.rangeMaxValue) {
    return `${units == "kgs" ? convertToKilograms(weight.min_weight) : weight.min_weight} or above`;
  }
  return `${units == "kgs" ? convertToKilograms(weight.min_weight) : weight.min_weight} - ${units == "kgs" ? convertToKilograms(weight.max_weight) : weight.max_weight}`;
};

export const checkServiceExistance = (
  serviceCodes,
  conditionToSatisfy = "ALL"
) => {
  if(userStore.admin() || userStore.superAdmin()){
    return true;
  }
  const services = userStore.getServiceAccess();
  if (services.length > 0) {
    if (_.isString(serviceCodes)) {
      if (services.indexOf(serviceCodes) >= 0) {
        return true;
      }
    } else if (_.isArray(serviceCodes)) {
      if (conditionToSatisfy === "ALL") {
        return (
          serviceCodes.filter((code) => !services.includes(code)).length === 0
        );
      } else if (conditionToSatisfy === "ANY") {
        return (
          serviceCodes.filter((code) => services.includes(code)).length > 0
        );
      }
    }
  } else {
    return true;
  }
  return false;
};

export const checkModuleExistance = (
  moduleCodes,
  conditionToSatisfy = "ALL"
) => {
  if(userStore.admin() || userStore.superAdmin()){
    return true;
  }
  const services = userStore.getModules();
  if (services.length > 0) {
    if (_.isString(moduleCodes)) {
      if (services.indexOf(moduleCodes) >= 0) {
        return true;
      }
    } else if (_.isArray(moduleCodes)) {
      if (conditionToSatisfy === "ALL") {
        return (
          moduleCodes.filter((code) => !services.includes(code)).length === 0
        );
      } else if (conditionToSatisfy === "ANY") {
        return (
          moduleCodes.filter((code) => services.includes(code)).length > 0
        );
      }
    }
  } else {
    return true;
  }
  return false;
};

export const checkAccessExistance = (
  screenType = ''
) => {
  if(!isEmpty(screenType)){
    switch(screenType){
      case 'DISPATCH': return (checkServiceExistance("OPSDS") && checkModuleExistance("DPMGT"))
      case 'PREPLAN': return ((checkServiceExistance("OPSPPS") && checkModuleExistance("PPMGT")))
      case 'ORDER': return (checkServiceExistance(["COI", "OPSOS"], "ALL"))
      case 'ACCOUNT': return (checkServiceExistance(["AI", "OPSAS"], "ALL"))
      case 'ACCOUNT_CONFIG': return (checkServiceExistance(["AI", "OPSAS", "ACNF"], "ALL"))


      default: return true
    }
  }
  return true;
};

const formatTime = (totalTime) => {
  let secHrs = secondsToHms(totalTime, true, false, false);
  secHrs = secHrs ? `${secHrs} Approx.` : "NA";
  return secHrs;
};

const formatDistance = (totalDistance) => {
  totalDistance = totalDistance
    ? `${parseFloat(totalDistance / 1609).toFixed(2)} mi.`
    : "NA";
  return totalDistance;
};

const base64ToPDF = (base64String, fileName ="") => {
  if(!isEmpty(base64String)) {
    const source = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    fileName = !isEmpty(fileName) ? fileName : "file.pdf" ;
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}

const responseTOCsv = (dataInCSV, fileName = "output", fileType = 'CSV') => {
  if(!isEmpty(dataInCSV)) {
   // Check if the decoded data is a CSV or Excel file (you can expand this check as needed)
   if (fileType === 'CSV') {
     fileName += ".csv";
   } else {
     fileName += ".xlsx";
   }

   // Create a download link and trigger the click event to download the file
   const downloadLink = document.createElement('a');
   downloadLink.href = `data:text/csv;charset=utf-8,${escape(dataInCSV)}`;
   downloadLink.download = fileName;
   document.body.appendChild(downloadLink);
   downloadLink.click();
   document.body.removeChild(downloadLink);
  }
}

const responseToPDF = (pdfData, fileName = "output") => {
  if (!isEmpty(pdfData)) {
    fileName += ".pdf";
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }
};


const base64ToExcel = (base64String, fileName = "output", fileType = 'EXCEL') => {
  if(!isEmpty(base64String)) {
   // Convert the Base64 string to a Uint8Array
   const binaryString = window.atob(base64String);
   const len = binaryString.length;
   const bytes = new Uint8Array(len);
   for (let i = 0; i < len; ++i) {
     bytes[i] = binaryString.charCodeAt(i);
   }

   // Create a Blob from the Uint8Array
   const blob = new Blob([bytes.buffer]);

   // Check if the decoded data is a CSV or Excel file (you can expand this check as needed)
   if (fileType === 'CSV') {
     fileName += ".csv";
   } else {
     fileName += ".xlsx";
   }

   // Create a download link and trigger the click event to download the file
   const downloadLink = document.createElement('a');
   downloadLink.href = URL.createObjectURL(blob);
   downloadLink.download = fileName;
   document.body.appendChild(downloadLink);
   downloadLink.click();
   document.body.removeChild(downloadLink);
  }
}

const base64ToBlob = (base64, contentType = 'application/pdf') => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

const base64ToIIF = (base64String, fileName = "output") => {
  if (base64String) {
    // Convert the Base64 string to a Uint8Array
    const binaryString = window.atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes.buffer], { type: 'text/plain' });

    // Set file extension to .iif
    fileName += ".iif";

    // Create a download link and trigger the click event to download the file
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

const printPDF = (base64Data) => {
  const blob = base64ToBlob(base64Data);
  const blobUrl = URL.createObjectURL(blob);

  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);

  // Use the beforeprint and afterprint events
  const beforePrint = () => {
      console.log('Preparing to print...');
  };

  const afterPrint = () => {
      console.log('Print dialog closed.');
      document.body.removeChild(iframe);
      URL.revokeObjectURL(blobUrl);
      window.removeEventListener('afterprint', afterPrint);
  };

  window.addEventListener('afterprint', afterPrint);

  iframe.onload = function () {
      setTimeout(() => {
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
          window.addEventListener('beforeprint', beforePrint);
      }, 1000);  // Delay to ensure the PDF is fully loaded
  };

  iframe.src = blobUrl;
}


const confirmationPopup = (confirmationInfo) => {
	const { title, content, onConfirm, onCancel } = confirmationInfo;
	confirm({
		title: title || "",
		content: content || "",
		okText: "Yes",
		okType: "danger",
		cancelText: "No",
		onOk() {
			onConfirm && typeof onConfirm === "function" && onConfirm();
		},
		onCancel() {
			onCancel &&  typeof onCancel === "function" && onCancel();
		},
	});
};

const generateAccessMessage = (message, type = 'error', defaultTime = 10) => {
  alertMessage(message, type, defaultTime);
}

const haveFreePackage = () => {
  const superAdmin = userStore.superAdmin();
  const hasFreePackage = superAdmin ? false : userStore.hasFreePackage();
  return hasFreePackage;
}
const convertToTags = (filter, keys, filterData) => {
  let tags = [];
  keys.forEach(key => {
    const filterKeyData = filterData[ key ]
    if (filter[ key ] && filterKeyData) {
      tags.push({
        filterKey: key,
        label: filterKeyData[ 'label' ],
        value: filterKeyData[ 'value' ],
        onClose: filterKeyData[ 'onClose' ] ? filterKeyData[ 'onClose' ] : () => { },
        closeable: filterKeyData[ 'closeable' ] ? true : false
      });
    }
  }
  );
  return tags.reduce((acc, curr) => {
    if (!acc.some(item => item.label === curr.label)) {
      acc.push(curr);
    }
    return acc;
  }
    , []).filter(item => item.value !== "");
};

// usage example: limitText(textstring, 3) => "a,b,c,d" => "a,b,c, +1 more"
const limitString = (stringData, limit) => {
  if(!isEmpty(stringData)){
    const stringArray = stringData.split(",");
    const stringArrayFirstFew = stringArray.slice(0, limit);
    const stringArrayRemaining = stringArray.slice(limit);
    const stringArrayRemainingCount = stringArrayRemaining.length;
    const stringArrayFirst5String = stringArrayFirstFew.join(","); 
    
    if(stringArrayRemainingCount > 0) {
    const stringArrayString = stringArrayFirst5String + " + " + stringArrayRemainingCount + " more";
    return stringArrayString;
    } else {
      return stringArrayFirst5String;
    }
  }
  return "";
  
}

const formatByTimeConfig = (time, isMilitaryTime, militaryFormat = "HH:mm", nonMilitaryFormat = "hh:mm A") => {
  // check if valid time
  if (!time) return "";
  isMilitaryTime = isMilitaryTime === 'true' || isMilitaryTime === true ? true : false;
  const defaultFormat = isMilitaryTime ? "HH:mm" : "hh:mm A";
  const format = isMilitaryTime ? militaryFormat : nonMilitaryFormat;
  // if format is not provided, use default
  if (!format) return moment(time).format(defaultFormat);
  else return moment(time).format(format);
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const handlePublicDownloads = (fileName = "Untitled") => {
    window.open(`${fileName}?q=${randomNumber()}`, "_blank");
};

const checkIfRequiredFieldsAreFilled = (data, requiredFields) => {
  // iterate through required fields and check if they are filled and if the field is object iterate through the object and check if the fields are filled
  let allFilled = true;
  Array.isArray(requiredFields) && requiredFields.forEach((field) => {
    if (typeof field === 'object') {
      const { key, fields } = field;
      if(_.isEmpty(data[ key ]) || !data[ key ] || !_.isObject(data[ key ])) {
        allFilled = false;
      }
      else fields.forEach((field) => {
        if (_.isObject(data[ key ]) && _.isEmpty(data[ key ][ field ])) {
          allFilled = false;
        }
      });
    }
    else if (_.isNumber(data[ field ])) {
      if (data[ field ] <= 0)
        allFilled = false;
    }
    else if (typeof field === 'string') {
      if (_.isEmpty(data[ field ])) {
        allFilled = false;
      }
    }
  });
  return allFilled;
};

const renderAddress = (address) => {
  // split the address into lines and render each line
  const addressLines = address.split(',');
  const addressLinesLength = addressLines.length;
  const addressLinesArr = [];
  for (let i = 0; i < addressLinesLength; i++) {
    // if i is greater than 2 then render the remaining lines in a single line
    if (i > 2) {
      addressLinesArr.push(addressLines.slice(i).join(','));
      i += addressLinesLength;
    } else {
      const line = addressLines[ i ];
      addressLinesArr.push(<div key={ i }>{ line }</div>);
    }
  }
  return addressLinesArr.map((line, index) => <div key={ index }>{ line }</div>);
}


function checkIfValidAddress(address) {
  if (address.address_line1.length > 0 && address.city.length > 0 && address.state.length > 0 && address.country.length > 0 && address.zipcode.length > 0) {
    return true;
  }
  return false;
}

const stopLvlPicturesFormat = (pictures = []) => {
  const orderPictures = pictures 
  const normalPictures = orderPictures.filter(
    (pic) =>
      (pic.image_type === "normal" || pic.image_type === "signature") &&
      pic.picture &&
      pic.picture.url
  );
  const otherImgs = normalPictures
    .filter(
      (picture) =>
        picture.image_type !== "print"
    )
    .map((picture) => ({
      captured_at: picture.captured_at,
      converted_img: picture.picture.url,
      id: picture._id,
      ack_id: picture.ack_id,
      picture_type: "normal",
      picture_obj: "",
      pic_title: !isEmpty(picture.pic_title) ? picture.pic_title : "",
      isNew: false,
      fileType: getFileType(picture.picture.url),
    }));
  return otherImgs;
};

const updatePicSettingsWithBol = (pic_settings = []) => {
  const settings = [...pic_settings];
  const existbolRec = _.find(settings, { pic_code: 'BOL'});
  const nweBolRec = {pic_code: "BOL",mandatory: "false",mandatory_for: [],required_for:"both"};
  if(isEmpty(existbolRec)){
    settings.push({...nweBolRec})
  }
  return settings;
}
const updatePicSettingsWithTender = (pic_settings = []) => {
  const settings = [...pic_settings];
  const existbolRec = _.find(settings, { pic_code: 'TENDER'});
  const nweBolRec = {pic_code: "TENDER",mandatory: "false",mandatory_for: [],required_for:"both"};
  if(isEmpty(existbolRec)){
    settings.push({...nweBolRec})
  }
  return settings;
}

const updatePicSettingsWithAirlineDocument = (pic_settings = []) => {
  const settings = [...pic_settings];
  const existAirlineRec = _.find(settings, { pic_code: 'AIRLINE'});
  const newAirlineRec = {pic_code: "AIRLINE",mandatory: "false",mandatory_for: [],required_for:"both"};
  if(isEmpty(existAirlineRec)){
    settings.push({...newAirlineRec})
  }
  return settings;
}


const blobDownload = (res , fileName , resolve) => {
  res.blob().then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    resolve();
  });
};

// this function is used to format the address received from the api
const getFormattedGeocodeAdress = (result) => {
  // if types of any of park, establishment, point_of_interest, tourist_attraction is present then it the address line 1
  let addressLine1, addressLine2 = '';
  if(_.find(result.address_components, {types: ["park", "establishment", "point_of_interest", "tourist_attraction"]})) {
    addressLine1 = _.get(_.find(result.address_components, {types: ["park", "establishment", "point_of_interest", "tourist_attraction"]}), "long_name", "")
    addressLine2 = _.get(_.find(result.address_components, {types: ["street_number"]}), "long_name", "") + " " + _.get(_.find(result.address_components, {types: ["route"]}), "long_name", "")
  }
  // else plus code (plus code represents no address) is present then it is the address line 1
  else if(_.find(result.address_components, {types: ["plus_code"]})) {
    addressLine1 = ""
    addressLine2 = ""
  }
  else{
    // address line 1 is street number + route
    // address line 2 is locality
    addressLine1 = _.get(_.find(result.address_components, {types: ["street_number"]}), "long_name", "") + " " + _.get(_.find(result.address_components, {types: ["route"]}), "long_name", "")
    if(_.find(result.address_components, {types: ["administrative_area_level_2", "political"]})){
      addressLine2 = _.get(_.find(result.address_components, {types: ["administrative_area_level_2", "political"]}), "long_name", "")
    }
    else if(_.find(result.address_components, {types: ["neighborhood", "political"]})){
      addressLine2 = _.get(_.find(result.address_components, {types: ["neighborhood", "political"]}), "long_name", "")
    }
    else if (_.find(result.address_components, {types: ["locality", "political"]}) ) {
      addressLine2 = _.get(_.find(result.address_components, {types: ["locality", "political"]}), "long_name", "")
    }
  }
  // if addressline 1 is still empty use formatted_address as address line 1
  if(addressLine1.trim() === ""){
    addressLine1 = result.formatted_address.split(",")[0]
  }

  if(addressLine2.trim() === ""){
    addressLine2 = _.get(_.find(result.address_components, {types: ["administrative_area_level_2", "political"]}), "long_name", "")
  }
  const city = _.get(_.find(result.address_components, {types: ["locality", "political"]}), "long_name", "") || _.get(_.find(result.address_components, {types: ["sublocality_level_1", "political"]}), "long_name", "") 
  const state = _.get(_.find(result.address_components, {types: ["administrative_area_level_1", "political"]}), "short_name", "")
  const country = _.get(_.find(result.address_components, {types: ["country", "political"]}), "short_name", "")
  const zip = _.get(_.find(result.address_components, {types: ["postal_code"]}), "long_name", "")
  const coordinates = _.get(result, "geometry.location", {})

  return {
    address_line1 : addressLine1,
    address_line2 : addressLine2,
    city,
    state,
    country,
    coordinates : {lat : coordinates.lat(), lng : coordinates.lng()},
    zipcode : zip
  }
}

const reverseGeoCodeFromLatLng = async ({location,geocoder})=>{
  return new Promise((resolve, reject)=>{
    // check if location has valid  lat and lng and is an object
    if(!!location && _.isObject(location) && !!location.lat && !!location.lng &&
      !isNaN(location.lat) && !isNaN(location.lng)){
      geocoder.geocode({location}, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            resolve(getFormattedGeocodeAdress(results[0]))
          } else {
            reject("No results found");
          }
        } else {
          reject("Geocoder failed due to: " + status);
        }
      });
    }
  })
}


const sortData = (data, key, isNumber = false) => {
  if (isNumber) {
    return data.sort((a, b) => {
      const aNumber = parseInt(a[key].replace(/[^0-9]/g, "")); // get the number part of the string
      const bNumber = parseInt(b[key].replace(/[^0-9]/g, ""));

      if (aNumber === bNumber) return 0;
      if (aNumber > bNumber) return 1;
      if (bNumber < aNumber) return -1;
    });
  } else {
    return data.sort((a, b) => {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  }
};


function checkValidAppointmentTime (pickupPreference , dropPreference ) {
  let errors = [];
  // check if pickup date is greater than drop date
  if (moment(pickupPreference.item_preference).isAfter(moment(dropPreference.item_preference))) {
    errors.push('Pickup date should be lesser than drop date');
  }
  // check if pickup time is greater than drop time
  if (
    pickupPreference.item_option[ 0 ] === 'CUSTOM' &&
    dropPreference.item_option[ 0 ] === 'CUSTOM'
  ) {
    if (
      moment(pickupPreference.start_time).isSame(
        moment(dropPreference.start_time),
      )
    ) {
      errors.push('Pickup and drop time should be different');
    }
    if (
      moment(pickupPreference.start_time).isAfter(
        moment(dropPreference.start_time),
      )
    ) {
      errors.push('Pickup time should be lesser than drop time');
    }
  }
  return errors;
}


  /**
   * 
   * @returns {Promise} A promise that returns an object containing the list of warehouses.
   * @example
   * getWarehouses().then((warehouses) => {
   *  // Do something with the warehouses list
   * });
   */

  const getWarehouses = () => {
    let warehousesList = [];
    return new Promise((resolve, reject) => {
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        warehousesList = result.locations.locations;
      } else {
        alertMessage(result.errors[ 0 ], 'error', 10);
      }
    }).finally(() => {
      resolve(warehousesList);
    })
    });

  };

/**
* Get a list of all accounts using the short form API call.
* @returns {Promise} A promise that returns an object containing the list of accounts.
* @example
\* getAccounts().then((accounts) => {
*  // Do something with the accounts list
* });
*/

const getAccounts = (orgId , whId,skip_wh) => {
  let accountsList = [];
    return new Promise((resolve, reject) => {
    fetchShortFormAccounts(orgId,whId,skip_wh).then((result) => {
      if (result.success) {
        accountsList = result.accounts;
      } else {
        alertMessage(result.errors[ 0 ], 'error', 10);
      }
    }).finally(() => {
      resolve(accountsList);
    }
    );
    });
  };

function getOrganizations (initial, cb, fetchFunc) {
  let inProgress = true;
  let organizations = [];

  return new Promise((resolve, reject) => {
    fetchFunc(1, null)
      .then((result) => {
        if (result.success) {
          organizations = result.organizations.organizations;
          inProgress = false;
          if (cb) {
            cb();
          }
          resolve(organizations);
        } else {
          const errorMessage = result.errors[ 0 ];
          reject(new Error(errorMessage));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

  const selectedWarehouseNames = (warehouseFilter) => {
    if (!warehouseFilter || !warehouseFilter.warehouses || !warehouseFilter.selectedWarehouses) {
      return '';
    }

    const selectedWarehouses = warehouseFilter.warehouses.filter(warehouse => warehouseFilter.selectedWarehouses.includes(warehouse.id));

    if (selectedWarehouses.length === 0) {
      return '';
    }

    const warehouseNames = selectedWarehouses.map(warehouse => warehouse.name);

    return warehouseNames.join(', ');
  }

/**
 * Transforms the specified date and time keys in the given object or array of objects into moment objects.
 * @param {Object|Array} data - The object or array of objects to transform.
 * @param {Object} options - An object containing the options for the transformation.
 * @param {Array<String>} options.dateKeys - The list of keys to transform as dates. Defaults to an empty array.
 * @param {Array<String>} options.timeKeys - The list of keys to transform as times. Defaults to an empty array.
 * @param {String} options.dateFormat - The format of the date values. Defaults to 'YYYY-MM-DD'.
 * @param {String} options.timeFormat - The format of the time values. Defaults to 'HH:mm:ss'.
 * @param {Boolean} options.isMilitaryTime - Indicates whether to use military time format for times. Defaults to false.
 * @returns {Object|Array} - The transformed object or array of objects.
 */
function transformKeysToMoment (data, options) {
  // Set default values for the options if not provided
  const {
    dateKeys = [],
    timeKeys = [],
    dateFormat = 'YYYY-MM-DD',
    timeFormat = 'HH:mm:ss',
    isMilitaryTime = false,
  } = options;

  // Clone the data to avoid modifying the original object
  const cloneData = _.cloneDeep(data);

  // Transforms a value into a moment object if it matches the specified format
  const transformToMoment = (value, format) => {
    if (moment(value, format, true).isValid()) {
      return moment(value, format);
    } else if (isMilitaryTime && moment(value, 'HH:mm', true).isValid()) {
      return moment(value, 'HH:mm');
    }
    return value;
  };

  // Transforms a key-value pair in an object recursively
  const transformObject = (obj) => {
    Object.entries(obj).forEach(([ key, value ]) => {
      if (_.isObject(value)) {
        // If the value is an object, recursively transform it
        obj[ key ] = transformObject(value);
      } else if (dateKeys.includes(key)) {
        // If the key is in the list of date keys, transform it as a date
        obj[ key ] = transformToMoment(value, dateFormat);
      } else if (timeKeys.includes(key)) {
        // If the key is in the list of time keys, transform it as a time
        obj[ key ] = transformToMoment(value, timeFormat);
      }
    });
    return obj;
  };

  // Transform the data based on its type
  if (_.isArray(cloneData)) {
    // If the data is an array, transform each object in it
    return _.map(cloneData, (obj) => transformObject(obj));
  } else {
    // If the data is an object, transform it directly
    return transformObject(cloneData);
  }
}

const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[ key ] || key;
    return { [ newKey ]: obj[ key ] };
  });

  return Object.assign({}, ...keyValues);
}

const isValidTimes = (obj, keys , isMilitaryTime) => {
  let isValid = true;
  const format = isMilitaryTime ? "HH:mm" : "hh:mm A";
  const invalidIndexes = [];
  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      keys.forEach((key) => {
        if (!moment(item[key], format, true).isValid()) {
          invalidIndexes.push(index);
          isValid = false;
        }
      });
    });
  } else {
    keys.forEach((key) => {
      if (!moment(obj[key], format, true).isValid()) {
        isValid = false;
      }
    });
  }

  return { isValid, invalidIndexes };
}

export const dynamicFileDownload = (url, data = {}, filename = "file", method = "POST", headers = {}) => {
  message.loading("Processing File Download", 0);
  return new Promise((resolve, reject) => {
    Axios({
      method,
      url,
      headers: { "Content-Type": "application/json", ...headers },
      data,
      responseType: "blob"
    }).then(response => {
      if(!response) {
        message.destroy();
        reject("Something went wrong");
      }
      if (response.status === 200) {
        if (response.headers[ "content-disposition" ]) {
          filename = response.headers[ "content-disposition" ].split("filename=")[ 1 ].replace(/"/g, "");
        }
        const url = window.URL.createObjectURL(new Blob([ response.data ]));
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        message.destroy();
        message.success("File generated successfully");
        resolve(true);
      } else {
        message.destroy();
        if(response?.data?.errors?.length > 0){
          message.error(response.data.errors.join(','));
        }
        reject(response.data.errors);
      }
    }).catch(error => {
     // reject(error);
     // Handle Axios request errors
     let errorMessage = "Network error occurred. Please try again later.";
     if (error.response) {
      if (error.response.data && error.response.data.errors) {
        // If server responds with {errors: ["message"]}
        errorMessage = error.response.data.errors.join("; "); // Joining errors into a single string
      } else {
        errorMessage = error.response.statusText; // Or use a generic error message
      }
     } else if (error.request) {
       // The request was made but no response was received
       errorMessage = "No response received from server.";
     } else {
       // Something happened in setting up the request that triggered an Error
       errorMessage = error.message;
     }
     message.destroy();
     message.error(errorMessage);
     reject(errorMessage);
    });
  });
};

function removeSpecialCharacterss (text = "") {
  if(typeof text !== "string") { return text; }
  const specialChars = "!\"#$%&'()*+./:;<=>?@[\\]^`{|}~";
  let result = '';

  for (let i = 0; i < text.length; i++) {
    if (!specialChars.includes(text[ i ])) {
      result += text[ i ];
    }
  }

  return result;
}
function removeSpecialCharacters(text = "", allowedSpecialChars = []) {
  if (typeof text !== "string") {
    return text;
  }

  let specialChars = "!\"#$%&'()*+./:;<=>?@[\\]^`{|}~";

  // Remove allowedSpecialChars from specialChars
  allowedSpecialChars.forEach(char => {
    specialChars = specialChars.replace(new RegExp(`\\${char}`, 'g'), '');
  });

  let result = '';

  for (let i = 0; i < text.length; i++) {
    if (!specialChars.includes(text[i])) {
      result += text[i];
    }
  }

  return result;
}

function getOperationalTimings(organizationSettings = {}, warehouse = {} ){
  let { start_time = "", end_time = "" } = warehouse || {};
  if(_.isEmpty(start_time) && _.isEmpty(end_time)) {
    start_time =  organizationSettings?.route_delivery_start_time ? organizationSettings.route_delivery_start_time : "08:00";
    end_time = organizationSettings?.route_delivery_end_time ? organizationSettings.route_delivery_end_time : "20:00";
  }
  return {start_time, end_time};
}

const getCurrentOrderInfo = async (id) => {
  const result = await fetchOrderDetails(id);
  if (result.success) {
    const orders = result.orders || [];
    const customerPreferences = result.orders.appointments || [];
    const preferences = customerPreferences.map((preferenceObj, index) => {
      return {
        s_no: index + 1,
        item_preference: preferenceObj.appt_date,
        item_option: preferenceObj.slots || [],
      };
    });
    const currentOrderInfo = _.find(orders, { id });
    return !isEmpty(currentOrderInfo) && _.isObject(currentOrderInfo)
      ? {
        ...currentOrderInfo,
        preferences: preferences,
      }
      : {};
  } else {
    renderAlertMessage(result.errors)
    return {};
  }
};

const getAvailableDrivers = async (startDate = moment(), endDate = moment().add(1, 'days'), startTime = '00:00', endTime = '23:59', zoneIds, warehouseId) => {
  const startDeliveryTime = moment(`${startDate.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm').format();
  const endDeliveryTime = moment(`${endDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm').format();
  const result = await fetchAvailableDrivers(startDeliveryTime, endDeliveryTime, zoneIds, warehouseId);
  if (result.success) {
    const users = result.users || [];
    return _.sortBy(users, 'employee_code');
  } else {
    throw new Error(result.errors[ 0 ]);
  }
};

const getOrderInfo = async (id) => {
  // Fetch the order details for the specified order ID.
  const preferences =
    {
      s_no: 1,
      item_preference: "",
      item_option: [],
    }
  const result = await fetchOrderDetails(id, "DISPATCHER");

  // Check if the request was successful.
  if (!result.success) {
    // Show an error message.
    throw new Error("Unable to fetch order information.");
  }

  // Get the order information from the response.
  const orders = result.orders || [];
  const customerPreferences = result.orders.appointments || [];

  // Update the preferences array with the customer preferences.
  customerPreferences.forEach((preferenceObj, index) => {
    const preferenceIndex = _.findIndex(preferences, [ "s_no", index + 1 ]);
    if (preferenceIndex >= 0) {
      preferences[ preferenceIndex ].item_preference = preferenceObj.appt_date;
      preferences[ preferenceIndex ].item_option = preferenceObj.slots || [];
    }
  });

  // Get the current order information.
  const currentOrderInfo = _.find(orders, { id });

  // Return the current order information.
  return currentOrderInfo;
};

export const checkIfAppointmenfFilled = (appointment = {}) =>
{
  const  { appt_date, end_time, has_expedite, slots, start_time } = appointment;
  if(!isEmpty(appt_date) || !isEmpty(end_time) || !isEmpty(start_time) || has_expedite === true || slots?.length > 0){
    return true;
  }
  return false;
}
export const isObjHaveValues = (dataObject = {}) =>
{
  const hasValue = _.some(dataObject, function (value) {
    return !isEmpty(value);
  });
  return hasValue
}

const sortArrayOfStrings = (arr = [], givenOrder = [], skipNonExisting = false) => {
  // arranf the given array of strings in given order, if the element
  // not exists in array it will append to the list
  const sortedArr = _.orderBy(arr, (str) => {
    const index = givenOrder.indexOf(str);
    return index === -1 ? Infinity : index;
  });
  if(!skipNonExisting){
    return (sortedArr.filter((str) => givenOrder.includes(str)));
  }
  return sortedArr;
};

function parseAddressComponents (addressComponents) {
  const addressObj = {
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    country: "",
    zipcode: ""
  };

  const locationObj = {
  };
  console.log("addressComponents", addressComponents)
  for (const component of addressComponents) {
    const type = _.get(component, 'types[0]', '');
    const longName = _.get(component, 'long_name', '');
    const shortName = _.get(component, 'short_name', '');

    switch (type) {
      case "street_number":
        addressObj[ "address_line1" ] = longName;
        break;
      case "route":
        addressObj[ "address_line1" ] = addressObj[ "address_line1" ]
          ? addressObj[ "address_line1" ] + " " + longName
          : longName;
        break;
      case "sublocality_level_1":
        addressObj[ "address_line2" ] = longName;
        break;
      case "sublocality_level_2":
        addressObj[ "address_line2" ] = addressObj[ "address_line2" ]
          ? addressObj[ "address_line2" ] + " " + longName
          : longName;
        break;
      case "locality":
        addressObj[ "city" ] = longName;
        break;
      case "administrative_area_level_1":
        addressObj[ "state" ] = longName;
        break;
      case "country":
        addressObj[ "country" ] = longName;
        break;
      case "postal_code":
        addressObj[ "zipcode" ] = longName;
        break;
      default:
        break;
    }
  }

  // If city is empty, copy state to city
  if (!addressObj[ "city" ] && addressObj[ "state" ]) {
    addressObj[ "city" ] = addressObj[ "state" ];
  }

  // If address line 2 exists and address line 1 is empty, move it to address line 1
  if (addressObj[ "address_line2" ] && !addressObj[ "address_line1" ]) {
    addressObj[ "address_line1" ] = addressObj[ "address_line2" ];
    addressObj[ "address_line2" ] = "";
  }

  const result = {
    l_address: { ...addressObj, ...locationObj },
  };

  return result;
}

function toScreenConfigFormat(data) {
  return data.reduce((acc, item) => {
    acc[item.key] = item.width;
    return acc;
  }
  , {});
}

function validateEmail(email){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function removeUnnecessaryCommas (str) {
  const arr = str.split(',').filter(num => num.trim() !== '');
  const result = arr.join(',');
  return result;
}

function metersToOtherMeasurements(meterValue, measurement) {
  let output = 0;
  if (!isNaN(meterValue)) {
      if (measurement == "miles") {
          output = meterValue * 0.00062137;

      } else if (measurement == "feet") {
          output = meterValue * 3.2808;
      } else if (measurement == "inches") {
          output = meterValue * 39.370;
      } else if (measurement == "cm") {
          output = meterValue / 0.01;
      } else if (measurement == "yards") {
          output = meterValue * 1.0936;
      } else if (measurement == "km") {
          output = meterValue / 1000
      }
      return output.toFixed(2) + " " + measurement;
  } else {
      return "";
  }

}

function formatUSPhoneNumber(phoneNumber) {
  const phoneNumberString = !isEmpty(phoneNumber) ? phoneNumber : '';
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
      var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  } else {
      return cleaned;
  }

}

function secondsToHms(d, showHours = true, showMinutes = true, showSeconds = true, showApprox = false) {
  d = Number(d);
  if (d > 0) {
      var totalHrs = Math.floor(d / 3600);
      var days = Math.floor(totalHrs / 24);
      var h = totalHrs % 24;
      var m = Math.floor((d % 3600) / 60);
      if(showMinutes === false && showApprox === true && m > 30 && h !== 0){
        h = h + 1;
      }
      var s = Math.floor((d % 3600) % 60);
      let output = "";
      let daysDisplay = days > 0 ? days + (days == 1 ? " Day " : " Days ") : "";
      let hDisplay = h > 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? " min " : " Mins ") : "";
      let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " Sec") : "";
      if (daysDisplay) {
          output = output + daysDisplay;
      }
      if (showHours) {
          output = output + (days > 0 ? " " : '') + hDisplay;
      }
      if (showMinutes || (showMinutes === false && showHours && h === 0)) {
          output = output + " " + mDisplay;
      }
      if (showSeconds) {
          output = output + " " + sDisplay;
      }
      return output.trim();
  } else {
      return 0;
  }
}

function flashMessages() {
  if ($(".flash-messages-container").find("div").length) {
      var messages_obj = $(".flash-messages-container").find("div");

      $.each(messages_obj, function (key, obj) {
          var msgType = $(obj).attr("class"),
              content = $(obj).html();

          showFlashMessage(msgType, content);

      });
  }
}

function showFlashMessage(msgType, message, shownTime = 0) {
  switch (msgType) {
      case "error":
          toastr.options.timeOut = 600000;
          toastr.options.extendedTimeOut = 150000;
          toastr.options.progressBar = false;
          toastr["error"](message, "Error");
          toastr.options.timeOut = 5000;
          toastr.options.extendedTimeOut = 2000;
          toastr.options.progressBar = true;
          break;
      case "explicit_success":

          toastr.options = {
              "closeButton": true,
              "tapToDismiss": false,
              "positionClass": "toast-top-right"
          };

          toastr.options.timeOut = 600000;
          toastr.options.extendedTimeOut = 150000;
          toastr.options.progressBar = false;
          toastr["success"](message, "");
          toastr.options.timeOut = 5000;
          toastr.options.extendedTimeOut = 2000;
          toastr.options.progressBar = true;

          break;
      case "success":
          toastr["success"](message, "Success");
          break;
      case "info":
          toastr["info"](message, "Info");
          break;
      case "notice":
          toastr["success"](message, "Info");
          break;
      case "warning":
          toastr["warning"](message, "Warning");
          break;
      case "alert":
          toastr["warning"](message, "Warning");
          break;
  }
}

function renderAlertMessage(errors) {
  if (_.isArray(errors) && errors.length > 0) {
     alertMessage(errors.join(','), 'error', 10); 
  } else {
     alertMessage(errors, 'error', 10);
  }
}

function isCurrentTimeGreaterThan(timeZoneId, dateTimeString) {
  // Get current time in the specified timezone
  const currentTime = moment.tz(timeZoneId);

  // Parse the dateTimeString to a moment object in the specified timezone
  const comparisonTime = moment.tz(dateTimeString, 'YYYY-MM-DD HH:mm', timeZoneId);

  // Compare if currentTime is greater than comparisonTime
  return currentTime.isAfter(comparisonTime);
}

/*
function getRouteStartTime(
  whStartTime = "07:30",
  whEndTime = "20:00",
  routeCompletiondate = "2024-07-04T14:16:48.000+00:00"
) {
  const currentTime = moment().format("HH:mm");
  const routeCompletionTime = routeCompletiondate ? moment(routeCompletiondate).format('HH:mm') : currentTime;

  function addMinutes(time, minutes) {
    return moment(time, "HH:mm").add(minutes, "minutes").format("HH:mm");
  }

  if (moment(currentTime, "HH:mm").isAfter(moment(routeCompletionTime, "HH:mm"))) {
    return addMinutes(currentTime, 30);
  } else {
    return addMinutes(routeCompletionTime, 30);
  }
}



*/


function getRouteStartTime(
  whStartTime = "00:00",
  whEndTime = "23:59",
  timeZoneId,
  selectedDate,
  routeCompletiondate = null
) {
 // 6th July 2024 03:30 AM

 // 12 July 2024 03:00 AM

 // 12 July 2024 08:00 PM 

 // 6th July
 // 7th July
   const dateTimeString =  `${selectedDate.format("YYYY-MM-DD")} ${whStartTime}`
   const currentTime = moment.tz(timeZoneId);
   const comparisonTime = moment.tz(dateTimeString, 'YYYY-MM-DD HH:mm', timeZoneId).subtract(30, "minutes");
   const isLessthanCurrent = currentTime.isAfter(comparisonTime)
   const isSameDate = moment.tz(timeZoneId).format("YYYY-MM-DD") ===  moment(selectedDate).format("YYYY-MM-DD");
   const currentTimeValue = isSameDate ? (isLessthanCurrent ?  currentTime :  comparisonTime)  : comparisonTime


  const routeCompletionTime = routeCompletiondate ? moment.tz(routeCompletiondate,timeZoneId): null;

  function addMinutes(time, minutes) {
    return moment(time).add(minutes, "minutes").format("HH:mm");
  }

  if (routeCompletionTime && currentTimeValue.isBefore(routeCompletionTime)) {
    return addMinutes(routeCompletionTime, 30);
  } else {
    return addMinutes(currentTimeValue, 30);
    // if(isLessthanCurrent) {
    //   return addMinutes(currentTimeValue, 30);
    // }

    // return moment(currentTimeValue).format("HH:mm");
  }
}

export const isProdEnvironment = () => {
  const hostname = window.location.hostname;
  if (['app.fleetenable.com', 'app1.fleetenable.com'].includes(hostname)){
    return true
  }
  return false;
}

const getWeightUnits = (params = { account_code: "", accountUnitContext: "" }) => {
  const { account_code, accountUnitContext } = params
  const defaultWeightUnits = "lbs";

  if (!_.isEmpty(account_code) && _.has(accountUnitContext, account_code)) {
    return accountUnitContext[account_code];
  }

  return defaultWeightUnits;
};

const convertToKilograms = (lbsValue, withLabel = false, afterDecimal = "") => {
  const kilograms = lbsValue * 0.453592;
  if (withLabel) {
    return `${(_.round(kilograms, 2))} kgs`;
  } else {
    if (!_.isEmpty(afterDecimal)) {
      return (_.round(kilograms, afterDecimal));
    }
    return kilograms;
  }
};


const convertToPounds = (kgsValue, withLabel = true, afterDecimal = "") => {
  const pounds = kgsValue * 2.20462;
  if (withLabel) {
    return `${(_.round(pounds, 2))} lbs`;
  } else {
    if (!_.isEmpty(afterDecimal)) {
      return (_.round(pounds, afterDecimal));
    }
    return pounds;

  }
};


const convertToUnitsWithBothLabels = (value, unitType = "kgs") => {

  if (value && unitType == "kgs") {
    return `${value} lbs / ${convertToKilograms(value)} kgs`;
  }

  if (value && unitType == "lbs") {
    return `${convertToPounds(value)} / ${value} kgs`;
  }

}

const getWeightWithBothUnits = (data, account_code, accountWeightUnits) => {
  if (account_code) {

    const preferredUnit = accountWeightUnits?.[account_code] || 'lbs';

    if (preferredUnit === 'kgs') {
      const kgs = parseFloat(data);
      const lbs = kgs * 2.20462; // Convert kgs to lbs
      return `${_.round(lbs, 2)} lbs / ${_.round(kgs, 2)} kg`;
    } else {
      const lbs = parseFloat(data);
      const kgs = lbs / 2.20462; // Convert lbs to kgs
      return `${_.round(lbs, 2)} lbs / ${_.round(kgs, 2)} kg`;
    }

  }

  return data;
}

// Common function to format weight in lbs and kgs
const getFormattedWeight = (lbs = "", kgs = "", toRoundDecimals = 2) => {
    const weightInLbs = lbs !== "N/A" ? `${Number(lbs).toFixed(toRoundDecimals)} lbs` : "";
    const weightInKgs = kgs !== "N/A" ? `${Number(kgs).toFixed(toRoundDecimals)} kgs` : "";
    return `${weightInLbs}${weightInLbs && weightInKgs ? " / " : ""}${weightInKgs}`;
};


const formatPickupDeliveryCompanyNames = (pickupLocation = "", deliveryLocation = "") => {
  // Handle cases where locations are null/undefined
  if (!pickupLocation && !deliveryLocation) {
    return '';
  }

  // Safely access company names with optional chaining and default to empty string
  const pickupName = pickupLocation?.company_name?.trim() || '';
  const deliveryName = deliveryLocation?.company_name?.trim() || '';

  // If both names exist, combine them with P: and D: prefixes
  if (pickupName && deliveryName) {
    return `P: ${pickupName} - D: ${deliveryName}`;
  }

  // If only pickup name exists
  if (pickupName) {
    return `P: ${pickupName}`;
  }

  // If only delivery name exists
  if (deliveryName) {
    return `D: ${deliveryName}`;
  }

  // If neither exists
  return '';
};


export {
  formatPickupDeliveryCompanyNames,
  renderWeightWithUnitCheckedHeading,
  getWeightWithBothUnits,
  base64ToPDF,
  clearSession,
  driverNameFromRoute,
  formatFullName,
  formateTwoDates,
  formateTwoUTCDates,
  formateTwoUTCTimes,
  drivercodeFromRoute,
  formatServiceDuration,
  getDriverName,
  validateMobile,
  renderWeightHeading,
  formatTime,
  formatDistance,
  confirmationPopup,
  generateAccessMessage,
  haveFreePackage,
  convertToTags,
  limitString,
  formatByTimeConfig,
  dummyRequest,
  handlePublicDownloads,
  checkIfRequiredFieldsAreFilled,
  renderAddress,
  checkIfValidAddress,
  getDriverFullDisplayName,
  updatePicSettingsWithTender,
  updatePicSettingsWithAirlineDocument,
  getDriverDisplayName,
  stopLvlPicturesFormat,
  updatePicSettingsWithBol,
  blobDownload,
  getFormattedGeocodeAdress,
  reverseGeoCodeFromLatLng,
  sortData,
  checkValidAppointmentTime,
  getWarehouses,
  getAccounts,
  getOrganizations,
  transformKeysToMoment,
  selectedWarehouseNames,
  renameKeys,
  isValidTimes,
  removeSpecialCharacters,
  getOperationalTimings,
  getCurrentOrderInfo,
  getAvailableDrivers,
  getOrderInfo,
  parseAddressComponents,
  sortArrayOfStrings,
  responseTOCsv,
  base64ToExcel,
  toScreenConfigFormat,
  formateTwoDateDays,
  validateEmail,
  removeUnnecessaryCommas,
  metersToOtherMeasurements,
  formatUSPhoneNumber,
  secondsToHms,
  flashMessages,
  showFlashMessage,
  renderAlertMessage,
  responseToPDF,
  printPDF,
  formatSingleDay,
  getRouteStartTime,
  base64ToIIF,
  getWeightUnits,
  convertToUnitsWithBothLabels,
  convertToKilograms,
  convertToPounds,
  getFormattedWeight
};
