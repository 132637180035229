import React, { Fragment, useMemo } from "react";
import { fetchAvailableDrivers } from "../../api/PreplanApi";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import _ from "lodash";
import moment from "moment"
import { Modal, Button, List, Tooltip, Icon } from 'antd';
import {
  Col,
  Row,
  Tag,
  Typography,
  Link,
  Popover
} from "../../common/UIComponents";
import { checkAccessExistance, checkServiceExistance, formatByTimeConfig } from "../../helpers/common";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import TypeOfOrder from "../orders/TypeOfOrder";
import { isEmpty } from "../../common/Common";
import Copyable from "../common/Copyable";
import AppConfig from "../../config/AppConfig";
import OrderAppointments from "../orders/OrderAppointments";
import { getAppointmentTime, getWindowTime } from "../../helpers/orders";
import AddressInfo from "../common/AddressInfo";
const { Text } = Typography;

const { orderTypeColorMapping } = AppConfig

export const getAvailableDriversInfo = (startDate, endDate, startTime, endTime, zone_ids="", warehouse_id=""
) => {
  const startDeliveryTime = moment(`${moment(startDate).format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm').format();
  const engDeliveryTime = moment(`${moment(endDate).format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm').format();
  return new Promise((resolve , reject) => {
    fetchAvailableDrivers(startDeliveryTime, engDeliveryTime, zone_ids, warehouse_id)
      .then((result) => {
        if (result.success) {
          const drivers = result.users || [];
          resolve({
            drivers: _.sortBy(drivers, 'employee_code'),
          });
        } else {
          reject();
          alertMessage(result.errors[ 0 ], 'error', 10);
        }
      });
  }
  );
};

// This function takes in an array of driver allocation errors and builds a description string from them.
// It returns a string with descriptions separated by new lines

export const renderDriverAllocationErrors = (allErrors) => {
  const assignedToOtherRoute = allErrors.find((error) => error.type === "assigned_to_other_route");
  const invalidAppointment = allErrors.find((error) => error.type === "invalid_appointment");
  const invalidLocation = allErrors.find((error) => error.type === "invalid_location");

  const buildDescription = (error) => {
    switch (error.type) {
      case "assigned_to_other_route":
        return error.orders.map((order) => {
          const [ orderNumber, routeNumber, date ] = order.split("-");
          return `The order ${orderNumber} is assigned to route ${routeNumber} on ${moment(date, "MM/DD/YY").format("Do MMMM YYYY")} \n`; 
        })
      case "invalid_appointment":
        return `The orders ${error.orders.join(", ")} having invalid appointments`;
      case "invalid_location":
        return `The orders ${error.orders.join(", ")} having invalid address`;
      default:
        return "";
    }
  };

  let description = '';
  if (assignedToOtherRoute && assignedToOtherRoute.orders.length > 0) {
    description += buildDescription(assignedToOtherRoute);
  }
  if (invalidAppointment && invalidAppointment.orders.length > 0) {
    if(description.length){
      // add a new line if there is already a description
      description += " /n ";
    }
    description += buildDescription(invalidAppointment);
  }
  if (invalidLocation && invalidLocation.orders.length > 0) {
    if(description.length){
      // add a new line if there is already a description
      description += " /n ";
    }
    description += buildDescription(invalidLocation);
  }

  return description;
};


export const renderOrderNumTypePreplan = (data, record, showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink = false, isInAggrid = false, gotoOrderDetails, showEditIcon = false, showStatusColor = false, statusColorContext) => {
  const textColor = showTriggerEdit && record.status === "EXCEPTION" ? "textRed" : "";

  const renderTypeOfOrderTag = () => (
    record?.type_of_order && (
      <div style={{ textAlign: 'center' }} className="type-of-order-tag-container">
        <TypeOfOrder
          order={record}
          orderTypeKey="type_of_order"
          relatedOrderKey="related_order"
          placement="topLeft"
          showBadge={true}
          isInAggrid={true}
        />
      </div>
    )
  );

  let foundObj = null;
  if (showStatusColor && statusColorContext) {
    const colorArr = statusColorContext || [];
    const incomingColor = !_.isEmpty(record?.color_tags) && record?.color_tags[0];
    foundObj = colorArr.find((e) => e.code === incomingColor.code);
  }

  const renderOrderLink = () => (
    record.status === "EXCEPTION" ?
      <>
        <Link
          onClick={() => handleEditOrder(record.id)}
          className={textColor}
          style={{ textDecorationColor: foundObj ? getContrastColor(foundObj?.color) : 'black' }}
        >
          {colorTagToolTip(foundObj, data)}
        </Link>
          &nbsp;&nbsp;
          <Popover
            title="Exception message"
            content={!isEmpty(record.exception_message) ? record.exception_message : "Reason Not Available"}
            overlayClassName="popoverWidth"
          >
            <Icon type="warning" theme="filled" style={{ color: "red" }} />
          </Popover>
        </>
      :
      <Link
        onClick={() => handleEditOrder(record.id)}
        className={textColor}
        style={{ textDecorationColor: foundObj ? getContrastColor(foundObj?.color) : 'black' }}
      >
        {colorTagToolTip(foundObj, data)}
      </Link>
  );

  const defaultRender = () => (
    <Row type="flex" align="middle" justify="space-around">
      <Col><Text className={textColor}>{data}</Text></Col>
      <Col>{renderTypeOfOrderTag()}</Col>
    </Row>
  );

  if (!checkServiceExistance("COS")) {
    return defaultRender();
  }

  if (showTriggerEdit) {
    return (
      <Row type="flex" justify="space-between" align="middle" className={isInAggrid ? "type-of-order-tag-container" : ""}>
        <Col>{renderTypeOfOrderTag()}</Col>
        <Col>{renderOrderLink()}</Col>
        <Col><Copyable text={data} /></Col>
      </Row>
    );
  }

  if (showDetailsLink !== false) {
    return (
      <Row type="flex" align="middle" gutter={8}>
        <Col>
          <div style={{ textAlign: 'center' }} className="type-of-order-tag-container">
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
              isInAggrid={true}
            />
          </div>
        </Col>
        <Col>
          {
            gotoOrderDetails ?
              <Link onClick={() => { gotoOrderDetails(record.id); }} style={{ textDecorationColor: foundObj ? getContrastColor(foundObj?.color) : 'inherit' }} >
                <span
                  className={showStatusColor ? "statusColored" : null}
                  style={colorStatusStyle(foundObj)}
                >
                  {colorTagToolTip(foundObj, data)}
                </span>
              </Link>
              :
              <CustomerOrderDetails
                order_no={data}
                order={{
                  id: record.id,
                  customer_order_number: data,
                }}
                showEdit={showEditIcon && !['PENDING', 'REJECTED'].includes(record.status) ? true : false}
                editClick={() => handleEditOrder(record.id)}
                refreshCallback={refreshCallback}
              />
          }
        </Col>
        {gotoOrderDetails && <Col>
          <Copyable text={data} />
        </Col>}
      </Row>
    );
  };

  return defaultRender();
};


export const renderOrderNumType = (data, record, showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink = false, isInAggrid = false, gotoOrderDetails, showEditIcon = false, showStatusColor = false, statusColorContext) => {
  const textColor = showTriggerEdit && record.status === "EXCEPTION" ? "textRed" : "";

  const renderTypeOfOrderTag = () => (
    record?.type_of_order && (
      <div style={{ textAlign: 'center' }} className="type-of-order-tag-container">
        <TypeOfOrder
          order={record}
          orderTypeKey="type_of_order"
          relatedOrderKey="related_order"
          placement="topLeft"
          showBadge={true}
          isInAggrid={true}
        />
      </div>
    )
  );

  let foundObj = null;
  if (showStatusColor && statusColorContext) {
    const colorArr = statusColorContext || [];
    foundObj = colorArr.find((e) => e.code === record?.statusColorCode);
  }


  const renderOrderLink = () => (
    <Link
      onClick={ () => handleEditOrder(record.id) }
      className={ textColor }
    >
      { record.status === "EXCEPTION" ? (
        <Popover
          title="Exception message"
          content={ !isEmpty(record.exception_message) ? record.exception_message : "Reason Not Available" }
          overlayClassName="popoverWidth"
        >
          { data }
        </Popover>
      ) : data }
    </Link>
  );

  const defaultRender = () => (
    <Row type="flex" align="middle" justify="space-around">
      <Col><Text className={ textColor }>{ data }</Text></Col>
      <Col>{ renderTypeOfOrderTag() }</Col>
    </Row>
  );

  if (!checkServiceExistance("COS")) {
    return defaultRender();
  }

  if (showTriggerEdit) {
    return (
      <Row type="flex" justify="space-between" align="middle" className={ isInAggrid ? "type-of-order-tag-container" : "" }>
        <Col>{ renderTypeOfOrderTag() }</Col>
        <Col>{ renderOrderLink() }</Col>
        <Col><Copyable text={ data } /></Col>
      </Row>
    );
  }

  if (showDetailsLink !== false) {
    return (
      <Row type="flex" align="middle" gutter={ 8 }>
        <Col>
          <div style={{ textAlign: 'center' }} className="type-of-order-tag-container">
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
              isInAggrid={true}
            />
          </div>
        </Col>
        <Col>
         {
          gotoOrderDetails ?
              <Link onClick={() => { gotoOrderDetails(record.id); }} style={{ textDecorationColor: foundObj || true ? getContrastColor('#9C1EE9') : 'inherit' }} >
                <span
                  className={showStatusColor ? "statusColored" : null}
                  style={{
                    backgroundColor: showStatusColor
                      ? (foundObj ? foundObj.color : '#9C1EE9')
                      : 'none',
                    color: foundObj || true ? getContrastColor('#9C1EE9') : 'inherit'
                  }}
                >
                  {data}
                </span>
              </Link>
          :
          <CustomerOrderDetails
              order_no={ data }
              order={ {
                id: record.id,
                customer_order_number: data,
              } }
              showEdit={ showEditIcon && ![ 'PENDING', 'REJECTED' ].includes(record.status) ? true : false }
              editClick={ () => handleEditOrder(record.id) }
              refreshCallback={ refreshCallback }
            />
         }
        </Col>
        {  gotoOrderDetails  && <Col>
          <Copyable text={ data } />
        </Col> }
      </Row>
    );
  }; 

  return defaultRender();
};



export function defaultSearchOrdersfilter(props) {
  return {
    fromDate: props.startTime ? props.startTime : moment(),
    toDate: props.endTime ? props.endTime : moment().add(2, "day"),
    filter: "",
    search_order_token: "",
    sortBy: "none",
    sortByType: "descend",
    account_codes: [],
    warehouse_id: props.showRouteAssigner ? props.warehouse_id : !_.isEmpty(props.route.warehouse_id) ? props.route.warehouse_id : '',
    search_key_type: "",
    search_key_value: "",
    [I18n.t("order.filters.schedule_orders.type")]: I18n.t(
      "order.filters.schedule_orders.scheduled_key"
    ),
    zone_ids: [],
    // created_by : "",
    // search_type: 'EQUALS'
  }
}

export function defaultSearchRecoveryfilter(props) {
  return {
    freight_forwarder: '',
    dateSearchBy: 'recovery_eta',
    dateSearchValue:  { 
      fromDate: props.startTime ? moment(props.startTime) : moment(), 
      toDate: props.endTime ? moment(props.endTime) : moment().add(6, "day") 
    },
    account_code: '',
    sortBy: "none",
    sortByType: "descend",
    [I18n.t('recoveries.schedule_times.type')]: props.scheduled_type
      ? props.scheduled_type
      : I18n.t("recoveries.schedule_times.both_key"),
    zone_ids: [],
  }
}

export function defaultSearchReleaseFilter(props, defaultStatus) {
  return {
    // warehouse_id: "ALL",
    account_code: "",
    sortBy: "none",
    sortByType: "descend",
    zone_ids: [],
    currentStatus: defaultStatus,
    dateSearchBy: "scheduled_release_date",
    dateSearchValue: { 
      fromDate: props.startTime ? moment(props.startTime) : moment(), 
      toDate: props.endTime ? moment(props.endTime) : moment().add(6, "day") 
    },
    [I18n.t("recoveries.schedule_times.type")]: props.scheduled_type ? props.scheduled_type : I18n.t("recoveries.schedule_times.both_key"),
    release_type: props?.reqFrom === 'dispatch' ? I18n.t('releases.release_type.options.OTHER_KEY') : I18n.t('releases.release_type.options.BOTH_KEY') 
  }
}

export function defaultSearchLHFilter(props, selectedWarehouses, destinationPoint) {
  return (
  {
    dateSearchValue: { 
      fromDate: props.startTime ? moment(props.startTime) : moment(), 
      toDate: props.endTime ? moment(props.endTime) : moment().add(6, "day") 
    },
    account_code: '',
    sortBy: "none",
    sortByType: "descend",
    startingPoint: Array.isArray(selectedWarehouses) ? selectedWarehouses[ 0 ] : selectedWarehouses,
    destinationPoint,
    warehouse_id: Array.isArray(selectedWarehouses) ? selectedWarehouses[ 0 ] : selectedWarehouses,
  })
}

export function defaultARChequefilter(props) {
  return {
    dateSearchBy: 'payment_date',
    dateSearchValue:  { 
      fromDate: null, 
      toDate: null 
    },
    batch_no: '',
    check_ach_no: ''
  }
}

export function defaultTransactionfilter(props) {
  return {
    search_by_type: 'INVOICE',
    search_value: '',
    sortBy: "none",
    sortByType: "ascend",
  }
}

export function defaultBatchHomeScreenFilter(props) {
  return {
    dateSearchValue:  {
      formattedFrom_date: moment().subtract(6, "day"),
      formattedTo_date:  moment(),
      sortBy: "none",
      sortByType: "ascend",
  }
}
}

export function defaultManageBatchFilter(props) {
  return {
    from_date: moment().subtract(29, "day"),
    to_date: moment(),
    account_code: "",
    status: "BOTH",
    sortBy: "none",
    sortByType: "descend",
  };
}

export function defaultCreditNotesFilter(props) {
  return {
    fromDate: moment().subtract(29, "day"),
    toDate: moment(),
    account_id:"",
    status: "BOTH",
    category_type: "ALL",
    sortBy: "none",
    sortByType: "descend",
    search_key_type: "",
    search_value: "",
    search_type: "EQUALS",
  }
}


export const showRouteAssignmentModal = ({
  orderNumbers = [],
  route = {},
  navigateToRoute = () => {},
  closeModal = () => {},
}) => {
  // Helper to format the driver's name or use employee code if present
  const getDriverInfo = (driver) => {
    return !isEmpty(driver.employee_code)
      ? driver.employee_code
      : formatFullName(driver);
  };

  // Helper to handle navigation and cleanup
  const handleNavigate = () => {
    navigateToRoute(route);
    closeModal(); // Assuming closeModal is a function that calls Modal.destroyAll()
  };

  Modal.info({
    title: 'Route Assignment Confirmation',
    content: (
      <div>
        { route?.primary_driver && (
        <>
        <p>
          The following orders have been successfully assigned to:
        </p>
          <p>
            <strong>Route #: {getDriverInfo(route?.primary_driver)}</strong>
          </p>
        </>
        )}
        <p>
          the following orders are assigned:
        </p>
        <OrderNumberList data={ orderNumbers } maxDisplay={ 5 } />
        { checkAccessExistance('DISPATCH') && <div style={ { marginTop: '16px' } }>
          <Row type="flex" gutter={8}>
            <Col>
          <Button type="primary" onClick={ handleNavigate } icon="eye">
            View Assigned Route
          </Button>
              </Col>
            <Col>
          <Button onClick={ closeModal } type="danger">
            Close
          </Button>
            </Col>
          </Row>
        </div>
        }
      </div>
    ),
    onOk () {
      closeModal();
    },
    okText: 'Close',
    width: 520,
    okButtonProps: { style: { display: 'none' } }, // Hide default OK button if not needed
    onCancel () {
      closeModal();
    }
  });
};

export const OrderNumberList = ({ data, maxDisplay = 2 }) => {
  const displayedOrders = data.slice(0, maxDisplay).map(orderNumber => (
    <Col key={ orderNumber } style={ { marginRight: 8 } }>
      { orderNumber }
      {/* Assuming Copyable is a component that you have defined elsewhere */ }
      <Copyable text={ orderNumber } />
    </Col>
  ));

  const remainingOrders = data.length > maxDisplay ? (
    <Col>
      <strong>... { data.length - maxDisplay } more</strong>
    </Col>
  ) : null;

  const content = (
    <div>
      { data.map(orderNumber => (
        <div key={ orderNumber }>
          { orderNumber }
          <Copyable text={ orderNumber } />
        </div>
      )) }
    </div>
  );

  if(data.length > maxDisplay) {
  return (
    <Popover content={ content } title="Customer Orders" overlayStyle={ { width: 200 } }>
      <Row type="flex" justify="start" align="middle">
        { displayedOrders }
        { remainingOrders }
      </Row>
    </Popover>
  );
  }
  return (
    <Row type="flex" justify="start" align="middle">
      { displayedOrders }
    </Row>
  )
};

export const AppointmentDisplay = (props) => {
  const { data , hideAppointmentdate = false , organizationSettings, showPDLabels, isMultiStop = false } = props;
  const allAppointments = _.get(data, "appointments", []);
  const timezone = _.get(data, "tz_short_form", "");
  const orderType = _.get(data, "type_of_order", "");
  const confirmedAppointments = allAppointments.filter(
    (appointment) => appointment.confirmed
  );

  if (data.appointments.length === 0) {
    return (
      <div className="no-appointments">
        <p>No appointments scheduled for this order.</p>
      </div>
    );
  }

  return allAppointments.length > 0 && (
    <Popover
      size="small"
      content={
        <div className="table-contact-info width500 fontsize12">
          <OrderAppointments
            appointments={ allAppointments }
            timezone={ timezone }
            showPDLabels={showPDLabels}
            isMultiStop={isMultiStop}
          />
        </div>
      }
      title={ I18n.t("appointmentForm.preference_title") }
    >
      <span>
        <u className="appointmentText">
          {isMultiStop ? `${allAppointments.length} Appointments` : getAppointmentTime(allAppointments, organizationSettings, hideAppointmentdate, orderType)}
        </u>
      </span>
    </Popover>
  );
};

export const WindowTimeDisplay = (props) => {
  const { data ,hideAppointmentdate = false, organizationSettings, showPDLabels, isMultiStop = false } = props;
  const allAppointments = _.get(data, "appointments", []);
  const timezone = _.get(data, "tz_short_form", "");
  const orderType = _.get(data, "type_of_order", "");
  const isMilitaryTime = organizationSettings.is_military_time === "true"

  if (data.appointments.length === 0 || _.isEmpty(data.appointments[0].start_window_time)) {
    return null
  }

  return allAppointments.length > 0 && (
    <Popover
      size="small"
      content={
        <div className="table-contact-info width500 fontsize12">
          {
            allAppointments.map((appointment, index) => (
              <Row key={appointment.slot_name}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row>
                    <Col sm={24} xs={24} md={24} lg={24}>
                      <div className="smallContent">
                        <span>
                          {isMultiStop ? <strong>{`Stop ${index + 1}- `}</strong> : ''}{showPDLabels === true ? `${appointment.type_of_loc}: ` : ''}{appointment.start_window_time
                            ? formatByTimeConfig(moment(appointment.start_window_time), isMilitaryTime, "DD MMM HH:mm", "DD MMM hh:mm A") : "NA"}{" "}
                          -{" "}
                          {appointment.end_window_time
                            ? formatByTimeConfig(moment(appointment.end_window_time), isMilitaryTime, "HH:mm", "hh:mm A") : "NA"}
                        </span>{" "}
                        <strong>{timezone}</strong>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))
          }
        </div>
      }
      title={"Window Time"}
    >
      <span>
        <u className="appointmentText">
          {isMultiStop ? `${allAppointments.length}` : getWindowTime(allAppointments, organizationSettings, hideAppointmentdate, orderType)}
        </u>
      </span>
    </Popover>
  );
};

const createAddressPopover = (address, titleKey, defaultTitle) => (
  <Popover
    title={address?.city ? I18n.t(titleKey) : I18n.t(defaultTitle)}
    content={<AddressInfo address={address} />}
    overlayStyle={{ width: 200 }}
  >
    {address && address.city
      ? `${address.location_code ? address.location_code : address.city}`
      : "NA"}
  </Popover>
);

export const fetchLocAddress = (data, type) => useMemo(() => {
  const sourcePoint =
    data?.origin && Object.keys(data.origin).length !== 0
      ? data.origin
      : data.warehouse_address;
  const destinationPoint =
    data?.destination && Object.keys(data.destination).length !== 0
      ? data.destination
      : data.customer_address;

  const pickUpAddress = createAddressPopover(sourcePoint, "general.origin_location", "general.warehouse_address");
  const dropAddress = createAddressPopover(destinationPoint, "general.destination_location", "general.customer_address");

  if (type === "origin") {
    if (["R", "TR"].includes(data.type_of_order)) {
      return dropAddress;
    }
    return pickUpAddress;
  }
  if (["R", "TR"].includes(data.type_of_order)) {
    return pickUpAddress;
  }
  return dropAddress;
}, [data]);

export const getAddress = (record, key) => {
  const addressKeys = [
    "address_line1",
    "address_line2",
    "city",
    "state",
    "country",
    "zipcode",
  ];
  if (!["T", "LH"].includes(record.type_of_order)) {
    const type = ["R", "TR"].includes(record.type_of_order)
      ? "P"
      : ["D", "TD"].includes(record.type_of_order)
      ? "D"
      : "";
    let address;
    if (key) {
      address =
        key === "pickup"
          ? _.get(record, "origin", {})
          : _.get(record, "destination", {});
    } else {
      address = _.get(record, "customer_address", {});
    }

    if (_.isEmpty(address)) {
      return "";
    }

    const filteredValues = addressKeys
      .map((e) => address[e])
      .filter((e) => !_.isEmpty(e));

    return `${filteredValues.join(", ")}`;
  } else {
    const address =
      key === "pickup"
        ? _.get(record, "origin", {})
        : _.get(record, "destination", {});

    if (_.isEmpty(address)) {
      return "";
    }

    const filteredValues = addressKeys
      .map((e) => address[e])
      .filter((e) => !_.isEmpty(e));

    return `${filteredValues.join(", ")}`;
  }
};

const processLocation = (x, locationType) => {
  return _.merge({}, _.get(x, `${locationType}.l_address`, {}), {
    status: _.get(x, `${locationType}.status`),
    company_name: _.get(x, `${locationType}.company_name`),
    level_of_service: _.get(x, `${locationType}.level_of_service`, "None"),
    location_id: _.get(x, `${locationType}.id`),
    service_duration: _.get(x, `${locationType}.service_duration`),
    driver_name: _.get(x, `${locationType}.driver_name`),
    l_type: _.get(x, `${locationType}.l_type`),
    location_partial_match:
      _.get(x, `${locationType}.location_partial_match`, false) || false,
  });
};

export const getBillingAddress = (record = {}, key) => {
  const locationType = key === "pickup" ? "origin" : "destination";
  const location = processLocation(record, locationType);

  const addressKeys = [
    "address_line1",
    "address_line2",
    "city",
    "state",
    "country",
    "zipcode",
  ];

  if (_.isEmpty(record)) {
    return "";
  }

  const filteredValues = addressKeys
    .map((e) => location[e]) 
    .filter((e) => !_.isEmpty(e));

  return `${filteredValues.join(", ")}`;
};

export const getBillingDriver = (record = {}, key = "") => {
  if (!record || typeof record !== "object") {
    return "NA"; 
  }

  const locationType = key === "pickup" ? "PICKUP" : "DELIVERY";

  const driver_info =
    Array.isArray(record.drivers_info) && record?.drivers_info?.length > 0
      ? record.drivers_info.find(
          (rec) => rec?.type_of_location === locationType
        )
      : null;

  const driver_code = driver_info?.driver_code || "NA";
  return driver_code;
};

//handeling billing Driver
export const getNotesDisplay = (record = {}) => {
  const { driver_notes_info = [], type_of_order = "" } = record;
  const isMultiLocation = ["T"].includes(type_of_order);

  const getNotesMsg = (notes) => {
    if (!notes) return "NA";
    const notesStr = String(notes);
    if (notesStr.length > 35) {
      return (
        <Popover
          content={
            <div className="table-contact-info">
              <span>{notesStr}</span>
            </div>
          }
          title="Driver Notes"
          overlayStyle={{ maxWidth: "350px" }}
        >
          <u className="text-decoration-dashed">{notesStr.substring(0, 35)}...</u>
        </Popover>
      );
    } else {
      return notesStr;
    }
  };

  if (!isMultiLocation) {
    // Single note case
    const note = driver_notes_info[0] || {};
    const notes = note?.notes;
    const displayedNote = !notes || String(notes).trim() === "" || notes === "NA"
        ? "NA"
        : (
            <>
          {note.driver_code || "NA"} - {getNotesMsg(notes)}
            </>
          );    
    return displayedNote;
  } else {
    // Multiple notes case
    let isPickup = true;
    return driver_notes_info
      .sort((a, b) => {
        const prefixA = a.type_of_loc === "PICKUP" ? "P" : "D";
        const prefixB = b.type_of_loc === "PICKUP" ? "P" : "D";

        if (prefixA === "P" && prefixB === "D") return -1;
        if (prefixA === "D" && prefixB === "P") return 1;
        return 0; 
      })
      .map((note) => {
        let prefix;

        // Check if type_of_loc is null
        if (note.type_of_loc === null) {
          // Alternate between "P" and "D"
          prefix = isPickup ? "P" : "D";
          isPickup = !isPickup; // Toggle between true (P) and false (D)
        } else {
          // Normal case
          prefix = note.type_of_loc === "PICKUP" ? "P" : "D";
        }

        const notes = note?.notes;
        const displayedNote = (
          <>
            <strong>{prefix}:</strong>{" "}
            {!notes || String(notes).trim() === "" || notes === "NA" ? (
              "NA"
            ) : (
              <>
                  {note.driver_code || "NA"} - {getNotesMsg(notes)}
              </>
            )}
          </>
        );
        return <div>{displayedNote}</div>;
      });
  }
};


export const getAddressForPreplanCol = (record, key) => {
  const addressKeys = [
    "address_line1",
    "address_line2",
    "city",
    "state",
    "country",
    "zipcode",
  ];
  if (!["T", "LH"].includes(record.type_of_order)) {
    const type = ["R", "TR"].includes(record.type_of_order)
      ? "P"
      : ["D", "TD"].includes(record.type_of_order)
      ? "D"
      : "";
    let address;
    if (key) {
      address =
        key === "pickup"
          ? _.get(record, "locations[0].l_address", {})
          : _.get(record, "locations[0].l_address", {});
    } else {
      address = _.get(record, "customer_address", {});
    }

    if (_.isEmpty(address)) {
      return "";
    }

    const filteredValues = addressKeys
      .map((e) => address[e])
      .filter((e) => !_.isEmpty(e));

    return `${filteredValues.join(", ")}`;
  } else {
    const address =
      key === "pickup"
        ? _.get(record, "locations[0].l_address", {})
        : _.get(record, "locations[1].l_address", {});

    if (_.isEmpty(address)) {
      return "";
    }

    const filteredValues = addressKeys
      .map((e) => address[e])
      .filter((e) => !_.isEmpty(e));

    return `${filteredValues.join(", ")}`;
  }
};

export const fetchAddress = (data, type) => useMemo(() => {
  const sourcePoint =
    data?.origin && Object.keys(data.origin).length !== 0
      ? _.get(data.origin, "l_address", {}) 
      : data.warehouse_address;
  const destinationPoint =
    data?.destination && Object.keys(data.destination).length !== 0
      ? _.get(data.destination, "l_address", {}) 
      : data.customer_address;

  const pickUpAddress = createAddressPopover(sourcePoint, "general.origin_location", "general.warehouse_address");
  const dropAddress = createAddressPopover(destinationPoint, "general.destination_location", "general.customer_address");

  if (type === "origin") {
    if (["R", "TR"].includes(data.type_of_order)) {
      return dropAddress;
    }
    return pickUpAddress;
  }
  if (["R", "TR"].includes(data.type_of_order)) {
    return pickUpAddress;
  }
  return dropAddress;
}, [data]);

// export const ZonesDisplay = (data) => useMemo(() => {
//   const content = _.isEmpty(data.order_zone_name)
//     ? "NA"
//     : _.join(data.order_zone_name, ", ");

//   const title = _.isEmpty(data.order_zone_name)
//     ? "NA"
//     : `${data.order_zone_name.length > 1
//       ? `${_.first(data.order_zone_name)} (+${data.order_zone_name.length - 1})`
//       : _.first(data.order_zone_name)
//     }`;

//   return (
//     <Popover
//       title="Zones List"
//       placement="topLeft"
//       content={ <div>{ content }</div> }
//       overlayStyle={ { width: 250 } }
//     >
//       { title }
//     </Popover>
//   );
// }, [ data ]);



export const ZonesDisplay = ({ data }) => useMemo(() => {
  
  const content = _.isEmpty(data.order_zone_name)
    ? "NA"
    : typeof data.order_zone_name[0] !== 'object'
      ? _.join(data.order_zone_name, ", ")
      : _.join(data.order_zone_name.map((e) => {
        return e.zone_name
      }),", ");

  const title = _.isEmpty(data.order_zone_name)
    ? "NA"
    : `${data.order_zone_name.length > 1
      ? `${_.isUndefined(_.first(data.order_zone_name).zone_name) ? _.first(data.order_zone_name) : _.first(data.order_zone_name).zone_name } (+${data.order_zone_name.length - 1})`
      : _.isUndefined(_.first(data.order_zone_name).zone_name)
        ? _.first(data.order_zone_name) : _.first(data.order_zone_name).zone_name
    }`;

// export const ZonesDisplay = ({ data }) => useMemo(() => {
//   const content = _.isEmpty(data.order_zone_name)
//     ? "NA"
//     : _.join(data.order_zone_name, ", ");

//   const title = _.isEmpty(data.order_zone_name)
//     ? "NA"
//     : `${data.order_zone_name.length > 1
//       ? `${_.first(data.order_zone_name)} (+${data.order_zone_name.length - 1})`
//       : _.first(data.order_zone_name)
//     }`;

  return (
    <Popover
      title="Zones List"
      placement="topLeft"
      content={ <div>{ content }</div> }
      overlayStyle={ { width: 250 } }
    >
      { title }
    </Popover>
  );
}, [ data ]);

export function pinLikeMarker (stopNo = null, bgColor, fgColor, size = 20) {
  // Create a new canvas element
  let hexBg = `#${bgColor}`;
  let hexFg = `#${fgColor}`;
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size * 1.25; // Extra height for the pin, proportional to the size

  // Get the 2D drawing context
  const ctx = canvas.getContext('2d');

  // Draw the round top of the marker
  const radius = size * 0.425; // Radius proportional to the size
  ctx.beginPath();
  ctx.arc(size / 2, radius, radius, 0, 2 * Math.PI, false); // Use 2 * Math.PI to draw a complete circle
  ctx.closePath();
  ctx.fillStyle = hexBg;
  ctx.fill();
  ctx.strokeStyle = '#000000'; // Replace hexBorderColor with the color you desire
  ctx.lineWidth = 1.5; // Replace borderWidth with the desired border width
  ctx.stroke();

  // Draw the pin
  ctx.beginPath();
  ctx.moveTo(size / 2, radius * 2); // Move to the bottom of the circle
  ctx.lineTo(size / 2, size * 1.25); // Draw a line to the desired position
  ctx.strokeStyle = '#000000'; // Set the line color (same as the border color)
  ctx.lineWidth = 1.25; // Set the line width (same as the border width)
  ctx.stroke();

  // ctx.beginPath();
  // ctx.moveTo(size / 2 - size * 0.125, radius); // Position proportional to the size
  // ctx.lineTo(size / 2 + size * 0.125, radius); // Position proportional to the size
  // ctx.lineTo(size / 2, size * 1.25); // Position proportional to the size
  // ctx.closePath();
  // ctx.fillStyle = hexBg;
  // ctx.fill();

  if (typeof stopNo === 'number') {
    // Draw the stop number
    ctx.font = `bold ${size * 0.55}px Arial`; // Font size proportional to the size
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = hexFg;
    ctx.fillText(stopNo, size / 2, size / 2);
  }

  // Return the data URL for the canvas
  return canvas.toDataURL();
}

export const calculateTotalWeight = (orders) => {
  let totalWeight = 0;

  orders.forEach((order) => {
    totalWeight += order.weight;
  });

  return totalWeight;
};

export const calculatePercentage = (weight, totalWeight) => {
  return (weight / totalWeight) * 100;
};


export const TagRenderer = ({ data }) => {
  const tagObj = data ? data : { color: '#FFF', label: '_ _ _' }
  // const colorObj = tagObj.find((e) => e.label == value.toLowerCase()) || '#e0e0e0';

  return (
    <div className="tag-cell">
      <span
        className="tag-indicator"
        style={{ backgroundColor: tagObj.color }}
      />
      <Tooltip title={tagObj.label}>
        <span className="tag-text">{_.startCase(tagObj.label)}</span>
      </Tooltip>
    </div>
  );
}


export const RenderOrderNo = ({ value, data }) => {
  const tagColors = data._tagColors || data || {};
  const colorObj = tagColors.find((e) => e.code == data.code) || {};

  return (
    <div className="tag-cell">
      <Tooltip title={_.startCase(colorObj.label)}>
        <span className="statusColored" style={{ backgroundColor: colorObj?.color, color: getContrastColor(colorObj.color) }} >{value}</span>
      </Tooltip>
    </div>
  );
}

export const getContrastColor = (hexColor) => {

  if (_.isEmpty(hexColor)) return;

  hexColor = hexColor.replace('#', '');


  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}

export const generateRandomName = () => {
  const firstNames = [
    "Alex", "Jordan", "Taylor", "Morgan", "Casey", "Jamie", "Chris",
    "Pat", "Sam", "Riley", "Dakota", "Quinn", "Avery", "Skyler", "Drew"
  ];

  const lastNames = [
    "Smith", "Brown", "Johnson", "Lee", "Garcia", "Martinez", "Davis",
    "Rodriguez", "Miller", "Lopez", "Hernandez", "Clark", "Hall", "Adams", "Baker"
  ];

  const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];

  return `${randomFirstName} ${randomLastName}`;
}

export const colorStatusStyle = (dispatchColorObj) => {
  const doesExist = !_.isEmpty(dispatchColorObj)
  const color = doesExist ? dispatchColorObj?.color : ''
  return {
    backgroundColor: doesExist ? color : "none",
    color: doesExist ? getContrastColor(color) : "black",
  }
}

export const colorTagToolTip = (dispatchColorObj, value) => {
  const doesExist = !_.isEmpty(dispatchColorObj)
  return (
    value ?
      <Tooltip title={doesExist ? _.startCase(dispatchColorObj?.label) : value}>
        <span
          className={doesExist ? "statusColored" : null}
          style={{ ...colorStatusStyle(dispatchColorObj) }}
        >
          {value}
        </span>
      </Tooltip>
      :
      ""
  );
}