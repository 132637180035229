import React, { Component, Fragment, Suspense, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import { Route, Switch, Redirect, NavLink, withRouter, matchPath } from "react-router-dom";
import "core-js/es6";
import "antd/dist/antd.css";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import {
  Avatar,
  Content,
  Dropdown,
  Header,
  Icon,
  Layout,
  Menu,
  Sider,
  Select,
  Input,
  Drawer,
  Button,
  Popover,
  Spin,
  Skeleton,
} from "./common/UIComponents";
import "../stylesheets/application.scss";
import logo from "../assets/images/logo-thumbnail.png";
import minLogo from "../assets/images/Icon_dashboard.png";
import AppRoutes, { CommonRoutes, DriverRoutes, SuperAdminRoutes, UserRoutes } from "./config/Routes";
import SideMenu from "./components/SideMenu";
import userStore from "./stores/UserStore";
import ErrorBoundary from "./components/ErrorBoundary";
import { alertMessage, checkIfDriverLoggedIn, customPasteSplit, isEmpty } from "./common/Common";
import I18n from "./common/I18n";
import { fetchOrganizationConfigs, fetchUserOrgs } from "./api/Organisations";
import OrderFiter from "./components/orders/OrderFilter";
import BaseModal from "./components/BaseModal";
import { fetchLocations } from "./api/LocationsApi";
import Renewal from './containers/Renewal';
import modules from './config/modules';
import { checkServiceExistance, sortData, clearSession, removeSpecialCharacters, renderAlertMessage } from './helpers/common';
import AppConfig from "./config/AppConfig";
import MainHeader from "./components/dashboard/MainHeader";
import { AppLoadingScreen, PageLoadingScreen } from "./components/common/LogisticsLoadingScreen";
import { AuthenticationService } from "./api/Auth";
import { AuthContext } from "./context/AuthContext";
// import { userRoles } from "../../../assets/javascripts/constants";
import { UserContext } from "./context/UserContext";
import AppRoute from "./AppRoute";
import { WarehouseContext } from "./context/WarehouseContext";
import { OrgContext } from "./context/OrgContext";
import { fetchUserForDisp } from "./api/DisplaySettings";
import { DisplaySettingsContext } from "./context/DisplaySettings";
import { fetchOrgRules } from "./api/Account";
import { RulesApi } from "./api/Rules";
import { setRulesData } from "./helpers/configurations";
import { OrderConfigContext } from "./context/OrderConfigContext"
import { getFromToDate } from "./components/orders/helpers";
import MemoizedSider from "./MemoizedSider";

// const admin = userStore.admin();
// const superAdmin = userStore.superAdmin();
// const superAdmin = ;
axios.defaults.withCredentials = true;
const { Search } = Input;

const userRoles = {
  super_admin: "super_admin",
  admin: "admin",
  driver: "driver",
  shipper: "shipper",
};
const hasDashboardAccess = checkServiceExistance(
  [ "NRLOGS", "UI", "COSTATS", "NRDS" ],
  "ANY"
);
const landingPage = hasDashboardAccess ? "/dashboard" : "/edit_profile";
const driverLandingPage = "/weekly_settlement_reports";

class CustomLayout extends Component {
  constructor (props) {
    super(props);
    this.state = {
      collapsed: true,
      organizations: [],
      searchOrderTokens: [],
      filterPlaceHolder: {
        fromDate: null,
        toDate: null,
        schedule_day_filter: "Current Week",
        filter: "",
        search_order_token: "",
        search_type : "EQUALS",
        sortBy: "",
        sortByType: "",
        account_codes: [],
        warehouse_id: "",
        zone_ids: [],
        order_type: "",
        search_key_type : "",
        vehicle_type: "",
        created_by_ids: []
      },
      filterWindow: false,
      // searchType: "CUSTOMER_ORDER_NUMBER",
      searchType: "",
      visible: false,
      organization_rules: {},
      saveNSearchCall: true,
      displaySettingsContext: {
        displayConfiguration: {},
        isFetchDisplaySettings: false
      },
      menuStatus: localStorage.getItem("menuStatus") ?? "un-pinned",
      isClosedPinned: localStorage.getItem("isClosedPinned") ?? "un-pinned",
    };
    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.setCurrentWhSavedSearch = _.debounce(this.setCurrentWhSavedSearch, 500);
    this.fetchPreComputedFilter = this.fetchPreComputedFilter.bind(this);
  }


  changeMenuStatus = (switchName, status) => {
    if (switchName == 'isOpenPinned') {
      this.setState({ menuStatus: status })
    }
    else {
      this.setState({ isClosedPinned: status })
    }

  }

  formatOrgConfig (orgConfigs) {
    const formattedOrgConfigs = {};
    orgConfigs.forEach(item => {
      formattedOrgConfigs[ item.setting_name ] = item.setting_value;
    });
    return formattedOrgConfigs;
  }
  formatDispConfig (dispConfigs) {
    const dispSettings = {
      userConfiguration: {},
      orgConfiguration: {}
    };
    dispConfigs.forEach(item => {
      if (_.isEmpty(item.user_id)) {
        dispSettings.orgConfiguration[ item.config_type ] = item.config_value;
      } else {
        dispSettings.userConfiguration[ item.config_type ] = item.config_value;
      }
    });
    return dispSettings;
  }

  updateDisplayContext = (type, config) => {
    const displayConfiguration = this.state.displaySettingsContext[ type ];
    this.setState({
      displaySettingsContext: { ...this.state.displaySettingsContext, [ type ]: { ...displayConfiguration, ...config }, isFetchDisplaySettings: false }
    }, () => {
    });
  };

  getDisplayConfigurations = () => {
    const { displaySettingsContext } = this.state;
    const { currentUser } = this.props.userContext;
    this.setState({ displaySettingsContext: { ...displaySettingsContext, isFetchDisplaySettings: true } });
    const currentOrgId = currentUser?.currentOrg ? currentUser.currentOrg.id : ""
    const currentUserId = currentUser ? currentUser.id : ""
    fetchUserForDisp(currentOrgId, currentUserId)
      .then((result) => {
        if (result.success) {
          this.setState({ displaySettingsContext: { ...displaySettingsContext, ...this.formatDispConfig(result.configuration), isFetchDisplaySettings: false } });
        }
      }).catch((error) => {
        this.setState({ displaySettingsContext: { ...displaySettingsContext, isFetchDisplaySettings: false } });
      });
  };



  updateDisplayContext = (type, config) => {
    const displayConfiguration = this.state.displaySettingsContext[ type ];
    this.setState({
      displaySettingsContext: { ...this.state.displaySettingsContext, [ type ]: { ...displayConfiguration, ...config }, isFetchDisplaySettings: false }
    }, () => {
    });
  };



  fetchPreComputedFilter = () => {
      const { currentUser } = this.props.userContext;
      const { selectedWarehouses, searchCriteria } = this.props.warehouseContext;
      const selectedwh = _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
          ? _.first(selectedWarehouses)
          : selectedWarehouses;
      const { orders = {} } = searchCriteria;
      const { getDefaultGlobalFilter } = this.props.orderConfigContext;
      const {
        account_code,
        from_date,
        order_type,
        to_date,
        zone_ids,
        schedule_orders_type,
        schedule_day_filter,
        vehicle_type,
        created_by_ids,
      } = orders;
      const { fromDate, toDate } = getFromToDate(schedule_day_filter, to_date , from_date);
      const defaultGlobalFilter = getDefaultGlobalFilter(fromDate, toDate);
      const delivery_zones = _.get(currentUser, 'delivery_zones', []);
      const filteredZones = _.chain(delivery_zones)
        .filter((zone) => zone.warehouse_id === selectedwh)
        .map((zone) => ({ label: zone.zone_name, value: zone.zone_id }))
        .value();
      const zonesIds = _.split(zone_ids, ',');
      const curentZones = _.filter(filteredZones, (zone) => _.includes(zonesIds, zone.value));
      const filter = {
        ...defaultGlobalFilter,
        schedule_day_filter,
        search_type: 'EQUALS',
        search_key_type: "",
        zone_ids: curentZones,
        order_type: order_type,
        account_codes: account_code ? _.split(account_code, ',') : [],
        schedule_orders_type: _.isEmpty(schedule_orders_type) ? "SCHEDULED" : schedule_orders_type,
        vehicle_type: vehicle_type,
        created_by_ids: created_by_ids ? _.split(created_by_ids, ',') : [],
      };
      return filter;
  }

  componentDidMount () {
    const { setFilterPlaceHolder } = this.props.orderConfigContext;
    const preComputedFilter = this.fetchPreComputedFilter();
    this.setState({ filterPlaceHolder: preComputedFilter } , () => {
      this.fetchData();
      setFilterPlaceHolder(preComputedFilter);
    })
  }

  async combineStates () {
    const { currentOrg , currentUser, setOrgRules } = this.props.userContext;
    // Define initial state values
    let newState = {
      organizations: [],
      currentOrg: currentOrg || null,
      displaySettingsContext: {
        isFetchDisplaySettings: false,
        // Add any other display settings properties with initial values here
      },
      apiProgress: false,
      organization_rules: {},
    };

    try {
      // Fetch user organizations and update the state
      const userOrgsResult = await fetchUserOrgs(false, this.props.userContext.currentUser.id);
      if (userOrgsResult.success) {
        newState.organizations = userOrgsResult.organizations;
        newState.currentOrg = currentOrg || userOrgsResult.organizations[ 0 ];
      } else {
        // Handle error if needed
        alertMessage(userOrgsResult.errors[ 0 ], "error", 10);
      }

      // Fetch display configurations and update the state
      const orgId = userStore.getStateValue('selectedOrg');
      const currentOrgId = currentOrg?.id ? currentOrg.id : "";
      const currentUserId = currentUser?.id ? currentUser.id : "";
      const displayConfigResult = await fetchUserForDisp(currentOrgId, currentUserId);
      if (displayConfigResult.success) {
        newState.displaySettingsContext = {
          ...newState.displaySettingsContext,
          ...this.formatDispConfig(displayConfigResult.configuration),
          isFetchDisplaySettings: false,
        };
      }

      // Fetch organization rules and update the state
      this.setState({ apiProgress: true });
      const orgRulesResult = await RulesApi.fetch(orgId);
      if (orgRulesResult.success) {
        const organization_rules = orgRulesResult.organization_rules || [];
        if (organization_rules.length > 0) {
          const { updatedConfig } = setRulesData(organization_rules, {}, {});
          const decimalPoints = updatedConfig?.org_billing_rules?.round_off_decimals ? updatedConfig.org_billing_rules.round_off_decimals : AppConfig.default_decimal_points
          localStorage.setItem('round_off_decimals', decimalPoints);
          newState.organization_rules = updatedConfig;
          setOrgRules(updatedConfig);
        } else {
          localStorage.setItem('round_off_decimals', AppConfig.default_decimal_points);
        }
      } else {
        // Handle error if needed
        alertMessage(orgRulesResult.errors[ 0 ], "error", 10);
        localStorage.setItem('round_off_decimals', AppConfig.default_decimal_points);
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error(error);
    } finally {
      newState.apiProgress = false;
      // Set the combined state once all fetch operations are completed
      this.setState({ ...this.state, ...newState });
    }
  }

  async fetchData () {
    const { currentRole } = this.props.userContext;
    const isSuperAdmin = currentRole === userRoles.super_admin;
    const isShipper = currentRole === userRoles.shipper;
    const shipperLandingPage = "/orders";
    const isDriver = checkIfDriverLoggedIn(currentRole);
    // Fetch and update the state
    if(!isSuperAdmin){
      await this.combineStates();
    }

    if(this.props.location.pathname === '/'){
      this.props.history.push( isSuperAdmin ? "/users" : isShipper ? shipperLandingPage : isDriver ? driverLandingPage : landingPage );
    }else{
      // check if user has access to the page
      const pathToValidate = this.props.location.pathname;
      // const accessiableRoutes = isSuperAdmin ? SuperAdminRoutes : UserRoutes;
      let accessiableRoutes = isSuperAdmin ? SuperAdminRoutes : isDriver ? DriverRoutes : UserRoutes;
      const showCreditLimit = _.get(this.state.organization_rules, "org_billing_rules.credit_limit_validation") === "true" 
      if(!showCreditLimit){
        accessiableRoutes = accessiableRoutes.filter( i => i.path !== '/credit_limit')
      }
      let matchingRoute = null;
      accessiableRoutes.forEach(route => {
        const match = matchPath(pathToValidate, {
          path: route.path,
          exact: route.exact,
        });
        if (match !== null) {
          // If route has an actionType parameter, check it against validActionTypes in the route
            if (
              match.params.actionType &&
              route.validActionTypes &&
              !route.validActionTypes.includes(match.params.actionType)
            ){
              // If actionType is invalid, null the match
              return;
            }
          matchingRoute = route;
        }
      });

      const exceptionalRoutePaths = ['manage_batch']
      const obtainedPath = pathToValidate.replaceAll('/', '');
      const mapToRedirectExceptionalRoute = (path) => {
        const keyVals = {
          'manage_batch': '/manage_checks',
        }
        if(exceptionalRoutePaths.includes(obtainedPath)){
          this.props.history.push(keyVals[obtainedPath])
        }
      }

      const isPathValid = matchingRoute !== null || CommonRoutes.some(route => route.path === pathToValidate) || exceptionalRoutePaths.includes(mapToRedirectExceptionalRoute);
      if (!isPathValid) {
        const isManageBatch = this.props.location.pathname.includes("manage_batch");
        if(isManageBatch){
          this.props.history.push('/manage_checks')
        }else{
          alertMessage("You don't have access to this page or the page doesn't exist" , "error");
        this.props.history.push(isSuperAdmin ? "/users" : (isShipper ? shipperLandingPage : isDriver ? driverLandingPage : landingPage));
      }
      }
  }}


  async fetchData () {
    const { currentRole } = this.props.userContext;
    const isSuperAdmin = currentRole === userRoles.super_admin;
    const isShipper = currentRole === userRoles.shipper;
    const shipperLandingPage = "/orders";
    const isDriver = checkIfDriverLoggedIn(currentRole)

    // Fetch and update the state
    if (!isSuperAdmin) {
      await this.combineStates();
    }

    this.handleRouting(isSuperAdmin, isShipper, shipperLandingPage, isDriver);
  }

  handleRouting (isSuperAdmin, isShipper, shipperLandingPage, isDriver) {
    if (this.props.location.pathname === '/') {
      this.redirectHome(isSuperAdmin, isShipper, shipperLandingPage, isDriver);
    } else {
      this.validatePageAccess(isSuperAdmin);
    }
  }

  redirectHome (isSuperAdmin, isShipper, shipperLandingPage, isDriver) {
    const redirectPath = isSuperAdmin ? "/users" : isShipper ? shipperLandingPage : isDriver? driverLandingPage : landingPage;
    this.props.history.push(redirectPath);
  }

  validatePageAccess (isSuperAdmin) {
    const { currentRole } = this.props.userContext;
    const pathToValidate = this.props.location.pathname;
    const isDriver = checkIfDriverLoggedIn(currentRole);
    let accessiableRoutes = this.getAccessibleRoutes(isSuperAdmin, isDriver);
    const showCreditLimit = this.showCreditLimit();
    const verifyOrgCode = this.verifyOrg();
    const {orgSettings} = this.props.orgContext;
    const isShipper = currentRole === userRoles.shipper;
    const viewFusionReport = _.get(orgSettings, "invoice_fusion_report", "false");
    const viewStatusReport = _.get(orgSettings, "invoice_status_report", "false");
    const viewMonthlyCutoff = _.get(orgSettings, "monthly_cutoff", "false");
    const codeForDockScan = ["ACTFT", "AVI","DEMO", "FLTENBL"]
    // const MonthlyCutOff = ["LYKES", "QA", "FLTENBL", "DEMO","FET","KENCO"];

    if (!showCreditLimit) {
      accessiableRoutes = accessiableRoutes.filter(i => i.path !== '/credit_limit');
    }

    if((this.props.location.pathname.includes("/invoice_status_report") && viewStatusReport === "false") || (this.props.location.pathname.includes("/qb_report") && viewFusionReport === "false")){
      alertMessage("You don't have access to this page or the page doesn't exist", "error");
      this.redirectHome(isSuperAdmin, isShipper, landingPage, isDriver);
    }

    if(!codeForDockScan.includes(verifyOrgCode) && this.props.location.pathname.includes("/dock_scan_report")){
      alertMessage("You don't have access to this page or the page doesn't exist", "error");
      this.redirectHome(isSuperAdmin, isShipper, landingPage, isDriver);
    }

    if (viewMonthlyCutoff === "false" && this.props.location.pathname.includes("/monthly_cutoff")) {
      alertMessage("You don't have access to this page or the page doesn't exist", "error");
      this.redirectHome(isSuperAdmin, isShipper, landingPage, isDriver);
    }

    // if (!MonthlyCutOff.includes(verifyOrgCode) && this.props.location.pathname.includes("/monthly_cutoff")) {
    //   alertMessage("You don't have access to this page or the page doesn't exist", "error");
    //   this.redirectHome(isSuperAdmin, isShipper, landingPage, isDriver);
    // }

    // // redirecting to homepage if at all any user have a history of accessing the page
    // if(["/divisions","/charted_accounts"].includes(this.props.location.pathname)){
    //   alertMessage("You don't have access to this page or the page doesn't exist", "error");
    //   this.redirectHome(isSuperAdmin, isShipper, landingPage, isDriver);
    // }

    const matchingRoute = this.findMatchingRoute(pathToValidate, accessiableRoutes);
    const exceptionalRoutePaths = [ 'manage_batch' ];

    if (!this.isPathValid(matchingRoute, pathToValidate, exceptionalRoutePaths)) {
      this.handleInvalidPath(pathToValidate, isSuperAdmin, isShipper, isDriver);
    }
  }

  getAccessibleRoutes (isSuperAdmin, isDriver) {
    return isSuperAdmin ? SuperAdminRoutes : isDriver ? DriverRoutes : UserRoutes;
  }

  showCreditLimit () {
    return _.get(this.state.organization_rules, "org_billing_rules.credit_limit_validation") === "true";
  }

  verifyOrg () {
    return _.get(this.props.orgContext, "currentOrg.code", null)
  } 

  findMatchingRoute (pathToValidate, accessiableRoutes) {
    let matchingRoute = null;
    accessiableRoutes.forEach(route => {
      const match = this.matchPathWithRoute(pathToValidate, route);
      if (match !== null) {
        if (this.isInvalidActionType(match, route)) {
          return;
        }
        matchingRoute = route;
      }
    });
    return matchingRoute;
  }

  matchPathWithRoute (pathToValidate, route) {
    return matchPath(pathToValidate, {
      path: route.path,
      exact: route.exact,
    });
  }

  isInvalidActionType (match, route) {
    return (
      match.params.actionType &&
      route.validActionTypes &&
      !route.validActionTypes.includes(match.params.actionType)
    );
  }

  isPathValid (matchingRoute, pathToValidate, exceptionalRoutePaths) {
    const obtainedPath = pathToValidate.replaceAll('/', '');
    const isExceptionalPath = exceptionalRoutePaths.includes(obtainedPath);

    if (isExceptionalPath) {
      this.redirectExceptionalRoute(obtainedPath);
    }

    return matchingRoute !== null || CommonRoutes.some(route => route.path === pathToValidate) || isExceptionalPath;
  }

  redirectExceptionalRoute (obtainedPath) {
    const exceptionalRoutePaths = ["manage_batch"];
    const keyVals = {
      'manage_batch': '/manage_checks',
    };

    if (exceptionalRoutePaths.includes(obtainedPath)) {
      this.props.history.push(keyVals[ obtainedPath ]);
    }
  }

  handleInvalidPath (pathToValidate, isSuperAdmin, isShipper, isDriver) {
    const isManageBatch = this.props.location.pathname.includes("manage_batch");

    if (isManageBatch) {
      this.props.history.push('/manage_checks');
    } else {
      alertMessage("You don't have access to this page or the page doesn't exist", "error");
      this.redirectHome(isSuperAdmin, isShipper, landingPage, isDriver);
    }
  }


  // componentDidMount () {
  //   const { currentRole } = this.props.userContext;
  //   const isSuperAdmin = currentRole === userRoles.super_admin;
  //   const isShipper = currentRole === userRoles.shipper;
  //   const shipperLandingPage = "/orders"
  //   const isManageBatch = this.props.location.pathname.includes("manage_batch");
  //   if (!isSuperAdmin) {
  //     this.getUserOrgs();
  //     this.getDisplayConfigurations();
  //     this.getOrgRules();
  //   }
  //   if(this.props.location.pathname === '/'){
  //     this.props.history.push( isSuperAdmin ? "/users" : (isShipper ? shipperLandingPage : landingPage));
  //   }else{
  //     // check if user has access to the page
  //     const pathToValidate = this.props.location.pathname;
  //     // const accessiableRoutes = isSuperAdmin ? SuperAdminRoutes : UserRoutes;
  //     let accessiableRoutes = isSuperAdmin ? SuperAdminRoutes : UserRoutes;
  //     const showCreditLimit = _.get(this.state.organization_rules, "org_billing_rules.credit_limit_validation") === "true" 
  //     if(!showCreditLimit){
  //       accessiableRoutes = accessiableRoutes.filter( i => i.path !== '/credit_limit')
  //     }
  //     let matchingRoute = null;
  //     accessiableRoutes.forEach(route => {
  //       const match = matchPath(pathToValidate, {
  //         path: route.path,
  //         exact: route.exact,
  //       });
  //       if (match !== null) {
  //         // If route has an actionType parameter, check it against validActionTypes in the route
  //           if (
  //             match.params.actionType &&
  //             route.validActionTypes &&
  //             !route.validActionTypes.includes(match.params.actionType)
  //           ){
  //             // If actionType is invalid, null the match
  //             return;
  //           }
  //         matchingRoute = route;
  //       }
  //     });

  //     const exceptionalRoutePaths = ['manage_batch']
  //     const obtainedPath = pathToValidate.replaceAll('/', '');
  //     const mapToRedirectExceptionalRoute = (path) => {
  //       const keyVals = {
  //         'manage_batch': '/manage_checks',
  //       }
  //       if(exceptionalRoutePaths.includes(obtainedPath)){
  //         this.props.history.push(keyVals[obtainedPath])
  //       }
  //     }

  //     const isPathValid = matchingRoute !== null || CommonRoutes.some(route => route.path === pathToValidate) || exceptionalRoutePaths.includes(mapToRedirectExceptionalRoute);
  //     if (!isPathValid) {
  //       if(isManageBatch){
  //         this.props.history.push('/manage_checks')
  //       }else{
  //         alertMessage("You don't have access to this page or the page doesn't exist" , "error");
  //       this.props.history.push(isSuperAdmin ? "/users" : (isShipper ? shipperLandingPage : landingPage));
  //     }
  //     }
  // }}

  componentDidUpdate(prevProps, prevState) {
    const { setFilterPlaceHolder } = this.props.orderConfigContext;
    if (
      !_.isEqual(
        prevProps.warehouseContext.searchCriteria.orders,
        this.props.warehouseContext.searchCriteria.orders
      )
    ) {
      const preComputedFilter = this.fetchPreComputedFilter();
      this.setState({ filterPlaceHolder: preComputedFilter }, () => {
        setFilterPlaceHolder(preComputedFilter);
      });
    }
  }


  setCurrentWhSavedSearch = (returnFilterVal = false) => {
    const {currentUser} = this.props.userContext;
    const filteredStatus  = this.state.filterPlaceHolder.filter;
    const { selectedWarehouses , searchCriteria } = this.props.warehouseContext;
    const selectedwh =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;
    const { orders = {} } = searchCriteria;
    const {
      account_code,
      from_date,
      order_type,
      to_date,
      zone_ids,
      schedule_orders_type,
      name_search_key,
      phone_search_key,
      schedule_day_filter,
      vehicle_type,
      created_by_ids,
    } = orders;
   // filtering current warehouse zones then setting saved search Zones....
    const delivery_zones = currentUser?.delivery_zones ?.length > 0
      ? currentUser.delivery_zones
      : [];
    const filteredZones = delivery_zones
      .filter((zone) => zone.warehouse_id === selectedwh)
      .map((zone) => ({ label: zone.zone_name, value: zone.zone_id }));
    const zonesIds = zone_ids?.length >0 ? zone_ids.split(",") : [];
    const curentZones = filteredZones && filteredZones.filter((zone) =>
      zonesIds.includes(zone.value)
    );
    //...........
    const { fromDate, toDate } = getFromToDate(schedule_day_filter , to_date , from_date);
    const filter = Object.assign({}, this.state.filterPlaceHolder, {
      // fromDate: !isEmpty(from_date)
      //   ? moment(from_date, "YYYY-MM-DD HH:mm:ss Z")
      //   : moment(),
      // toDate: !isEmpty(to_date)
      //   ? moment(to_date, "YYYY-MM-DD HH:mm:ss Z")
      //   : moment().add(6, "day"),
      fromDate,
      toDate,
      schedule_day_filter,
      filter: filteredStatus,
      search_type: 'EQUALS',
      search_order_token: "",
      sortBy: "none",
      sortByType: "descend",
      account_codes: !isEmpty(account_code) ? account_code.split(",") : [],
      warehouse_id: "",
      // search_key_type:
      //   name_search_key && !isEmpty(name_search_key)
      //     ? "name_search_key"
      //     : phone_search_key && !isEmpty(phone_search_key)
      //     ? "phone_search_key"
      //       : "hawb",
      // search_key_value:
      //   name_search_key && !isEmpty(name_search_key)
      //     ? name_search_key
      //     : phone_search_key && !isEmpty(phone_search_key)
      //     ? phone_search_key
      //     : "",
      [I18n.t("order.filters.schedule_orders.type")]: !isEmpty(
        schedule_orders_type
      )
        ? schedule_orders_type
        : I18n.t("order.filters.schedule_orders.scheduled_key"),
      zone_ids: curentZones?.length > 0 ? curentZones : [],
      order_type: !isEmpty(order_type) ? order_type : "",
      vehicle_type,
      created_by_ids: created_by_ids ? _.split(created_by_ids, ',') : [],
    });
    this.handleChildFilterChange(filter);
    if(returnFilterVal){
      return filter
    }
  };

  filterWhs = (selectedWarehouse) => {
    this.setState({
      filterPlaceHolder: {
        ...this.state.filterPlaceHolder,
        warehouse_id: selectedWarehouse
      }
    });
  };

  getOrgRules = () => {
    this.setState({ apiProgress: true });
    const orgId = userStore.getStateValue("selectedOrg");
    RulesApi.fetch(orgId).then((result) => {
      if (result.success) {
        const organization_rules = result.organization_rules || [];
        if (organization_rules.length > 0) {
          const decimalPoints = updatedConfig?.round_off_decimals ? updatedConfig.round_off_decimals : AppConfig.default_decimal_points
          localStorage.setItem('round_off_decimals', decimalPoints);
          const { updatedConfig } = setRulesData(organization_rules, {}, {});
          this.setState(
            {
              apiProgress: false,
              organization_rules: updatedConfig,
            },
            () => { }
          );
        }
      } else {
        renderAlertMessage(result.errors)
        const decimalPoints = updatedConfig
        localStorage.setItem('round_off_decimals', AppConfig.default_decimal_points);
        this.setState({ apiProgress: false, organization_rules: {} });
      }
    });
  };

  clearTheValues = () => {
    this.setState({
      selectedOrg: '',
      orgLogo: logo,
    });
  };

  // setOrgValue = (index) => {
  //   this.setState({
  //     selectedOrg: this.state.organizations[ index ].id,
  //     currentOrg: this.state.organizations[ index ],
  //     orgLogo: logo
  //   }, () => {
  //     // userStore.setStateValue("selectedOrg", this.state.organizations[index].id);
  //     // userStore.setStateValue("currentOrg", this.state.organizations[index]);
  //   });
  // };


  getUserOrgs = () => {
    const { currentOrg } = this.props.userContext;
    fetchUserOrgs(false, this.props.userContext.currentUser.id).then((result) => {
      if (result.success) {
        this.setState(
          {
            organizations: result.organizations,
            currentOrg: currentOrg || result.organizations[ 0 ],
          },
        );
      } else {
        this.clearTheValues();
        renderAlertMessage(result.errors)
      }
    });
  };

  toggleSideBar (status) {
    this.setState({ collapsed: status });
  }

  // handleHeaderOnChange = (element, value) => {
  //   const index = _.findIndex(this.state.organizations, [ "id", value ]);
  //   if (index >= 0) {
  //     userStore.setStateValue("currentOrg", this.state.organizations[ index ]);
  //     userStore.setStateValue(element, value, () => {
  //       window.location.reload(false);
  //     });
  //   } else if (this.state.organizations.length > 0) {
  //     userStore.setStateValue("currentOrg", this.state.organizations[ 0 ]);
  //     userStore.setStateValue(element, this.state.organizations[ 0 ].id, () => {
  //       window.location.reload(false);
  //     });
  //   }
  // };

  orderNumberSearch = (value) => {
    const { setFilterPlaceHolder } = this.props.orderConfigContext;
    let newFilterPlaceHolder = {};
    const filteredString = value; //removeSpecialCharacters(value)
    
    if(isEmpty(filteredString)){
      const filter = this.setCurrentWhSavedSearch(true);
      newFilterPlaceHolder = { ...filter };
    }
    else{
      newFilterPlaceHolder = {
        ...this.state.filterPlaceHolder,
        filter: !isEmpty(filteredString) ? "" : I18n.t("order.default_status"),
        fromDate: null,
        toDate: null,
        schedule_day_filter : "Current Week",
        search_order_token: filteredString,
        search_key_value: filteredString,
        sortBy: "none",
        account_codes: [],
        warehouse_id: "",
        zone_ids: [],
        vehicle_type:"",
        created_by_ids: [],
      }; 
    }
    
    this.setState(
      {
        filterPlaceHolder: newFilterPlaceHolder,
        searchType: "byOrders",
        // saveNSearchCall: false,
      },
      () => {
        setFilterPlaceHolder(this.state.filterPlaceHolder);
        this.props.history.push({
          pathname: "/orders",
          filterPlaceHolder: this.state.filterPlaceHolder,
          searchType: "byOrders",
          // saveNSearchCall: false,
          calledFrom: "otherScreen",
        });
      }
    );
  };

  handleOnChange = (element, value) => {
    this.setState({
      [ element ]: !isEmpty(value) ? value : "",
    });
  };

  // closeFilterWindow = () => {
  //   this.setState({ filterWindow: false });
  // };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState({
      filterPlaceHolder: Object.assign({}, filter),
      searchType: "",
    }, () => {
      const { setFilterPlaceHolder } = this.props.orderConfigContext;
      setFilterPlaceHolder(filter)
      if(cb){
        cb()
      }
    });
  };

  handleFilterValueChange = (element, value, isSearch = false) => {
    this.setState(
      {
        filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
          [ element ]: value,
        }),
        searchType: "",
      },
      () => {
        if (isSearch) {
          this.orderNumberSearch(value);
        }
        if (element === "search_key_type") {
          // if equals the do order num search else handle as usual
          const searchedVal = this.state.filterPlaceHolder.search_key_value
          // const orderConfigContext
          const { setFilterPlaceHolder } = this.props.orderConfigContext;
          setFilterPlaceHolder({
            ...this.state.filterPlaceHolder,
            search_key_type: value,
          })
          if(searchedVal && searchedVal.length > 0){
          if (this.state.filterPlaceHolder.search_type === "EQUALS") {
            this.orderNumberSearch();
          } else {
            this.handleSearch(true);
          }
        }}
      }
    );
  };

  handleSearch = (storeOrderNum = false) => {
    const { filterPlaceHolder, setFilterPlaceHolder } = this.props.orderConfigContext;
    this.setState(
      {
        filterWindow: false,
        filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
        search_order_token: storeOrderNum ? this.state.filterPlaceHolder.search_order_token : "",
        }),
        searchType: "byFilter",
        saveNSearchCall: true
      },
      () => {
        setFilterPlaceHolder(this.state.filterPlaceHolder)
          const currentPath = this.props.location.pathname;
          const pathToPush =  "/orders"; // isOrdersBetaPage ? "/orders-beta" :
          this.props.history.push({
            pathname: pathToPush,
            filterPlaceHolder: this.state.filterPlaceHolder,
            searchType: "byFilter",
            saveNSearchCall: true,
            calledFrom: "otherScreen"
          });
        }
    );
  };

  handleSaveNSearch = () => {
    this.handleSearch();
    // this.setState({isSaveSearchCall: false})
  }

  // handleOnTagsChange = (element, value, changed, changedIndex) => {
  //   let intersectedZones = [];
  //   const errors = [];
  //   const duplicatedZipCodes = [];
  //   if (changed.length > 0) {
  //     changed.forEach((pincode) => {
  //       intersectedZones = [];
  //       intersectedZones = this.state.zones.filter(
  //         (zone, zoneIndex) =>
  //           zoneIndex !== index && zone.zip_codes.includes(pincode)
  //       );
  //       if (intersectedZones.length > 0) {
  //         duplicatedZipCodes.push(pincode);
  //         errors.push(
  //           `Zip code ${pincode} already in zone '${intersectedZones
  //             .map((zone) => zone.name)
  //             .join(",")}'`
  //         );
  //       }
  //     });
  //   }
  //   if (errors.length > 0) {
  //     this.setState({
  //       errors,
  //     });
  //     const excludedZipCodes = _.difference(value, duplicatedZipCodes);
  //     this.props.onChange(index, element, excludedZipCodes);
  //   } else {
  //     this.setState({
  //       errors,
  //     });
  //     this.props.onChange(index, element, value);
  //   }
  // };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
      searchType: "",
    });
  };

  handleChildFilterChange = (filter) => {
    const { setFilterPlaceHolder } = this.props.orderConfigContext;
    this.setState({
      filterPlaceHolder: filter,
      searchType: filter.search_order_token !== "" ? "byOrders" : "byFilter",
      saveNSearchCall : true
    },()=>{
      setFilterPlaceHolder(filter);
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  closeMenu = () => {
    this.setState({
      visible: false,
    });
  };


  menuButtonToggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render () {
    const { currentRole, currentUser } = this.props.userContext;
    const user = currentUser;
    const isSuperAdmin = currentRole === userRoles.super_admin;
    const isShipper = currentRole === userRoles.shipper;
    const shipperLandingPage = "/orders";
    const isDriver = checkIfDriverLoggedIn(currentRole);
    const currentOrg = user?.currentOrg ? user.currentOrg : {};

      const commonProps = {
        isOrderPage: this.props.location.pathname === "/orders",
        handleAppStateOnChange: this.handleOnChange,
        handleChildFilterChange: this.handleChildFilterChange,
        filterPlaceHolder: this.state.filterPlaceHolder,
        searchType: this.state.searchType,
        updateDisplayContext: this.updateDisplayContext,
        appContexts: {
          userContext: this.props.userContext,
          orgContext: this.props.orgContext,
          warehouseContext: this.props.warehouseContext,
          orderConfigContext: this.props.orderConfigContext
        },
      };

      const routesToRender = isSuperAdmin ? SuperAdminRoutes : isDriver ? DriverRoutes : UserRoutes;

    return (
      <DisplaySettingsContext.Provider value={ this.state.displaySettingsContext }>
        <Layout hasSider className="main-layout">
          <Suspense fallback={ <AppLoadingScreen /> }>
            <MainHeader
              currentUser={ currentUser }
              currentOrg={currentOrg}
              menuButtonToggleSidebar={ this.menuButtonToggleSidebar }
              orderNumberSearch={ this.orderNumberSearch }
              filterPlaceHolder={ this.state.filterPlaceHolder }
              handleFilterValueChange={ this.handleFilterValueChange }
              handleVisibleChange={ this.handleVisibleChange }
              filterWindow={ this.state.filterWindow }
              handleFilterPlaceHolderChange={ this.handleFilterPlaceHolderChange }
              handleSearch={ this.handleSearch}
              handleSaveNSearch={this.handleSaveNSearch}
              landingPage={ (isShipper ? shipperLandingPage : isDriver ? driverLandingPage : landingPage) }
              handleOnChange={ this.handleOnChange }
              isSuperAdmin={ isSuperAdmin }
              isShipper={ isShipper }
              isDriver={isDriver}
              WarehouseContext={ this.props.warehouseContext }
            />
          </Suspense>

          <Layout style={ { height: "100vh" } }>
            { !isShipper && !isDriver &&
              <Suspense fallback={ <Skeleton /> }>
                <MemoizedSider menuStatus={ this.state.menuStatus } isClosedPinned={ this.state.isClosedPinned } changeMenuStatus={ this.changeMenuStatus } />
              </Suspense>
            }
            <Content style={ { marginTop: 54, marginLeft: !isShipper && !isDriver ? this.state.menuStatus == "pinned" ? 220 : 70 : 0 } }>
              <Suspense fallback={ <PageLoadingScreen /> }>
                <Switch>
                  <>
                    { routesToRender.map((route, index) => (
                      <AppRoute key={ index } route={ route } routeIndex={ route.key } { ...commonProps } />
                    )) }
                    { CommonRoutes.map((route, index) => (
                      <AppRoute key={ index } route={ route } routeIndex={ route.key } { ...commonProps } />
                    )) }
                  </>
                </Switch>
              </Suspense>
            </Content>
          </Layout>
        </Layout>
      </DisplaySettingsContext.Provider>
    );
  }
}


export const FleetLayout = withRouter((props) => {
  const userContextData = useContext(UserContext);
  const orgContextData = useContext(OrgContext);
  const warehouseContextData = useContext(WarehouseContext);
  const orderConfigContextData = useContext(OrderConfigContext);

  return (
    <CustomLayout
      userContext={ userContextData }
      orgContext={ orgContextData }
      warehouseContext={ warehouseContextData }
      orderConfigContext = {orderConfigContextData}
      { ...props }
    />
  );
}
);


export default FleetLayout;
