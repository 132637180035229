/* eslint-disable react/sort-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import {
  Col,
  Row,
  Icon,
  Upload,
  Button,
  FormItem,
  Popconfirm,
  Tooltip,
} from "../../common/UIComponents";
import Gallery from "./Gallery";
import {
  downloadOrderPictures,
  PdfPreviewPictures,
  sendPodImagesToEmails,
} from "../../api/OrdersApi";
import {
  alertMessage,
  randomUUID,
  base64MimeType,
  getFileType,
  isEmpty,
  getFileIcon,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormErrors from "./FormErrors";
import SelectImage from "./SelectImage";
import PODEmailsModal from "../Tasks/PODEmailsModal";
import {base64ToPDF,checkServiceExistance,printPDF, renderAlertMessage, stopLvlPicturesFormat } from "../../helpers/common";
import PODfiles from "./Pods";
import userStore from "../../stores/UserStore";
import DeleteIcon from "./DeleteIcon";
import BaseModal from "../BaseModal";
import PDFPreviewComponent from "../../common/PdfPreview";
import { fetchAccountConfigs } from "../../api/Account";

const { Dragger } = Upload;

const defaultFileInfo = {
  captured_at: "",
  //   pic_title: '',
  //   pic_code: '',
  //   ack_id: '',
  picture_type: "normal",
  picture_obj: "",
};


class UploadGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      pictures: [],
      imagesVisible: false,
      podFilesInfo: [],
      moreFilesInfo: [],
      fileList: [],
      config: {},
      imageUrl: "",
      order: {},
      selectedImages: [],
      showPODEmailsModal: false,
      errors: [],
      sentPods: false,
      pictureInfo: [],
      emailProgress: false,
      fileUploaded: false,
      fileName : "PDF Preview",
      showOrderPreview: false,
      orderPreviewUrl: null,
      orderFileName: null,
      accountConfigurations: [],
      inProgress: false,
      uploadProgress : false,
      uploadedFile:[],
      podPicSettings : [],
      blobUrl: null,
      pageLabels: {}
    };
    this.myViewer = React.createRef();
    this.scroll = this.scroll.bind(this);
    this.onImageSelect = this.onImageSelect.bind(this);
    this.getBase64 = this.getBase64.bind(this);
  }

  scroll(direction) {
    const far = ($(".image-container").width() / 2) * direction;
    const pos = $(".image-container").scrollLeft() + far;
    $(".image-container").animate({ scrollLeft: pos }, 1000);
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      const bloburl = URL.createObjectURL(file);
      this.setState({
        uploadedFile : [file],
        blobUrl: bloburl,
        orderFileName: file.name,
      });
    }, 0);
  };
  
  handlePageLabelsUpdate = (newPageLabels) => {
    this.setState({ pageLabels: newPageLabels });
  };
  moveToLast = () => {
    $(".image-container").animate({ scrollLeft: 5000 }, 1000);
  };
  moveToFirst = () => {
    $(".image-container").animate({ scrollLeft: 0 }, 1000);
  };

  addAttachments = () => {
    let { config, order, takeMultipleSigns, currentOrderPics, refer, invoice } = this.props;
    if (refer === 'invoice') {
      order = {
        id: currentOrderPics.customer_order_id,
        location_id: currentOrderPics.location_id,
        account_id: invoice.account_details
          ? invoice.account_details.id
          : "",
        organization_id: userStore.getStateValue("selectedOrg"),
        pictures: currentOrderPics.pictures,
      };
    }

    if (refer !== 'stop') {
      const orderPictures = order.pictures || [];
      const normalPictures = orderPictures.filter(
        (pic) =>
          (pic.image_type === "normal" || pic.image_type === "signature") &&
          pic.picture &&
          pic.picture.url
      );
      const picSettings = config.pod_pic_settings || [];
      if (_.findIndex(picSettings, [ "pic_code", "signature" ]) < 0) {
        picSettings.push({
          pic_code: "signature",
          mandatory: config.pod_signature,
          required_for: "both",
        });
      }

      const signatureCount = picSettings.filter(
        (pic) => pic.pic_code === "signature" && !pic.isNew
      ).length;

      if (takeMultipleSigns) {
        if (signatureCount >= 1) {
          picSettings.push({
            pic_code: "signature",
            mandatory: config.pod_signature,
            src: "",
            ack_id: `${randomUUID()}_n`,
            isNew: true,
            id: "",
            pic_title: I18n.t("configurations.pod_signature_label"),
          });
        }
      }

      const settingPicCodes = picSettings.map((setting) => setting.pic_code);
      const otherImgs = normalPictures
        .filter(
          (picture) =>
            picture.image_type !== "print" &&
            (isEmpty(picture.pic_code) ||
              !settingPicCodes.includes(picture.pic_code))
        )
        .map((picture) => ({
          captured_at: picture.captured_at,
          converted_img: picture.picture.url,
          id: picture._id,
          ack_id: picture.ack_id,
          picture_type: "normal",
          picture_obj: "",
          pic_title: !isEmpty(picture.pic_title) ? picture.pic_title : "",
          isNew: false,
          fileType: getFileType(picture.picture.url),
        }));
      picSettings.forEach((setting, index) => {
        let pic = "";
        const isSignature = setting.pic_code === "signature";
        if (isSignature) {
          pic = _.find(normalPictures, { image_type: "signature" });
        } else {
          pic = _.find(normalPictures, { pic_code: setting.pic_code });
        }
        if (!isEmpty(pic)) {
          picSettings[ index ] = Object.assign({}, setting, {
            src: pic.picture.url,
            ack_id: pic.ack_id,
            id: pic._id,
            isNew: false,
            fileType: getFileType(pic.picture.url),
          });
        } else {
          picSettings[ index ] = Object.assign({}, setting, {
            src: "",
            ack_id: `${randomUUID()}_n`,
            id: "",
            isNew: true,
          });
        }
        picSettings[ index ].pic_title = isSignature
          ? I18n.t("configurations.pod_signature_label")
          : setting.pic_code;
      });
      this.setState({
        config: {
          ...this.props.config, pod_pic_settings: picSettings,
          filteredPods: picSettings.filter((e) => this.filterPodsCheck(e))
        }, moreFilesInfo: otherImgs, order,
      });
    } else {
      this.setState({ config: {}, moreFilesInfo: order.pictures });
    }
  }

  filterPodsCheck = (e) => {
    if(this.props.refer === 'invoice'){
      const { pod_pictures } = this.props.invoice;
      const currentPodLeg =
        pod_pictures?.length > 0
          ? pod_pictures.find(
              (rec) =>
                rec.location_id === this.props.currentOrderPics.location_id
            )
          : {};
      console.log("currentPod Legs::", currentPodLeg);
      return _.lowerCase(e.required_for) == _.lowerCase(currentPodLeg.type_of_loc) || e.required_for == 'both'
    }
    if (!_.isEmpty(this.props?.currentLocation)) {
      if (!_.isEmpty(this.props?.currentLocation?.type_of_loc)) {
        return _.lowerCase(e.required_for) == _.lowerCase(this.props.currentLocation?.type_of_loc) || e.required_for == 'both'
      }
      else if (!_.isEmpty(this.props?.currentLocation?.type_of_order)) {
        return _.lowerCase(e.required_for) == _.lowerCase(this.props.currentLocation?.type_of_order) || e.required_for == 'both'
      }
    }

    if (this.props?.currentOrder) {
      if (!_.isEmpty(this.props?.currentOrder?.type_of_order)) {
        const key = ['R', 'TR'].includes(this.props?.currentOrder?.type_of_order) ? 'pickup' : ['D', 'TD'].includes(this.props?.currentOrder?.type_of_order) ? 'delivery' : null
        return _.lowerCase(e.required_for) == key || e.required_for == 'both'
      }
    }

  }

  componentDidMount () {
    this.addAttachments();
  }

  componentDidUpdate(prevProps, prevState) {
    const { blobUrl, fileName, showOrderPreview } = this.state;
  
    if ((!_.isEqual(prevProps.config, this.props.config)) || (!_.isEqual(prevProps.order, this.props.order)) || (!_.isEqual(prevProps.currentOrderPics, this.props.currentOrderPics) || (!_.isEqual(prevProps.invoice, this.props.invoice)))) {
      this.addAttachments();
    }
  
    if (blobUrl !== prevState.blobUrl && blobUrl && fileName) {
      this.setState({
        showOrderPreview: true,
        orderPreviewUrl: blobUrl,
        orderFileName: fileName,
      });
    }
  
    if (showOrderPreview && !prevState.showOrderPreview) {
      this.getAccountConfigs();
    }
  }
  
  

  handleClosePreview = () => {
    if (this.state.blobUrl) {
      URL.revokeObjectURL(this.state.blobUrl);
    }
    this.setState({
      showOrderPreview: false,
      orderPreviewUrl: null,
      blobUrl: null,
    });
  };

getAccountConfigs = () => {
  this.setState({ uploadProgress: true });
  const account_id = this.state.order.account_id || '';
  const organization_id = this.state.order.organization_id || '';
  
  fetchAccountConfigs(account_id, organization_id).then((result) => {
    if (result.success) {
      const accountConfigurations = result.account_configurations || [];
      
      const podPicSettings = accountConfigurations.find(
        (config) => config.setting_name === "pod_pic_settings"
      );

      this.setState({ 
        accountConfigurations,
        podPicSettings: podPicSettings ? podPicSettings.setting_value : null,
        uploadProgress: false 
      });
    } else {
      renderAlertMessage(result.errors);
      this.setState({ uploadProgress: false });
    }
  });
};


  getHDImage = (imageList, ackIdNormal) => {
    let ackIdHD = ackIdNormal.replace(/_n$/, '_p');
    return _.find(imageList, { ack_id: ackIdHD }) || null;
 }

  handleDownload = () => {
    const { selectedImages, order } = this.state;
    const pictures = _.get(order, 'pictures', []) || [];
    const printPictures = selectedImages.map(picId => {
      const picObj = _.find(pictures, { _id: picId });
      if (!picObj?.ack_id) {
        return picId;
      }
      const hdImage = this.getHDImage(pictures, picObj.ack_id);
      return hdImage?._id || picId;
    });
    this.setState({ inProgress: true });
      const refer_id =  order.order_id;
      const location_id = this.props.currentLocation === null ? this.props.order.cs_location.id : this.props.currentLocation.id;
      const pictures_ids = printPictures?.length > 0 ? printPictures.join(',') : ''
    downloadOrderPictures(refer_id,location_id,pictures_ids)
      .then((result) => {
        if (result.success) {
          printPDF(result.data.combine_attachments_pdf)
          // base64ToPDF(
          //   result.data.combine_attachments_pdf,
          //   `${"Attachements"}_${moment().format(
          //     "MM-DD-YYYY"
          //   )}`
          // );
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  handleUploadPdfPictures = () => {
    const { order, pageLabels,uploadedFile } = this.state;
    const data = {
      account_id : order.account_id,
      organization_id : order.organization_id,
      order_id : order.order_id,
      page_labels : pageLabels,
      uploaded_file : uploadedFile,
      location_id : this.props.currentLocation === null ? this.props.order.cs_location.id : this.props.currentLocation.id
    }

    this.setState({ uploadProgress: true });
    PdfPreviewPictures(data)
    .then((result) => {
        if (result.success) {
            alertMessage("Added Successfully", 'success', 5);
            this.setState({showOrderPreview: false})
            this.props.getOrderDetails()

        } else {
            renderAlertMessage(result.errors);
            this.setState({ uploadProgress: false });
        }
    });
};

  



  onClose = () => {
    const podSet = this.state.config?.pod_pic_settings ? this.state.config.pod_pic_settings.length > 0 ? this.state.config.pod_pic_settings.map(
      (pic) => {
        if (pic.isNew) {
          return { ...pic, src: "" };
        }
        return pic;
      }
    ) : [] : []
    const resettedPods = {
      ...this.state.config,
      pod_pic_settings: podSet,
      filteredPods: podSet.filter((e) => this.filterPodsCheck(e))
    }

    this.setState(
      {
        imagesVisible: false,
        podFilesInfo: [],
        moreFilesInfo : this.state.moreFilesInfo.filter(pic => !pic.isNew),
        fileList: [],
        errors: [],
        config: resettedPods,
        pictureInfo: [],
      },
      () => {
        this.props.refreshOrder();
      }
    );
  };

  getBase64 = (img, ackId) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.addEventListener("load", () => resolve({ ackId, result: reader.result }));
      reader.addEventListener("error", (error) => reject(error));
    });
  };

  getBase64ForMoreFiles = (img, ackId, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(ackId, reader.result));
    reader.readAsDataURL(img);
  };

  handleUploadImages = () => {
    this.setState({
      currentFileInfo: { ...defaultFileInfo },
      imagesVisible: true,
    });
  };

  resetFileObject = () => {
    this.setState({
      currentFileInfo: {
        csv_file: "",
        process_by_columns: true,
        contains_header: true,
        account_code: "",
        warehouse_code: "",
      },
    });
  };

  handleMoreFileChange = (info) => {
    this.setState({
      inProgress: true,
      errors: [],
    });
    const { status } = info.file;
    let fileList = [...info.fileList];
    if (status !== "uploading") {
      // info.fileList.splice(0,1);
      const ackId = `${randomUUID()}_n`;
      const moreFilesInfo = [...this.state.moreFilesInfo];
      moreFilesInfo.push(
        Object.assign({}, defaultFileInfo, {
          picture_obj: info.file.originFileObj,
          captured_at: moment().format(),
          ack_id: ackId,
          converted_img: "",
          isNew: true,
          fileType: "",
        })
      );
      this.setState(
        {
          moreFilesInfo,
        },
        () => {
          this.getBase64ForMoreFiles(info.file.originFileObj, ackId, (refId, image) => {
            const fileIndex = _.findIndex(this.state.moreFilesInfo, [
              "ack_id",
              refId,
            ]);
            if (fileIndex >= 0) {
              const currentFiles = [...this.state.moreFilesInfo];
              currentFiles[fileIndex].converted_img = image;
              currentFiles[fileIndex].fileType = getFileType(image);
              this.setState({
                moreFilesInfo: currentFiles,
              });
            }
            this.moveToLast();
          });
        }
      );
    }

    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        //   this.getBase64(info.file.originFileObj, (image) => {
        //     file.converted_img = image;
        //   });
        file.url = file.response.url;
        file.ack_id = `${randomUUID()}_n`;
      }
      return file;
    });

    this.setState({ fileList: fileList || [], inProgress: false });
  };

  handleOnChange = (element, value) => {
    this.setState({
      currentFileInfo: Object.assign({}, this.state.currentFileInfo, {
        [element]: value,
      }),
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.props.refreshOrder();
  };

  addNewSignature = () => {
    if (this.props.takeMultipleSigns === true) {
      const newSignature = {
        pic_code: "signature",
        mandatory: "true",
        src: "",
        ack_id: `${randomUUID()}_n`,
        isNew: true,
        id: "",
        pic_title: I18n.t("configurations.pod_signature_label"),
        required_for: "both",
      };
      this.setState({
        config: { ...this.state.config, pod_pic_settings: [ ...this.state.config.pod_pic_settings, newSignature ] },
      });
    }
  };

  getReferId = (refer = 'order') => {
    if (refer === 'stop') {
      return this.props.selectedStop;
    }
    else if(refer === 'release'){
      return this.props.release_id;
    }
      return this.state.order.id;
  }

  getOpCode = (refer = 'order') => {
    if (refer === 'stop') {
      return 'ORRDSP';
    }
    else if(refer === 'release'){
      return 'OREDP';
    }
      return 'NRDSOP';
  }

  handleSave = () => {
    this.setState({ inProgress: true });
    const moreFilesInfo = this.state.moreFilesInfo
      ? [...this.state.moreFilesInfo]
      : [];
    const podFilesInfo = this.state.podFilesInfo
      ? [...this.state.podFilesInfo]
      : [];
    const moreFiles = moreFilesInfo.filter((pic) => pic.isNew);
    const orderPics = [].concat(moreFiles, podFilesInfo);
    if (orderPics.length > 0) {
      const pics = orderPics.map((picture) => {
        const pic = Object.assign({}, picture);
        delete pic.converted_img;
        delete pic.fileType;
        delete pic.isNew;
        return pic;
      });
      const itemPictures = {
        item_pictures: pics.map((pic) => { return { ...pic, refer_id: this.getReferId(this.props.refer) } }),
        refer_id: this.getReferId(this.props.refer),
      };
      const data = {
        refer: this.props.refer == 'stop' ? 'stop' : this.props.refer == 'release' ? 'release' : "order",
        pictures: [itemPictures],
        from_web: "true",
      };
      if(this.props.currentLocation && this.props.currentLocation.id){
        data.location_id = this.props.currentLocation.id;
      }
      if(this.state.order?.location_id){
        data.location_id = this.state.order.location_id;
      }
      this.props.uploadApi(data).then((result) => {
        if (result.success) {
          if (
            result.orders &&
            result.orders.errors &&
            result.orders.errors.length > 0
          ) {
            this.setState({
              errors: result.orders.errors,
              inProgress: false,
              fileList: [],
            });
          } else {
            this.setState({
              inProgress: false,
              errors: [],
              fileList: [],
              podFilesInfo: [],
              moreFilesInfo: this.props.refer === "stop" ?  stopLvlPicturesFormat(result.orders.pictures_obj.pictures[ 0 ].pictures) : this.props.refer === "release" ? stopLvlPicturesFormat(result.pictures) : [],
            } , () => {
              if(this.props.refer === "stop") {
                this.props.handleStopAttachmentsSuccess(result.orders.pictures_obj.pictures[ 0 ].pictures);
              }else if(this.props.refer === "release") {
                this.props.handleReleaseAttachmentsSuccess(result.pictures);
              }
            });
            this.handleSaveSuccess(I18n.t("messages.uploaded"));
            this.addNewSignature();
          }
        } else {
          this.setState({
            errors:
              result.orders && result.orders.errors
                ? result.orders.errors
                : result.errors || [],
            inProgress: false,
            fileList: [],
          });
        }
      });
    } else {
      this.setState({ errors: ["Please upload atleast one image"] });
    }
  };

  openLightbox = (event, index, element, imageElement) => {
    event.preventDefault();
    if (element === "moreFilesInfo") {
      const fileList = [...this.state[element]];
      if (
        fileList[index] &&
        fileList[index][imageElement] &&
        !isEmpty(fileList[index].fileType)
      ) {
        const { fileType } = fileList[index];
        if (fileType === "image") {
          this.setState({
            currentImage: 0,
            lightboxIsOpen: true,
            pictures: [{ src: fileList[index][imageElement] }],
          });
        } else if (fileList[index].isNew === true) {
          const buffer = fileList[index][imageElement].split(",")[1];
          const mimeType = base64MimeType(fileList[index][imageElement]);
          // .replace("data:", "")
          // .replace(/^.+,/, "");
          const pdfWindow = window.open("");
          pdfWindow.document.write(
            `<iframe width='100%' height='100%' src='data:${mimeType};base64, ${encodeURI(
              buffer
            )}'></iframe>`
          );
          if (mimeType !== "application/pdf") {
            setInterval(() => {
              pdfWindow.close();
            }, 10 * 1000);
          }
        } else {
          window.open(fileList[index][imageElement], "_blank");
        }
      }
      // const cleaneData = _.compact(fileList);
      // const cleanedPictures = cleaneData.map(pic => ({ src: pic[imageElement] }));
      // this.setState({
      //   currentImage: index,
      //   lightboxIsOpen: true,
      //   pictures: cleanedPictures,
      // });
    } else {
      const { config } = this.state;
      const fileList = config[ element ][ index ]
        ? config[ element ][ index ]
        : {};
      const cleaneData = _.isObject(fileList) ? [{...fileList}] :
      (_.isArray(fileList) ? _.compact(fileList) : []);
      const fileObj = cleaneData.length > 0 ? cleaneData[0] : {};
      if(fileObj.fileType === "image") {
        const cleanedPictures = cleaneData.map((pic) => ({
          src: pic[imageElement],
        }));
        this.setState({
          currentImage: 0,
          lightboxIsOpen: true,
          pictures: cleanedPictures,
        });
      } else if (fileObj.isNew === true) {
          const buffer = fileObj[imageElement].split(",")[1];
          const mimeType = base64MimeType(fileObj[imageElement]);
          // .replace("data:", "")
          // .replace(/^.+,/, "");
          const pdfWindow = window.open("");
          pdfWindow.document.write(
            `<iframe width='100%' height='100%' src='data:${mimeType};base64, ${encodeURI(
              buffer
            )}'></iframe>`
          );
          if (mimeType !== "application/pdf") {
            setInterval(() => {
              pdfWindow.close();
            }, 10 * 1000);
          }
        } else {
          window.open(fileObj[imageElement], "_blank");
        }
      
    }
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  deleteNavigation = (ackId, type, isNew) => {
    if (isNew) {
      this.deleteImage(ackId, type);
      this.setState({
        pictureInfo: this.state.pictureInfo.filter(pic => pic.ack_id !== ackId)
      })
    } else {
      const data = {
        refer: this.props.refer == 'stop' ? 'stop' : this.props.refer == 'release' ? 'release': "order",
        refer_id: this.getReferId(this.props.refer),
        ack_id: ackId,
        account_id: this.state.order.account_id,
        organization_id: userStore.getStateValue('selectedOrg')
      };
      this.props.deleteAttachments(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.deleted"));
          this.deleteImage(ackId, type);
          this.setState({
            pictureInfo: this.state.pictureInfo.filter(pic => pic.ack_id !== ackId)
          })
          this.props.deleteReleaseAttachments(ackId)
        } else {
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  deleteImage = (ackId, type) => {
    if (type === "moreImages") {
      const moreFilesInfo = [...this.state.moreFilesInfo];
      const fileObjIndex = _.findIndex(moreFilesInfo, ["ack_id", ackId]);
      if (fileObjIndex >= 0) {
        moreFilesInfo.splice(fileObjIndex, 1);
      }
      this.setState({
        moreFilesInfo,
      });
    } else {
      const podFilesInfo = [...this.state.podFilesInfo];
      const fileObjIndex = _.findIndex(podFilesInfo, ["ack_id", ackId]);
      const { config } = this.state
      const podPicSettings =
        config && config.pod_pic_settings ? [...config.pod_pic_settings] : [];
      const podPicSettingsIndex = _.findIndex(podPicSettings, [
        "ack_id",
        ackId,
      ]);
      if (fileObjIndex >= 0) {
        podFilesInfo.splice(fileObjIndex, 1);
      }
      if (podPicSettingsIndex >= 0) {
        podPicSettings[podPicSettingsIndex].src = "";
        podPicSettings[podPicSettingsIndex].isNew = true;
        config.pod_pic_settings = podPicSettings;
        podPicSettings[podPicSettingsIndex].fileType = "";
      }
      this.setState({
        podFilesInfo,
        config,
      });
    }
  };
  uploadButton = (title, isMandatory) => (
    <div>
      <Icon type={this.state.loading ? "loading" : "plus"} />
      <div className="ant-upload-text textBold textCaptilize">
        {isMandatory ? <sup className="textRed">*</sup> : ""}
        {title}
      </div>
    </div>
  );

  handleChange = (info, record) => {
    if (info.file.status !== "uploading") {
      const ackId = record.ack_id ? record.ack_id : `${randomUUID()}_n`;
      const picName = info.file.name;
      const podFileObj = {
        ...defaultFileInfo,
        picture_obj: info.file.originFileObj,
        captured_at: moment().format(),
        ack_id: ackId,
        converted_img: "",
        pic_title: record.pic_title || "",
        pic_code: record.pic_code || "",
        picture_type:
          record.pic_code === "signature"
            ? "signature"
            : defaultFileInfo.picture_type,
        isNew: false,
        fileType: "",
        fileName: picName,
      };
  
      // Update pictureInfo in state
      this.setState({ pictureInfo: [...this.state.pictureInfo, podFileObj] });
  
      if (record.ack_id) {
        const fileIndex = _.findIndex(this.state.podFilesInfo, ["ack_id", record.ack_id]);
        if (fileIndex >= 0) {
          // Find the file index and update the file info
          const updatedFileInfo = this.state.podFilesInfo.map((file, index) => {
            if (index === fileIndex) {
              return {
                ...file,
                converted_img: "",
                captured_at: moment().format(),
                picture_obj: info.file.originFileObj,
                fileType: "",
                fileName: picName,
              };
            }
            return file;
          });
          this.setState({ podFilesInfo: updatedFileInfo });
        } else {
          this.setState({ podFilesInfo: [...this.state.podFilesInfo, podFileObj] });
        }
      } else {
        this.setState({ podFilesInfo: [...this.state.podFilesInfo, podFileObj] });
      }
  
      // Use getBase64 to convert the image
      this.getBase64(info.file.originFileObj, ackId).then((data) => {
          const { ackId, result } = data;
          const fileIndex = _.findIndex(this.state.podFilesInfo, ["ack_id", ackId]);
          const { config } = this.state;
          const podPicSettings = config && config.pod_pic_settings ? [...config.pod_pic_settings] : [];
          const podPicSettingsIndex = _.findIndex(podPicSettings, ["ack_id", ackId]);
          const fileType = getFileType(result);
  
          if (fileIndex >= 0) {
            const updatedFileInfo = this.state.podFilesInfo.map((file, index) => {
              if (index === fileIndex) {
                return {
                  ...file,
                  converted_img: result,
                  fileType,
                };
              }
              return file;
            });
            this.setState({ podFilesInfo: updatedFileInfo });
          }
  
          if (podPicSettingsIndex >= 0) {
            const updatedPodPicSettings = podPicSettings.map((setting, index) => {
              if (index === podPicSettingsIndex) {
                return {
                  ...setting,
                  src: result,
                  fileType,
                };
              }
              return setting;
            });
            this.setState({
              config: {
                ...config, pod_pic_settings: updatedPodPicSettings,
                filteredPods: updatedPodPicSettings.filter((e) => this.filterPodsCheck(e))
              },

            });
          }
  
          this.moveToFirst();
        })
        .catch((error) => {
          console.error("Error while converting image:", error);
        });
    }
  };
  
  onImageSelect = (image) => {
    let selectedImages = [...this.state.selectedImages]; // Use let instead of const
    const index = selectedImages.indexOf(image);
    if (index === -1) {
      selectedImages.push(image);
    } else {
      selectedImages = selectedImages.filter((img) => img !== image); // Now this reassignment is valid
    }
    this.setState({ selectedImages });
  };

  sendPodImagesToEmails = async (emails, description) => {
    const currentLocation = _.get(this.props.order, "type_of_order", "") || _.get(this.props.currentOrderPics, "type_of_order", "") || ""
    this.setState({emailProgress: true})
    const { selectedImages, order } = this.state;
    const {refer , selectedStop } = this.props;
    const { order_id } = order;
    const data = {
      customer_order_id: this.getReferId(this.props.refer),
      picture_ids: selectedImages,
      emails,
      description,
      refer_type : refer === 'invoice' ? 'order' : refer,
    }
    if (currentLocation === "T") {
      data.location_id = this.props.currentLocation?.id;
    }

    const response = await sendPodImagesToEmails(data);
    if (response.success) {
      alertMessage("Images sent successfully");
      this.setState({
        selectedImages: [],
        showPODEmailsModal: false,
        sentPods: true,
        emailProgress: false,
      } , () => { this.setState({ sentPods: false }) });
      this.props.closeGallery();
    } else {
      this.setState({emailProgress: false})
      alertMessage(response.error, "error", 10);
    }
  };
  openPODEmailsModal = () => {
    this.setState({
      showPODEmailsModal: true,
    });
  };
  renderPODEmailsModal = () => {
    return (
      <PODEmailsModal
        closeModal={this.closePODEmailsModal}
        onSend={this.sendPodImagesToEmails}
        muteButton={this.state.emailProgress}
      />
    );
  };
  closePODEmailsModal = () => {
    this.setState({
      showPODEmailsModal: false,
      selectedImages: [],
    });
  };

  fetchTypeOfOrder = () => {
    const { refer , invoice } = this.props;
    const { order  } = this.state;
    switch (refer) {
      case 'order':
      case 'release':
        return order.type_of_order;
      case 'invoice':
        const { location_id } = order;
        const { pod_pictures } = invoice;
        const orderPodPictures = pod_pictures.filter(pod => pod?.location_id === location_id);
        return orderPodPictures[ 0 ].type_of_order;
      default:
        return null;
    }
  };


  renderPreviewModal = () => {
    const { showOrderPreview, blobUrl } = this.state;

    return (
      <BaseModal
        title={'PDF Preview'}
        visible={showOrderPreview}
        style={{top:20}}
        onCancel={this.handleClosePreview}
        maskClosable={false}
        width="65%"
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button
              icon='close'
              className="ant-btn ant-btn-danger"
              onClick={this.handleClosePreview}
            >
              Cancel
            </Button>
            <Button
              icon='upload'
              className="ant-btn ant-btn-primary"
              onClick={this.handleUploadPdfPictures}
              loading={this.state.uploadProgress}
            >
              Upload
            </Button>
          </div>
        }
        destroyOnClose
      >
        <PDFPreviewComponent
          pdfFile={blobUrl}
          podPicSettings={this.state.podPicSettings}
          onPageLabelsUpdate={this.handlePageLabelsUpdate} // Pass the callback to the child
          organizationSettings = {this.props.organizationSettings}
        />
      </BaseModal>
    );
  };

  render() {
    const props = {
      name: "file",
      onChange: this.handleMoreFileChange,
      multiple: true,
      // accept: 'image/*',
    };
    const { config,fileUploaded,fileList } = this.state
    const configImgaes =
      config && config.pod_pic_settings
        ? config.pod_pic_settings.filter(
            (file) => !isEmpty(file.src)
          ).length
        : 0;
    const otherImages = this.state.moreFilesInfo
      ? this.state.moreFilesInfo
      : [];
    const totalImages = configImgaes + otherImages.length;
    const newImages = otherImages.filter((file) => file.isNew === true).length;
    const newPodFiles = this.state.podFilesInfo
      ? this.state.podFilesInfo.length
      : [];
    const newFiles = newPodFiles + newImages;
    const deleteOpCode = this.getOpCode(this.props.refer)
    return (
      <Fragment>
        {this.state.errors && this.state.errors.length > 0 && (
          <Row className="marginBottom20">
            <Col xs={24}>{FormErrors(this.state.errors)}</Col>
          </Row>
        )}
        <Row className="marginBottom20">
          <Col xs={24}>
            { config && this.props.shouldCollectPods &&
              config.pod_pic_settings &&
              config.pod_pic_settings.length > 0 && (
                <FormItem label="Predefined Proof of delivery">
                  <PODfiles
                  pods={this.state.config.filteredPods}
                    openLightbox={this.openLightbox}
                    handleChange={this.handleChange}
                    deleteNavigation={this.deleteNavigation}
                    config={config}
                    type_of_order={this.fetchTypeOfOrder()}
                    pictureInfo={this.state.pictureInfo}
                  />
                </FormItem>
              )}
          </Col>
        </Row>
        <div>
        <FormItem label="Add more attachments" style={{ marginTop: 20 }}>
          <Dragger
            {...props}
            fileList={fileList}
            showUploadList={!(fileList.length > 0)}
            previewFile
            customRequest={this.dummyRequest}
            // disabled={this.state.fileList.length > 0}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag attachments to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for bulk attachments upload.{" "}
            </p>
          </Dragger>
        </FormItem>

        {fileList.length === 1 && fileList[0].type === "application/pdf" && (
          <div>
            {this.renderPreviewModal()}
          </div>
        )}
      </div>

        <Row>
          <Col xs={24} className="alignCenter margin10">
            {/* {this.state.fileList.length > 0 && <Col xs={24}>
                                            {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel)}
                                        </Col>
                                        } */}
            {newFiles > 0 && (
              <h4 className="smallText textBold alignCenter">
                Total attachments to be saved&nbsp;:&nbsp;
                {newFiles}
              </h4>
            )}
            <Button
              type="danger"
              className="margin10"
              onClick={this.onClose}
              disabled={!(newFiles > 0)}
              icon='close-circle'
            >
              {I18n.t("general.clear")}
            </Button>
            <Button
              type="primary"
              className="margin10"
              onClick={this.handleSave}
              loading={this.state.inProgress}
              disabled={!(newFiles > 0)}
              icon='upload'
            >
              {I18n.t("general.upload")}
            </Button>
          </Col>
        </Row>
        {false && (
          <Row>
            <Col xs={24} className="ant-upload-list-picture-card">
              { config.pod_pic_settings &&
                config.pod_pic_settings
                  .filter((file) => !isEmpty(file.src))
                  .map((file, index) => (
                    <div className="ant-upload-list-item marginTop20">
                      <div className="ant-upload-list-item-info">
                        <span>
                          <span
                            className="ant-upload-list-item-thumbnail"
                            rel="noopener noreferrer"
                          >
                            {file.fileType === "image" ? (
                              <img
                                src={file.src}
                                alt="Pod"
                                className="ant-upload-list-item-image"
                              />
                            ) : (
                              <Icon
                                type={getFileIcon(file.src)}
                                style={{
                                  fontSize: 108,
                                }}
                                theme="twoTone"
                              />
                            )}
                          </span>
                        </span>
                      </div>
                      <span className="ant-upload-list-item-actions">
                        {checkServiceExistance(deleteOpCode) && (
                          <Popconfirm
                            placement="topRight"
                            title={I18n.t("messages.delete_confirm")}
                            onConfirm={() =>
                              this.deleteNavigation(
                                file.ack_id,
                                "pod_pics",
                                file.isNew
                              )
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteIcon/>
                            &nbsp;&nbsp;&nbsp; 
                          </Popconfirm>
                        )}
                        <Icon
                          type={file.fileType === "image" ? "eye" : "download"}
                          onClick={(event) =>
                            this.openLightbox(
                              event,
                              index,
                              "moreFilesInfo",
                              "converted_img"
                            )
                          }
                        />
                      </span>
                      <div className="picTag textCaptilize">
                        {file.pic_title && file.pic_title !== "N/A"
                          ? file.pic_title
                          : "Attachment"}
                      </div>
                    </div>
                  ))}

              {this.state.moreFilesInfo.map((file, index) => (
                <div className="ant-upload-list-item marginTop20">
                  <div className="ant-upload-list-item-info">
                    <span>
                      <span
                        className="ant-upload-list-item-thumbnail"
                        rel="noopener noreferrer"
                      >
                        {file.converted_img ? (
                          file.fileType === "image" ? (
                            <img
                              src={file.converted_img}
                              alt="Pod"
                              className="ant-upload-list-item-image"
                            />
                          ) : (
                            <Icon
                              type={getFileIcon(file.fileType)}
                              style={{
                                fontSize: 108,
                              }}
                              theme="twoTone"
                            />
                          )
                        ) : (
                          <Icon
                            type="loading"
                            style={{ fontSize: 30 }}
                            className="textBold ant-upload-list-item-image"
                          />
                        )}
                      </span>
                    </span>
                  </div>
                  <span className="ant-upload-list-item-actions">
                    {checkServiceExistance(deleteOpCode) && (
                      <Popconfirm
                        placement="topRight"
                        title={I18n.t("messages.delete_confirm")}
                        onConfirm={() =>
                          this.deleteNavigation(
                            file.ack_id,
                            "moreImages",
                            file.isNew
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteIcon/>
                        &nbsp;&nbsp;&nbsp; 
                      </Popconfirm>
                    )}
                    <Icon
                      type={file.fileType === "image" ? "eye" : "download"}
                      onClick={(event) =>
                        this.openLightbox(
                          event,
                          index,
                          "moreFilesInfo",
                          "converted_img"
                        )
                      }
                    />
                  </span>
                  <div className="picTag">
                    {file.pic_title && file.pic_title !== "N/A"
                      ? file.pic_title
                      : "Attachment"}
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        )}
        {totalImages > 0 && (
          <FormItem label="Uploaded attachments">
            <Row>
              <Col xs={24} className="scrollBlock">
                <div className="main">
                  <Row>
                    <Col xs={2} className="center buttonsblock">
                      <Icon
                        type="left-circle"
                        theme="filled"
                        className="prev"
                        onClick={() => this.scroll(-1)}
                      />
                      {/* <Button className="prev" icon="left" shape="circle" type="primary"  /> */}
                    </Col>

                    <Col xs={20}>
                      <div className="image-container">
                        {
                          config.pod_pic_settings &&
                          config.pod_pic_settings.map(
                              (file, index) =>
                                !isEmpty(file.src) ? (
                                  <Fragment key={`fgallery${file.ack_id}`}>
                                    <div
                                      className="ant-upload-list-item"
                                      key={`gallery${file.ack_id}`}
                                    >
                                      <div className="ant-upload-list-item-info">
                                        <span
                                          className="ant-upload-list-item-thumbnail"
                                          rel="noopener noreferrer"
                                        >
                                          {file.fileType === "image" ? (
                                            <img
                                              src={file.src}
                                              alt="Pod"
                                              className="ant-upload-list-item-image"
                                            />
                                          ) : (
                                            <Icon
                                              type={getFileIcon(file.fileType)}
                                              style={{
                                                fontSize: 80,
                                                margin: 10,
                                              }}
                                              theme="twoTone"
                                              className="marginTop10"
                                            />
                                          )}
                                        </span>
                                      </div>
                                      <span className="ant-upload-list-item-actions">
                                        <Icon
                                          type={
                                            file.fileType === "image"
                                              ? "eye"
                                              : "download"
                                          }
                                          onClick={(event) =>
                                            this.openLightbox(
                                              event,
                                              index,
                                              "pod_pic_settings",
                                              "src"
                                            )
                                          }
                                        />
                                        {checkServiceExistance(deleteOpCode) && (
                                          <Popconfirm
                                            placement="topRight"
                                            title={I18n.t(
                                              "messages.delete_confirm"
                                            )}
                                            onConfirm={() =>
                                              this.deleteNavigation(
                                                file.ack_id,
                                                "pod_pics",
                                                file.isNew
                                              )
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Tooltip>
                                              <DeleteIcon/>
                                              &nbsp;&nbsp;&nbsp;
                                            </Tooltip>
                                          </Popconfirm>
                                        )}
                                      </span>
                                      {/* <div className="picTag textCaptilize">
																				{
																					file.pic_code
																				}
																			</div> */}
                                      {checkServiceExistance("SPOD") && (
                                        <SelectImage
                                          file={file}
                                          onImageSelect={this.onImageSelect}
                                          title={file.pic_code || "Attachment"}
                                          sentPods={this.state.sentPods}
                                        />
                                      )}
                                    </div>
                                  </Fragment>
                                ) : null
                            )
                        }
                        {this.state.moreFilesInfo.map((file, index) => (
                          <Fragment key={index}>
                            <div
                              className="ant-upload-list-item"
                              key={`gallery${file.ack_id}`}
                            >
                              <div className="ant-upload-list-item-info">
                                <span
                                  className="ant-upload-list-item-thumbnail"
                                  rel="noopener noreferrer"
                                >
                                  {file.converted_img ? (
                                    file.fileType === "image" ? (
                                      <img
                                        src={file.converted_img}
                                        alt="Pod"
                                        className="ant-upload-list-item-image"
                                      />
                                    ) : (
                                      <Icon
                                        type={getFileIcon(file.fileType)}
                                        style={{
                                          fontSize: 108,
                                          margin: 10,
                                        }}
                                        theme="twoTone"
                                        className="marginTop10"
                                      />
                                    )
                                  ) : (
                                    <Icon
                                      type="loading"
                                      style={{
                                        fontSize: 30,
                                      }}
                                      className="textBold"
                                    />
                                  )}
                                </span>
                              </div>
                              <span className="ant-upload-list-item-actions">
                                <Icon
                                  type={
                                    file.fileType === "image"
                                      ? "eye"
                                      : "download"
                                  }
                                  onClick={(event) =>
                                    this.openLightbox(
                                      event,
                                      index,
                                      "moreFilesInfo",
                                      "converted_img"
                                    )
                                  }
                                />
                                {checkServiceExistance(deleteOpCode) && (
                                  <Popconfirm
                                    placement="topRight"
                                    title={I18n.t("messages.delete_confirm")}
                                    onConfirm={() =>
                                      this.deleteNavigation(
                                        file.ack_id,
                                        "moreImages",
                                        file.isNew
                                      )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <DeleteIcon/>
                                    &nbsp;&nbsp;&nbsp;
                                  </Popconfirm>
                                )}
                              </span>
                              {checkServiceExistance("SPOD") && (
                                <SelectImage
                                  file={file}
                                  onImageSelect={this.onImageSelect}
                                  title={
                                    file.pic_title && file.pic_title !== "N/A"
                                      ? file.pic_title
                                      : "Attachment"
                                  }
                                  sentPods={this.state.sentPods}
                                />
                              )}
                            </div>
                            {/* <span className="bottomButton">
                                <Popconfirm
                                  placement="topRight"
                                  title={I18n.t('messages.delete_confirm')}
                                  onConfirm={() => this.deleteNavigation(file.ack_id, 'moreImages', file.isNew)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Icon
                                    type='delete'
                                  />
                                </Popconfirm>&nbsp;&nbsp;
                                <Icon type={file.fileType === 'image' ? 'eye' : 'download'} onClick={event => this.openLightbox(event, index, 'moreFilesInfo', 'converted_img')} />
                                  </span> */}
                          </Fragment>
                        ))}
                      </div>
                      {checkServiceExistance("SPOD") && (
                        <Button
                          type="primary"
                          size={"default"}
                          style={{
                            marginTop: "2rem",
                          }}
                          disabled={this.state.selectedImages.length === 0}
                          onClick={this.openPODEmailsModal}
                          icon='mail'
                        >
                          Send to Email
                        </Button>
                      )}
                      {this.props.showPrintedAttachments && (
                      <Button
                          className="buttonCerulean"
                          size={"default"}
                          style={{
                            marginTop: "2rem",
                            marginLeft: "1rem",
                          }}
                          disabled={this.state.selectedImages.length === 0}
                          onClick={this.handleDownload}
                          loading={this.state.inProgress}
                          icon='printer'
                        >
                          Print
                        </Button>
                        )}
                    </Col>

                    <Col xs={2} className="center buttonsblock">
                      <Icon
                        type="right-circle"
                        theme="filled"
                        className="next"
                        onClick={() => this.scroll(1)}
                      />
                      {/* <Button className="next" icon="right" shape="circle" type="primary" onClick={this.scroll.bind(null, 1)} /> */}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </FormItem>
        )}

        {this.state.lightboxIsOpen && this.state.pictures.length > 0 && (
          <Gallery
            images={this.state.pictures}
            onClose={this.closeLightbox}
            currentImage={this.state.currentImage}
          />
        )}
        {this.state.showPODEmailsModal && this.renderPODEmailsModal()}
      </Fragment>
    );
  }
}

UploadGallery.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.string).isRequired,
  refreshOrder: PropTypes.func.isRequired,
  uploadApi: PropTypes.func.isRequired,
  config: PropTypes.string.isRequired,
  order: PropTypes.shape().isRequired,
  closeGallery: PropTypes.func.isRequired,
  shouldCollectPods: PropTypes.bool.isRequired,
  refer: PropTypes.string.isRequired,
  takeMultipleSigns: PropTypes.bool.isRequired,
  selectedStop: PropTypes.string,
  handleStopAttachmentsSuccess : PropTypes.func,
  deleteAttachments : PropTypes.func,
  handleReleaseAttachmentsSuccess : PropTypes.func,
  deleteReleaseAttachments : PropTypes.func,
};

UploadGallery.defaultProps = {
  shouldCollectPods: true,
  refreshOrder: () => {},
  refer: "order",
  takeMultipleSigns: false,
  handleStopAttachmentsSuccess : () => {},
  deleteAttachments : () => {},
  handleReleaseAttachmentsSuccess : () => {},
  deleteReleaseAttachments : () => {},
};

export default UploadGallery;
